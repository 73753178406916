export function getItemSizeStyle(param, isDetached) {
    let { width, height } = param;
    const detachedStyles = isDetached ? {
        position: 'absolute',
        left: height ? '0' : `-${width || 0}px`,
        right: height ? '0' : void 0,
        top: width ? '0' : `-${height || 0}px`,
        bottom: width ? '0' : void 0
    } : {};
    return {
        width: width ? `${width}px` : void 0,
        height: height ? `${height}px` : void 0,
        ...detachedStyles
    };
}
