import { toRef, computed } from 'vue';
import { useRouter } from 'vue-router';
import { getLocationHref } from '@leon-hub/service-locator-env';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { getSportEventDetailsSeoMetaInfo, getSportEventDetailsSeoMetaInfoFromPostStatistic } from 'web/src/modules/sportline/submodules/event-details/utils';
export function useSportlineEventDetailsRoutePage() {
    const router = useRouter();
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const isLoaded = toRef(sportlineEventDetailsStore, 'isLoaded');
    const isReadyToDisplayContent = toRef(sportlineEventDetailsStore, 'isReadyToDisplayContent');
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const betlinePostMatchStatistics = toRef(sportlineEventStatisticStore, 'betlinePostMatchStatistics');
    const metaParameters = computed(()=>{
        if (!isLoaded.value || !isReadyToDisplayContent.value) return {};
        if (sportlineEvent.value) return getSportEventDetailsSeoMetaInfo(sportlineEvent.value, {
            vm: {
                $router: router
            }
        });
        if (betlinePostMatchStatistics.value) return getSportEventDetailsSeoMetaInfoFromPostStatistic(betlinePostMatchStatistics.value, {
            url: getLocationHref()
        });
        return {};
    });
    function clearDataOnLeavingPage() {
        sportlineEventDetailsStore.clearDetails();
        // clear event info after leave page (need for leave page watch in store)
        sportlineEventDetailsStore.setPageIdentifier({});
        sportlineEventStatisticStore.setPageIdentifier({});
    }
    return {
        sportlineEvent,
        isLoaded,
        isReadyToDisplayContent,
        metaParameters,
        clearDataOnLeavingPage
    };
}
