import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { Theme } from '@leon-hub/api-sdk';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { useTheme } from 'web/src/modules/theme/composables';
import { SportradarFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { getDefaultWidgetUrl } from 'web/src/modules/framed-app/utils';
import { SportradarLiveScorePostMessageBusInitiator, SportradarLiveScorePostMessageEvent } from 'web/src/modules/framed-app/components/SportradarLiveScoreFramedWidget/utils/SportradarLiveScorePostMessageEvent';
import { SportradarStatisticPostMessageBusInitiator, SportradarStatisticPostMessageEvent } from 'web/src/modules/framed-app/components/SportradarStatisticsFramedWidget/utils/SportradarStatisticPostMessageEvent';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
export function useSportradarWidgetRoutePage(props) {
    const widgetsStore = useSportlineWidgetsStore();
    const internalWidgetLoaded = ref(false);
    const isIframeVisible = ref(true);
    const errorMessage = 'Unknown sportradar widget';
    const widgetsConfig = computed(()=>{
        switch(props.type){
            case 'livescore':
                return widgetsStore.sportradarLiveScoreFrameConfigFinal;
            case 'statistics':
                return widgetsStore.sportradarStatisticsConfigFinal;
            default:
                throw new Error(errorMessage);
        }
    });
    function composeWidgetUrl(widgetUrl, customLang) {
        const widgetLang = customLang || 'en';
        let fullWidgetUrl = widgetUrl;
        const { theme } = useTheme();
        if (theme.value === Theme.LIGHT) fullWidgetUrl += 'light';
        fullWidgetUrl += `/${widgetLang}`;
        return fullWidgetUrl;
    }
    const additionalQueryStringParameters = computed(()=>{
        let widgetUrl = '';
        if (widgetsConfig.value?.productionUrl) widgetUrl = composeWidgetUrl(widgetsConfig.value?.productionUrl, widgetsConfig.value?.customLang);
        return [
            {
                [SportradarFramedWidgetProperties.ENCODED_URL]: encodeURIComponent(widgetUrl)
            }
        ];
    });
    const iframeName = computed(()=>{
        switch(props.type){
            case 'livescore':
                return IframeWidget.SportradarLiveScore;
            case 'statistics':
                return IframeWidget.SportradarStatistics;
            default:
                throw new Error(errorMessage);
        }
    });
    const iframeWidgetSrc = computed(()=>widgetsConfig.value?.iframeUrl ? `${widgetsConfig.value?.iframeUrl}/${iframeName.value}` : getDefaultWidgetUrl(iframeName.value));
    function getInitiatorByWidget(widget) {
        switch(widget){
            case IframeWidget.SportradarLiveScore:
                return SportradarLiveScorePostMessageBusInitiator;
            case IframeWidget.SportradarStatistics:
                return SportradarStatisticPostMessageBusInitiator;
            default:
                throw new Error(errorMessage);
        }
    }
    let postMessageBus;
    const frame = ref();
    function onHeightChanged(param) {
        let { height } = param;
        frame.value?.resizeIframe(height + 10);
    }
    function onIframeLoaded() {
        internalWidgetLoaded.value = true;
    }
    function initPostMessageBus() {
        internalWidgetLoaded.value = false;
        postMessageBus?.dispose();
        const iframeContentWindow = requireContentWindowByIFrameName(iframeName.value);
        postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: getInitiatorByWidget(iframeName.value)
        });
        switch(props.type){
            case 'livescore':
                postMessageBus.on(SportradarLiveScorePostMessageEvent.heightChanged, onHeightChanged);
                postMessageBus.on(SportradarLiveScorePostMessageEvent.onLoad, onIframeLoaded);
                break;
            case 'statistics':
                postMessageBus.on(SportradarStatisticPostMessageEvent.heightChanged, onHeightChanged);
                postMessageBus.on(SportradarStatisticPostMessageEvent.onLoad, onIframeLoaded);
                break;
            default:
                throw new Error(errorMessage);
        }
    }
    watch(iframeName, async ()=>{
        isIframeVisible.value = false;
        await nextTick();
        isIframeVisible.value = true;
    });
    onBeforeUnmount(()=>{
        postMessageBus?.dispose();
        postMessageBus = void 0;
    });
    return {
        frame,
        internalWidgetLoaded,
        isIframeVisible,
        iframeWidgetSrc,
        iframeName,
        additionalQueryStringParameters,
        initPostMessageBus
    };
}
