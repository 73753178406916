import { computed } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { SportlineNavigationItemCustomStyle } from 'web/src/modules/sportline/enums/navigation';
import { isSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import { displayConfigToIconConfig } from './utils';
export function useSportlineVirtualSportFilter(props, emits) {
    const { options, filter } = props;
    function emitChangeFilter(option) {
        emits.changeFilter({
            urlName: option.navigationParameters.urlName
        });
    }
    const items = computed(()=>options.value.sports.map((option)=>{
            const { displayConfig } = option;
            const to = {
                name: RouteName.SPORTLINE_VIRTUAL_SPORT,
                params: {
                    sport: option.sport.toLowerCase()
                }
            };
            const isActive = isSelectedVirtualSportFilterOption(option, filter.value);
            const icon = displayConfigToIconConfig(displayConfig);
            return {
                key: option.id,
                icon,
                title: displayConfig?.label || option.sport,
                customStyle: displayConfig?.highlighted ? SportlineNavigationItemCustomStyle.Bundesliga : void 0,
                isActive,
                to,
                onClick: ()=>emitChangeFilter(option),
                dataTest: option.sport,
                transitionId: option.sport
            };
        }));
    return {
        items
    };
}
