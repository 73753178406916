import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
export function getSportEventDetailsSeoMetaInfo(sportEventDetails, options) {
    if (!sportEventDetails || !options) return {};
    return getSportEventSeoMetaInfo({
        sportEvent: sportEventDetails,
        sport: sportEventDetails.sport,
        region: sportEventDetails.region,
        league: sportEventDetails.league,
        ...options
    });
}
