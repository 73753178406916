import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
import { onActivated, onDeactivated, onMounted, onUnmounted } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import useCybersportSegmentPage from 'web/src/modules/sportline/submodules/cybersport/composables/useCybersportSegmentPage';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';
import FilterTransition from 'web/src/modules/sportline/components/FilterTransition/FilterTransition.vue';
import { VCover } from 'web/src/components/Cover';
import CybersportFavoritesTab from 'web/src/modules/sportline/submodules/cybersport/views/CybersportFavoritesTab.vue';
import CybersportNoEvents from 'web/src/modules/sportline/submodules/cybersport/components/CybersportNoEvents.vue';
import CybersportDefaultFilter from 'web/src/modules/sportline/submodules/cybersport/components/Filter/default/SportlineCybersportFilter.vue';
import CybersportDefaultAllTab from './all-tab/CybersportDefaultAllTab.vue';
import CybersportBetSwipeAllTab from './all-tab/CybersportBetSwipeAllTab.vue';
import CybersportDefaultTab from './region-tab/CybersportDefaultTab.vue';
import CybersportBetSwipeTab from './region-tab/CybersportBetSwipeTab.vue';
import { useCybersportNavigationSectionActivation } from './composables/useCybersportNavigationSectionActivation';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CybersportRoutePage',
    setup (__props) {
        const { isReady, totalCount, allGroupForFilter, mainGroups, groupsForDisplay, favoriteGroupForFilter, streamEventsGroupForFilter, isFilterTransitionFadeEffectEnabled, filterTransitionDuration, seoMetaInfo, setActiveTransitionId, canShowLiveForGroup, canShowPrematchForGroup, listTransition, onChangeFilter, onActivatedFiltersHook, onDeactivatedFiltersHook, getEmptyEventsComponentProperties, sportElement } = useCybersportSegmentPage();
        useSeoMetaParameters(seoMetaInfo);
        const { isBetSwipeLiveLayoutEnabled } = useSportlineLayoutSettings();
        onMounted(onActivatedFiltersHook);
        onActivated(onActivatedFiltersHook);
        onUnmounted(onDeactivatedFiltersHook);
        onDeactivated(onDeactivatedFiltersHook);
        useCybersportNavigationSectionActivation();
        useKeepAlive([
            RouteName.HOME,
            RouteName.SPORTLINE_LIVE_EVENTS,
            RouteName.SPORTLINE_PREMATCH_EVENTS,
            RouteName.SPORT_REGION,
            RouteName.SPORT_LEAGUE,
            RouteName.SPORT_EVENT_DETAILS,
            RouteName.SPORTLINE_CYBERSPORT
        ]);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _unref(isReady) ? (_openBlock(), _createBlock(FilterTransition, {
                    ref_key: "listTransition",
                    ref: listTransition,
                    key: "sport-events-segment-page",
                    disabled: !_unref(isFilterTransitionFadeEffectEnabled),
                    "fade-in-duration": _unref(filterTransitionDuration).fadeIn,
                    "fade-out-duration": _unref(filterTransitionDuration).fadeOut,
                    onChangeId: _unref(setActiveTransitionId)
                }, {
                    filter: _withCtx((param)=>{
                        let { duration, afterEnter, afterLeave, beforeEnter, beforeLeave } = param;
                        return [
                            (_openBlock(), _createBlock(CybersportDefaultFilter, {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['events-list-filter']),
                                "total-count": _unref(totalCount),
                                "all-group": _unref(allGroupForFilter),
                                "favorite-group": _unref(favoriteGroupForFilter),
                                "stream-group": _unref(streamEventsGroupForFilter),
                                groups: _unref(mainGroups),
                                onChangeFilter: _unref(onChangeFilter)
                            }, {
                                afterItem: _withCtx((param)=>{
                                    let { item } = param;
                                    return [
                                        _createVNode(_Transition, {
                                            name: "fade",
                                            duration: duration,
                                            onBeforeLeave: ($event)=>beforeLeave(item.transitionId),
                                            onAfterLeave: afterLeave,
                                            onBeforeEnter: ($event)=>beforeEnter(item.transitionId),
                                            onAfterEnter: afterEnter
                                        }, {
                                            default: _withCtx(()=>[
                                                    item.isActive ? (_openBlock(), _createElementBlock("div", {
                                                        key: item.key
                                                    })) : _createCommentVNode("", true)
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "duration",
                                            "onBeforeLeave",
                                            "onAfterLeave",
                                            "onBeforeEnter",
                                            "onAfterEnter"
                                        ])
                                    ];
                                }),
                                _: 2
                            }, 1032, [
                                "class",
                                "total-count",
                                "all-group",
                                "favorite-group",
                                "stream-group",
                                "groups",
                                "onChangeFilter"
                            ]))
                        ];
                    }),
                    content: _withCtx((param)=>{
                        let { isFadeOut, extraStyle } = param;
                        return [
                            _withDirectives(_createElementVNode("div", {
                                key: "sport-events-segment-content",
                                class: _normalizeClass({
                                    [_ctx.$style['events-list']]: true,
                                    [_ctx.$style['events-list--extra-paddings']]: void 0
                                }),
                                style: _normalizeStyle(extraStyle)
                            }, [
                                0 === _unref(totalCount) && _unref(allGroupForFilter) && _unref(canShowLiveForGroup)(_unref(allGroupForFilter)) ? (_openBlock(), _createBlock(CybersportNoEvents, _mergeProps({
                                    key: 0
                                }, _unref(getEmptyEventsComponentProperties)(_unref(allGroupForFilter)), {
                                    key: `${_unref(allGroupForFilter).key}-empty`,
                                    title: _ctx.$t('WEB2_LIVE_NOEVENTS_TITLE')
                                }), null, 16, [
                                    "title"
                                ])) : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupsForDisplay), (group)=>(_openBlock(), _createBlock(_unref(VCover), {
                                        key: group.key,
                                        opened: _unref(canShowLiveForGroup)(group)
                                    }, {
                                        default: _withCtx((param)=>{
                                            let { isLoaded, isOpen } = param;
                                            return [
                                                isLoaded && group.type === _unref(SegmentPageTabType).Favorite && isOpen ? _withDirectives((_openBlock(), _createBlock(CybersportFavoritesTab, {
                                                    key: `${group.key}-loaded`,
                                                    "is-active": isOpen
                                                }, null, 8, [
                                                    "is-active"
                                                ])), [
                                                    [
                                                        _directive_data_test,
                                                        {
                                                            el: 'favorites-tab',
                                                            shown: isOpen || _unref(canShowPrematchForGroup)(group)
                                                        }
                                                    ]
                                                ]) : isLoaded && group.type === _unref(SegmentPageTabType).All ? (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: 1
                                                }, [
                                                    (_openBlock(), _createElementBlock(_Fragment, {
                                                        key: 1
                                                    }, [
                                                        _unref(sportElement) && isOpen ? _withDirectives((_openBlock(), _createBlock(CybersportDefaultAllTab, {
                                                            key: `${group.key}-loaded`
                                                        })), [
                                                            [
                                                                _directive_data_test,
                                                                {
                                                                    el: 'all-tab',
                                                                    shown: isOpen
                                                                }
                                                            ]
                                                        ]) : _createCommentVNode("", true)
                                                    ], 64))
                                                ], 64)) : isLoaded && group.type === _unref(SegmentPageTabType).Default ? (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: 2
                                                }, [
                                                    _withDirectives((_openBlock(), _createBlock(CybersportDefaultTab, {
                                                        key: `${group.key}-loaded`,
                                                        "sport-element": _unref(sportElement),
                                                        group: group,
                                                        "is-open": isOpen,
                                                        "can-show-prematch": _unref(canShowPrematchForGroup)(group)
                                                    }, null, 8, [
                                                        "sport-element",
                                                        "group",
                                                        "is-open",
                                                        "can-show-prematch"
                                                    ])), [
                                                        [
                                                            _directive_data_test,
                                                            {
                                                                el: 'group-tab',
                                                                shown: isOpen || _unref(canShowPrematchForGroup)(group)
                                                            }
                                                        ]
                                                    ])
                                                ], 64)) : (_openBlock(), _createElementBlock("div", {
                                                    key: `${group.key}-unloaded`
                                                }))
                                            ];
                                        }),
                                        _: 2
                                    }, 1032, [
                                        "opened"
                                    ]))), 128))
                            ], 6), [
                                [
                                    _vShow,
                                    !isFadeOut
                                ]
                            ])
                        ];
                    }),
                    _: 1
                }, 8, [
                    "disabled",
                    "fade-in-duration",
                    "fade-out-duration",
                    "onChangeId"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'CybersportRoutePage'
                ]
            ]);
        };
    }
});
