import { toRef, computed } from 'vue';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
export function useLeaguePageStatistic() {
    const leaguePageStore = useLeaguePageStore();
    const leagueStandings = toRef(leaguePageStore, 'leagueStandings');
    const leagueMatchesStatistic = toRef(leaguePageStore, 'leagueMatchesStatistic');
    const leagueMatchesResults = toRef(leaguePageStore, 'leagueMatchesResults');
    const leaguePlayoff = toRef(leaguePageStore, 'leaguePlayoff');
    const leagueTopPlayers = toRef(leaguePageStore, 'leagueTopPlayers');
    const isReady = toRef(leaguePageStore, 'isStatisticReady');
    const isLoading = toRef(leaguePageStore, 'isStatisticLoading');
    const isEmpty = computed(()=>!leagueStandings.value && !leagueMatchesStatistic.value && !leagueMatchesResults.value && !leaguePlayoff.value && !leagueTopPlayers.value);
    return {
        leagueStandings,
        leagueMatchesStatistic,
        leagueMatchesResults,
        leaguePlayoff,
        leagueTopPlayers,
        isReady,
        isLoading,
        isEmpty
    };
}
