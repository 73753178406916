import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useRoute } from 'vue-router';
import { computed, toRef } from 'vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useRootNavigationStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavigationMainContentLoaderRouteComponent',
    setup (__props) {
        const store = useRootNavigationStore();
        const isRouteContentLoading = toRef(store, 'isRouteContentLoading');
        const route = useRoute();
        const isLoading = computed(()=>!!isRouteContentLoading.value[route.name]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-loader-wrapper'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['loader-masked'])
                }, [
                    _createVNode(_unref(VLoaderDelayed))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'NavigationMainContentLoaderRouteComponent'
                ],
                [
                    _vShow,
                    isLoading.value
                ]
            ]);
        };
    }
});
