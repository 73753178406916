import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "teams-confrontation-statistic-block"
};
const _hoisted_2 = {
    class: "teams-confrontation-statistic-block__heading"
};
const _hoisted_3 = {
    class: "chart-heading chart-heading--left"
};
const _hoisted_4 = {
    class: "chart-heading"
};
const _hoisted_5 = {
    class: "chart-heading chart-heading--right"
};
const _hoisted_6 = {
    class: "teams-confrontation-statistic-block__charts"
};
const _hoisted_7 = {
    class: "chart-bar chart-bar--left"
};
const _hoisted_8 = {
    class: "chart-bar chart-bar--right"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'TeamsConfrontationStatisticBlock',
    props: {
        confrontationBlock: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.confrontationBlock.host.value), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.confrontationBlock.name), 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.confrontationBlock.guest.value), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", {
                            class: "chart-bar__filling chart-bar__filling--left",
                            style: _normalizeStyle({
                                width: `${_ctx.confrontationBlock.host.percent}%`
                            })
                        }, null, 4)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", {
                            class: "chart-bar__filling chart-bar__filling--right",
                            style: _normalizeStyle({
                                width: `${_ctx.confrontationBlock.guest.percent}%`
                            })
                        }, null, 4)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'TeamsConfrontationStatisticBlock'
                ]
            ]);
        };
    }
});
