import { computed } from 'vue';
import { RecycleScrollerDirection } from '../enums';
import { createComputedTotalSize } from '../utils';
export function useHorizontalWrapperProps(props, getDistanceLeft) {
    const { options, visibleItemsState } = props;
    const totalWidth = createComputedTotalSize(options, RecycleScrollerDirection.HORIZONTAL);
    const offsetLeft = computed(()=>getDistanceLeft(visibleItemsState.value.start));
    const wrapperProps = computed(()=>({
            style: {
                height: '100%',
                width: `${totalWidth.value - offsetLeft.value}px`,
                marginLeft: `${offsetLeft.value}px`,
                display: 'flex'
            }
        }));
    return {
        wrapperProps
    };
}
export function useVerticalWrapperProps(props, getDistanceTop) {
    const { options, visibleItemsState } = props;
    const totalHeight = createComputedTotalSize(options, RecycleScrollerDirection.VERTICAL);
    const offsetTop = computed(()=>getDistanceTop(visibleItemsState.value.start));
    const wrapperProps = computed(()=>({
            style: {
                width: '100%',
                height: `${totalHeight.value - offsetTop.value}px`,
                marginTop: `${offsetTop.value}px`
            }
        }));
    return {
        wrapperProps
    };
}
