import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFavoriteSportEventData } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { resolveLeaguePageLink, resolveLivePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportlineEventDetailsToolbar() {
    const router = useRouter();
    const { $translate } = useI18n();
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const betlineMatchStatistics = toRef(sportlineEventStatisticStore, 'betlineMatchStatistics');
    const betlinePostMatchStatistics = toRef(sportlineEventStatisticStore, 'betlinePostMatchStatistics');
    const { sportlineEventId, sportlineType, isOutright } = useSportlineEventType({
        sportEvent: sportlineEvent
    });
    const { isFavorite, canFavorite, emitChangeFavoriteState } = useFavoriteSportEventData({
        sportlineEventId,
        sportlineEventType: sportlineType
    });
    const statisticsTeams = computed(()=>betlineMatchStatistics.value?.teams ?? (betlinePostMatchStatistics.value ? betlinePostMatchStatistics.value.teams : null) ?? null);
    const isSportlineEventExist = computed(()=>!!sportlineEvent.value);
    const sport = computed(()=>sportlineEvent.value?.sport);
    const region = computed(()=>sportlineEvent.value?.region);
    const league = computed(()=>sportlineEvent.value?.league);
    const leagueName = computed(()=>league.value?.name ?? null);
    const regionName = computed(()=>region.value?.name ?? null);
    const matchTitle = computed(()=>{
        if (!sportlineEvent.value) {
            const teams = statisticsTeams.value?.map((param)=>{
                let { name } = param;
                return name;
            });
            if (teams?.length === 2) return `${$translate('WEB2_MATCH_STATISTIC_MATCH_RESULTS').value} ${teams.join(' - ')}`;
            return null;
        }
        if (isOutright.value) return `${$translate('JSP_SPORT_FAMILY_BETS').value} ${sportlineEvent.value.league.name}`;
        return `${$translate('JSP_BET_ON_MATCH').value} ${sportlineEvent.value.name}`;
    });
    function onGoBackClick() {
        if (sportlineEvent.value?.type === SportlineType.Live && sport.value) {
            router.back(resolveLivePageLink(sport.value.navigationParameters));
            return;
        }
        if (sportlineEvent.value && league.value) {
            router.back(resolveLeaguePageLink(league.value.navigationParameters));
            return;
        }
        router.back();
    }
    return {
        leagueName,
        regionName,
        matchTitle,
        isSportlineEventExist,
        isFavorite,
        canFavorite,
        emitChangeFavoriteState,
        onGoBackClick
    };
}
