import { computed } from 'vue';
/**
 * Extract sport, region and league from SportlineEventDetails
 * Return Maybe<Sport> for Maybe<SportEventDetails> or just Sport for SportEventDetails
 */ export function useSportlineEventDetailsParts(props) {
    const { sportEventDetails } = props;
    const sport = computed(()=>sportEventDetails.value ? sportEventDetails.value.sport : null);
    const region = computed(()=>sportEventDetails.value ? sportEventDetails.value.region : null);
    const league = computed(()=>sportEventDetails.value ? sportEventDetails.value.league : null);
    return {
        sport,
        region,
        league
    };
}
