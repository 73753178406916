import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { useFavoriteRegionFilterTabs } from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import SportlineFavoriteRegionsFilter from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/SportlineFavoriteRegionsFilter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveFavoriteRegionsFilter',
    props: {
        selectedRegionFamily: {}
    },
    emits: [
        "select-region-family"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const selectedRegionFamily = toRef(props, 'selectedRegionFamily', null);
        const favoriteEventsList = toRef(useSportlineLiveStore(), 'favoriteEventsFullList');
        function selectRegionFamily(value) {
            emit('select-region-family', value);
        }
        const { activeTabIndex, favoriteRegionsTabs } = useFavoriteRegionFilterTabs({
            selectedRegionFamily
        }, {
            selectRegionFamily
        }, favoriteEventsList);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportlineFavoriteRegionsFilter, {
                "active-tab-index": _unref(activeTabIndex),
                "favorite-regions-tabs": _unref(favoriteRegionsTabs),
                "selected-region-family": selectedRegionFamily.value
            }, null, 8, [
                "active-tab-index",
                "favorite-regions-tabs",
                "selected-region-family"
            ])), [
                [
                    _directive_auto_id,
                    'LiveFavoriteRegionsFilter'
                ]
            ]);
        };
    }
});
