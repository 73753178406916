import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useHeadlineCybersportLayout, useHeadlineWideLayout } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeadlineCybersportWideLayout',
    props: {
        sportEventDetails: {},
        hasInplayComponent: {
            type: Boolean
        },
        hasPrimaryMarket: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportEventDetails');
        const hasPrimaryMarket = toRef(props, 'hasPrimaryMarket', false);
        const hasInplayComponent = toRef(props, 'hasInplayComponent', false);
        const { backgroundStyles, backgroundColor, streamAspectRatio } = useHeadlineCybersportLayout({
            sportlineEvent
        });
        const { backgroundLineType, headlineLayoutName } = useHeadlineWideLayout({
            autoLayoutAllowed: true,
            sportlineEvent,
            hasPrimaryMarket,
            hasInplayComponent
        });
        const layoutClassName = computed(()=>`headline-layout--${backgroundLineType.value}-${headlineLayoutName.value}`);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['headline-layout']]: true,
                    [_ctx.$style[layoutClassName.value]]: !!layoutClassName.value
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['headline-layout__info']]: true,
                        [_ctx.$style['headline-layout__background']]: true,
                        [_ctx.$style['headline-layout__info--full-width']]: !hasInplayComponent.value
                    }),
                    style: _normalizeStyle(_unref(backgroundStyles))
                }, [
                    _renderSlot(_ctx.$slots, "info", {
                        backgroundColor: _unref(backgroundColor)
                    })
                ], 6),
                hasInplayComponent.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['headline-layout__inplay-component']),
                    style: _normalizeStyle({
                        'padding-top': `${Math.round(50 * _unref(streamAspectRatio))}%`,
                        backgroundColor: _unref(backgroundColor) ?? void 0
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-layout__inplay-component-content'])
                    }, [
                        _renderSlot(_ctx.$slots, "inplay-component", {
                            backgroundColor: _unref(backgroundColor)
                        })
                    ], 2)
                ], 6)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeadlineCybersportWideLayout'
                ]
            ]);
        };
    }
});
