import { RecycleScrollerDirection } from '../enums';
const scrollToDictionaryForElementScrollKey = {
    [RecycleScrollerDirection.HORIZONTAL]: 'scrollLeft',
    [RecycleScrollerDirection.VERTICAL]: 'scrollTop'
};
export function createScrollTo(type, calculateRange, getDistance, containerRef) {
    return (index)=>{
        if (!containerRef.value) return;
        const scrollAttrName = scrollToDictionaryForElementScrollKey[type];
        // eslint-disable-next-line no-param-reassign
        containerRef.value[scrollAttrName] = getDistance(index);
        calculateRange();
    };
}
export function createScrollBy(type, calculateRange, containerRef) {
    return function(size) {
        let doCalculateRange = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
        if (!containerRef.value) return;
        const scrollAttrName = scrollToDictionaryForElementScrollKey[type];
        // eslint-disable-next-line no-param-reassign
        containerRef.value[scrollAttrName] += size;
        if (doCalculateRange) calculateRange();
    };
}
