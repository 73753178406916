import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { VRecycleScrollerVertical } from 'web/src/components/RecycleScroller';
import { isEventElement, isLeagueHeadlineElement, isSportHeadlineElement } from './guards';
import { getRecycleScrollerCssVars, createGetEventElementSize, createGetLeagueHeadlineElementSize, createGetSportHeadlineElementSize } from './utils';
import VirtualListRendererElement from './VirtualListRendererElement.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualList',
    props: {
        elements: {},
        expandedIds: {},
        expandedHeights: {},
        extendedBlockType: {},
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        overscan: {}
    },
    emits: [
        "event-resize"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const elements = toRef(()=>props.elements);
        const expandedIds = toRef(()=>props.expandedIds);
        const expandedHeights = toRef(()=>props.expandedHeights);
        const getEventElementSize = createGetEventElementSize({
            expandedIds,
            expandedHeights
        });
        const getLeagueHeadlineElementSize = createGetLeagueHeadlineElementSize();
        const getSportHeadlineElementSize = createGetSportHeadlineElementSize();
        const options = computed(()=>elements.value.map((element)=>{
                if (isEventElement(element)) return getEventElementSize(element);
                if (isLeagueHeadlineElement(element)) return getLeagueHeadlineElementSize(element);
                if (isSportHeadlineElement(element)) return getSportHeadlineElementSize(element);
                return {
                    height: 0
                };
            }));
        const cssVars = getRecycleScrollerCssVars();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VRecycleScrollerVertical), {
                options: options.value,
                "disable-own-scroll": "",
                style: _normalizeStyle(_unref(cssVars)),
                overscan: _ctx.overscan
            }, {
                item: _withCtx((param)=>{
                    let { item } = param;
                    return [
                        _createVNode(VirtualListRendererElement, {
                            element: elements.value[item.index],
                            "can-add-event-to-favorite": true,
                            "extended-block-type": _ctx.extendedBlockType,
                            "is-market-types-selection-enabled": _ctx.isMarketTypesSelectionEnabled,
                            onEventResize: _cache[0] || (_cache[0] = ($event)=>emit('event-resize', $event))
                        }, null, 8, [
                            "element",
                            "can-add-event-to-favorite",
                            "extended-block-type",
                            "is-market-types-selection-enabled"
                        ])
                    ];
                }),
                _: 1
            }, 8, [
                "options",
                "style",
                "overscan"
            ])), [
                [
                    _directive_auto_id,
                    'VirtualList'
                ]
            ]);
        };
    }
});
