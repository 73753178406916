import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let SportradarLiveScoreWidgetPrefetch = class SportradarLiveScoreWidgetPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        if (!useSiteConfigStore().isSportradarLiveScoreEnabled) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        await useSportlineWidgetsStore().fetchSportradarLiveScoreConfig();
        next();
    }
};
export { SportradarLiveScoreWidgetPrefetch as default };
