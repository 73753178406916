import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { PopperApplyTarget } from 'web/src/components/Popper/VPopper/enums';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
import BreadcrumbsRelatedEvents from './BreadcrumbsRelatedEvents.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RelatedEventsBreadcrumbsItem',
    props: {
        sportElement: {},
        relatedEventsList: {},
        activeEventId: {},
        title: {},
        position: {},
        open: {
            type: Boolean
        }
    },
    emits: [
        "toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VBreadcrumb, {
                type: _unref(BreadcrumbType).DEFAULT,
                title: _ctx.title,
                position: _ctx.position,
                open: _ctx.open,
                "apply-target": _unref(PopperApplyTarget).Content,
                "can-expand": _ctx.relatedEventsList.length > 0,
                controlled: "",
                onToggle: _cache[0] || (_cache[0] = ($event)=>emit('toggle', _unref(BreadcrumbId).Events))
            }, _createSlots({
                _: 2
            }, [
                _ctx.sportElement && _ctx.relatedEventsList.length > 0 ? {
                    name: "default",
                    fn: _withCtx((param)=>{
                        let { forceHide } = param;
                        return [
                            _createVNode(BreadcrumbsRelatedEvents, {
                                "sport-element": _ctx.sportElement,
                                "related-events": _ctx.relatedEventsList,
                                "active-event-id": _ctx.activeEventId,
                                "is-market-types-selection-enabled": "",
                                onClickEvent: ($event)=>{
                                    emit('toggle', _unref(BreadcrumbId).Events);
                                    forceHide();
                                }
                            }, null, 8, [
                                "sport-element",
                                "related-events",
                                "active-event-id",
                                "onClickEvent"
                            ])
                        ];
                    }),
                    key: "0"
                } : void 0
            ]), 1032, [
                "type",
                "title",
                "position",
                "open",
                "apply-target",
                "can-expand"
            ])), [
                [
                    _directive_auto_id,
                    'RelatedEventsBreadcrumbsItem'
                ]
            ]);
        };
    }
});
