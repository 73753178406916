import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
export function useEventDetailsMiniScoreBoard(props) {
    const { sportEvent } = props;
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isTimeReversedIndicatorAvailable = toRef(sportlineSettingsStore, 'isTimeReversedIndicatorAvailable');
    const { matchProgress } = useLiveMatchProgress({
        sportEvent
    });
    const { totalScore, stage, gameScoreProgress, isStaticLiveProgress, isTimeReversed, staticReversedTimeProgressLabel, staticDirectTimeProgressLabel, additionalTime, mainTimeTotalSeconds, mainTimeProgress, mainTimeProgressShortFormat, additionalTimeTotalSeconds, additionalTimeProgress, scoreDetailsLine, mainTimeDirectiveDirection, additionalTimeDirectiveDirection } = useLiveStatus(matchProgress);
    return {
        isTimeReversedIndicatorAvailable,
        totalScore,
        stage,
        gameScoreProgress,
        isStaticLiveProgress,
        isTimeReversed,
        staticReversedTimeProgressLabel,
        staticDirectTimeProgressLabel,
        additionalTime,
        mainTimeTotalSeconds,
        mainTimeProgress,
        mainTimeProgressShortFormat,
        additionalTimeTotalSeconds,
        additionalTimeProgress,
        scoreDetailsLine,
        mainTimeDirectiveDirection,
        additionalTimeDirectiveDirection
    };
}
