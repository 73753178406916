import RouteName from '@leon-hub/routing-config';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';
let PrerenderSportEventDetailsPageNavigationService = class PrerenderSportEventDetailsPageNavigationService extends BaseSportEventDetailsPageNavigationService {
    prefetch(router, to, from, next) {
        super.prefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            this.loadPageData(router, to, from, next);
        });
    }
    /* Method for tests */ // eslint-disable-next-line class-methods-use-this
    getIsReadyToDisplay() {
        const detailsStore = useSportlineEventDetailsStore();
        return detailsStore.isReadyToDisplayContent;
    }
    // eslint-disable-next-line class-methods-use-this
    async loadPageData(router, to, from, next) {
        const detailsStore = useSportlineEventDetailsStore();
        const { sportEventId } = getSportlinePageNavigationParameters(to);
        if (sportEventId) // default navigation behaviour registration
        detailsStore.createOnInitBehaviour(sportEventId, {});
        await detailsStore.initialRequests();
        if (detailsStore.sportEventDetails) {
            next();
            return;
        }
        const statisticStore = useSportlineEventStatisticStore();
        if (statisticStore.hasPostMatchStatistics) {
            next();
            return;
        }
        // use direct redirect to home but not RedirectByBreadcrumbs (made in LEONWEB-6332)
        next(router.resolve301location({
            name: RouteName.HOME
        }));
    }
};
/**
 * Handle navigation for prerender (search bots)
 * Check missing event and redirect
 */ export { PrerenderSportEventDetailsPageNavigationService as default };
