import { computed, ref } from 'vue';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
export function useHeadlineInplayComponentShowState(props) {
    const { activeSlideId, isLiveWidgetAvailable, isStreamAvailable } = props;
    const doForceShowInplayComponent = props.doForceShowInplayComponent ?? ref(false);
    const doShowInfo = computed(()=>!activeSlideId.value || activeSlideId.value === HeadlineSlideName.Info);
    const internalActiveSlideId = computed(()=>doShowInfo.value && doForceShowInplayComponent.value ? HeadlineSlideName.LiveStream : activeSlideId.value);
    const doShowStream = computed(()=>internalActiveSlideId.value === HeadlineSlideName.LiveStream);
    const doShowLiveWidget = computed(()=>internalActiveSlideId.value === HeadlineSlideName.LiveWidget);
    const isStreamShown = computed(()=>{
        if (!isStreamAvailable.value) return false;
        // show anyway if stream is available but widget is not
        if (doForceShowInplayComponent.value && !isLiveWidgetAvailable.value) return true;
        return doShowStream.value;
    });
    const isLiveWidgetShown = computed(()=>{
        if (!isLiveWidgetAvailable.value) return false;
        // show anyway if widget is available but stream is not
        if (doForceShowInplayComponent.value && !isStreamAvailable.value) return true;
        return doShowLiveWidget.value;
    });
    return {
        isLiveWidgetShown,
        isStreamShown
    };
}
