import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "sport-event-match-statistic"
};
const _hoisted_2 = {
    class: "sport-event-match-statistic__left"
};
const _hoisted_3 = {
    class: "sport-event-match-statistic__match-info"
};
const _hoisted_4 = {
    class: "sport-event-match-statistic__meta"
};
const _hoisted_5 = {
    key: 0,
    class: "sport-event-match-statistic__current-score"
};
const _hoisted_6 = {
    class: "sport-event-match-statistic__teams"
};
const _hoisted_7 = {
    class: "sport-event-match-statistic__team-name"
};
const _hoisted_8 = {
    class: "sport-event-match-statistic__team-name"
};
const _hoisted_9 = {
    class: "sport-event-match-statistic__scores-holder"
};
const _hoisted_10 = {
    class: "sport-event-match-statistic__score"
};
const _hoisted_11 = {
    class: "sport-event-match-statistic__score"
};
const _hoisted_12 = {
    class: "sport-event-match-statistic__scores"
};
const _hoisted_13 = {
    class: "sport-event-match-statistic__right"
};
import { toRef } from 'vue';
import MatchStatisticResultIndicator from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchStatisticResultIndicator.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { useSportEventMatchStatistic } from './useSportEventMatchStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventMatchStatistic',
    props: {
        match: {},
        displayedForTeam: {
            default: null
        },
        sportFamily: {}
    },
    setup (__props) {
        const props = __props;
        const { isLive, host, guest, resultScore, isHostWinner, isGuestWinner, setsScore, date } = useSportEventMatchStatistic({
            match: toRef(props, 'match'),
            sportFamily: toRef(props, 'sportFamily')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("span", {
                                class: _normalizeClass([
                                    "sport-event-match-statistic__date",
                                    {
                                        'sport-event-match-statistic__date--live': _unref(isLive)
                                    }
                                ])
                            }, _toDisplayString(_unref(date)), 3),
                            _unref(isLive) && _ctx.match.gameScore ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.match.gameScore), 1)) : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _unref(host) && _unref(guest) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        "sport-event-match-statistic__team",
                                        {
                                            'sport-event-match-statistic__team--live': _unref(isLive),
                                            'sport-event-match-statistic__team--winner': !_unref(isLive) && _unref(isHostWinner)
                                        }
                                    ])
                                }, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: "sport-event-match-statistic__team-logo",
                                        team: _unref(host)
                                    }, null, 8, [
                                        "team"
                                    ]),
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(host).name), 1)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        "sport-event-match-statistic__team",
                                        {
                                            'sport-event-match-statistic__team--live': _unref(isLive),
                                            'sport-event-match-statistic__team--winner': !_unref(isLive) && _unref(isGuestWinner)
                                        }
                                    ])
                                }, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: "sport-event-match-statistic__team-logo",
                                        team: _unref(guest)
                                    }, null, 8, [
                                        "team"
                                    ]),
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(guest).name), 1)
                                ], 2)
                            ], 64)) : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(setsScore), (setScore, index)=>(_openBlock(), _createElementBlock("div", {
                                key: `${index}_${setScore[0]}_${setScore[1]}}`,
                                class: "sport-event-match-statistic__scores"
                            }, [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(setScore[0]), 1),
                                _createElementVNode("div", _hoisted_11, _toDisplayString(setScore[1]), 1)
                            ]))), 128)),
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    "sport-event-match-statistic__score",
                                    {
                                        'sport-event-match-statistic__score--live': _unref(isLive),
                                        'sport-event-match-statistic__score--winner': !_unref(isLive) && _unref(isHostWinner)
                                    }
                                ])
                            }, _toDisplayString(_unref(resultScore)[0]), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    "sport-event-match-statistic__score",
                                    {
                                        'sport-event-match-statistic__score--live': _unref(isLive),
                                        'sport-event-match-statistic__score--winner': !_unref(isLive) && _unref(isGuestWinner)
                                    }
                                ])
                            }, _toDisplayString(_unref(resultScore)[1]), 3)
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                    _createVNode(MatchStatisticResultIndicator, {
                        match: _ctx.match,
                        "displayed-for-team": _ctx.displayedForTeam
                    }, null, 8, [
                        "match",
                        "displayed-for-team"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportEventMatchStatistic'
                ]
            ]);
        };
    }
});
