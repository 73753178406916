import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportsPageNavigationService } from 'web/src/modules/sportline/submodules/sports/services/navigation/useSportsPageNavigationService';
let SportsPagePrefetch = class SportsPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        useSportsPageNavigationService().prefetch(router, to, from, next);
        return Promise.resolve();
    }
};
export { SportsPagePrefetch as default };
