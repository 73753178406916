import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
const _hoisted_3 = {
    key: 2
};
const _hoisted_4 = [
    "title"
];
const _hoisted_5 = [
    "title"
];
const _hoisted_6 = {
    key: 1
};
import { toRef } from 'vue';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import StatisticColoredLabel from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticColoredLabel.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import StatisticPlayerLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticPlayerLogo.vue';
import { StatisticColoredLabelSize, StatisticColoredLabelType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import { useLeagueTopPlayers, TopPlayerListType } from './useLeagueTopPlayers';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueTopPlayers',
    props: {
        leagueTopPlayers: {}
    },
    setup (__props) {
        const props = __props;
        const { canExpand, isExpanded, lists } = useLeagueTopPlayers({
            leagueTopPlayers: toRef(props, 'leagueTopPlayers')
        });
        return (_ctx, _cache)=>_unref(lists).length > 0 ? (_openBlock(), _createBlock(VList, {
                key: 0,
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-top-players",
                            title: _ctx.$t('WEB2_STATISTICS_TOP_PLAYERS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass({
                                            [_ctx.$style['league-top-players__content']]: true,
                                            [_ctx.$style['league-top-players__content--one-column']]: _unref(lists).length < 2
                                        })
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lists), (list)=>(_openBlock(), _createElementBlock("div", {
                                                key: list.type,
                                                class: _normalizeClass(_ctx.$style['players-list'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['players-list-title'])
                                                }, [
                                                    _createElementVNode("div", null, [
                                                        list.type === _unref(TopPlayerListType).SCORERS ? (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 0
                                                        }, [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_STATS_SCORERS')), 1)
                                                        ], 64)) : list.type === _unref(TopPlayerListType).ASSISTS ? (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 1
                                                        }, [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_STATS_ASSISTANT')), 1)
                                                        ], 64)) : _createCommentVNode("", true)
                                                    ]),
                                                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                                                        _createVNode(StatisticColoredLabel, {
                                                            class: _normalizeClass(_ctx.$style['player-stat']),
                                                            label: _ctx.$t('WEB2_STATS_PLAYER_TOTAL_GAMES_SHORT'),
                                                            size: _unref(StatisticColoredLabelSize).DEFAULT,
                                                            type: _unref(StatisticColoredLabelType).TRANSPARENT
                                                        }, null, 8, [
                                                            "class",
                                                            "label",
                                                            "size",
                                                            "type"
                                                        ])
                                                    ])), [
                                                        [
                                                            _unref(vPopperHint),
                                                            _ctx.$t('WEB2_STATS_PLAYER_TOTAL_GAMES')
                                                        ]
                                                    ]),
                                                    list.type === _unref(TopPlayerListType).SCORERS ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                                                        _createVNode(StatisticColoredLabel, {
                                                            class: _normalizeClass(_ctx.$style['player-stat']),
                                                            label: _ctx.$t('WEB2_STATS_PLAYER_TOTAL_GOALS_SHORT'),
                                                            size: _unref(StatisticColoredLabelSize).DEFAULT,
                                                            type: _unref(StatisticColoredLabelType).TRANSPARENT
                                                        }, null, 8, [
                                                            "class",
                                                            "label",
                                                            "size",
                                                            "type"
                                                        ])
                                                    ])), [
                                                        [
                                                            _unref(vPopperHint),
                                                            _ctx.$t('WEB2_STATS_PLAYER_TOTAL_GOALS')
                                                        ]
                                                    ]) : list.type === _unref(TopPlayerListType).ASSISTS ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                                                        _createVNode(StatisticColoredLabel, {
                                                            class: _normalizeClass(_ctx.$style['player-stat']),
                                                            label: _ctx.$t('WEB2_STATS_PLAYER_TOTAL_ASSISTS_SHORT'),
                                                            size: _unref(StatisticColoredLabelSize).DEFAULT,
                                                            type: _unref(StatisticColoredLabelType).TRANSPARENT
                                                        }, null, 8, [
                                                            "class",
                                                            "label",
                                                            "size",
                                                            "type"
                                                        ])
                                                    ])), [
                                                        [
                                                            _unref(vPopperHint),
                                                            _ctx.$t('WEB2_STATS_PLAYER_TOTAL_ASSISTS')
                                                        ]
                                                    ]) : (_openBlock(), _createElementBlock("div", _hoisted_3))
                                                ], 2),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['players-list__items'])
                                                }, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.list, (player, idx)=>(_openBlock(), _createElementBlock("div", {
                                                            key: idx,
                                                            class: _normalizeClass({
                                                                [_ctx.$style['players-list-item']]: true,
                                                                [_ctx.$style['players-list-item--the-best']]: 0 === idx
                                                            })
                                                        }, [
                                                            _createElementVNode("div", null, [
                                                                _createElementVNode("span", {
                                                                    class: _normalizeClass(_ctx.$style['player-ranking'])
                                                                }, _toDisplayString(player.ranking) + ".", 3)
                                                            ]),
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['players-list-item__logos'])
                                                            }, [
                                                                _createElementVNode("div", {
                                                                    class: _normalizeClass({
                                                                        [_ctx.$style['player-logos']]: true,
                                                                        [_ctx.$style['player-logos--the-best']]: 0 === idx
                                                                    })
                                                                }, [
                                                                    player.player ? (_openBlock(), _createBlock(StatisticPlayerLogo, {
                                                                        key: 0,
                                                                        class: _normalizeClass([
                                                                            _ctx.$style['player-logos__logo'],
                                                                            _ctx.$style['player-logos__logo--player']
                                                                        ]),
                                                                        player: player.player
                                                                    }, null, 8, [
                                                                        "class",
                                                                        "player"
                                                                    ])) : _createCommentVNode("", true),
                                                                    player.team ? (_openBlock(), _createBlock(StatisticTeamLogo, {
                                                                        key: 1,
                                                                        class: _normalizeClass([
                                                                            _ctx.$style['player-logos__logo'],
                                                                            _ctx.$style['player-logos__logo--team']
                                                                        ]),
                                                                        team: player.team
                                                                    }, null, 8, [
                                                                        "class",
                                                                        "team"
                                                                    ])) : _createCommentVNode("", true)
                                                                ], 2)
                                                            ], 2),
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['players-list-item__identity'])
                                                            }, [
                                                                _createElementVNode("span", {
                                                                    class: _normalizeClass(_ctx.$style['player-name']),
                                                                    title: player.name ?? void 0
                                                                }, _toDisplayString(player.name), 11, _hoisted_4),
                                                                0 === idx ? (_openBlock(), _createElementBlock("span", {
                                                                    key: 0,
                                                                    class: _normalizeClass(_ctx.$style['player-position']),
                                                                    title: (player.positionFull || player.positionShort) ?? void 0
                                                                }, _toDisplayString(player.positionFull || player.positionShort), 11, _hoisted_5)) : _createCommentVNode("", true)
                                                            ], 2),
                                                            _createElementVNode("div", null, [
                                                                _createVNode(StatisticColoredLabel, {
                                                                    class: _normalizeClass(_ctx.$style['player-stat']),
                                                                    label: String(player.matchesPlayed || ''),
                                                                    size: _unref(StatisticColoredLabelSize).DEFAULT,
                                                                    type: _unref(StatisticColoredLabelType).TRANSPARENT
                                                                }, null, 8, [
                                                                    "class",
                                                                    "label",
                                                                    "size",
                                                                    "type"
                                                                ])
                                                            ]),
                                                            _createElementVNode("div", null, [
                                                                _createVNode(StatisticColoredLabel, {
                                                                    class: _normalizeClass(_ctx.$style['player-stat']),
                                                                    label: player.value || '',
                                                                    size: _unref(StatisticColoredLabelSize).DEFAULT,
                                                                    type: _unref(StatisticColoredLabelType).DEFAULT
                                                                }, null, 8, [
                                                                    "class",
                                                                    "label",
                                                                    "size",
                                                                    "type"
                                                                ])
                                                            ])
                                                        ], 2))), 128))
                                                ], 2)
                                            ], 2))), 128))
                                    ], 2),
                                    _unref(canExpand) ? (_openBlock(), _createBlock(ExpandButton, {
                                        key: 0,
                                        "is-expanded": _unref(isExpanded),
                                        "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                                        "opened-title": _ctx.$t('WEB2_MATCH_STATISTIC_HIDE'),
                                        "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_SHOW'),
                                        class: _normalizeClass(_ctx.$style['league-top-players__expand-button'])
                                    }, null, 8, [
                                        "is-expanded",
                                        "opened-title",
                                        "closed-title",
                                        "class"
                                    ])) : _createCommentVNode("", true)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })) : (_openBlock(), _createElementBlock("div", _hoisted_6));
    }
});
