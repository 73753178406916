import { SportWidgetType } from 'web/src/modules/sportline/enums';
export function isSportradarLMTDetailsHeadlineWidget(value) {
    return value.type === SportWidgetType.SPORTRADAR;
}
export function isLSportsDetailsHeadlineWidget(value) {
    return value.type === SportWidgetType.LSPORTS;
}
export function isBetgeniusDetailsHeadlineWidget(value) {
    return value.type === SportWidgetType.BET_GENIUS;
}
