import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import SportlineFilter from 'web/src/modules/sportline/components/navigation-filter/default/SportlineFilter.vue';
import { useSportlineLiveFilter } from '../useSportlineLiveFilter';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineLiveFilter',
    props: {
        totalCount: {
            default: 0
        },
        allGroup: {
            default: null
        },
        streamGroup: {
            default: null
        },
        favoriteGroup: {
            default: null
        },
        groups: {}
    },
    emits: [
        "change-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { items } = useSportlineLiveFilter({
            totalCount: toRef(props, 'totalCount'),
            allGroup: toRef(props, 'allGroup'),
            streamGroup: toRef(props, 'streamGroup'),
            favoriteGroup: toRef(props, 'favoriteGroup'),
            groups: toRef(props, 'groups')
        }, {
            changeFilter (payload) {
                emit('change-filter', payload);
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportlineFilter, {
                items: _unref(items)
            }, {
                afterItem: _withCtx((param)=>{
                    let { item } = param;
                    return [
                        _renderSlot(_ctx.$slots, "afterItem", {
                            item: item
                        })
                    ];
                }),
                _: 3
            }, 8, [
                "items"
            ])), [
                [
                    _directive_auto_id,
                    'SportlineLiveFilter'
                ]
            ]);
        };
    }
});
