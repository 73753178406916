import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useLeagueRepresentation } from 'web/src/modules/sportline/submodules/core-statistic/composables';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
export function useLeaguePageHeadline(props) {
    const { sport, league, leagueData, leagueRepresentationData, hasMatches, hasPromotions, hasStatistic, activeSectionId } = props;
    const { $translate } = useI18n();
    const { backgroundImage, backgroundColor } = useLeagueRepresentation({
        sport,
        leagueRepresentationData
    });
    const switcherOptions = computed(()=>{
        const options = [];
        if (hasMatches.value) options.push({
            id: LeaguePageSectionId.EventsList,
            label: $translate('WEB2_SPORTLINE_PAGE_SECTION_MATCHES').value,
            iconName: IconName.PLATE_SCORE,
            iconSize: IconSize.SIZE_16
        });
        if (hasPromotions.value) options.push({
            id: LeaguePageSectionId.Promotions,
            label: $translate('WEB2_SPORTLINE_PAGE_SECTION_PROMOTIONS').value,
            iconName: IconName.PROMOS,
            iconSize: IconSize.SIZE_16
        });
        if (hasStatistic.value) options.push({
            id: LeaguePageSectionId.Statistic,
            label: $translate('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC').value,
            iconName: IconName.STATISTIC,
            iconSize: IconSize.SIZE_12
        });
        return options;
    });
    const switcherProperties = computed(()=>({
            kind: backgroundColor.value ? SwitcherKind.TRANSPARENT_WHITE : SwitcherKind.TRANSPARENT,
            activeId: activeSectionId.value,
            isSmall: true,
            isWideClickZone: true
        }));
    const title = computed(()=>league.value?.name || '');
    const subtitle = computed(()=>sport.value?.name || '');
    const logoUrl = computed(()=>leagueRepresentationData.value?.logoUrl || null);
    const sportIconName = computed(()=>leagueRepresentationData.value?.icon || sport.value?.representation?.icon || null);
    const hasTimeline = computed(()=>!!leagueData.value?.currentSeasonStart && !!leagueData.value?.currentSeasonEnd);
    return {
        backgroundColor,
        backgroundImage,
        switcherOptions,
        switcherProperties,
        title,
        subtitle,
        logoUrl,
        sportIconName,
        hasTimeline
    };
}
