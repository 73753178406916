import { computed } from 'vue';
export function useLastGamesMatchStatistic(props) {
    const hostMatches = computed(()=>[
            ...props.betlineMatchStatistics.value?.homeMatches ?? []
        ]);
    const guestMatches = computed(()=>[
            ...props.betlineMatchStatistics.value?.awayMatches ?? []
        ]);
    return {
        hostMatches,
        guestMatches
    };
}
