import { computed } from 'vue';
export function createVisibleItemsStartIndex(offset, overscan) {
    return computed(()=>Math.max(offset.value - overscan.value, 0));
}
export function createVisibleItemsEndIndex(offset, viewCapacity, overscan, sourceLength) {
    return computed(()=>Math.min(offset.value + viewCapacity.value + overscan.value, sourceLength.value));
}
export function createVisibleItemsState(start, end) {
    return computed(()=>({
            start: start.value,
            end: end.value
        }));
}
