import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { selectPluralForm } from 'web/src/modules/core/utils/language';
export function usePluralFormLabelsCache(formFactories) {
    const { locale } = useI18nLocale();
    const cache = {};
    function getLabelFor(count) {
        if (!cache[count]) cache[count] = selectPluralForm(formFactories, count, locale.value);
        return cache[count];
    }
    return {
        getLabelFor
    };
}
