import { watch } from 'vue';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { getListenerTarget, getScrollTarget } from '../utils';
export function useOnScrollListeners(props) {
    const { disableOwnScroll, containerRef, listenerTargetRef, onScroll } = props;
    let target;
    // eslint-disable-next-line unicorn/prevent-abbreviations
    let removeListenerFn;
    function removeListener() {
        removeListenerFn?.();
        removeListenerFn = void 0;
        listenerTargetRef.value = null;
    }
    function addListener() {
        removeListener();
        target = getListenerTarget(containerRef.value);
        target?.addEventListener('scroll', onScroll, {
            passive: true
        });
        listenerTargetRef.value = getScrollTarget(containerRef.value);
        removeListenerFn = ()=>{
            target?.removeEventListener('scroll', onScroll);
        };
    }
    onComponentActivated(addListener);
    onComponentDeactivated(removeListener);
    watch(disableOwnScroll, addListener);
}
