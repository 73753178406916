import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { useVirtualSportDebugStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import VirtualSportFilter from 'web/src/modules/sportline/submodules/virtual-sport/components/Filter/SportlineVirtualSportFilter.vue';
import VirtualSportWidget from 'web/src/modules/sportline/submodules/virtual-sport/views/Widget/VirtualSportWidget.vue';
import { useVirtualSportRoutePage } from './useVirtualSportRoutePage';
import DebugIndicator from './views/DebugIndicator.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualSportRoutePage',
    setup (__props) {
        const debugVirtualSportStore = useVirtualSportDebugStore();
        const isDebugEnabled = toRef(debugVirtualSportStore, 'isDebugEnabled');
        const { title, internalWidgetLoaded, widgetConfig, selectedFilter, filterOptions, selectedOption, selectedBetsMappings, onChangeSelectedSportFilter, onWidgetLoaded } = useVirtualSportRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    _ctx.$style['virtual-sport'],
                    _ctx.$style['component-wrapper']
                ])
            }, [
                _createVNode(VirtualSportFilter, {
                    filter: _unref(selectedFilter),
                    options: _unref(filterOptions),
                    onChangeFilter: _unref(onChangeSelectedSportFilter)
                }, null, 8, [
                    "filter",
                    "options",
                    "onChangeFilter"
                ]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['virtual-sport__title'])
                }, [
                    _createTextVNode(_toDisplayString(_unref(title)) + " ", 1),
                    isDebugEnabled.value ? (_openBlock(), _createBlock(DebugIndicator, {
                        key: 0
                    })) : _createCommentVNode("", true)
                ], 2),
                _unref(selectedOption) && _unref(selectedOption).clientId ? (_openBlock(), _createBlock(VirtualSportWidget, {
                    key: 0,
                    "client-id": _unref(selectedOption).clientId,
                    "iframe-url": _unref(widgetConfig).iframeUrl,
                    "provider-url": _unref(widgetConfig).providerUrl,
                    stylable: _unref(widgetConfig).stylable,
                    "selected-virtual-sport": _unref(selectedOption).sport,
                    "selected-bets-mappings": _unref(selectedBetsMappings),
                    "internal-widget-loaded": _unref(internalWidgetLoaded),
                    class: _normalizeClass(_ctx.$style['virtual-sport__widget']),
                    onWidgetLoaded: _unref(onWidgetLoaded)
                }, null, 8, [
                    "client-id",
                    "iframe-url",
                    "provider-url",
                    "stylable",
                    "selected-virtual-sport",
                    "selected-bets-mappings",
                    "internal-widget-loaded",
                    "class",
                    "onWidgetLoaded"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VirtualSportRoutePage'
                ]
            ]);
        };
    }
});
