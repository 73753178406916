import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRefs, defineAsyncComponent } from 'vue';
import { usePostMatchStatisticHeadline } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DefaultPostMatchStatisticHeadline',
    props: {
        postMatchStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const PostMatchInfo = // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/modules/sportline/submodules/event-details/views/headline-info/default/wide/PostMatchInfo.vue'));
        // eslint-disable-next-line max-len
        const { kickoff, competitors, finalScore, penaltyScore, backgroundColor } = usePostMatchStatisticHeadline(toRefs(props));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['post-match-statistic-headline']),
                style: _normalizeStyle({
                    backgroundColor: _unref(backgroundColor)
                })
            }, [
                _unref(competitors) ? (_openBlock(), _createBlock(_unref(PostMatchInfo), {
                    key: 0,
                    competitors: _unref(competitors),
                    "total-score": _unref(finalScore),
                    "penalty-score": _unref(penaltyScore),
                    kickoff: _unref(kickoff)
                }, null, 8, [
                    "competitors",
                    "total-score",
                    "penalty-score",
                    "kickoff"
                ])) : _createCommentVNode("", true)
            ], 6)), [
                [
                    _directive_auto_id,
                    'DefaultPostMatchStatisticHeadline'
                ]
            ]);
        };
    }
});
