import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MatchesStatisticCompetitors',
    props: {
        teams: {}
    },
    setup (__props) {
        const props = __props;
        const teams = toRef(props, 'teams');
        const host = computed(()=>teams.value[0]);
        const guest = computed(()=>teams.value[1]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['matches-statistic-competitors'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['matches-statistic-competitor'])
                }, [
                    _createVNode(StatisticTeamLogo, {
                        class: _normalizeClass(_ctx.$style['matches-statistic-competitor-logo']),
                        team: host.value
                    }, null, 8, [
                        "class",
                        "team"
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['matches-statistic-competitor-name'])
                    }, _toDisplayString(host.value.name), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['matches-statistic-competitor'],
                        _ctx.$style['matches-statistic-competitor--guest']
                    ])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['matches-statistic-competitor-name'])
                    }, _toDisplayString(guest.value.name), 3),
                    _createVNode(StatisticTeamLogo, {
                        class: _normalizeClass(_ctx.$style['matches-statistic-competitor-logo']),
                        team: guest.value
                    }, null, 8, [
                        "class",
                        "team"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'MatchesStatisticCompetitors'
                ]
            ]);
        };
    }
});
