import { ref, watch, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportsListDateFilter } from 'web/src/modules/sportline/submodules/sports-list/components/composables';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportsDateTimeFilter() {
    const router = useRouter();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isSportFiltersEnabled = toRef(sportlineSettingsStore, 'isSportFiltersEnabled');
    const sportlineSportsStore = useSportlineSportsStore();
    const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
    const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
    const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isLoaded');
    const { reloadListForFilter } = sportlineSportsStore;
    const dateTimeFilterRef = ref();
    const { dateTimeFilterCustomFrom: datetimeCustomFrom, dateTimeFilterCustomTo: datetimeCustomTo, dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod, dateTimeFilterActiveSportlineType: datetimeActiveSportlineType } = useSportsListDateFilter({
        dateTimeFilter: sportsListFilter
    });
    function isSportsPage() {
        return router.currentRoute.value.name === RouteName.SPORTLINE_PREMATCH_EVENTS;
    }
    /**
   * Make redirect ot common sports page from today and etc
   */ function redirectToSportsPage() {
        if (isSportsPage()) return;
        const urlName = selectedSportFilter.value.sportFamily;
        router.push(resolveSportsPageLink({
            urlName
        }));
    }
    async function onDateTimeFilterSelect(filter) {
        redirectToSportsPage();
        await reloadListForFilter(filter);
    }
    async function onDateTimeFilterSelectCustomRange(range) {
        redirectToSportsPage();
        await reloadListForFilter(range);
    }
    function dateTimeFilterSlideToActive() {
        if (!isReadyToDisplayContent.value) return;
        dateTimeFilterRef.value?.slideToActive();
    }
    let stopWatchRouteHandle;
    function startWatchRoute() {
        stopWatchRouteHandle = stopWatchRouteHandle ?? watch(router.currentRoute, ()=>{
            dateTimeFilterSlideToActive();
        }, {
            deep: true,
            immediate: true
        });
    }
    function stopWatchRoute() {
        stopWatchRouteHandle?.();
        stopWatchRouteHandle = void 0;
    }
    onComponentActivated(startWatchRoute);
    onComponentDeactivated(stopWatchRoute);
    return {
        dateTimeFilterRef,
        isSportFiltersEnabled,
        datetimeActiveKickoffPeriod,
        datetimeActiveSportlineType,
        datetimeCustomFrom,
        datetimeCustomTo,
        onDateTimeFilterSelect,
        onDateTimeFilterSelectCustomRange
    };
}
