import { computed } from 'vue';
import { getMarketColumnsFromMarketColumnsInfo, getMarketTypesFromMarketColumnsInfo } from 'web/src/modules/sportline/composables/markets/utils';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
function mapGroup(source, param) {
    let { canShowLiveForGroup, canShowPrematchForGroup } = param;
    const liveElement = canShowLiveForGroup(source) ? source.liveElement : void 0;
    const prematchElement = canShowPrematchForGroup(source) ? source.prematchElement : void 0;
    const element = liveElement ?? prematchElement ?? null;
    if (!element) return null;
    const group = {
        ...source,
        liveElement,
        prematchElement
    };
    const marketsColumns = getMarketColumnsFromMarketColumnsInfo(element);
    const marketsTypes = getMarketTypesFromMarketColumnsInfo(element);
    const key = createSportlineFragmentBasisKeyValue({
        sportId: element?.sport.id,
        salt: liveElement ? 'live' : 'prematch'
    });
    const columnInfo = {
        marketsColumns,
        marketsTypes,
        key
    };
    return {
        group,
        columnInfo
    };
}
export function createVirtualListGroups(groups, options) {
    return computed(()=>groups.value.map((group)=>mapGroup(group, options)).filter((group)=>null !== group));
}
