import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueDefaultEventsList',
    props: {
        sportElement: {
            default: null
        },
        outrightEvents: {},
        hasEvents: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _ctx.sportElement && _ctx.hasEvents ? (_openBlock(), _createBlock(SportlineSportBlock, {
                    key: _ctx.sportElement.sport.id,
                    "sport-element": _ctx.sportElement,
                    "is-market-types-selection-enabled": _ctx.isMarketTypesSelectionEnabled,
                    "custom-title": _ctx.$t('WEB2_SPORTLINE_PAGE_SECTION_MATCHES'),
                    "is-filter-active": "",
                    "can-add-to-favorite": true,
                    "extended-block-type": _unref(ExtendedEventBlock).League,
                    "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                        sportId: _ctx.sportElement.sport.id
                    })
                }, null, 8, [
                    "sport-element",
                    "is-market-types-selection-enabled",
                    "custom-title",
                    "can-add-to-favorite",
                    "extended-block-type",
                    "basis-key"
                ])) : _createCommentVNode("", true),
                _ctx.outrightEvents && _ctx.outrightEvents.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(SportEventsOutrightHeadline),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.outrightEvents, (outrightElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                            key: `outright_${outrightElement.sport.id}`,
                            "sport-element": outrightElement,
                            "is-filter-active": "",
                            "is-market-types-selection-enabled": false,
                            "is-column-names-display-disabled": "",
                            "without-headline": "",
                            "is-display-main-events-disabled": "",
                            "is-display-outright-headline-disabled": "",
                            "is-display-outright-events-enabled": "",
                            "can-add-to-favorite": true,
                            "can-add-outrights-to-favorite": true,
                            "extended-block-type": _unref(ExtendedEventBlock).League,
                            "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                                sportId: outrightElement.sport.id,
                                salt: 'outrights'
                            })
                        }, null, 8, [
                            "sport-element",
                            "can-add-to-favorite",
                            "can-add-outrights-to-favorite",
                            "extended-block-type",
                            "basis-key"
                        ]))), 128))
                ], 64)) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'LeagueDefaultEventsList'
                ]
            ]);
        };
    }
});
