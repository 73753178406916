import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import RelatedEventsDropdown from 'web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsDropdown.vue';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventDetailsToolbarContent',
    props: {
        name: {},
        region: {},
        isFavorite: {
            type: Boolean
        },
        hasToggleButton: {
            type: Boolean
        },
        hasFavoriteButton: {
            type: Boolean
        },
        isOnlyPendingFavorite: {
            type: Boolean
        }
    },
    emits: [
        "toggle-favorite"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const dropdown = ref();
        const iconSize = IconSize.SIZE_24;
        const dropdownIconSize = IconSize.SIZE_24;
        const expandIconProperties = [
            {
                size: dropdownIconSize,
                name: IconName.EXPAND_DOWN
            },
            {
                size: dropdownIconSize,
                name: IconName.EXPAND_UP
            }
        ];
        const activeFavoriteIcon = {
            name: IconName.STAR,
            size: iconSize
        };
        const inactiveFavoriteIcon = {
            name: IconName.STAR_OUTLINE,
            size: iconSize
        };
        function hide() {
            dropdown.value?.hide();
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-toolbar'])
            }, [
                _renderSlot(_ctx.$slots, "backButton"),
                _ctx.hasToggleButton ? (_openBlock(), _createBlock(RelatedEventsDropdown, {
                    key: 0,
                    ref_key: "dropdown",
                    ref: dropdown
                }, {
                    reference: _withCtx((param)=>{
                        let { opened } = param;
                        return [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['sport-event-toolbar__dropdown-container'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['sport-event-toolbar__label'])
                                }, [
                                    _createCommentVNode("", true),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['sport-event-toolbar__wrapper'])
                                    }, [
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['sport-event-toolbar__name'])
                                        }, _toDisplayString(_ctx.name ?? ''), 3),
                                        _createVNode(_unref(VIcon), _mergeProps({
                                            class: _ctx.$style['sport-event-toolbar__expand-button']
                                        }, opened ? expandIconProperties[1] : expandIconProperties[0]), null, 16, [
                                            "class"
                                        ])
                                    ], 2)
                                ], 2)
                            ], 2)
                        ];
                    }),
                    default: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "relatedList", {
                                hide: hide
                            })
                        ]),
                    _: 3
                }, 512)) : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['sport-event-toolbar__label'])
                }, [
                    _createCommentVNode("", true),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['sport-event-toolbar__name'])
                    }, _toDisplayString(_ctx.name ?? ''), 3)
                ], 2)),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-event-toolbar__button-container'])
                }, [
                    _ctx.hasFavoriteButton ? (_openBlock(), _createBlock(VFavoriteToggle, {
                        key: 0,
                        "active-icon": activeFavoriteIcon,
                        "inactive-icon": inactiveFavoriteIcon,
                        "is-favorite": _ctx.isFavorite,
                        "is-button": "",
                        "is-only-pending": _ctx.isOnlyPendingFavorite,
                        onClick: _cache[0] || (_cache[0] = ($event)=>emit('toggle-favorite'))
                    }, null, 8, [
                        "is-favorite",
                        "is-only-pending"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventDetailsToolbarContent'
                ]
            ]);
        };
    }
});
