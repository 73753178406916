import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { isLeaguePageRouteName } from 'web/src/modules/sportline/submodules/navigation/guards';
import { useBaseLeaguePageNavigationGuard } from './useBaseLeaguePageNavigationGuard';
import { useLeagueNavigationBackButton } from './useLeagueNavigationBackButton';
export function useDefaultLeaguePageNavigationGuard() {
    const { activateBackButton } = useLeagueNavigationBackButton();
    const leaguePageStore = useLeaguePageStore();
    const isReady = toRef(leaguePageStore, 'isReady');
    const currentLeagueId = toRef(leaguePageStore, 'loadedDataLeagueId');
    const canDisplayAnything = toRef(leaguePageStore, 'canDisplayAnything');
    const { initialRequests, awaitStateIsLoaded, awaitLeavePage, syncBackgroundRequests, updateDateFilterForLeagueLoading, setConfigForLeagueLoading } = leaguePageStore;
    const baseNavigationGuard = useBaseLeaguePageNavigationGuard();
    const loadLeagueConfig = toRef(baseNavigationGuard, 'loadLeagueConfig');
    const { getRedirectLocation, clearLoadLeagueConfig, prefetch: basePrefetch } = baseNavigationGuard;
    const navigationStore = useRootNavigationStore();
    const { setRouteContentLoaded, setOnlyFirstRouteContentLoading } = navigationStore;
    /**
   * Fully reload league (when have not data or navigate between leagues)
   */ async function loadLeagueForConfig(config, routeName) {
        setOnlyFirstRouteContentLoading(routeName);
        setConfigForLeagueLoading(config);
        await initialRequests();
        setRouteContentLoaded(routeName);
    }
    /**
   * Just sync data when navigate from some page (not league page) to already loaded league data
   */ function syncLeagueForConfig(config, routeName) {
        if (config.filter) updateDateFilterForLeagueLoading(config.filter);
        syncBackgroundRequests({});
        setRouteContentLoaded(routeName);
    }
    function handleRedirectForWrongParameters(router, to) {
        const redirectTo = getRedirectLocation(router, to);
        if (redirectTo) router.pushRedirect(redirectTo, 301);
    }
    /**
   * Subscribe to load state and handle redirects after page is ready
   */ async function handleRedirectsForEmptyPage(router, to) {
        await awaitStateIsLoaded();
        if (canDisplayAnything.value) {
            // it is ok, we have some events in this league
            handleRedirectForWrongParameters(router, to);
            return;
        }
        const redirect = useRedirectBySportsTree({
            router,
            code: 301,
            allowedTargets: {
                sport: true,
                region: true
            }
        });
        redirect.make();
        awaitLeavePage().then(()=>{
            // clear timeout after try back to MISSING page
            redirect.clearTimeout();
        });
    }
    function prefetch(router, to, from, next) {
        basePrefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            const config = {
                ...loadLeagueConfig.value
            };
            clearLoadLeagueConfig();
            const loadingRouteName = isLeaguePageRouteName(to.name) ? to.name : RouteName.SPORT_LEAGUE;
            const navigationParameters = getSportlinePageNavigationParameters(to);
            const isLoadingNewLeague = currentLeagueId.value && navigationParameters.leagueId !== String(currentLeagueId.value);
            const { setPersistLocation } = useSportlinePersistLocation();
            setPersistLocation('sports', navigationParameters, 'league');
            activateBackButton(navigationParameters);
            if (!isReady.value || isLoadingNewLeague) {
                loadLeagueForConfig(config, loadingRouteName);
                handleRedirectsForEmptyPage(router, to);
            } else {
                syncLeagueForConfig(config, loadingRouteName);
                handleRedirectForWrongParameters(router, to);
            }
            next();
        });
    }
    return {
        prefetch
    };
}
