import { computed } from 'vue';
export function useLeaguePlayoffRound(props) {
    const { round } = props;
    const blocks = computed(()=>round.value.blocks || []);
    const blockPairs = computed(()=>{
        const result = [];
        const blocksList = blocks.value;
        if (blocksList.length <= 0) return [];
        if (1 === blocksList.length) return [
            [
                blocksList[0] || null
            ]
        ];
        for(let index = 0; index < blocksList.length; index += 2)result.push([
            blocksList[index] || null,
            blocksList[index + 1] || null
        ]);
        return result;
    });
    return {
        blockPairs
    };
}
