import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, isRef as _isRef, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_3 = {
    class: "post-match-statistic-incident__score"
};
const _hoisted_4 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_5 = {
    class: "post-match-statistic-incident__score"
};
const _hoisted_6 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_7 = {
    class: "post-match-statistic-incident__score"
};
const _hoisted_8 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_9 = {
    class: "post-match-statistic-incident__score"
};
const _hoisted_10 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_11 = {
    class: "post-match-statistic-incident__score"
};
const _hoisted_12 = {
    class: "post-match-statistic-incident post-match-statistic-incident--match-completed"
};
const _hoisted_13 = {
    class: "post-match-statistic-incident__label"
};
const _hoisted_14 = {
    class: "post-match-statistic-incident__score"
};
import { toRef } from 'vue';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import PostMatchStatisticIncidentItem from 'web/src/modules/sportline/submodules/event-details/components/post-match-statistic/PostMatchStatisticIncidentItem.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import usePostMatchStatisticIncidentsList from './usePostMatchStatisticIncidentsList';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticIncidentsList',
    props: {
        postMatchStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const postMatchStatistic = toRef(props, 'postMatchStatistic');
        const { isOpen, teams, marks } = usePostMatchStatisticIncidentsList({
            postMatchStatistic
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(teams) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", null, [
                    _unref(isOpen) ? (_openBlock(true), _createElementBlock(_Fragment, {
                        key: 0
                    }, _renderList(_unref(marks), (mark, index)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                            mark.type === _unref(TimelineMarkType).HALF_TIME ? (_openBlock(), _createElementBlock("div", {
                                key: `${mark.type}_${index}_HF`,
                                class: "post-match-statistic-incident post-match-statistic-incident--break"
                            }, [
                                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_HALF_TIME')), 1),
                                _createElementVNode("span", _hoisted_3, _toDisplayString(mark.label), 1)
                            ])) : mark.type === _unref(TimelineMarkType).FULL_TIME ? (_openBlock(), _createElementBlock("div", {
                                key: `${mark.type}_${index}_FT`,
                                class: "post-match-statistic-incident post-match-statistic-incident--break"
                            }, [
                                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_FULL_TIME')), 1),
                                _createElementVNode("span", _hoisted_5, _toDisplayString(mark.label), 1)
                            ])) : mark.type === _unref(TimelineMarkType).HALF_EXTRA_TIME ? (_openBlock(), _createElementBlock("div", {
                                key: `${mark.type}_${index}_HET`,
                                class: "post-match-statistic-incident post-match-statistic-incident--break"
                            }, [
                                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_HALF_EXTRA_TIME')), 1),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(mark.label), 1)
                            ])) : mark.type === _unref(TimelineMarkType).FULL_EXTRA_TIME ? (_openBlock(), _createElementBlock("div", {
                                key: `${mark.type}_${index}_FET`,
                                class: "post-match-statistic-incident post-match-statistic-incident--break"
                            }, [
                                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_FULL_EXTRA_TIME')), 1),
                                _createElementVNode("span", _hoisted_9, _toDisplayString(mark.label), 1)
                            ])) : mark.type === _unref(TimelineMarkType).PENALTY_SHOOTOUT ? (_openBlock(), _createElementBlock("div", {
                                key: `${mark.type}_${index}_PS`,
                                class: "post-match-statistic-incident post-match-statistic-incident--break"
                            }, [
                                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_PENALTY_SHOOTOUT')), 1),
                                _createElementVNode("span", _hoisted_11, _toDisplayString(mark.label), 1)
                            ])) : mark.type === _unref(TimelineMarkType).INCIDENTS ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 5
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mark.hostIncidents, (incident, incidentIndex)=>(_openBlock(), _createBlock(PostMatchStatisticIncidentItem, {
                                        key: `${mark.type}_${index}_${incident.competitorType}_${incident.type}_${incidentIndex}_incident`,
                                        incident: incident,
                                        team: _unref(teams)[0],
                                        class: "post-match-statistic-incident"
                                    }, null, 8, [
                                        "incident",
                                        "team"
                                    ]))), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mark.guestIncidents, (incident, incidentIndex)=>(_openBlock(), _createBlock(PostMatchStatisticIncidentItem, {
                                        key: `${mark.type}_${index}_${incident.competitorType}_${incident.type}_${incidentIndex}_incident`,
                                        incident: incident,
                                        team: _unref(teams)[1],
                                        class: "post-match-statistic-incident"
                                    }, null, 8, [
                                        "incident",
                                        "team"
                                    ]))), 128))
                            ], 64)) : _createCommentVNode("", true)
                        ], 64))), 256)) : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_MATCH_COMPLETED_WITH_SCORE')), 1),
                        _createElementVNode("span", _hoisted_14, _toDisplayString(postMatchStatistic.value.resultScore), 1)
                    ])
                ]),
                _unref(marks).length > 0 ? (_openBlock(), _createBlock(ExpandButton, {
                    key: 0,
                    "is-expanded": _unref(isOpen),
                    "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isOpen) ? isOpen.value = $event : null),
                    "opened-title": _ctx.$t('WEB2_MATCH_STATISTIC_HIDE'),
                    "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_SHOW'),
                    class: "post-match-statistic-incidents-list-toggle"
                }, null, 8, [
                    "is-expanded",
                    "opened-title",
                    "closed-title"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticIncidentsList'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
