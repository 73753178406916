import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SportlineEventDetailsToolbarContent from 'web/src/modules/sportline/submodules/event-details/components/toolbar/SportlineEventDetailsToolbarContent.vue';
import { useSportlineEventDetailsToolbar } from './useSportlineEventDetailsToolbar';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportEventDetailsToolbarDesktop',
    props: {
        hasRelatedEvents: {
            type: Boolean
        }
    },
    setup (__props) {
        const { matchTitle, isFavorite, canFavorite, isSportlineEventExist, emitChangeFavoriteState, onGoBackClick } = useSportlineEventDetailsToolbar();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportlineEventDetailsToolbarContent, {
                name: _unref(matchTitle),
                "is-favorite": _unref(isFavorite),
                "has-favorite-button": _unref(isSportlineEventExist) && _unref(canFavorite),
                "has-toggle-button": _ctx.hasRelatedEvents,
                onToggleFavorite: _unref(emitChangeFavoriteState)
            }, {
                backButton: _withCtx(()=>[
                        _createVNode(VButton, {
                            class: "sport-event-toolbar__back",
                            "icon-name": _unref(IconName).ARROW_LEFT,
                            "icon-size": _unref(IconSize).SIZE_24,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            onClick: _unref(onGoBackClick)
                        }, null, 8, [
                            "icon-name",
                            "icon-size",
                            "kind",
                            "onClick"
                        ])
                    ]),
                relatedList: _withCtx((param)=>{
                    let { hide } = param;
                    return [
                        _renderSlot(_ctx.$slots, "relatedList", {
                            hide: hide
                        })
                    ];
                }),
                _: 3
            }, 8, [
                "name",
                "is-favorite",
                "has-favorite-button",
                "has-toggle-button",
                "onToggleFavorite"
            ])), [
                [
                    _directive_auto_id,
                    'SportEventDetailsToolbarDesktop'
                ]
            ]);
        };
    }
});
