import { ref, toRef, watch, computed } from 'vue';
import { replace } from '@leon-hub/utils';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { BetgeniusFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import { getDefaultWidgetUrl } from 'web/src/modules/framed-app/utils';
function getWidgetLocale(widgetLocales, locale) {
    const customerLocale = locale.replace('_', '-').toLowerCase();
    return widgetLocales.find((value)=>value.locale === customerLocale)?.locale ?? widgetLocales.find((value)=>value.default)?.locale ?? '';
}
export function useBetgeniusWidget(props) {
    const { isActive, liveWidget, locale } = props;
    const betgeniusWidgetConfig = toRef(useSportlineWidgetsStore(), 'betgeniusConfigFinal');
    const readyToLoading = ref(false);
    const iframeWidgetSrc = computed(()=>{
        const config = betgeniusWidgetConfig.value;
        return config?.iframeUrl ? `${config.iframeUrl}/${IframeWidget.Betgenius}` : getDefaultWidgetUrl(IframeWidget.Betgenius);
    });
    const additionalQueryStringParameters = computed(()=>{
        const config = betgeniusWidgetConfig.value;
        const url = config?.active && config?.productionUrl ? replace(config.productionUrl, {
            fixtureId: liveWidget.value.id,
            locale: getWidgetLocale(config.widgetLocales, config.customLang || locale.value)
        }) : null;
        return url ? [
            {
                [BetgeniusFramedWidgetProperties.EncodedUrl]: encodeURIComponent(url)
            }
        ] : [];
    });
    watch(isActive, (value)=>{
        if (!value) return;
        readyToLoading.value = true;
    }, {
        immediate: true
    });
    return {
        readyToLoading,
        iframeWidgetSrc,
        additionalQueryStringParameters
    };
}
