import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { toRef, ref } from 'vue';
import SportlineFilter from 'web/src/modules/sportline/components/navigation-filter/default/SportlineFilter.vue';
import { useSportlineSportsFilter } from './useSportlineSportsFilter';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineSportsFilter',
    props: {
        options: {},
        filter: {},
        isFavoritesTabEnabled: {
            type: Boolean
        },
        isZeroMarginTabEnabled: {
            type: Boolean
        }
    },
    emits: [
        "change-filter"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { items } = useSportlineSportsFilter({
            options: toRef(props, 'options'),
            isFavoritesTabEnabled: toRef(props, 'isFavoritesTabEnabled', false),
            isZeroMarginTabEnabled: toRef(props, 'isZeroMarginTabEnabled', false),
            filter: toRef(props, 'filter')
        }, {
            changeFilter (filter) {
                emit('change-filter', filter);
            }
        });
        const baseFilter = ref();
        async function slideToActive(smooth) {
            await baseFilter.value?.slideToActive(smooth);
        }
        __expose({
            slideToActive
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportlineFilter, {
                ref_key: "baseFilter",
                ref: baseFilter,
                items: _unref(items)
            }, {
                afterItem: _withCtx((param)=>{
                    let { item } = param;
                    return [
                        _renderSlot(_ctx.$slots, "afterItem", {
                            item: item
                        })
                    ];
                }),
                _: 3
            }, 8, [
                "items"
            ])), [
                [
                    _directive_auto_id,
                    'SportlineSportsFilter'
                ]
            ]);
        };
    }
});
