import { ref, watch, toRef, computed } from 'vue';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useTheme } from 'web/src/modules/theme/composables';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { VirtualSportFramedWidgetProperties } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/enums';
import { VirtualSportWidgetPostMessageEvent, VirtualSportWidgetPostMessageBusInitiator } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/utils';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import { getDefaultWidgetUrl, getSearchParameterValue } from 'web/src/modules/framed-app/utils';
export function useVirtualSportWidget(props, emit) {
    const { clientId, iframeUrl, providerUrl, selectedVirtualSport, selectedBetsMappings, stylable } = props;
    const { theme } = useTheme();
    const lang = toRef(useI18nStore(), 'lang');
    const { addToSlip, removeFromSlip } = useVirtualSportStore();
    const iframeName = IframeWidget.VirtualSport;
    let postMessageBus = null;
    const frame = ref();
    const iframeWidgetSrc = computed(()=>iframeUrl.value ? `${iframeUrl.value}/${iframeName}` : getDefaultWidgetUrl(iframeName));
    const additionalQueryStringParameters = computed(()=>[
            {
                [VirtualSportFramedWidgetProperties.IsDesktop]: '1'
            },
            {
                [VirtualSportFramedWidgetProperties.Lang]: lang.value
            },
            {
                [VirtualSportFramedWidgetProperties.Theme]: theme.value.toLowerCase()
            }
        ]);
    function onWidgetBetsChanged(payload) {
        for (const bet of payload.added)addToSlip(bet);
        for (const bet of payload.removed)removeFromSlip(bet);
    }
    function syncConfig() {
        if (!selectedVirtualSport.value) return;
        const extraParameters = {};
        if (stylable.value) {
            const extraStyleParameter = getSearchParameterValue(VirtualSportFramedWidgetProperties.Style);
            extraParameters[VirtualSportFramedWidgetProperties.Style] = extraStyleParameter ? String(extraStyleParameter) : void 0;
        }
        postMessageBus?.emit(VirtualSportWidgetPostMessageEvent.syncConfig, {
            ...extraParameters,
            clientId: clientId.value,
            sport: selectedVirtualSport.value,
            url: providerUrl.value
        });
    }
    function onIFrameMounted() {
        const iframeContentWindow = requireContentWindowByIFrameName(iframeName);
        postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: VirtualSportWidgetPostMessageBusInitiator
        });
        postMessageBus.on(VirtualSportWidgetPostMessageEvent.stateChanged, (state)=>{
            emit.widgetLoaded(state.loaded);
            frame.value?.resizeIframe(state.height);
        });
        postMessageBus.on(VirtualSportWidgetPostMessageEvent.betsChanged, onWidgetBetsChanged);
        postMessageBus.on(VirtualSportWidgetPostMessageEvent.syncConfig, syncConfig);
    }
    function releaseWidget() {
        postMessageBus?.dispose();
        emit.widgetLoaded(false);
    }
    watch(selectedVirtualSport, syncConfig);
    watch(selectedBetsMappings, (mappings)=>{
        postMessageBus?.emit(VirtualSportWidgetPostMessageEvent.slipSelectedMappingsChanged, {
            mappings
        });
    }, {
        immediate: true
    });
    return {
        iframeName,
        frame,
        iframeWidgetSrc,
        additionalQueryStringParameters,
        onIFrameMounted,
        releaseWidget
    };
}
