import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "post-match-statistic-simple-result-score"
};
const _hoisted_2 = {
    class: "post-match-statistic-simple-result-score__teams"
};
const _hoisted_3 = {
    class: "post-match-statistic-simple-result-score__team"
};
const _hoisted_4 = {
    class: "post-match-statistic-simple-result-score__team-name"
};
const _hoisted_5 = {
    class: "post-match-statistic-simple-result-score__team"
};
const _hoisted_6 = {
    class: "post-match-statistic-simple-result-score__team-name"
};
const _hoisted_7 = {
    class: "post-match-statistic-simple-result-score__right"
};
const _hoisted_8 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_9 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_10 = {
    key: 1,
    class: "post-match-statistic-simple-result-score__scores post-match-statistic-simple-result-score__scores--final"
};
const _hoisted_11 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_12 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_13 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_14 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_15 = {
    key: 0,
    class: "post-match-statistic-simple-result-score__scores"
};
const _hoisted_16 = {
    class: "post-match-statistic-simple-result-score__score"
};
const _hoisted_17 = {
    class: "post-match-statistic-simple-result-score__score"
};
import { toRef } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import usePostMatchStatisticSimpleResultScore from './usePostMatchStatisticSimpleResultScore';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticSimpleResultScore',
    props: {
        postMatchStatistic: {},
        isDisplayScoresByTimes: {
            type: Boolean
        },
        isDisplayScoresBySets: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const postMatchStatistic = toRef(props, 'postMatchStatistic');
        const isDisplayScoresByTimes = toRef(props, 'isDisplayScoresByTimes', false);
        const isDisplayScoresBySets = toRef(props, 'isDisplayScoresBySets', false);
        const { teams, resultScore, scoresDetails, overtimeScores } = usePostMatchStatisticSimpleResultScore({
            postMatchStatistic,
            isDisplayScoresByTimes,
            isDisplayScoresBySets
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(teams) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(StatisticTeamLogo, {
                            class: "post-match-statistic-simple-result-score__team-logo",
                            team: _unref(teams)[0]
                        }, null, 8, [
                            "team"
                        ]),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(teams)[0].name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(StatisticTeamLogo, {
                            class: "post-match-statistic-simple-result-score__team-logo",
                            team: _unref(teams)[1]
                        }, null, 8, [
                            "team"
                        ]),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(teams)[1].name), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    isDisplayScoresBySets.value ? (_openBlock(true), _createElementBlock(_Fragment, {
                        key: 0
                    }, _renderList(_unref(scoresDetails), (score, index)=>(_openBlock(), _createElementBlock("div", {
                            key: `by-sets-${index}`,
                            class: "post-match-statistic-simple-result-score__scores"
                        }, [
                            _createElementVNode("div", _hoisted_8, _toDisplayString(score[0]), 1),
                            _createElementVNode("div", _hoisted_9, _toDisplayString(score[1]), 1)
                        ]))), 128)) : _createCommentVNode("", true),
                    _unref(resultScore) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(resultScore)[0]), 1),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(resultScore)[1]), 1)
                    ])) : _createCommentVNode("", true),
                    isDisplayScoresByTimes.value ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 2
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(scoresDetails), (score, index)=>(_openBlock(), _createElementBlock("div", {
                                key: `by-times-${index}`,
                                class: "post-match-statistic-simple-result-score__scores"
                            }, [
                                _createElementVNode("div", _hoisted_13, _toDisplayString(score[0]), 1),
                                _createElementVNode("div", _hoisted_14, _toDisplayString(score[1]), 1)
                            ]))), 128)),
                        _unref(overtimeScores) ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(overtimeScores)[0]), 1),
                            _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(overtimeScores)[1]), 1)
                        ])) : _createCommentVNode("", true)
                    ], 64)) : _createCommentVNode("", true)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticSimpleResultScore'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
