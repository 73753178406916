import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { LazyMainBannerSectionRouteComponent as MainBannerSectionRouteComponent } from 'web/src/modules/banners/components/MainBannerSectionRouteComponent';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import SportlineCybersportBlock from 'web/src/modules/sportline/submodules/cybersport/views/CybersportBlock/SportlineCybersportBlock.vue';
import CybersportNoEvents from 'web/src/modules/sportline/submodules/cybersport/components/CybersportNoEvents.vue';
import { useCybersportRegionTab } from './useCybersportRegionTab';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CybersportDefaultTab',
    props: {
        group: {},
        sportElement: {},
        canShowPrematch: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const group = toRef(props, 'group');
        const sportElement = toRef(props, 'sportElement');
        const canShowPrematch = toRef(props, 'canShowPrematch', false);
        const isOpen = toRef(props, 'isOpen', false);
        const settingsStore = useSportlineSettingsStore();
        const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');
        const { liveGroup, prematchGroup } = useCybersportRegionTab({
            group
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(liveGroup) && sportElement.value ? _withDirectives((_openBlock(), _createBlock(SportlineCybersportBlock, {
                    key: `${_unref(liveGroup).key}-live`,
                    "custom-title": _ctx.$t('WEB2_LIVE_SPORTLINE'),
                    "region-element": _unref(liveGroup).liveElement,
                    "sport-element": sportElement.value,
                    "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                    "collapse-league-key": "default-cybersport-league"
                }, null, 8, [
                    "custom-title",
                    "region-element",
                    "sport-element",
                    "is-market-types-selection-enabled"
                ])), [
                    [
                        _vShow,
                        isOpen.value
                    ]
                ]) : _createCommentVNode("", true),
                canShowPrematch.value && group.value.isEmpty ? (_openBlock(), _createBlock(CybersportNoEvents, {
                    key: `${group.value.key}-empty`,
                    "custom-title": _ctx.$t('WEB2_LIVE_SPORTLINE'),
                    "region-element": group.value.filterElement ?? group.value.prematchElement
                }, null, 8, [
                    "custom-title",
                    "region-element"
                ])) : _createCommentVNode("", true),
                isOpen.value ? (_openBlock(), _createBlock(_unref(MainBannerSectionRouteComponent), {
                    key: `${group.value.key}-banner`,
                    "is-static": "",
                    location: _unref(BannerLocation).CUSTOM,
                    "banner-type": _unref(BannerType).INLINE_BANNER,
                    "custom-location-id": group.value.transitionId,
                    "banner-category": _unref(BannerCategory).SUPER_LIVE
                }, null, 8, [
                    "location",
                    "banner-type",
                    "custom-location-id",
                    "banner-category"
                ])) : _createCommentVNode("", true),
                canShowPrematch.value && _unref(prematchGroup) && sportElement.value ? (_openBlock(), _createBlock(VLazyRender, {
                    key: 3
                }, {
                    default: _withCtx(()=>[
                            _unref(prematchGroup).doShowTitleComponent ? (_openBlock(), _createBlock(SportlineComingHeader, {
                                key: `${_unref(prematchGroup).key}-before-prematch`,
                                caption: _ctx.$t('JS_SPORTS_QUICKFILTERS_ALL')
                            }, null, 8, [
                                "caption"
                            ])) : _createCommentVNode("", true),
                            _withDirectives((_openBlock(), _createBlock(SportlineCybersportBlock, {
                                key: `${_unref(prematchGroup).key}-coming`,
                                "custom-title": _ctx.$t('JS_SPORTS_QUICKFILTERS_ALL'),
                                "region-element": _unref(prematchGroup).prematchElement,
                                "sport-element": sportElement.value,
                                "is-coming-headline": !_unref(prematchGroup).showComingName,
                                "upcoming-label-type": _unref(UpcomingLabelType).Timer,
                                "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                                "collapse-league-key": "default-cybersport-league"
                            }, null, 8, [
                                "custom-title",
                                "region-element",
                                "sport-element",
                                "is-coming-headline",
                                "upcoming-label-type",
                                "is-market-types-selection-enabled"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'group-coming'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                })) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'CybersportDefaultTab'
                ]
            ]);
        };
    }
});
