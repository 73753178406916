import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { toRef, computed } from 'vue';
import { useSportlineElementMarketColumns, useSportlineElementMarketTypes } from 'web/src/modules/sportline/composables/markets';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import RegionHeadline from 'web/src/modules/sportline/views/headline/RegionHeadline.vue';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineCybersportBlock',
    props: {
        regionElement: {},
        sportElement: {},
        withoutHeadline: {
            type: Boolean
        },
        isComingHeadline: {
            type: Boolean
        },
        upcomingLabelType: {},
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        collapseLeagueKey: {},
        canAddLeaguesToFavorite: {
            type: Boolean
        },
        customTitle: {}
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
        const withoutHeadline = toRef(props, 'withoutHeadline', false);
        const sportElement = toRef(props, 'sportElement');
        const regionElement = toRef(props, 'regionElement');
        const sportId = computed(()=>sportElement.value.sport.id);
        const basisKey = createSportlineFragmentBasisKey({
            sportId
        });
        const { marketsColumns } = useSportlineElementMarketColumns({
            sportlineElement: sportElement
        });
        const { marketsTypes } = useSportlineElementMarketTypes({
            sportlineElement: regionElement
        });
        const { canSelectMarketType } = useProvideMarketColumn({
            basisKey,
            isMarketTypesSelectionEnabled,
            marketsColumns,
            marketsTypes
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return regionElement.value.counters.live > 0 || regionElement.value.counters.prematch > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                withoutHeadline.value ? _createCommentVNode("", true) : (_openBlock(), _createBlock(RegionHeadline, {
                    key: 0,
                    "is-market-types-selection-enabled": _unref(canSelectMarketType),
                    "custom-title": _ctx.customTitle,
                    "region-id": regionElement.value.region.id,
                    "count-events": regionElement.value.counters.total,
                    "is-coming": _ctx.isComingHeadline,
                    "basis-key": _unref(basisKey)
                }, null, 8, [
                    "is-market-types-selection-enabled",
                    "custom-title",
                    "region-id",
                    "count-events",
                    "is-coming",
                    "basis-key"
                ])),
                _createVNode(SportlineRegionBlock, {
                    sport: sportElement.value.sport,
                    "region-element": regionElement.value,
                    "upcoming-label-type": _ctx.upcomingLabelType,
                    "can-add-to-favorite": true,
                    "can-add-outrights-to-favorite": true,
                    "collapse-league-key": _ctx.collapseLeagueKey,
                    "is-show-add-league-to-favorite": _ctx.canAddLeaguesToFavorite,
                    "extended-block-type": _unref(ExtendedEventBlock).Cybersport,
                    "basis-key": _unref(basisKey),
                    onClickEvent: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                }, null, 8, [
                    "sport",
                    "region-element",
                    "upcoming-label-type",
                    "can-add-to-favorite",
                    "can-add-outrights-to-favorite",
                    "collapse-league-key",
                    "is-show-add-league-to-favorite",
                    "extended-block-type",
                    "basis-key"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportlineCybersportBlock'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
