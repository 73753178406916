import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import { getSportFamilyEmoji } from 'web/src/modules/sportline/utils';
import DateTime from 'web/src/utils/DateTime';
export function getSportEventDetailsSeoMetaInfoFromPostStatistic(statistic, options) {
    if (!statistic) return {};
    const host = statistic.teams?.[0];
    const guest = statistic.teams?.[1];
    if (!host || !guest) return {};
    const sportFamily = statistic.family || '';
    const kickoff = statistic.matchDate;
    return {
        name: `${host.name || ''} - ${guest.name || ''}`,
        kickoff: kickoff ? kickoff.toString() : '',
        time: kickoff ? DateTime.withTimeStamp(kickoff).toTime() : '',
        date: kickoff ? useDateLocalizers().toFullLocaleDate(kickoff) : '',
        startDateISO: kickoff ? new DateTime(kickoff).toISOString() : '',
        sport: '',
        // have no data
        sportFamily,
        sportEmoji: getSportFamilyEmoji(sportFamily),
        region: '',
        // have no data
        league: '',
        // have no data
        url: options?.url || '',
        home: host.name || '',
        away: guest.name || '',
        logoHome: host.logo || '',
        logoAway: guest.logo || ''
    };
}
