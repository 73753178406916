import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { useNeedRedirectFromTab } from 'web/src/modules/sportline/components/SportlineTab/composables';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import { useSportsZeroMarginTab } from '../composables/tabs/useSportsZeroMarginTab';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsZeroMarginTab',
    props: {
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "redirect-from-tab",
        "reset-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const isActive = toRef(props, 'isActive');
        const { isMarketTypesSelectionEnabled, isReadyToDisplayContent, zeroMarginEventsList, hasElementsForCurrentFilter, isNeedRedirectFromTab } = useSportsZeroMarginTab({
            isActive
        });
        useNeedRedirectFromTab({
            isNeedRedirectFromTab
        }, {
            emitRedirectFromTab () {
                emit('redirect-from-tab');
            }
        });
        const basisKey = createSportlineFragmentBasisKey({
            salt: 'zero-margin'
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isReadyToDisplayContent) ? _unref(hasElementsForCurrentFilter) ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 1
                }, _renderList(_unref(zeroMarginEventsList), (sportElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                        key: sportElement.sport.id,
                        "sport-element": sportElement,
                        "is-market-types-selection-enabled": _unref(isMarketTypesSelectionEnabled),
                        "can-add-to-favorite": true,
                        "extended-block-type": _unref(ExtendedEventBlock).ZeroMargin,
                        "basis-key": _unref(basisKey)
                    }, null, 8, [
                        "sport-element",
                        "is-market-types-selection-enabled",
                        "can-add-to-favorite",
                        "extended-block-type",
                        "basis-key"
                    ]))), 128)) : (_openBlock(), _createBlock(SportNoResults, {
                    key: 2,
                    "can-reset-filter": "",
                    onReset: _cache[0] || (_cache[0] = ($event)=>emit('reset-filter'))
                })) : (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                    key: 0
                }))
            ])), [
                [
                    _directive_auto_id,
                    'SportsZeroMarginTab'
                ]
            ]);
        };
    }
});
