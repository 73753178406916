import { toRef, ref } from 'vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useSportsListFilterTransition() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isFilterTransitionFadeEffectEnabled = toRef(sportlineSettingsStore, 'isFilterTransitionFadeEffectEnabled');
    const filterTransitionDuration = toRef(sportlineSettingsStore, 'filterTransitionDuration');
    const sportlineSportsStore = useSportlineSportsStore();
    const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
    const listTransitionRef = ref();
    const activeTransitionId = ref(null);
    const lastShownSportFamily = ref(null);
    function onChangeTransitionId(id) {
        activeTransitionId.value = id;
        lastShownSportFamily.value = id ? // we will use transitionId for display active tab
        null : // display last tab before transition changed
        selectedSportFilter.value.sportFamily ?? null;
    }
    return {
        listTransitionRef,
        activeTransitionId,
        lastShownSportFamily,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        onChangeTransitionId
    };
}
