import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';
import OddsList from 'web/src/modules/sportline/submodules/odds/components/OddsList/OddsList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OddsRoutePage',
    setup (__props) {
        const oddsStore = useOddsStore();
        const odds = toRef(oddsStore, 'oddsList');
        const customerOdd = toRef(oddsStore, 'userOddTypeLocal');
        function setOdd(oddKind) {
            oddsStore.setCustomerOdd(oddKind);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['odds-wrapper'])
            }, [
                _createVNode(OddsList, {
                    odd: customerOdd.value,
                    odds: odds.value,
                    onClick: _cache[0] || (_cache[0] = ($event)=>setOdd($event))
                }, null, 8, [
                    "odd",
                    "odds"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'OddsRoutePage'
                ]
            ]);
        };
    }
});
