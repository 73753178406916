import { computed, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
export function useJoinMatchesStatistic(props) {
    const { playStatistic } = props;
    const { $translate } = useI18n();
    const locale = toRef(useI18nStore(), 'locale');
    const hostGoals = computed(()=>playStatistic.value?.host.goals ?? 0);
    const hostGoalsPayload = computed(()=>({
            count: String(hostGoals.value)
        }));
    const hostGoalsLabelOne = $translate('WEB2_MATCH_STATISTIC_GOAL_ONE', hostGoalsPayload);
    const hostGoalsLabelTwo = $translate('WEB2_MATCH_STATISTIC_GOAL_TWO', hostGoalsPayload);
    const hostGoalsLabelMany = $translate('WEB2_MATCH_STATISTIC_GOAL_MANY', hostGoalsPayload);
    const hostGoalsLabel = computed(()=>LanguageMicroSettings.plural([
            hostGoalsLabelOne.value,
            hostGoalsLabelTwo.value,
            hostGoalsLabelMany.value
        ], hostGoals.value, locale.value));
    const hostWins = computed(()=>playStatistic.value?.host.wins ?? 0);
    const hostWinsPayload = computed(()=>({
            count: String(hostWins.value)
        }));
    const hostWinsLabelOne = $translate('WEB2_MATCH_STATISTIC_WIN_ONE', hostWinsPayload);
    const hostWinsLabelTwo = $translate('WEB2_MATCH_STATISTIC_WIN_TWO', hostWinsPayload);
    const hostWinsLabelMany = $translate('WEB2_MATCH_STATISTIC_WIN_MANY', hostWinsPayload);
    const hostWinsLabel = computed(()=>LanguageMicroSettings.plural([
            hostWinsLabelOne.value,
            hostWinsLabelTwo.value,
            hostWinsLabelMany.value
        ], hostWins.value, locale.value));
    const guestGoals = computed(()=>playStatistic.value?.guest.goals ?? 0);
    const guestGoalsPayload = computed(()=>({
            count: String(guestGoals.value)
        }));
    const guestGoalsLabelOne = $translate('WEB2_MATCH_STATISTIC_GOAL_ONE', guestGoalsPayload);
    const guestGoalsLabelTwo = $translate('WEB2_MATCH_STATISTIC_GOAL_TWO', guestGoalsPayload);
    const guestGoalsLabelMany = $translate('WEB2_MATCH_STATISTIC_GOAL_MANY', guestGoalsPayload);
    const guestGoalsLabel = computed(()=>LanguageMicroSettings.plural([
            guestGoalsLabelOne.value,
            guestGoalsLabelTwo.value,
            guestGoalsLabelMany.value
        ], guestGoals.value, locale.value));
    const guestWins = computed(()=>playStatistic.value?.guest.wins ?? 0);
    const guestWinsPayload = computed(()=>({
            count: String(guestWins.value)
        }));
    const guestWinsLabelOne = $translate('WEB2_MATCH_STATISTIC_WIN_ONE', guestWinsPayload);
    const guestWinsLabelTwo = $translate('WEB2_MATCH_STATISTIC_WIN_TWO', guestWinsPayload);
    const guestWinsLabelMany = $translate('WEB2_MATCH_STATISTIC_WIN_MANY', guestWinsPayload);
    const guestWinsLabel = computed(()=>LanguageMicroSettings.plural([
            guestWinsLabelOne.value,
            guestWinsLabelTwo.value,
            guestWinsLabelMany.value
        ], guestWins.value, locale.value));
    const totalMatches = computed(()=>playStatistic.value?.totalGames ?? 0);
    const totalMatchesPayload = computed(()=>({
            count: String(totalMatches.value)
        }));
    const totalMatchesLabelOne = $translate('WEB2_MATCH_STATISTIC_MATCH_ONE', totalMatchesPayload);
    const totalMatchesLabelTwo = $translate('WEB2_MATCH_STATISTIC_MATCH_TWO', totalMatchesPayload);
    const totalMatchesLabelMany = $translate('WEB2_MATCH_STATISTIC_MATCH_MANY', totalMatchesPayload);
    const totalMatchesLabel = computed(()=>LanguageMicroSettings.plural([
            totalMatchesLabelOne.value,
            totalMatchesLabelTwo.value,
            totalMatchesLabelMany.value
        ], totalMatches.value, locale.value));
    const totalDraws = computed(()=>playStatistic.value?.draws ?? 0);
    const totalDrawsPayload = computed(()=>({
            count: String(totalDraws.value)
        }));
    const totalDrawsLabelOne = $translate('WEB2_MATCH_STATISTIC_DRAW_ONE', totalDrawsPayload);
    const totalDrawsLabelTwo = $translate('WEB2_MATCH_STATISTIC_DRAW_TWO', totalDrawsPayload);
    const totalDrawsLabelMany = $translate('WEB2_MATCH_STATISTIC_DRAW_MANY', totalDrawsPayload);
    const totalDrawsLabel = computed(()=>LanguageMicroSettings.plural([
            totalDrawsLabelOne.value,
            totalDrawsLabelTwo.value,
            totalDrawsLabelMany.value
        ], totalDraws.value, locale.value));
    return {
        hostGoalsLabel,
        hostWinsLabel,
        guestGoalsLabel,
        guestWinsLabel,
        totalMatchesLabel,
        totalDrawsLabel
    };
}
