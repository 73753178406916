import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0
};
import { computed, toRef } from 'vue';
import { BetlineStatisticMatchStatus, BetlineStatisticWinnerType } from 'web/src/modules/sportline/enums/rest';
import { getWinnerByType } from 'web/src/modules/sportline/utils/rest/statistic';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
const isLive = false;
export default /*@__PURE__*/ _defineComponent({
    __name: 'MatchStatisticResultIndicator',
    props: {
        match: {},
        displayedForTeam: {}
    },
    setup (__props) {
        const props = __props;
        const match = toRef(props, 'match');
        const displayedForTeam = toRef(props, 'displayedForTeam', null);
        // @TODO compare with current open details
        const winner = computed(()=>getWinnerByType(match.value.teams, match.value.winner));
        const isDraw = computed(()=>match.value.winner === BetlineStatisticWinnerType.Draw);
        const isCompletedOnPenalty = computed(()=>match.value.status === BetlineStatisticMatchStatus.COMPLETED_ON_PENALTY);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return displayedForTeam.value || isLive ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                isLive ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass([
                        _ctx.$style['match-statistic-result-indicator'],
                        _ctx.$style['match-statistic-result-indicator--unknown']
                    ])
                }, _cache[0] || (_cache[0] = [
                    _createTextVNode("?")
                ]), 2)), [
                    [
                        _unref(vPopperHint),
                        _ctx.$t('WEB2_LIVE_SPORTLINE')
                    ]
                ]) : isDraw.value ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass([
                        _ctx.$style['match-statistic-result-indicator'],
                        _ctx.$style['match-statistic-result-indicator--draw']
                    ])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW_SHORT')), 1)
                ], 2)), [
                    [
                        _unref(vPopperHint),
                        _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW')
                    ]
                ]) : winner.value && winner.value.id === displayedForTeam.value?.id ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: _normalizeClass([
                        _ctx.$style['match-statistic-result-indicator'],
                        _ctx.$style['match-statistic-result-indicator--win']
                    ])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_SHORT')), 1)
                ], 2)), [
                    [
                        _unref(vPopperHint),
                        isCompletedOnPenalty.value ? _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_ON_PENALTY') : _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN')
                    ]
                ]) : _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 3,
                    class: _normalizeClass([
                        _ctx.$style['match-statistic-result-indicator'],
                        _ctx.$style['match-statistic-result-indicator--lose']
                    ])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_SHORT')), 1)
                ], 2)), [
                    [
                        _unref(vPopperHint),
                        isCompletedOnPenalty.value ? _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_ON_PENALTY') : _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE')
                    ]
                ])
            ])), [
                [
                    _directive_auto_id,
                    'MatchStatisticResultIndicator'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
