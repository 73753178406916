import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useBaseSportEventsRegionPageNavigationGuard } from 'web/src/modules/sportline/submodules/region/services/navigation/useBaseSportEventsRegionPageNavigationGuard';
import { useLoadRegionConfig } from 'web/src/modules/sportline/submodules/region/composables';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { getIsBrokenUrl } from './utils/getIsBrokenUrl';
export function usePrerenderSportEventsRegionPageNavigationGuard() {
    const regionPageStore = useRegionPageStore();
    const navigationStore = useSportlineNavigationStore();
    const containEvents = toRef(regionPageStore, 'containEvents');
    const region = toRef(regionPageStore, 'region');
    const baseRegionNavigationGuard = useBaseSportEventsRegionPageNavigationGuard();
    const sportlineNavigationBetweenPagesFilter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');
    const { prefetch: basePrefetch } = baseRegionNavigationGuard;
    function prefetch(router, to, from, next) {
        const { regionConfig } = useLoadRegionConfig({
            to: toRef(to),
            sportlineNavigationBetweenPagesFilter
        });
        basePrefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            // LEONWEB-3297 handle 301 redirect broken url
            if (getIsBrokenUrl(to)) {
                next(router.resolve301location({
                    name: RouteName.HOME
                }));
                return;
            }
            regionPageStore.awaitStateIsLoaded().then(()=>{
                if (!containEvents.value && !region.value) {
                    // @TODO split 301 and 302 redirects for just empty events and corrupted regions
                    next(router.resolve302location({
                        name: RouteName.HOME
                    }));
                    return;
                }
                next();
            });
            regionPageStore.setConfigForRegionLoading(regionConfig.value);
            regionPageStore.initialRequests();
        });
    }
    return {
        prefetch
    };
}
