import RouteName from '@leon-hub/routing-config';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { usePrerenderSportsPageNavigation } from 'web/src/modules/sportline/submodules/sports/composables/redirects';
import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';
let PrerenderSportsPageNavigationService = class PrerenderSportsPageNavigationService extends BaseSportsPageNavigationService {
    prefetch(router, to, from, next) {
        super.prefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            const { setRouteContentLoaded } = useRootNavigationStore();
            const { initialRequests, awaitStateIsLoaded } = useSportlineSportsStore();
            const { proceedRedirects } = usePrerenderSportsPageNavigation({
                router,
                to,
                from
            });
            const dateTimeFilter = this.getDateTimeFilter(to);
            initialRequests({
                silent: false,
                dateTimeFilter
            }).then(()=>{
                setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
            });
            awaitStateIsLoaded().then(()=>proceedRedirects(next), next);
        });
    }
};
export { PrerenderSportsPageNavigationService as default };
