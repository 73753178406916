import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
export function useHeadlineDefaultLayout(props) {
    const { sport } = props;
    const { backgroundColor } = useSportBackgroundColor({
        sport
    });
    return {
        backgroundColor
    };
}
