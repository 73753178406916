import { computed } from 'vue';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
export function useAvailableHeadlineSlides(props) {
    const { sportlineEvent, isStreamAvailable, isLiveWidgetAvailable } = props;
    const switcherSlidesIds = computed(()=>{
        const slides = [];
        if (sportlineEvent.value) slides.push({
            id: HeadlineSlideName.Info
        });
        if (isStreamAvailable.value) slides.push({
            id: HeadlineSlideName.LiveStream
        });
        if (isLiveWidgetAvailable.value) slides.push({
            id: HeadlineSlideName.LiveWidget
        });
        return slides;
    });
    return {
        switcherSlidesIds
    };
}
