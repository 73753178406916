import { computed, toValue } from 'vue';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import { getGroupsForSportElement, getNoEventsElement } from 'web/src/modules/sportline/views/virtual-list-renderer/utils';
export function reduceLiveElements(displayedGroups, options, result) {
    let prematchGroupsCount = 0;
    const groups = toValue(displayedGroups);
    const canCollapseSport = toValue(options.canCollapseSport ?? false);
    const isDefaultCollapsedSport = toValue(options.isDefaultCollapsedSport ?? false);
    const expandedSportStateMap = toValue(options.expandedSportStateMap ?? {});
    for (const [index, { group, columnInfo }] of groups.entries()){
        const { liveElement, prematchElement, filterElement } = group;
        if (prematchElement) prematchGroupsCount += 1;
        if (!liveElement) {
            result.push(...getNoEventsElement(filterElement ?? null, {
                blockLayout: VirtualListBlockLayout.Live,
                basisKey: columnInfo.key
            }));
            continue;
        }
        result.push(...getGroupsForSportElement(group.liveElement ?? null, {
            isLastSport: groups.length === index + 1 && !prematchGroupsCount,
            blockLayout: VirtualListBlockLayout.Live,
            basisKey: columnInfo.key,
            collapseSportKey: canCollapseSport ? group.expandKey : null,
            isDefaultCollapsedSport,
            expandedSportStateMap
        }));
    }
    return result;
}
export function reducePrematchElements(displayedGroups, options, result) {
    const groups = toValue(displayedGroups);
    const canCollapseSport = toValue(options.canCollapseSport ?? false);
    const isDefaultCollapsedSport = toValue(options.isDefaultCollapsedSport ?? false);
    const expandedSportStateMap = toValue(options.expandedSportStateMap ?? {});
    for (const [index, { group, columnInfo }] of groups.entries()){
        const { prematchElement } = group;
        if (!!prematchElement) result.push(...getGroupsForSportElement(group.prematchElement ?? null, {
            isLastSport: groups.length === index + 1,
            blockLayout: VirtualListBlockLayout.Coming,
            basisKey: columnInfo.key,
            collapseSportKey: canCollapseSport ? group.expandKey : null,
            isDefaultCollapsedSport,
            expandedSportStateMap
        }));
    }
    return result;
}
export function createVirtualListRef(displayedGroups, options) {
    return computed(()=>reducePrematchElements(displayedGroups, options, reduceLiveElements(displayedGroups, options, [])));
}
