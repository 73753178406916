import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { StatisticColoredLabelType, StatisticColoredLabelSize } from 'web/src/modules/sportline/submodules/core-statistic/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticColoredLabel',
    props: {
        label: {
            default: ''
        },
        type: {
            default: StatisticColoredLabelType.DEFAULT
        },
        size: {
            default: StatisticColoredLabelSize.DEFAULT
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "statistic-colored-label",
                    {
                        'statistic-colored-label--type-transparent': _ctx.type === _unref(StatisticColoredLabelType).TRANSPARENT,
                        'statistic-colored-label--type-default': _ctx.type === _unref(StatisticColoredLabelType).DEFAULT,
                        'statistic-colored-label--type-home': _ctx.type === _unref(StatisticColoredLabelType).HOME,
                        'statistic-colored-label--type-away': _ctx.type === _unref(StatisticColoredLabelType).AWAY,
                        'statistic-colored-label--size-default': _ctx.size === _unref(StatisticColoredLabelSize).DEFAULT,
                        'statistic-colored-label--size-medium': _ctx.size === _unref(StatisticColoredLabelSize).MEDIUM
                    }
                ])
            }, [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'StatisticColoredLabel'
                ]
            ]);
        };
    }
});
