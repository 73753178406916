import { toRef, ref } from 'vue';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportsListDateFilter } from 'web/src/modules/sportline/submodules/sports-list/components/composables';
export function useAllTabDateTimeFilter() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isSportFiltersEnabled = toRef(sportlineSettingsStore, 'isSportFiltersEnabled');
    const sportlineCybersportStore = useSportlineCybersportStore();
    const currentDateTimeFilter = toRef(sportlineCybersportStore, 'sportsTreeDateTimeFilter');
    const { reloadSportsTreeForFilter } = sportlineCybersportStore;
    const dateTimeFilterRef = ref();
    const { dateTimeFilterCustomFrom: datetimeCustomFrom, dateTimeFilterCustomTo: datetimeCustomTo, dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod, dateTimeFilterActiveSportlineType: datetimeActiveSportlineType } = useSportsListDateFilter({
        dateTimeFilter: currentDateTimeFilter
    });
    async function onDateTimeFilterSelect(filter) {
        await reloadSportsTreeForFilter(filter);
    }
    async function onDateTimeFilterSelectCustomRange(range) {
        await reloadSportsTreeForFilter(range);
    }
    async function resetDateTimeFilter() {
        await reloadSportsTreeForFilter(null);
    }
    return {
        dateTimeFilterRef,
        isSportFiltersEnabled,
        currentDateTimeFilter,
        datetimeActiveKickoffPeriod,
        datetimeActiveSportlineType,
        datetimeCustomFrom,
        datetimeCustomTo,
        onDateTimeFilterSelect,
        onDateTimeFilterSelectCustomRange,
        resetDateTimeFilter
    };
}
