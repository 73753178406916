/**
 * @see web/src/utils/LanguageMicroSettings.ts
 *
 * Select one of factories to create Ref with translation
 */ export function selectPluralForm(factories, number, locale) {
    if ('ru_RU' === locale) {
        let n = Math.abs(number);
        n %= 100;
        if (n >= 5 && n <= 20) return factories[2](number);
        n %= 10;
        if (1 === n) return factories[0](number);
        if (n >= 2 && n <= 4) return factories[1](number);
        return factories[2](number);
    }
    if (1 === number) return factories[0](number);
    return factories[2](number);
}
