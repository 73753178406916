import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    ref: "container"
};
import { toRef, computed } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { SportlineType } from 'web/src/modules/sportline/enums';
import EventDetailsMiniScoreBoard from 'web/src/modules/sportline/submodules/event-details/views/EventDetailsMiniScoreBoard.vue';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventDetailsFixedBarRouteComponent',
    setup (__props) {
        const bus = useEventsBus();
        const sportlineEventDetailsStore = useSportlineEventDetailsStore();
        const isMiniBoardShown = toRef(sportlineEventDetailsStore, 'isMiniBoardShown');
        const sportEventDetails = toRef(sportlineEventDetailsStore, 'sportEventDetails');
        function onMiniScoreBoardClick() {
            bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
        }
        const isMiniBoardAllowed = computed(()=>sportEventDetails.value?.type === SportlineType.Live);
        const miniBoardStyles = computed(()=>({
                'margin-top': isMiniBoardShown.value ? '0' : '-82px',
                opacity: isMiniBoardShown.value ? '1' : '0'
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                isMiniBoardAllowed.value && sportEventDetails.value && _unref(isLiveSportlineEvent)(sportEventDetails.value) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['mini-board__container'])
                }, [
                    _createVNode(EventDetailsMiniScoreBoard, {
                        class: _normalizeClass({
                            [_ctx.$style['mini-board']]: true,
                            [_ctx.$style['mini-board--animation-hide']]: !isMiniBoardShown.value
                        }),
                        style: _normalizeStyle(miniBoardStyles.value),
                        "sport-event-details": sportEventDetails.value,
                        onClick: onMiniScoreBoardClick
                    }, null, 8, [
                        "class",
                        "style",
                        "sport-event-details"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'SportlineEventDetailsFixedBarRouteComponent'
                ]
            ]);
        };
    }
});
