import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useVirtualSportDebugStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DebugIndicator',
    setup (__props) {
        const debugVirtualSportStore = useVirtualSportDebugStore();
        const debugBetDelay = toRef(debugVirtualSportStore, 'betDelay');
        const delayInProgress = toRef(debugVirtualSportStore, 'delayInProgress');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return debugBetDelay.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['debug-indicator']),
                title: `Bet delay: ${debugBetDelay.value}ms`
            }, [
                delayInProgress.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['debug-indicator-progress']),
                    style: _normalizeStyle({
                        'animation-duration': `${debugBetDelay.value}ms`
                    })
                }, null, 6)) : (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 1,
                    name: _unref(IconName).ATTENTION_OUTLINE,
                    size: _unref(IconSize).SIZE_20
                }, null, 8, [
                    "name",
                    "size"
                ]))
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'DebugIndicator'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
