import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import LeaguesList from 'web/src/modules/sportline/submodules/sports-list/views/leagues-list/LeaguesList.vue';
import RegionSpoiler from 'web/src/modules/sportline/submodules/sports-list/views/regions-list/RegionSpoiler.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import { useSportsSportTab } from '../composables/tabs/useSportsSportTab';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsSportTab',
    props: {
        sportId: {},
        isChildRoute: {
            type: Boolean
        }
    },
    emits: [
        "redirect-from-tab",
        "reset-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportId = toRef(props, 'sportId');
        const isChildRoute = toRef(props, 'isChildRoute', false);
        const { sportElement, topLeagues, isCyberSportPage, hasElementsForCurrentFilter, currentDateTimeFilter, setOpenRegionState, isRegionOpen } = useSportsSportTab({
            sportId
        });
        // @see LEONWEB-5037 do not redirect from empty sports tabs
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(sportElement) && _unref(topLeagues).length > 0 ? (_openBlock(), _createBlock(LeaguesList, {
                    key: 0,
                    sport: _unref(sportElement).sport,
                    leagues: _unref(topLeagues),
                    "date-time-filter": _unref(currentDateTimeFilter),
                    "is-top": ""
                }, null, 8, [
                    "sport",
                    "leagues",
                    "date-time-filter"
                ])) : _createCommentVNode("", true),
                _unref(sportElement) && _unref(hasElementsForCurrentFilter) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-header'])
                    }, _toDisplayString(_unref(isCyberSportPage) ? _ctx.$t('WEB2_ESPORT_GAMES_ALL_TITLE') : _ctx.$t('WEB2_SPORTS_REGIONS_ALL_TITLE')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['regions'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sportElement).regions, (regionElement)=>(_openBlock(), _createBlock(RegionSpoiler, {
                                key: `level1-spoiler-${regionElement.region.id}-${isChildRoute.value}`,
                                "sport-element": _unref(sportElement),
                                "region-element": regionElement,
                                "is-open": !!process.env.VUE_APP_PRERENDER || _unref(isRegionOpen)(regionElement.region.id),
                                "date-time-filter": _unref(currentDateTimeFilter),
                                "is-cyber-sport-page": _unref(isCyberSportPage),
                                "is-child-route": isChildRoute.value,
                                onChangeOpenState: ($event)=>_unref(setOpenRegionState)(regionElement.region.id, $event)
                            }, null, 8, [
                                "sport-element",
                                "region-element",
                                "is-open",
                                "date-time-filter",
                                "is-cyber-sport-page",
                                "is-child-route",
                                "onChangeOpenState"
                            ]))), 128))
                    ], 2)
                ], 64)) : (_openBlock(), _createBlock(SportNoResults, {
                    key: 2,
                    "can-reset-filter": "",
                    onReset: _cache[0] || (_cache[0] = ($event)=>emit('reset-filter'))
                }))
            ])), [
                [
                    _directive_auto_id,
                    'SportsSportTab'
                ]
            ]);
        };
    }
});
