import { IconName, IconSize } from '@leon-hub/icons';
export const favoriteIconSize = IconSize.SIZE_24;
export const activeFavoriteIcon = {
    name: IconName.STAR,
    size: favoriteIconSize
};
export const inactiveFavoriteIcon = {
    name: IconName.STAR_OUTLINE,
    size: favoriteIconSize
};
