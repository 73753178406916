import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import HeadlineDefaultWideLayout from './HeadlineDefaultWideLayout.vue';
import HeadlineCybersportWideLayout from './HeadlineCybersportWideLayout.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WideHeadlineLayout',
    props: {
        segmentId: {},
        sportEventDetails: {},
        hasInplayComponent: {
            type: Boolean
        },
        hasPrimaryMarket: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const segmentId = toRef(props, 'segmentId');
        const sportEventDetails = toRef(props, 'sportEventDetails');
        const hasInplayComponent = toRef(props, 'hasInplayComponent');
        const hasPrimaryMarket = toRef(props, 'hasPrimaryMarket');
        return (_ctx, _cache)=>segmentId.value === _unref(SportSegmentId).CyberSport ? (_openBlock(), _createBlock(HeadlineCybersportWideLayout, {
                key: 0,
                "sport-event-details": sportEventDetails.value,
                "has-inplay-component": hasInplayComponent.value,
                "has-primary-market": hasPrimaryMarket.value
            }, {
                info: _withCtx((param)=>{
                    let { backgroundColor } = param;
                    return [
                        _renderSlot(_ctx.$slots, "info", {
                            backgroundColor: backgroundColor
                        })
                    ];
                }),
                "inplay-component": _withCtx((param)=>{
                    let { setHeight } = param;
                    return [
                        _renderSlot(_ctx.$slots, "inplay-component", {
                            setHeight: setHeight
                        })
                    ];
                }),
                _: 3
            }, 8, [
                "sport-event-details",
                "has-inplay-component",
                "has-primary-market"
            ])) : (_openBlock(), _createBlock(HeadlineDefaultWideLayout, {
                key: 1,
                "sport-event-details": sportEventDetails.value,
                "has-inplay-component": hasInplayComponent.value,
                "has-primary-market": hasPrimaryMarket.value
            }, {
                info: _withCtx((param)=>{
                    let { backgroundColor } = param;
                    return [
                        _renderSlot(_ctx.$slots, "info", {
                            backgroundColor: backgroundColor
                        })
                    ];
                }),
                "inplay-component": _withCtx((param)=>{
                    let { setHeight, backgroundColor } = param;
                    return [
                        _renderSlot(_ctx.$slots, "inplay-component", {
                            setHeight: setHeight,
                            backgroundColor: backgroundColor
                        })
                    ];
                }),
                _: 3
            }, 8, [
                "sport-event-details",
                "has-inplay-component",
                "has-primary-market"
            ]));
    }
});
