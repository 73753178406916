import { ref, computed } from 'vue';
export var TopPlayerListType;
var TopPlayerListType1;
(TopPlayerListType1 = TopPlayerListType || (TopPlayerListType = {}))["SCORERS"] = "scorers", TopPlayerListType1["ASSISTS"] = "assists";
export function useLeagueTopPlayers(props) {
    const { leagueTopPlayers } = props;
    const collapseAfterSize = 5;
    const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);
    const scorersRaw = computed(()=>(leagueTopPlayers.value.scorers || []).map((player)=>({
                ...player
            })));
    const assistsRaw = computed(()=>(leagueTopPlayers.value.assists || []).map((player)=>({
                ...player
            })));
    const canExpand = computed(()=>scorersRaw.value.length > collapseAfterSize || assistsRaw.value.length > collapseAfterSize);
    const scorers = computed(()=>isExpanded.value ? scorersRaw.value : scorersRaw.value.slice(0, collapseAfterSize));
    const assists = computed(()=>isExpanded.value ? assistsRaw.value : assistsRaw.value.slice(0, collapseAfterSize));
    const lists = computed(()=>[
            {
                type: "scorers",
                list: scorers.value
            },
            {
                type: "assists",
                list: assists.value
            }
        ].filter((list)=>list.list.length > 0));
    return {
        canExpand,
        isExpanded,
        lists
    };
}
