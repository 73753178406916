import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualListRendererNoEventsElement',
    props: {
        element: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['no-events-content'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['no-events-content__label'])
                }, [
                    _ctx.element.blockLayout === _unref(VirtualListBlockLayout).Live ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_LIVE_NOEVENTS_TITLE')), 1)
                    ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('JS_EVENTS_NOT_FOUND')), 1)
                    ], 64))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VirtualListRendererNoEventsElement'
                ]
            ]);
        };
    }
});
