import { computed, toRef } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { SportEventStatus } from 'web/src/modules/sportline/enums';
import { isSportlineEventSuspended as checkIsSportlineEventSuspended } from 'web/src/modules/sportline/utils/sportlineEvent';
import { useSportlineEventDetailsParts } from 'web/src/modules/sportline/submodules/event-details/composables/event-details';
export function useSportlineEventDetailsContent() {
    const { $translate } = useI18n();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isMarketGroupsTabsEnabled = toRef(sportlineSettingsStore, 'isMarketGroupsTabsEnabled');
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const isLoaded = toRef(sportlineEventDetailsStore, 'isLoaded');
    const isShowingLoadingIndicator = toRef(sportlineEventDetailsStore, 'isShowingLoadingIndicator');
    const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const relatedSportListElement = toRef(sportlineEventDetailsStore, 'relatedSportListElement');
    const leagueEventsList = toRef(sportlineEventDetailsStore, 'leagueEventsList');
    const defaultLayoutType = toRef(sportlineEventDetailsStore, 'defaultLayoutType');
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const hasStatistic = toRef(sportlineEventStatisticStore, 'hasMatchStatistic');
    const hasPostMatchStatistic = toRef(sportlineEventStatisticStore, 'hasPostMatchStatistics');
    const { sport, region, league } = useSportlineEventDetailsParts({
        sportEventDetails: sportlineEvent
    });
    const activeEventId = computed(()=>sportlineEvent.value?.id);
    const isSportlineEventExist = computed(()=>!!sportlineEvent.value);
    const isSportlineEventClosed = computed(()=>sportlineEvent.value?.status === SportEventStatus.Closed);
    const isSportlineEventSuspended = computed(()=>checkIsSportlineEventSuspended(sportlineEvent.value));
    const isRelatedEventsAvailable = computed(()=>!!relatedSportListElement.value);
    const eventIsOverLabel = $translate('JSP_SPORT_EVENT_FINISHED');
    const eventIsSuspendedLabel = $translate('JSP_SPORT_EVENT_PAUSE');
    const suspendedLabel = computed(()=>isSportlineEventClosed.value ? eventIsOverLabel.value : eventIsSuspendedLabel.value);
    function onHeadlineVisibilityChanged(state) {
        sportlineEventDetailsStore.setIsMiniBoardShown(!state);
    }
    function onLayoutTypeChanged(value) {
        sportlineEventDetailsStore.setDefaultLayoutType(value);
    }
    return {
        isMarketGroupsTabsEnabled,
        isLoaded,
        isShowingLoadingIndicator,
        sportlineEvent,
        relatedSportListElement,
        leagueEventsList,
        defaultLayoutType,
        hasStatistic,
        hasPostMatchStatistic,
        sport,
        region,
        league,
        activeEventId,
        isSportlineEventExist,
        isSportlineEventClosed,
        isSportlineEventSuspended,
        isRelatedEventsAvailable,
        suspendedLabel,
        onHeadlineVisibilityChanged,
        onLayoutTypeChanged
    };
}
