import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
import { toRef, onBeforeUnmount } from 'vue';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { VLogoLoader } from 'web/src/components/Loader';
import { useVirtualSportWidget } from './useVirtualSportWidget';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualSportWidget',
    props: {
        clientId: {},
        iframeUrl: {},
        providerUrl: {},
        selectedVirtualSport: {},
        selectedBetsMappings: {},
        stylable: {
            type: Boolean
        },
        internalWidgetLoaded: {
            type: Boolean
        }
    },
    emits: [
        "widget-loaded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const clientId = toRef(props, 'clientId');
        const iframeUrl = toRef(props, 'iframeUrl');
        const providerUrl = toRef(props, 'providerUrl');
        const selectedVirtualSport = toRef(props, 'selectedVirtualSport');
        const selectedBetsMappings = toRef(props, 'selectedBetsMappings', []);
        const stylable = toRef(props, 'stylable', false);
        const internalWidgetLoaded = toRef(props, 'internalWidgetLoaded', false);
        const { iframeName, frame, iframeWidgetSrc, additionalQueryStringParameters, onIFrameMounted, releaseWidget } = useVirtualSportWidget({
            clientId,
            iframeUrl,
            providerUrl,
            selectedVirtualSport,
            selectedBetsMappings,
            stylable
        }, {
            widgetLoaded (value) {
                emit('widget-loaded', value);
            }
        });
        onBeforeUnmount(releaseWidget);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['virtual-sport-widget'])
            }, [
                internalWidgetLoaded.value ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['virtual-sport-widget__loader'])
                }, [
                    _createVNode(_unref(VLogoLoader), {
                        class: _normalizeClass(_ctx.$style['virtual-sport-widget__loader-image'])
                    }, null, 8, [
                        "class"
                    ])
                ], 2)),
                _withDirectives(_createVNode(_unref(VIframe), {
                    ref_key: "frame",
                    ref: frame,
                    class: _normalizeClass(_ctx.$style['virtual-sport-widget-frame']),
                    src: _unref(iframeWidgetSrc),
                    "full-width": "",
                    name: _unref(iframeName),
                    "additional-query-string-parameters": _unref(additionalQueryStringParameters),
                    onVnodeMounted: _unref(onIFrameMounted)
                }, null, 8, [
                    "class",
                    "src",
                    "name",
                    "additional-query-string-parameters",
                    "onVnodeMounted"
                ]), [
                    [
                        _vShow,
                        internalWidgetLoaded.value
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VirtualSportWidget'
                ]
            ]);
        };
    }
});
