import { watch } from 'vue';
import { useElementSize } from '@leon-hub/vue-utils/src/element-size';
export function useWatchForSizes(listenerTargetRef, containerRef, calculateRange) {
    const size = useElementSize(listenerTargetRef);
    watch([
        size.width,
        size.height,
        containerRef
    ], ()=>{
        calculateRange();
    });
}
