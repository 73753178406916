import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1
};
import { computed } from 'vue';
import { buildPostMatchStatisticsFromSportlineEvent } from 'web/src/modules/sportline/submodules/event-statistic/utils';
import { useSportlineEventDetailsHeadlineData } from './composables';
import PostMatchStatisticHeadline from '../post-match-statistic/PostMatchStatisticHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ClosedSportlineEventDetailsHeadline',
    setup (__props) {
        const { sportlineEvent, sport } = useSportlineEventDetailsHeadlineData();
        const postMatchStatistic = computed(()=>buildPostMatchStatisticsFromSportlineEvent({
                sportlineEvent: sportlineEvent.value,
                sport: sport.value
            }));
        return (_ctx, _cache)=>postMatchStatistic.value ? (_openBlock(), _createBlock(PostMatchStatisticHeadline, {
                key: 0,
                "post-match-statistic": postMatchStatistic.value
            }, null, 8, [
                "post-match-statistic"
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_1));
    }
});
