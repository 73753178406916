import { toValue } from 'vue';
import { VirtualListElementType, VirtualListBlockLayout } from '../enums';
export function getNoEventsElement(sportElement, props) {
    const { basisKey, isLastSport, blockLayout } = props;
    const collapseSportKey = toValue(props.collapseSportKey ?? null);
    const sportHeadline = sportElement ? {
        type: VirtualListElementType.SportHeadline,
        sport: sportElement.sport,
        isLastSport: isLastSport ?? false,
        blockLayout: blockLayout ?? VirtualListBlockLayout.Default,
        basisKey,
        collapseSportKey,
        eventsCount: 0,
        isMarketTypesSelectionEnabled: false,
        hideEventsCounter: true
    } : null;
    const noEvents = {
        type: VirtualListElementType.NoEvents,
        blockLayout: blockLayout ?? VirtualListBlockLayout.Default,
        basisKey
    };
    return sportHeadline ? [
        sportHeadline,
        noEvents
    ] : [
        noEvents
    ];
}
