import { computed } from 'vue';
import { useLeagueRepresentation } from 'web/src/modules/sportline/submodules/core-statistic/composables';
import { useSportlineEventChampionshipName } from 'web/src/modules/sportline/composables/sportline-event';
import DateTime from 'web/src/utils/DateTime';
export function useOutrightInfoHeadline(props) {
    const { sportlineEvent, sport, leagueRepresentationData } = props;
    const { backgroundImage, backgroundColor } = useLeagueRepresentation({
        sport,
        leagueRepresentationData
    });
    const { championshipName, outrightName } = useSportlineEventChampionshipName({
        sportlineEvent
    });
    const date = computed(()=>DateTime.withTimeStamp(sportlineEvent.value.kickoff).toDate());
    const time = computed(()=>DateTime.withTimeStamp(sportlineEvent.value.kickoff).toTime());
    const hasZeroMargin = computed(()=>sportlineEvent.value.hasZeroMargin);
    return {
        backgroundImage,
        backgroundColor,
        championshipName,
        outrightName,
        date,
        time,
        hasZeroMargin
    };
}
