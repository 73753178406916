import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import WidgetWrapper from 'web/src/modules/sportline/submodules/widgets/views/WidgetWrapper.vue';
import { useBetgeniusWidget } from './useBetgeniusWidget';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetgeniusWidget',
    props: {
        liveWidget: {},
        locale: {},
        isFloatingModeEnabled: {
            type: Boolean
        },
        isFloatingModeAllowed: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        },
        aspectRatio: {}
    },
    setup (__props) {
        const props = __props;
        const isFloatingModeEnabled = toRef(props, 'isFloatingModeEnabled');
        const isFloatingModeAllowed = toRef(props, 'isFloatingModeAllowed');
        const isActive = toRef(props, 'isActive', false);
        const liveWidget = toRef(props, 'liveWidget');
        const locale = toRef(props, 'locale');
        const { readyToLoading, iframeWidgetSrc, additionalQueryStringParameters } = useBetgeniusWidget({
            isActive,
            liveWidget,
            locale
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['gs-pitch-widget'])
            }, [
                _createVNode(WidgetWrapper, {
                    class: _normalizeClass(_ctx.$style['gs-pitch-widget__component']),
                    "is-floating-mode-allowed": isFloatingModeAllowed.value,
                    "is-floating-mode-enabled": isFloatingModeEnabled.value
                }, {
                    default: _withCtx(()=>[
                            _unref(readyToLoading) && _unref(iframeWidgetSrc) ? (_openBlock(), _createBlock(_unref(VIframe), {
                                key: 0,
                                src: _unref(iframeWidgetSrc),
                                "additional-query-string-parameters": _unref(additionalQueryStringParameters),
                                name: _unref(IframeWidget).Betgenius,
                                "full-height": "",
                                "full-width": ""
                            }, null, 8, [
                                "src",
                                "additional-query-string-parameters",
                                "name"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "is-floating-mode-allowed",
                    "is-floating-mode-enabled"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetgeniusWidget'
                ]
            ]);
        };
    }
});
