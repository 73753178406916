import { ref, computed } from 'vue';
import useMatchStatisticsTeams from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { filterEmptyMark, filterSameTypeMarks, parseCardToMark, parseGoalToMark, parseScoreToMark, parseSubstitutionToMark, sortMarksByAsc } from 'web/src/modules/sportline/submodules/core-statistic/utils';
export default function usePostMatchStatisticIncidentsList(props) {
    const { postMatchStatistic } = props;
    const isOpen = ref(false);
    const { teams } = useMatchStatisticsTeams(postMatchStatistic);
    const marks = computed(()=>{
        const sportFamily = SportFamily.Soccer;
        const breakMarks = postMatchStatistic.value.scores.reduce((result, score)=>[
                ...result,
                ...parseScoreToMark(score, {
                    sportFamily
                })
            ], []).filter(filterSameTypeMarks);
        const cardMarks = postMatchStatistic.value.cards.map((card)=>parseCardToMark(card, {
                sportFamily
            })).filter(filterEmptyMark);
        const goalsMarks = postMatchStatistic.value.goals.map((goal)=>parseGoalToMark(goal, {
                sportFamily
            })).filter(filterEmptyMark);
        const substitutionsMarks = postMatchStatistic.value.substitutions.map((substitution)=>parseSubstitutionToMark(substitution, {
                sportFamily
            })).filter(filterEmptyMark);
        return sortMarksByAsc([
            ...cardMarks,
            ...goalsMarks,
            ...substitutionsMarks,
            ...breakMarks
        ]);
    });
    return {
        isOpen,
        teams,
        marks
    };
}
