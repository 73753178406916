import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, watch, computed } from 'vue';
import { onComponentDeactivated } from '@leon-hub/vue-utils';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import WidgetWrapper from './WidgetWrapper.vue';
import PleaseLoginWidget from './PleaseLoginWidget.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StreamWidget',
    props: {
        sportlineEventId: {
            default: null
        },
        streamUrl: {
            default: "/blank.html"
        },
        doShowStreamPreviewInfo: {
            type: Boolean,
            default: false
        },
        isFloatingModeEnabled: {
            type: Boolean
        },
        isFloatingModeAllowed: {
            type: Boolean
        },
        isActive: {
            type: Boolean,
            default: false
        },
        aspectRatio: {}
    },
    emits: [
        "stream-setup",
        "stream-started",
        "stream-release"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportlineEventId = toRef(()=>props.sportlineEventId);
        const doShowStreamPreviewInfo = toRef(()=>props.doShowStreamPreviewInfo);
        const streamUrl = toRef(()=>props.streamUrl);
        const isActive = toRef(()=>props.isActive);
        const isStreamShown = computed(()=>!!sportlineEventId.value && isActive.value && !!streamUrl.value && !doShowStreamPreviewInfo.value);
        watch([
            sportlineEventId,
            isActive
        ], (param)=>{
            let [id, value] = param;
            if (!id || !value) return;
            emit('stream-setup');
        }, {
            immediate: true
        });
        watch(isStreamShown, (value)=>{
            if (!value) return;
            // @see LEONWEB-14247 save preview info after first time stream was shown
            emit('stream-started');
        }, {
            immediate: true
        });
        onComponentDeactivated(()=>{
            emit('stream-release');
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['live-stream-slide'])
            }, [
                isActive.value ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    doShowStreamPreviewInfo.value ? (_openBlock(), _createBlock(PleaseLoginWidget, {
                        key: 0
                    })) : false === streamUrl.value ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['live-stream-slide__error'])
                    }, [
                        _createVNode(VJumbotron, {
                            heading: _ctx.$t('WEB2_STREAM_UNAVAILABLE_ERROR')
                        }, null, 8, [
                            "heading"
                        ])
                    ], 2)) : streamUrl.value ? (_openBlock(), _createBlock(WidgetWrapper, {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['live-stream-slide__content']),
                        "is-floating-mode-allowed": _ctx.isFloatingModeAllowed,
                        "is-floating-mode-enabled": _ctx.isFloatingModeEnabled,
                        "is-stream": ""
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(), _createBlock(_unref(VIframe), {
                                    key: streamUrl.value,
                                    src: streamUrl.value,
                                    "full-height": "",
                                    "full-width": "",
                                    name: _unref(IframeWidget).LiveStream,
                                    frameborder: "0",
                                    allowfullscreen: false
                                }, null, 8, [
                                    "src",
                                    "name",
                                    "allowfullscreen"
                                ]))
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "is-floating-mode-allowed",
                        "is-floating-mode-enabled"
                    ])) : (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['live-stream-slide__loader'])
                    }, [
                        _createVNode(_unref(VLoaderDelayed))
                    ], 2))
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'StreamWidget'
                ]
            ]);
        };
    }
});
