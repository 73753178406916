import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1
};
import { toRef } from 'vue';
import FloatingWidgetBox from 'web/src/modules/sportline/submodules/widgets/components/FloatingWidgetBox.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WidgetWrapper',
    props: {
        isFloatingModeAllowed: {
            type: Boolean
        },
        isFloatingModeEnabled: {
            type: Boolean
        },
        isStream: {
            type: Boolean
        },
        isFullSize: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const isFloatingModeAllowed = toRef(props, 'isFloatingModeAllowed', false);
        const isFloatingModeEnabled = toRef(props, 'isFloatingModeEnabled', false);
        const isStream = toRef(props, 'isStream', false);
        const isFullSize = toRef(props, 'isFullSize', false);
        return (_ctx, _cache)=>isFloatingModeAllowed.value ? (_openBlock(), _createBlock(FloatingWidgetBox, {
                key: 0,
                "is-floating-mode-enabled": isFloatingModeEnabled.value,
                "is-stream": isStream.value,
                "is-full-size": isFullSize.value
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "is-floating-mode-enabled",
                "is-stream",
                "is-full-size"
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default")
            ]));
    }
});
