import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { LogoSource } from 'web/src/modules/sportline/enums';
import PowerPlayBadge from 'web/src/modules/sportline/submodules/event-details/components/power-play/PowerPlayBadge.vue';
import HeadlineTeamScore from '../../components/HeadlineTeamScore.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeadlineTeam',
    props: {
        role: {},
        position: {
            default: 'center'
        },
        name: {},
        score: {},
        innings: {
            type: Boolean
        },
        logo: {},
        logoMeta: {},
        powerPlay: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['headline-team__container']]: true,
                    [_ctx.$style[`headline-team__container--${_ctx.position}`]]: true
                })
            }, [
                _ctx.name || _ctx.logo ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['headline-team']]: true,
                        [_ctx.$style['headline-team--with-logo']]: !!_ctx.logo
                    })
                }, [
                    _ctx.logo ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['headline-team-logo__container'])
                    }, [
                        _createElementVNode("img", {
                            src: _ctx.logo,
                            class: _normalizeClass({
                                [_ctx.$style['headline-team-logo']]: true,
                                [_ctx.$style['headline-team-logo--large']]: !!_ctx.logoMeta && _ctx.logoMeta.source === _unref(LogoSource).Scores
                            }),
                            alt: ""
                        }, null, 10, _hoisted_1)
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['headline-team-name']]: true,
                            [_ctx.$style[`headline-team-name--${_ctx.role}`]]: true
                        })
                    }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.name ?? ''), 1),
                        _ctx.powerPlay ? (_openBlock(), _createBlock(PowerPlayBadge, {
                            key: 0,
                            count: _ctx.powerPlay
                        }, null, 8, [
                            "count"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2)) : _createCommentVNode("", true),
                _ctx.score ? (_openBlock(), _createBlock(HeadlineTeamScore, {
                    key: 1,
                    score: _ctx.score,
                    role: _ctx.role,
                    innings: _ctx.innings,
                    size: "32",
                    gap: "16"
                }, null, 8, [
                    "score",
                    "role",
                    "innings"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeadlineTeam'
                ]
            ]);
        };
    }
});
