import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeProps as _normalizeProps, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = [
    "alt",
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticPlayerLogo',
    props: {
        player: {}
    },
    setup (__props) {
        const iconProperties = {
            name: IconName.BRAND_LOGO,
            size: IconSize.SIZE_16
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.player.logo ? _withDirectives((_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: _ctx.player.playerName ?? void 0,
                src: _ctx.player.logo
            }, null, 8, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'StatisticPlayerLogo'
                ]
            ]) : (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                key: 1
            }, iconProperties)), null, 16));
        };
    }
});
