import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { PostMessageEvent } from '@leon-hub/postmessage-bus';
export const VirtualSportWidgetPostMessageBusInitiator = 'VirtualSportFramedWidget';
let VirtualSportWidgetPostMessageEvent = class VirtualSportWidgetPostMessageEvent extends PostMessageEvent {
};
_define_property(VirtualSportWidgetPostMessageEvent, "syncConfig", new PostMessageEvent('widget-sync-config'));
_define_property(VirtualSportWidgetPostMessageEvent, "stateChanged", new PostMessageEvent('widget-state-changed'));
_define_property(VirtualSportWidgetPostMessageEvent, "betsChanged", new PostMessageEvent('widget-bets-changed'));
_define_property(VirtualSportWidgetPostMessageEvent, "slipSelectedMappingsChanged", new PostMessageEvent('slip-selected-mappings-changed'));
_define_property(VirtualSportWidgetPostMessageEvent, "clearBet", new PostMessageEvent('widget-clear-bet'));
_define_property(VirtualSportWidgetPostMessageEvent, "clearAllBets", new PostMessageEvent('widget-clear-all-bets'));
export { VirtualSportWidgetPostMessageEvent as default };
