import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import JoinMatchesStatistic from 'web/src/modules/sportline/submodules/event-details/components/statistic/JoinMatchesStatistic.vue';
import SportEventMatchStatistic from 'web/src/modules/sportline/submodules/event-details/components/statistic/SportEventMatchStatistic.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'H2hMatchStatisticBlock',
    props: {
        teams: {},
        joinMatches: {},
        h2hMatchStatistic: {},
        sportFamily: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _ctx.teams && (_ctx.joinMatches.length > 0 || _ctx.h2hMatchStatistic) ? (_openBlock(), _createBlock(VListItemAccordion, {
                            key: 0,
                            id: "history-of-games",
                            title: _ctx.$t('WEB2_MATCH_STATISTIC_HISTORY_OF_HEAD_TO_HEAD'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", null, [
                                        _createVNode(JoinMatchesStatistic, {
                                            class: "h2h-match-statistic__join-matches",
                                            teams: _ctx.teams,
                                            "play-statistic": _ctx.h2hMatchStatistic
                                        }, null, 8, [
                                            "teams",
                                            "play-statistic"
                                        ]),
                                        _createElementVNode("div", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.joinMatches, (match)=>(_openBlock(), _createBlock(SportEventMatchStatistic, {
                                                    key: match.id ?? void 0,
                                                    match: match,
                                                    "sport-family": _ctx.sportFamily
                                                }, null, 8, [
                                                    "match",
                                                    "sport-family"
                                                ]))), 128))
                                        ])
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'H2hMatchStatisticBlock'
                ]
            ]);
        };
    }
});
