import { computed } from 'vue';
import { useSportlineElementMarketColumns, useSportlineElementMarketTypes } from 'web/src/modules/sportline/composables/markets';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import { useFirstLeagueInSportElement, useFirstElementsInSportElement } from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import { getSportEventElementsListCounters } from 'web/src/modules/sportline/utils';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
export function useBreadcrumbsRelatedEvents(props) {
    const { relatedEvents, sportElement, isMarketTypesSelectionEnabled } = props;
    const { marketsColumns } = useSportlineElementMarketColumns({
        sportlineElement: sportElement
    });
    const { marketsTypes } = useSportlineElementMarketTypes({
        sportlineElement: sportElement
    });
    // @TODO check logic for Live Outrights
    const liveAndPreMatchEvents = computed(()=>relatedEvents.value.filter((element)=>!element.sportEvent.isOutright));
    const outrightEvents = computed(()=>relatedEvents.value.filter((element)=>element.sportEvent.isOutright));
    const doShowOutrightEvents = computed(()=>outrightEvents.value.length > 0);
    const { sport, region, league } = useFirstLeagueInSportElement({
        sportElement
    });
    const sportId = computed(()=>sport.value.id);
    const basisKey = createSportlineFragmentBasisKey({
        sportId
    });
    const { regionElement: defaultRegionElement, leagueElement: defaultLeagueElement } = useFirstElementsInSportElement({
        sportElement
    });
    const regionElement = computed(()=>{
        const counters = getSportEventElementsListCounters(relatedEvents.value);
        return {
            ...defaultRegionElement.value,
            leagues: [
                {
                    ...defaultLeagueElement.value,
                    sportEvents: liveAndPreMatchEvents.value,
                    outrightEvents: outrightEvents.value,
                    counters
                }
            ],
            counters
        };
    });
    useProvideMarketColumn({
        basisKey,
        isMarketTypesSelectionEnabled,
        marketsColumns,
        marketsTypes
    });
    return {
        sport,
        region,
        league,
        regionElement,
        doShowOutrightEvents
    };
}
