import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { FilledLogoBackgroundSize } from 'web/src/modules/sportline/components/SportlineLogo/enums';
import FilledSportlineLogo from './FilledSportlineLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FilledSportlineLogoWithBackground',
    props: {
        sportIconName: {},
        fillColor: {},
        countryCode: {},
        sportSegmentId: {},
        src: {},
        alt: {},
        height: {},
        borderlessFlag: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const height = toRef(props, 'height', FilledLogoBackgroundSize.SIZE_40);
        const sportSegmentId = toRef(props, 'sportSegmentId');
        const sizeMapping = {
            [FilledLogoBackgroundSize.FULL_SIZE]: IconSize.FULL_SIZE,
            [FilledLogoBackgroundSize.SIZE_84]: IconSize.SIZE_56,
            [FilledLogoBackgroundSize.SIZE_52]: IconSize.SIZE_32,
            [FilledLogoBackgroundSize.SIZE_40]: IconSize.SIZE_28,
            [FilledLogoBackgroundSize.SIZE_32]: IconSize.SIZE_24
        };
        const flagSizeMapping = {
            [FilledLogoBackgroundSize.FULL_SIZE]: VCountryFlagSize.FULL_WIDTH,
            [FilledLogoBackgroundSize.SIZE_84]: VCountryFlagSize.SIZE_40,
            [FilledLogoBackgroundSize.SIZE_52]: VCountryFlagSize.SIZE_28,
            [FilledLogoBackgroundSize.SIZE_40]: VCountryFlagSize.SIZE_22,
            [FilledLogoBackgroundSize.SIZE_32]: VCountryFlagSize.SIZE_18
        };
        const size = computed(()=>sizeMapping[height.value]);
        const flagSize = computed(()=>flagSizeMapping[height.value]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['filled-sportline-logo-background']]: true,
                    [_ctx.$style[`filled-sportline-logo-background--${height.value}`]]: true
                })
            }, [
                _createVNode(FilledSportlineLogo, {
                    src: _ctx.src,
                    "country-code": _ctx.countryCode,
                    "sport-icon-name": _ctx.sportIconName,
                    "sport-segment-id": sportSegmentId.value,
                    "fill-color": _ctx.fillColor,
                    size: size.value,
                    "flag-size": flagSize.value,
                    "borderless-flag": _ctx.borderlessFlag
                }, null, 8, [
                    "src",
                    "country-code",
                    "sport-icon-name",
                    "sport-segment-id",
                    "fill-color",
                    "size",
                    "flag-size",
                    "borderless-flag"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'FilledSportlineLogoWithBackground'
                ]
            ]);
        };
    }
});
