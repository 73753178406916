/* eslint-disable class-methods-use-this */ import RouteName from '@leon-hub/routing-config';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { isOptionalFilterSportFamily } from 'web/src/modules/sportline/guards';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
let BaseSportsPageNavigationService = class BaseSportsPageNavigationService {
    prefetch(router, to, from, next) {
        const navigationParameters = getSportlinePageNavigationParameters(to);
        const { sportFamily } = navigationParameters;
        const family = sportFamily?.toLowerCase();
        const checkFilterResult = this.checkAllowedFilter(navigationParameters);
        if (isSportFamilyEquals(family, CustomFilter.ZeroMarginDeprecated)) {
            next(router.resolve301location(resolveSportsPageLink({
                urlName: CustomFilter.ZeroMargin
            })));
            return;
        }
        if (void 0 !== checkFilterResult) {
            next(checkFilterResult);
            return;
        }
        useSportlineSportsStore().setPageIdentifier(navigationParameters);
        useSportlineSportsStore().setSelectedSportFamily(family || null);
        next();
    }
    checkAllowedFilter(navigationParameters) {
        const { sportFamily } = navigationParameters;
        const { allowedSportFamily, customFilters } = useSportlineSportsStore();
        return isOptionalFilterSportFamily(sportFamily, allowedSportFamily, customFilters) ? void 0 : {
            name: RouteName.ERROR_NOT_FOUND_404
        };
    }
    // eslint-disable-next-line class-methods-use-this
    getDateTimeFilter(to) {
        const navigationStore = useSportlineNavigationStore();
        const savedFilter = navigationStore.sportlineNavigationBetweenPagesFilter;
        navigationStore.setSportlineNavigationBetweenPagesFilter(null);
        return to.meta.dateFilter ?? savedFilter ?? void 0;
    }
};
export { BaseSportsPageNavigationService as default };
