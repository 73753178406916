import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: "progress"
};
const _hoisted_2 = {
    key: "progress-direct"
};
const _hoisted_3 = {
    key: "progress-inactive"
};
const _hoisted_4 = {
    key: 0
};
const _hoisted_5 = {
    key: 1
};
import { ref, toRef, onActivated, onDeactivated } from 'vue';
import { useDoShowMatchProgress } from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/composables';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import SportlineLiveEventProgress from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/SportlineLiveEventProgress.vue';
import PowerPlayBadge from 'web/src/modules/sportline/submodules/event-details/components/power-play/PowerPlayBadge.vue';
import { vTimer } from 'web/src/modules/sportline/directives';
import { useEventDetailsMiniScoreBoard } from './useEventDetailsMiniScoreBoard';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EventDetailsMiniScoreBoard',
    props: {
        sportEventDetails: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        // disable rule for directives (for some reason it doesn't find them )
        /* eslint-disable vue/no-undef-properties */ const props = __props;
        const emit = __emit;
        const sportlineEvent = toRef(props, 'sportEventDetails');
        const isActive = ref(true);
        const { isTimeReversedIndicatorAvailable, totalScore, stage, gameScoreProgress, isStaticLiveProgress, isTimeReversed, staticReversedTimeProgressLabel, staticDirectTimeProgressLabel, additionalTime, mainTimeTotalSeconds, mainTimeProgress, mainTimeProgressShortFormat, additionalTimeTotalSeconds, additionalTimeProgress, scoreDetailsLine, mainTimeDirectiveDirection, additionalTimeDirectiveDirection } = useEventDetailsMiniScoreBoard({
            sportEvent: sportlineEvent
        });
        const { doShowTimeline } = useDoShowMatchProgress({
            sportlineEvent
        });
        function emitClick(event) {
            emit('click', event);
        }
        onActivated(()=>{
            isActive.value = true;
        });
        onDeactivated(()=>{
            isActive.value = false;
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-details-score']),
                onClick: emitClick
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['mini-score-board-top'])
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([
                            _ctx.$style['mini-score-board-top__side'],
                            _ctx.$style['scoreboard-team'],
                            _ctx.$style['scoreboard-team--host']
                        ])
                    }, [
                        _withDirectives((_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(_ctx.$style['scoreboard-team__label'])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.sportEventDetails.competitors[0]?.name), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-name'
                                }
                            ]
                        ]),
                        _ctx.sportEventDetails.incidentStatistics.host.powerPlay ? _withDirectives((_openBlock(), _createBlock(PowerPlayBadge, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['team-power-play']),
                            count: _ctx.sportEventDetails.incidentStatistics.host.powerPlay
                        }, null, 8, [
                            "class",
                            "count"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-power-play'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _withDirectives((_openBlock(), _createElementBlock("span", {
                            class: _normalizeClass(_ctx.$style['scoreboard-team__score'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(totalScore).host), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-score'
                                }
                            ]
                        ])
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'mini-score-board__team--home'
                            }
                        ]
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['mini-score-board-top__divider'])
                    }, null, 2),
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([
                            _ctx.$style['mini-score-board-top__side'],
                            _ctx.$style['scoreboard-team'],
                            _ctx.$style['scoreboard-team--guest']
                        ])
                    }, [
                        _withDirectives((_openBlock(), _createElementBlock("span", {
                            class: _normalizeClass(_ctx.$style['scoreboard-team__score'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(totalScore).guest), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-score'
                                }
                            ]
                        ]),
                        _ctx.sportEventDetails.incidentStatistics.guest.powerPlay ? _withDirectives((_openBlock(), _createBlock(PowerPlayBadge, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['team-power-play']),
                            count: _ctx.sportEventDetails.incidentStatistics.guest.powerPlay
                        }, null, 8, [
                            "class",
                            "count"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-power-play'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _withDirectives((_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(_ctx.$style['scoreboard-team__label'])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.sportEventDetails.competitors[1]?.name), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'mini-score-board__team-name'
                                }
                            ]
                        ])
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'mini-score-board__team--away'
                            }
                        ]
                    ])
                ], 2),
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['mini-score-board-bottom'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['mini-score-board-bottom__period'])
                    }, [
                        _createTextVNode(_toDisplayString(_unref(stage)) + " ", 1),
                        _unref(gameScoreProgress) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(gameScoreProgress)), 1)) : _unref(isStaticLiveProgress) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(isTimeReversedIndicatorAvailable) && _unref(isTimeReversed) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(staticReversedTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", {
                                    key: "progress",
                                    class: _normalizeClass(_ctx.$style['sport-event-details-score__reversed-time-indicator'])
                                }, _toDisplayString(_unref(staticReversedTimeProgressLabel)), 3)) : _createCommentVNode("", true)
                            ], 64)) : _unref(staticDirectTimeProgressLabel) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(staticDirectTimeProgressLabel)), 1)) : _createCommentVNode("", true)
                        ], 64)) : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                            isActive.value ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(additionalTime) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(mainTimeProgressShortFormat)) + " +", 1),
                                    _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                        _createTextVNode(_toDisplayString(_unref(additionalTimeProgress)), 1)
                                    ])), [
                                        [
                                            _unref(vTimer),
                                            _unref(additionalTimeTotalSeconds),
                                            _unref(additionalTimeDirectiveDirection)
                                        ]
                                    ])
                                ], 64)) : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                                    _createTextVNode(_toDisplayString(_unref(mainTimeProgress)), 1)
                                ])), [
                                    [
                                        _unref(vTimer),
                                        _unref(mainTimeTotalSeconds),
                                        _unref(mainTimeDirectiveDirection)
                                    ]
                                ])
                            ], 64)) : _createCommentVNode("", true)
                        ]))
                    ], 2),
                    _unref(scoreDetailsLine) ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['mini-score-board-bottom__label'])
                    }, _toDisplayString(`(${_unref(scoreDetailsLine)})`), 3)) : _createCommentVNode("", true)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'mini-score-board__stage-info'
                        }
                    ]
                ]),
                _unref(doShowTimeline) ? _withDirectives((_openBlock(), _createBlock(SportlineLiveEventProgress, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['live-match-info-progress-bar']),
                    "sportline-event": _ctx.sportEventDetails,
                    "progress-kind": _unref(ProgressKind).DEFAULT
                }, null, 8, [
                    "class",
                    "sportline-event",
                    "progress-kind"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'mini-score-board__progress'
                        }
                    ]
                ]) : (_openBlock(), _createElementBlock("div", _hoisted_5))
            ], 2)), [
                [
                    _directive_auto_id,
                    'EventDetailsMiniScoreBoard'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'mini-score-board'
                    }
                ]
            ]);
        };
    }
});
