import { ref, computed } from 'vue';
export function useLeaguePlayoff(props) {
    const { playoff } = props;
    const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);
    const expandAfterSize = 2;
    const expandAfterBlocksSize = 2;
    const rawRounds = computed(()=>playoff.value.rounds.map((round)=>({
                ...round
            })));
    const canExpand = computed(()=>rawRounds.value.length > expandAfterSize || rawRounds.value.some((round)=>round.blocks.length > expandAfterBlocksSize));
    const rounds = computed(()=>{
        if (!canExpand.value) return rawRounds.value;
        if (!isExpanded.value) return rawRounds.value.slice(-expandAfterSize).map((round)=>({
                ...round,
                blocks: round.blocks.slice(0, expandAfterBlocksSize)
            }));
        return rawRounds.value;
    });
    return {
        isExpanded,
        canExpand,
        rounds
    };
}
