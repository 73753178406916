import RouteName from '@leon-hub/routing-config';
import { isOptionalString } from '@leon-hub/guards';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { findSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
let VirtualSportPagePrefetch = class VirtualSportPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const virtualSportStore = useVirtualSportStore();
        if (!virtualSportStore.isVirtualSportAvailable) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return Promise.resolve();
        }
        const navigationStore = useRootNavigationStore();
        isOptionalString(to.params.sport);
        const filter = {
            urlName: to.params.sport
        };
        navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_VIRTUAL_SPORT);
        if (filter.urlName) {
            // eslint-disable-next-line max-len
            const optionForNavigation = findSelectedVirtualSportFilterOption(virtualSportStore.filterOptions, filter);
            if (!optionForNavigation) {
                next({
                    name: RouteName.ERROR_NOT_FOUND_404
                });
                return Promise.resolve();
            }
        }
        virtualSportStore.setListFilter(filter);
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_VIRTUAL_SPORT);
        next();
        return Promise.resolve();
    }
};
export { VirtualSportPagePrefetch as default };
