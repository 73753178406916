import { IconSize } from '@leon-hub/icons';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
export function displayConfigToIconConfig(displayConfig) {
    if (!displayConfig) return null;
    if (displayConfig.sportFamily) return {
        type: SportlineNavigationItemType.SportIcon,
        props: {
            sportName: displayConfig.sportFamily,
            size: IconSize.SIZE_16
        }
    };
    if (displayConfig.icon) return {
        type: SportlineNavigationItemType.Icon,
        props: {
            name: displayConfig.icon,
            size: displayConfig.highlighted ? IconSize.SIZE_28 : IconSize.SIZE_16
        }
    };
    return null;
}
