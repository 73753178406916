import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import { useBreadcrumbsRelatedEvents } from './useBreadcrumbsRelatedEvents';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BreadcrumbsRelatedEvents',
    props: {
        sportElement: {},
        relatedEvents: {},
        activeEventId: {},
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    emits: [
        "click-event"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const sportElement = toRef(props, 'sportElement');
        const relatedEvents = toRef(props, 'relatedEvents');
        const activeEventId = toRef(props, 'activeEventId', null);
        const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
        const { sport, league, regionElement, doShowOutrightEvents } = useBreadcrumbsRelatedEvents({
            relatedEvents,
            sportElement,
            isMarketTypesSelectionEnabled
        });
        const sportId = computed(()=>sport.value.id);
        const basisKey = createSportlineFragmentBasisKey({
            sportId
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: `details-related-league-${_unref(league).id}`,
                class: _normalizeClass(_ctx.$style['sport-event-related-list'])
            }, [
                _createVNode(SportlineRegionBlock, {
                    sport: _unref(sport),
                    "region-element": _unref(regionElement),
                    "active-event-id": activeEventId.value,
                    "can-add-to-favorite": true,
                    "can-add-outrights-to-favorite": true,
                    "extended-block-type": _unref(ExtendedEventBlock).Breadcrumbs,
                    "basis-key": _unref(basisKey),
                    onClickEvent: _cache[0] || (_cache[0] = ($event)=>emit('click-event'))
                }, null, 8, [
                    "sport",
                    "region-element",
                    "active-event-id",
                    "can-add-to-favorite",
                    "can-add-outrights-to-favorite",
                    "extended-block-type",
                    "basis-key"
                ]),
                _unref(doShowOutrightEvents) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(SportEventsOutrightHeadline),
                    _createVNode(SportlineRegionBlock, {
                        sport: _unref(sport),
                        "region-element": _unref(regionElement),
                        "active-event-id": activeEventId.value,
                        "is-hide-league-info": "",
                        "is-column-names-display-disabled": "",
                        "is-display-main-events-disabled": "",
                        "is-display-outright-headline-disabled": "",
                        "is-display-outright-events-enabled": "",
                        "can-add-to-favorite": true,
                        "can-add-outrights-to-favorite": true,
                        "extended-block-type": _unref(ExtendedEventBlock).Breadcrumbs,
                        "basis-key": _unref(basisKey),
                        onClickEvent: _cache[1] || (_cache[1] = ($event)=>emit('click-event'))
                    }, null, 8, [
                        "sport",
                        "region-element",
                        "active-event-id",
                        "can-add-to-favorite",
                        "can-add-outrights-to-favorite",
                        "extended-block-type",
                        "basis-key"
                    ])
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BreadcrumbsRelatedEvents'
                ]
            ]);
        };
    }
});
