import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "ball-possession-statistic-block"
};
const _hoisted_2 = {
    class: "ball-possession-statistic-block__heading"
};
const _hoisted_3 = {
    class: "ball-possession-chart-heading"
};
const _hoisted_4 = {
    class: "ball-possession-statistic-block__charts"
};
const _hoisted_5 = {
    class: "ball-possession-chart-bar"
};
const _hoisted_6 = {
    class: "ball-possession-chart-bar__filling-borders ball-possession-chart-bar__filling-borders--left"
};
const _hoisted_7 = {
    class: "ball-possession-team__holder"
};
const _hoisted_8 = {
    class: "ball-possession-team"
};
const _hoisted_9 = {
    class: "ball-possession-team__name"
};
const _hoisted_10 = {
    class: "ball-possession-team__score"
};
const _hoisted_11 = {
    class: "ball-possession-chart-bar"
};
const _hoisted_12 = {
    class: "ball-possession-chart-bar__filling-borders ball-possession-chart-bar__filling-borders--right"
};
const _hoisted_13 = {
    class: "ball-possession-team__holder"
};
const _hoisted_14 = {
    class: "ball-possession-team ball-possession-team--right"
};
const _hoisted_15 = {
    class: "ball-possession-team__name ball-possession-team__name--right"
};
const _hoisted_16 = {
    class: "ball-possession-team__score ball-possession-team__score--right"
};
const _hoisted_17 = {
    class: "ball-possession-sport-icon__holder"
};
const _hoisted_18 = {
    class: "ball-possession-sport-icon"
};
import { computed, toRef } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { SportIcon } from '@components/sport-icon';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TeamBallPossessionStatisticBlock',
    props: {
        confrontationBlock: {},
        teams: {},
        sportFamily: {}
    },
    setup (__props) {
        const props = __props;
        const teams = toRef(props, 'teams', null);
        const sportFamily = toRef(props, 'sportFamily', null);
        const host = computed(()=>teams.value?.[0] ?? null);
        const guest = computed(()=>teams.value?.[1] ?? null);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.confrontationBlock.name), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", {
                                class: "ball-possession-chart-bar__filling ball-possession-chart-bar__filling--left",
                                style: _normalizeStyle({
                                    width: `${_ctx.confrontationBlock.host.percent}%`
                                })
                            }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", null, [
                                    host.value ? (_openBlock(), _createBlock(StatisticTeamLogo, {
                                        key: 0,
                                        class: "ball-possession-team__logo",
                                        team: host.value
                                    }, null, 8, [
                                        "team"
                                    ])) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    host.value ? (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _createTextVNode(_toDisplayString(host.value.name), 1)
                                    ], 64)) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.confrontationBlock.host.value), 1)
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                                class: "ball-possession-chart-bar__filling ball-possession-chart-bar__filling--right",
                                style: _normalizeStyle({
                                    width: `${_ctx.confrontationBlock.guest.percent}%`
                                })
                            }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", null, [
                                    guest.value ? (_openBlock(), _createBlock(StatisticTeamLogo, {
                                        key: 0,
                                        class: "ball-possession-team__logo",
                                        team: guest.value
                                    }, null, 8, [
                                        "team"
                                    ])) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                    guest.value ? (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _createTextVNode(_toDisplayString(guest.value.name), 1)
                                    ], 64)) : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.confrontationBlock.guest.value), 1)
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                            sportFamily.value ? (_openBlock(), _createBlock(_unref(SportIcon), {
                                key: 0,
                                size: _unref(IconSize).SIZE_16,
                                "sport-name": sportFamily.value
                            }, null, 8, [
                                "size",
                                "sport-name"
                            ])) : _createCommentVNode("", true)
                        ])
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'TeamBallPossessionStatisticBlock'
                ]
            ]);
        };
    }
});
