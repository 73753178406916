import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed } from 'vue';
import { isSportradarLMTDetailsHeadlineWidget, isLSportsDetailsHeadlineWidget, isBetgeniusDetailsHeadlineWidget } from 'web/src/modules/sportline/submodules/event-details/guards/headline/widgets';
import BetgeniusWidget from './BetgeniusWidget.vue';
import LSportsWidget from './LSportsWidget.vue';
import SportradarLiveMatchTrackerWidget from './SportradarLiveMatchTrackerWidget.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TrackerWidget',
    props: {
        widget: {},
        backgroundColor: {},
        isFloatingModeEnabled: {
            type: Boolean
        },
        isFloatingModeAllowed: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        },
        aspectRatio: {}
    },
    emits: [
        "size-changed"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function getActualProp(key, defaultValue) {
            return computed(()=>props.widget.props[key] ?? props[key] ?? defaultValue);
        }
        const isActive = getActualProp('isActive', false);
        const isFloatingModeAllowed = getActualProp('isFloatingModeAllowed', false);
        const isFloatingModeEnabled = getActualProp('isFloatingModeEnabled', false);
        const aspectRatio = getActualProp('aspectRatio', void 0);
        return (_ctx, _cache)=>_unref(isSportradarLMTDetailsHeadlineWidget)(_ctx.widget) ? (_openBlock(), _createBlock(SportradarLiveMatchTrackerWidget, {
                key: 0,
                "sport-event-details": _ctx.widget.props.sportEventDetails,
                "is-floating-mode-enabled": _unref(isFloatingModeEnabled),
                "is-active": _unref(isActive),
                "is-floating-mode-allowed": _unref(isFloatingModeAllowed),
                "aspect-ratio": _unref(aspectRatio),
                "background-color": _ctx.backgroundColor,
                onSizeChanged: _cache[0] || (_cache[0] = ($event)=>emit('size-changed', $event))
            }, null, 8, [
                "sport-event-details",
                "is-floating-mode-enabled",
                "is-active",
                "is-floating-mode-allowed",
                "aspect-ratio",
                "background-color"
            ])) : _unref(isLSportsDetailsHeadlineWidget)(_ctx.widget) ? (_openBlock(), _createBlock(LSportsWidget, {
                key: 1,
                "sport-event-details": _ctx.widget.props.sportEventDetails,
                "is-floating-mode-enabled": _unref(isFloatingModeEnabled),
                "is-active": _unref(isActive),
                "is-floating-mode-allowed": _unref(isFloatingModeAllowed),
                "aspect-ratio": _unref(aspectRatio)
            }, null, 8, [
                "sport-event-details",
                "is-floating-mode-enabled",
                "is-active",
                "is-floating-mode-allowed",
                "aspect-ratio"
            ])) : _unref(isBetgeniusDetailsHeadlineWidget)(_ctx.widget) ? (_openBlock(), _createBlock(BetgeniusWidget, {
                key: 2,
                "live-widget": _ctx.widget.props.liveWidget,
                locale: _ctx.widget.props.locale,
                "is-floating-mode-enabled": _unref(isFloatingModeEnabled),
                "is-active": _unref(isActive),
                "is-floating-mode-allowed": _unref(isFloatingModeAllowed),
                "aspect-ratio": _unref(aspectRatio)
            }, null, 8, [
                "live-widget",
                "locale",
                "is-floating-mode-enabled",
                "is-active",
                "is-floating-mode-allowed",
                "aspect-ratio"
            ])) : _createCommentVNode("", true);
    }
});
