import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconSize, IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PleaseLoginWidget',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['please-login-widget'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['please-login-title__holder'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['please-login-icon-holder'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            size: _unref(IconSize).SIZE_40,
                            name: _unref(IconName).VIDEO_CAMERA_OFF
                        }, null, 8, [
                            "size",
                            "name"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['please-login-title'])
                    }, _toDisplayString(_ctx.$t('WEB2_STREAM_PLEASE_SIGN_IN')), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['please-login-buttons'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['please-login-buttons__button'])
                    }, [
                        _createVNode(VButton, {
                            label: _ctx.$t('WEB2_DO_LOGIN'),
                            tag: _unref(Tag).ROUTER_LINK,
                            href: _ctx.$router.resolve({
                                name: _unref(RouteName).LOGIN
                            }).fullPath,
                            height: _unref(ButtonHeight).SMALL,
                            kind: _unref(ButtonKind).PRIMARY_DARK_VERSION,
                            "full-width": ""
                        }, null, 8, [
                            "label",
                            "tag",
                            "href",
                            "height",
                            "kind"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['please-login-buttons__button'])
                    }, [
                        _createVNode(VButton, {
                            label: _ctx.$t('JSPNAV_USTAT_REGISTER'),
                            tag: _unref(Tag).ROUTER_LINK,
                            href: _ctx.$router.resolve({
                                name: _unref(RouteName).REGISTRATION
                            }).fullPath,
                            height: _unref(ButtonHeight).SMALL,
                            kind: _unref(ButtonKind).PRIMARY_OPACITY,
                            "full-width": ""
                        }, null, 8, [
                            "label",
                            "tag",
                            "href",
                            "height",
                            "kind"
                        ])
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PleaseLoginWidget'
                ]
            ]);
        };
    }
});
