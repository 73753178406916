/* eslint-disable class-methods-use-this */ import RouteName from '@leon-hub/routing-config';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { isSportEventsSportsDateTimeFilterChanged } from 'web/src/modules/sportline/submodules/sports/utils';
import { isOptionalFilterSportFamily } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';
let DefaultSportsPageNavigationService = class DefaultSportsPageNavigationService extends BaseSportsPageNavigationService {
    prefetch(router, to, from, next) {
        super.prefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            const dateTimeFilter = this.getDateTimeFilter(to);
            const isFilterChanged = this.isFilterChanged(dateTimeFilter);
            const navigationStore = useRootNavigationStore();
            const sportlineSportsStore = useSportlineSportsStore();
            const { setPersistLocation } = useSportlinePersistLocation();
            setPersistLocation('sports', sportlineSportsStore.pageIdentifier);
            if (sportlineSportsStore.isLoaded && !isFilterChanged) {
                sportlineSportsStore.syncBackgroundRequests();
                navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
                next();
                return;
            }
            navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_PREMATCH_EVENTS);
            sportlineSportsStore.initialRequests({
                silent: false,
                dateTimeFilter
            }).then(()=>{
                navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
            });
            sportlineSportsStore.awaitStateIsLoaded().then(()=>{
                const { sportFamily } = getSportPageNavigationParameters(to);
                const selectedSport = useSportlineSportsStore().actualSportElement;
                // LEONWEB-3298 redirects for alias
                if (selectedSport && sportFamily && selectedSport.sport.navigationParameters.urlName !== sportFamily) router.push301({
                    name: router.currentRoute.value.name || '',
                    params: {
                        family: selectedSport.sport.navigationParameters.urlName
                    }
                });
            });
            next();
        });
    }
    checkAllowedFilter(navigationParameters) {
        const { sportFamily } = navigationParameters;
        const { allowedSportFamily, customFilters } = useSportlineSportsStore();
        return isOptionalFilterSportFamily(sportFamily, allowedSportFamily, customFilters) ? void 0 : {
            name: RouteName.SPORTLINE_PREMATCH_EVENTS
        };
    }
    isFilterChanged(dateTimeFilter) {
        return !!dateTimeFilter && isSportEventsSportsDateTimeFilterChanged(dateTimeFilter, useSportlineSportsStore().sportsListFilter);
    }
};
export { DefaultSportsPageNavigationService as default };
