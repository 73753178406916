import { computed } from 'vue';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { HeadlineSlideType } from 'web/src/modules/sportline/submodules/event-details/enums';
export function useInfoSlideType(props) {
    const { sportlineEvent } = props;
    const infoSlideType = computed(()=>{
        if (!sportlineEvent.value) return null;
        const { type, isOutright } = sportlineEvent.value;
        switch(true){
            case isOutright:
                return HeadlineSlideType.OutrightInfo;
            case type === SportlineType.Live:
                return HeadlineSlideType.LiveInfo;
            case type === SportlineType.Prematch:
                return HeadlineSlideType.PreMatchInfo;
            default:
                return null;
        }
    });
    return {
        infoSlideType
    };
}
