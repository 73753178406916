import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { useSportlineSegmentFilter } from 'web/src/modules/sportline/submodules/segment/components/Filter/composables';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
import { resolveOptionLocation } from './utils';
export function useSportlineLiveFilter(props, emit) {
    const { totalCount, allGroup, streamGroup, favoriteGroup, groups } = props;
    function emitChangeFilter(payload) {
        emit.changeFilter(payload);
    }
    const { allFilterItem, favoriteFilterItem, streamFilterItem } = useSportlineSegmentFilter({
        totalCount,
        allGroup,
        streamGroup,
        favoriteGroup,
        emitChangeFilter,
        resolveOptionLocation
    });
    const items = computed(()=>{
        const list = [];
        if (allFilterItem.value) list.push(allFilterItem.value);
        if (favoriteFilterItem.value) list.push(favoriteFilterItem.value);
        if (streamFilterItem.value) list.push(streamFilterItem.value);
        for (const group of groups.value){
            const sport = group.filterElement?.sport;
            if (!sport) continue;
            const to = resolveOptionLocation(group);
            const icon = {
                type: SportlineNavigationItemType.SportIcon,
                props: {
                    sportName: sport.representation.family,
                    size: IconSize.SIZE_16
                }
            };
            const filterItem = {
                key: group.key,
                icon,
                title: sport.name,
                isActive: group.isActive,
                to,
                onClick: ()=>emitChangeFilter({
                        group,
                        to
                    }),
                dataTest: sport.name,
                transitionId: group.transitionId
            };
            list.push(filterItem);
        }
        return list;
    });
    return {
        items
    };
}
