import { computed } from 'vue';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { isSportFamiliesIncludes } from 'web/src/modules/sportline/utils';
export default function usePostMatchStatisticResults(props) {
    const isDisplayTimeline = computed(()=>isSportFamiliesIncludes([
            SportFamily.Soccer
        ], props.postMatchStatistic.value?.family));
    const isDisplayScoresByTimes = computed(()=>isSportFamiliesIncludes([
            SportFamily.IceHockey,
            SportFamily.Basketball,
            SportFamily.TableTennis,
            SportFamily.Volleyball,
            SportFamily.Handball,
            SportFamily.Baseball
        ], props.postMatchStatistic.value?.family));
    const isDisplayScoresBySets = computed(()=>isSportFamiliesIncludes([
            SportFamily.Tennis
        ], props.postMatchStatistic.value?.family));
    return {
        isDisplayTimeline,
        isDisplayScoresByTimes,
        isDisplayScoresBySets
    };
}
