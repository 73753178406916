import { computed } from 'vue';
import { IconSize } from '@leon-hub/icons';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
import { isCyberSportDiscipline } from 'web/src/modules/sportline/guards';
import { RegionFamilyToIconMapping, RegionFamilyToIconMappingDefault } from 'web/src/modules/sportline/constants';
import { useSportlineSegmentFilter } from 'web/src/modules/sportline/submodules/segment/components/Filter/composables';
import { resolveOptionLocation } from './utils';
export function useSportlineCybersportFilter(props, emit) {
    const { totalCount, allGroup, streamGroup, favoriteGroup, groups } = props;
    function emitChangeFilter(payload) {
        emit.changeFilter(payload);
    }
    const { allFilterItem, favoriteFilterItem, streamFilterItem } = useSportlineSegmentFilter({
        totalCount,
        allGroup,
        streamGroup,
        favoriteGroup,
        emitChangeFilter,
        resolveOptionLocation
    });
    const items = computed(()=>{
        const list = [];
        if (allFilterItem.value) list.push(allFilterItem.value);
        if (favoriteFilterItem.value) list.push(favoriteFilterItem.value);
        if (streamFilterItem.value) list.push(streamFilterItem.value);
        for (const group of groups.value){
            const region = group.filterElement?.region;
            if (!region) continue;
            const to = resolveOptionLocation(group);
            const iconName = region.representation.icon;
            const icon = {
                type: SportlineNavigationItemType.Icon,
                props: {
                    name: isCyberSportDiscipline(iconName) ? RegionFamilyToIconMapping[iconName] : RegionFamilyToIconMappingDefault,
                    size: IconSize.SIZE_16
                }
            };
            const filterItem = {
                key: group.key,
                icon,
                title: region.name || '',
                isActive: group.isActive,
                to,
                onClick: ()=>emitChangeFilter({
                        group,
                        to
                    }),
                dataTest: region.name || void 0,
                transitionId: group.transitionId
            };
            list.push(filterItem);
        }
        return list;
    });
    return {
        items
    };
}
