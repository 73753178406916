import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { getIsBrokenUrl } from 'web/src/modules/sportline/submodules/region/services/navigation/utils';
import { useLoadRegionConfig } from 'web/src/modules/sportline/submodules/region/composables';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useBaseSportEventsRegionPageNavigationGuard } from './useBaseSportEventsRegionPageNavigationGuard';
import { useRegionNavigationBackButton } from './useRegionNavigationBackButton';
export function useDefaultSportEventsRegionPageNavigationGuard() {
    const regionPageStore = useRegionPageStore();
    const routeNavigationStore = useRootNavigationStore();
    const navigationStore = useSportlineNavigationStore();
    const isReady = toRef(regionPageStore, 'isReady');
    const currentRegionLoadingConfigId = toRef(regionPageStore, 'currentRegionLoadingConfigId');
    const baseRegionNavigationGuard = useBaseSportEventsRegionPageNavigationGuard();
    const sportlineNavigationBetweenPagesFilter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');
    const { getRedirectLocation, prefetch: basePrefetch } = baseRegionNavigationGuard;
    const { setOnlyFirstRouteContentLoading, setRouteContentLoaded } = routeNavigationStore;
    const { activateBackButton } = useRegionNavigationBackButton();
    const { setConfigForRegionLoading, initialRequests, clearRegion, syncBackgroundRequests, awaitStateIsLoaded } = regionPageStore;
    async function loadRegionForConfig(config, routeName) {
        setOnlyFirstRouteContentLoading(routeName);
        setConfigForRegionLoading(config);
        await initialRequests();
        setRouteContentLoaded(routeName);
    }
    function handleRedirectForWrongParameters(router, to) {
        const redirectTo = getRedirectLocation(router, to);
        if (redirectTo) router.pushRedirect(redirectTo, 301);
    }
    async function handleRedirectsForEmptyPage(router, to) {
        await awaitStateIsLoaded();
        if (isReady.value) {
            handleRedirectForWrongParameters(router, to);
            return;
        }
        const redirect = useRedirectBySportsTree({
            router,
            code: 301,
            allowedTargets: {
                sport: true,
                region: true
            }
        });
        redirect.make();
        regionPageStore.awaitLeavePage().then(()=>{
            redirect.clearTimeout();
        });
    }
    function syncRegionForConfig(config, routeName) {
        if (config.filter) clearRegion();
        syncBackgroundRequests({});
        setRouteContentLoaded(routeName);
    }
    function prefetch(router, to, from, next) {
        basePrefetch(router, to, from, (superResult)=>{
            const { regionConfig } = useLoadRegionConfig({
                to: toRef(to),
                sportlineNavigationBetweenPagesFilter
            });
            navigationStore.setSportlineNavigationBetweenPagesFilter(null);
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            if (getIsBrokenUrl(to)) {
                useRedirectBySportsTree({
                    router,
                    code: 301,
                    allowedTargets: {
                        region: true
                    }
                }).redirect();
                return;
            }
            const navigationParameters = getSportlinePageNavigationParameters(to);
            const isLoadingNewRegion = !currentRegionLoadingConfigId.value || navigationParameters.regionId !== currentRegionLoadingConfigId.value;
            activateBackButton(navigationParameters);
            const { setPersistLocation } = useSportlinePersistLocation();
            setPersistLocation('sports', navigationParameters, 'region');
            if (!isReady.value || isLoadingNewRegion) {
                loadRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
                handleRedirectsForEmptyPage(router, to);
            } else {
                syncRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
                handleRedirectForWrongParameters(router, to);
            }
            next();
        });
    }
    return {
        prefetch
    };
}
