import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
export function useSportsRoutePageMetaInfo() {
    const router = useRouter();
    const sportlineSportsStore = useSportlineSportsStore();
    const selectedSportFamily = toRef(sportlineSportsStore, 'selectedSportFamily');
    const isZeroMarginEventsTabSelected = toRef(sportlineSportsStore, 'isZeroMarginEventsTabSelected');
    const zeroMarginEventsList = toRef(sportlineSportsStore, 'zeroMarginEventsList');
    const activeListFilterSportElement = toRef(sportlineSportsStore, 'activeListFilterSportElement');
    const vm = {
        $router: router
    };
    const zeroMarginMetaParameters = computed(()=>({
            sport: null,
            sportFamily: CustomFilter.ZeroMargin.toLowerCase(),
            sportEmoji: null,
            sportEvents: {
                value: zeroMarginEventsList.value?.flatMap((param)=>{
                    let { sport, regions } = param;
                    return regions.flatMap((param)=>{
                        let { region, leagues } = param;
                        return leagues.flatMap((param)=>{
                            let { league, sportEvents } = param;
                            return sportEvents.map((param)=>{
                                let { sportEvent } = param;
                                return getSportEventSeoMetaInfo({
                                    sportEvent,
                                    sport,
                                    region,
                                    league,
                                    vm
                                });
                            });
                        });
                    });
                }).filter((sportEventMeta)=>'name' in sportEventMeta),
                merge (oldValue, newValue) {
                    return [
                        ...oldValue,
                        ...newValue
                    ];
                }
            }
        }));
    const metaParameters = computed(()=>{
        if (!selectedSportFamily.value) return {
            sport: null,
            sportFamily: null,
            sportEmoji: null
        };
        if (isZeroMarginEventsTabSelected.value) return zeroMarginMetaParameters.value;
        const sportElement = activeListFilterSportElement.value;
        return {
            sport: sportElement?.sport.name,
            sportFamily: sportElement?.sport.navigationParameters.urlName,
            sportEmoji: sportElement?.sport.representation.emoji
        };
    });
    useSeoMetaParameters(metaParameters);
}
