import { ref } from 'vue';
export function useHeadlineDimensionExpose() {
    const headlineElement = ref();
    return {
        headlineElement,
        getPosition () {
            return headlineElement.value?.offsetTop ?? 0;
        },
        getHeight () {
            return headlineElement.value?.scrollHeight ?? 0;
        }
    };
}
