import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { VScrollbar } from '@components/v-scrollbar';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import { useLeaguePlayoff } from './useLeaguePlayoff';
import LeaguePlayoffRound from './LeaguePlayoffRound.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoff',
    props: {
        leaguePlayoff: {}
    },
    setup (__props) {
        const props = __props;
        const playoff = toRef(props, 'leaguePlayoff');
        const { isExpanded, canExpand, rounds } = useLeaguePlayoff({
            playoff
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-playoff",
                            title: _ctx.$t('WEB2_STATISTICS_PLAYOFF'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createVNode(_unref(VScrollbar), {
                                        "test-el": "league-playoff"
                                    }, {
                                        default: _withCtx(()=>[
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['league-playoff__content'])
                                                }, [
                                                    playoff.value.name ? (_openBlock(), _createElementBlock("div", {
                                                        key: 0,
                                                        class: _normalizeClass(_ctx.$style['league-playoff__title'])
                                                    }, _toDisplayString(playoff.value.name), 3)) : _createCommentVNode("", true),
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['league-playoff__rounds'])
                                                    }, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rounds), (round, roundIndex)=>(_openBlock(), _createBlock(LeaguePlayoffRound, {
                                                                key: roundIndex,
                                                                round: round,
                                                                "is-last-round": roundIndex + 1 >= _unref(rounds).length
                                                            }, null, 8, [
                                                                "round",
                                                                "is-last-round"
                                                            ]))), 128))
                                                    ], 2)
                                                ], 2)
                                            ]),
                                        _: 1
                                    }),
                                    _unref(canExpand) ? (_openBlock(), _createBlock(ExpandButton, {
                                        key: 0,
                                        "is-expanded": _unref(isExpanded),
                                        "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                                        "opened-title": _ctx.$t('WEB2_MATCH_STATISTIC_HIDE'),
                                        "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_SHOW')
                                    }, null, 8, [
                                        "is-expanded",
                                        "opened-title",
                                        "closed-title"
                                    ])) : _createCommentVNode("", true)
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LeaguePlayoff'
                ]
            ]);
        };
    }
});
