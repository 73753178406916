import { toRef } from 'vue';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
export function useSportlineEventDetailsHeadlineData() {
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const sportElement = toRef(sportlineEventDetailsStore, 'sportElement');
    const sport = toRef(sportlineEventDetailsStore, 'sport');
    const region = toRef(sportlineEventDetailsStore, 'region');
    const league = toRef(sportlineEventDetailsStore, 'league');
    const isStreamAvailable = toRef(sportlineEventDetailsStore, 'isStreamAvailable');
    const defaultSwiperSlideId = toRef(sportlineEventDetailsStore, 'defaultSwiperSlideId');
    const widgetConfig = toRef(sportlineEventDetailsStore, 'sportEventWidgetConfig');
    const liveWidgetType = toRef(sportlineEventDetailsStore, 'liveWidgetType');
    const { saveSwiperSlideId: onHeadlineSlideChanged, reloadStream: reloadStreamInfo } = sportlineEventDetailsStore;
    return {
        sportlineEvent,
        sportElement,
        sport,
        region,
        league,
        isStreamAvailable,
        defaultSwiperSlideId,
        widgetConfig,
        liveWidgetType,
        reloadStreamInfo,
        onHeadlineSlideChanged
    };
}
