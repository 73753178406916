import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "redirect-from-empty-page-info"
};
const _hoisted_2 = {
    class: "redirect-from-empty-page-info__title"
};
const _hoisted_3 = {
    class: "redirect-from-empty-page-info__description"
};
import { onBeforeUnmount } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useSportlineRedirectFromEmptyPageInfo } from './useSportlineRedirectFromEmptyPageInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineRedirectFromEmptyPageInfo',
    setup (__props) {
        const { isShown, title, description, clearInfo } = useSportlineRedirectFromEmptyPageInfo();
        onBeforeUnmount(clearInfo);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isShown) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(title)), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(description)), 1),
                _createElementVNode("div", {
                    class: "redirect-from-empty-page-info__close-button",
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(clearInfo) && _unref(clearInfo)(...args);
                    })
                }, [
                    _createVNode(_unref(VIcon), {
                        size: _unref(IconSize).SIZE_24,
                        name: _unref(IconName).CROSS
                    }, null, 8, [
                        "size",
                        "name"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportlineRedirectFromEmptyPageInfo'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
