import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import useLeagueProgressBar from './useLeagueProgressBar';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueProgressBar',
    props: {
        currentSeasonStart: {},
        currentSeasonEnd: {},
        backgroundColor: {}
    },
    setup (__props) {
        const props = __props;
        const currentSeasonStart = toRef(props, 'currentSeasonStart', null);
        const currentSeasonEnd = toRef(props, 'currentSeasonEnd', null);
        const backgroundColor = toRef(props, 'backgroundColor', null);
        const { isStarted, isEnded, dateStart, dateEnd, startsInLabel, progressBarPercentage, progressBarKind } = useLeagueProgressBar({
            currentSeasonStart,
            currentSeasonEnd,
            backgroundColor
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['league-progress-bar']]: true,
                    [_ctx.$style['league-progress-bar--default']]: !backgroundColor.value,
                    [_ctx.$style['league-progress-bar--colored']]: !!backgroundColor.value
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['league-progress-bar__time-label-wrapper'])
                }, [
                    _createElementVNode("div", {
                        style: _normalizeStyle({
                            'flex-basis': `${_unref(progressBarPercentage)}%`
                        }),
                        class: _normalizeClass(_ctx.$style['league-progress-bar__time-label-completed'])
                    }, [
                        _unref(isEnded) ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['league-progress-bar__time-label'])
                        }, _toDisplayString(_ctx.$t('WEB2_LEAGUE_IS_OVER')), 3)) : _unref(isStarted) ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['league-progress-bar__time-label'])
                        }, _toDisplayString(_unref(progressBarPercentage)) + "%", 3)) : (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                            key: 2,
                            text: _ctx.$t('WEB2_LEAGUE_WILL_COME_IN'),
                            class: _normalizeClass(_ctx.$style['league-progress-bar__time-label-extra-info'])
                        }, {
                            time: _withCtx(()=>[
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['league-progress-bar__time-label'])
                                    }, _toDisplayString(_unref(startsInLabel)), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "text",
                            "class"
                        ]))
                    ], 6),
                    _createElementVNode("div", {
                        style: _normalizeStyle({
                            'flex-basis': `${100 - _unref(progressBarPercentage)}%`
                        })
                    }, "   ", 4)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['league-progress-bar__bar'])
                }, [
                    _createVNode(VLinearProgress, {
                        value: _unref(progressBarPercentage),
                        height: _unref(ProgressHeight).TINY_EXTRA,
                        kind: _unref(progressBarKind),
                        class: _normalizeClass(_ctx.$style['league-progress-bar__filling'])
                    }, null, 8, [
                        "value",
                        "height",
                        "kind",
                        "class"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['league-progress-bar__dates'])
                }, [
                    _createElementVNode("div", null, _toDisplayString(_unref(dateStart)), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(dateEnd)), 1)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeagueProgressBar'
                ]
            ]);
        };
    }
});
