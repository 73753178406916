import { computed } from 'vue';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
export function useWidgetSwitcher(props) {
    const { slideIds, activeSlideId, emitSetActiveSlide } = props;
    const isStreamAvailable = computed(()=>!!slideIds.value.find((param)=>{
            let { id } = param;
            return id === HeadlineSlideName.LiveStream;
        }));
    const isLiveWidgetAvailable = computed(()=>!!slideIds.value.find((param)=>{
            let { id } = param;
            return id === HeadlineSlideName.LiveWidget;
        }));
    function setActiveSlide(value) {
        if (activeSlideId.value === value) {
            emitSetActiveSlide(HeadlineSlideName.Info);
            return;
        }
        emitSetActiveSlide(value);
    }
    return {
        isStreamAvailable,
        isLiveWidgetAvailable,
        setActiveSlide
    };
}
