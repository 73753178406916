import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "match-block-details-team__identity"
};
const _hoisted_2 = {
    class: "match-block-details-team__name"
};
const _hoisted_3 = {
    class: "match-block-details-team-history"
};
const _hoisted_4 = {
    class: "match-block-details-team-history__item-content match-block-details-team-history__date"
};
const _hoisted_5 = {
    class: "match-block-details-team-history__item-content"
};
const _hoisted_6 = {
    class: "match-block-details-team-history__item-content"
};
import { toRef } from 'vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { useLeaguePlayoffMatchesBlock } from './useLeaguePlayoffMatchesBlock';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoffMatchesBlockDetalis',
    props: {
        block: {}
    },
    emits: [
        "require-close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const block = toRef(props, 'block');
        const { matchScores, matchesHistory, participants } = useLeaguePlayoffMatchesBlock({
            block
        });
        function onBlockClick() {
            emit('require-close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "match-block-details",
                onClick: onBlockClick
            }, [
                _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(participants), (participant, participantIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: participantIndex,
                            class: _normalizeClass([
                                "match-block-details-team",
                                {
                                    'match-block-details-team--winner': participant.winner
                                }
                            ])
                        }, [
                            participant.team ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createElementVNode("div", _hoisted_1, [
                                    _createVNode(StatisticTeamLogo, {
                                        class: "match-block-details-team__logo",
                                        team: participant.team
                                    }, null, 8, [
                                        "team"
                                    ]),
                                    _createElementVNode("div", _hoisted_2, _toDisplayString(participant.team.name), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(matchScores), (scores, scoresKey)=>(_openBlock(), _createElementBlock("div", {
                                        key: scoresKey,
                                        class: "match-block-details-team__result-score"
                                    }, _toDisplayString(participant.team.id ? scores[participant.team.id] || '-' : '-'), 1))), 128))
                            ], 64)) : _createCommentVNode("", true)
                        ], 2))), 128))
                ]),
                _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(matchesHistory), (item, itemIndex)=>(_openBlock(), _createElementBlock("div", {
                            key: itemIndex,
                            class: "match-block-details-team-history__item"
                        }, [
                            _createElementVNode("div", _hoisted_4, _toDisplayString(item.date), 1),
                            _createElementVNode("div", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.names, (name, nameIndex)=>(_openBlock(), _createElementBlock("span", {
                                        key: nameIndex,
                                        class: "match-block-details-team-history__name"
                                    }, _toDisplayString(name), 1))), 128))
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.scores, (score, scoreIndex)=>(_openBlock(), _createElementBlock("span", {
                                        key: scoreIndex,
                                        class: "match-block-details-team-history__score"
                                    }, _toDisplayString(score || ' '), 1))), 128))
                            ])
                        ]))), 128))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LeaguePlayoffMatchesBlockDetalis'
                ]
            ]);
        };
    }
});
