import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 4
};
import { isEventElement, isLeagueHeadlineElement, isSportHeadlineElement, isNoEventsElement } from './guards';
import VirtualListRendererEventElement from './components/VirtualListRendererEventElement.vue';
import VirtualListRendererLeagueHeadline from './components/VirtualListRendererLeagueHeadline.vue';
import VirtualListRendererSportHeadline from './components/VirtualListRendererSportHeadline.vue';
import VirtualListRendererNoEventsElement from './components/VirtualListRendererNoEventsElement.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualListRendererElement',
    props: {
        element: {},
        canAddEventToFavorite: {
            type: Boolean
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        extendedBlockType: {}
    },
    emits: [
        "event-resize"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>_unref(isEventElement)(_ctx.element) ? (_openBlock(), _createBlock(VirtualListRendererEventElement, {
                key: 0,
                element: _ctx.element,
                "can-add-to-favorite": _ctx.canAddEventToFavorite,
                "extended-block-type": _ctx.extendedBlockType,
                onResize: _cache[0] || (_cache[0] = ($event)=>emit('event-resize', $event))
            }, null, 8, [
                "element",
                "can-add-to-favorite",
                "extended-block-type"
            ])) : _unref(isLeagueHeadlineElement)(_ctx.element) ? (_openBlock(), _createBlock(VirtualListRendererLeagueHeadline, {
                key: 1,
                element: _ctx.element
            }, null, 8, [
                "element"
            ])) : _unref(isSportHeadlineElement)(_ctx.element) ? (_openBlock(), _createBlock(VirtualListRendererSportHeadline, {
                key: 2,
                element: _ctx.element,
                "is-market-types-selection-enabled": _ctx.isMarketTypesSelectionEnabled
            }, null, 8, [
                "element",
                "is-market-types-selection-enabled"
            ])) : _unref(isNoEventsElement)(_ctx.element) ? (_openBlock(), _createBlock(VirtualListRendererNoEventsElement, {
                key: 3,
                element: _ctx.element
            }, null, 8, [
                "element"
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_1));
    }
});
