import { computed, toRef } from 'vue';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
export function useLeaguePageHeadlineData() {
    const leaguePageStore = useLeaguePageStore();
    const sportElement = toRef(leaguePageStore, 'headerDataSportElement');
    const regionElement = toRef(leaguePageStore, 'headerDataRegionElement');
    const leagueElement = toRef(leaguePageStore, 'headerDataLeagueElement');
    const leagueData = toRef(leaguePageStore, 'leagueData');
    const leagueRepresentationData = toRef(leaguePageStore, 'leagueRepresentationData');
    const sport = computed(()=>sportElement.value?.sport ?? null);
    const region = computed(()=>regionElement.value?.region ?? null);
    const league = computed(()=>leagueElement.value?.league ?? null);
    return {
        sport,
        region,
        league,
        leagueData,
        leagueRepresentationData
    };
}
