import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { defaultOverscanValue } from './constants';
import { useOnScrollListeners } from './composables';
import { useVerticalRecycleScroller } from './useRecycleScroller';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VRecycleScrollerVertical',
    props: {
        options: {},
        overscan: {},
        disableOwnScroll: {
            type: Boolean
        }
    },
    setup (__props, param) {
        let { expose: __expose } = param;
        const props = __props;
        const options = toRef(props, 'options');
        const overscan = toRef(props, 'overscan', defaultOverscanValue);
        const disableOwnScroll = toRef(props, 'disableOwnScroll', false);
        const { list, containerProps, wrapperProps, scrollTo, scrollBy } = useVerticalRecycleScroller({
            options,
            overscan
        });
        const { containerRef, listenerTargetRef, onScroll } = containerProps;
        __expose({
            scrollTo,
            scrollBy
        });
        useOnScrollListeners({
            containerRef,
            listenerTargetRef,
            disableOwnScroll,
            onScroll
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "containerRef",
                ref: containerRef,
                class: _normalizeClass({
                    [_ctx.$style['vertical-scrollable']]: !disableOwnScroll.value
                })
            }, [
                _createElementVNode("div", {
                    style: _normalizeStyle(_unref(wrapperProps).style)
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item)=>(_openBlock(), _createElementBlock("div", {
                            key: item.index,
                            style: _normalizeStyle(item.style)
                        }, [
                            _renderSlot(_ctx.$slots, "item", {
                                item: item,
                                option: options.value[item.index]
                            })
                        ], 4))), 128))
                ], 4)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VRecycleScrollerVertical'
                ]
            ]);
        };
    }
});
