import { toValue } from 'vue';
import { getSportElementEventsCounters } from 'web/src/modules/sportline/utils';
import { getIsExpandedFromMap } from 'web/src/modules/sportline/composables/expandable-block/utils';
import { VirtualListElementType, VirtualListBlockLayout } from '../enums';
export function getGroupsForSportElement(sportElement, props) {
    if (!sportElement) return [];
    const isLastSport = props.isLastSport ?? false;
    const blockLayout = props.blockLayout ?? VirtualListBlockLayout.Default;
    const isDefaultCollapsedSport = toValue(props.isDefaultCollapsedSport ?? false);
    const collapseSportKey = toValue(props.collapseSportKey ?? null);
    const expandedSportStateMap = toValue(props.expandedSportStateMap ?? {});
    const { basisKey } = props;
    const isExpandedSport = getIsExpandedFromMap(expandedSportStateMap, collapseSportKey, !isDefaultCollapsedSport);
    const sportHeadline = {
        type: VirtualListElementType.SportHeadline,
        sport: sportElement.sport,
        isLastSport,
        blockLayout,
        eventsCount: getSportElementEventsCounters(sportElement).total,
        basisKey,
        collapseSportKey
    };
    if (!isExpandedSport) return [
        sportHeadline
    ];
    const regionsCount = sportElement.regions.length;
    const elements = sportElement.regions.flatMap((regionElement, regionIndex)=>{
        const leaguesCount = regionElement.leagues.length;
        const isLastRegionInSport = regionsCount === regionIndex + 1;
        return regionElement.leagues.flatMap((leagueElement, leagueIndex)=>{
            const isLastLeagueInRegion = leaguesCount === leagueIndex + 1;
            const leagueHeadline = {
                type: VirtualListElementType.LeagueHeadline,
                sport: sportElement.sport,
                region: regionElement.region,
                leagueElement,
                isLastLeagueInRegion,
                isLastRegionInSport,
                isLastSport,
                basisKey
            };
            const eventsCount = leagueElement.sportEvents.length;
            return [
                leagueHeadline,
                ...leagueElement.sportEvents.flatMap((sportlineEvent, eventIndex)=>({
                        type: VirtualListElementType.Event,
                        sport: sportElement.sport,
                        sportlineEvent,
                        isLastEventInLeague: eventsCount === eventIndex + 1,
                        isLastLeagueInRegion,
                        isLastRegionInSport,
                        isLastSport,
                        blockLayout,
                        basisKey
                    }))
            ];
        });
    });
    return [
        sportHeadline,
        ...elements
    ];
}
