import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
import { IconName } from '@leon-hub/icons';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventSuspendedInfo',
    props: {
        isShort: {
            type: Boolean
        },
        title: {},
        isPaused: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>_ctx.isShort ? (_openBlock(), _createBlock(VHintBlock, {
                key: 0,
                label: _ctx.title,
                icon: _ctx.isPaused ? _unref(IconName).PAUSE_CIRCLE : _unref(IconName).ATTENTION_OUTLINE,
                "is-centered": false
            }, null, 8, [
                "label",
                "icon",
                "is-centered"
            ])) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['suspended-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['suspended-info__title'])
                }, _toDisplayString(_ctx.$t('WEB2_SPORT_EVENT_UNAVAILABLE')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['suspended-info__description'])
                }, _toDisplayString(_ctx.$t('WEB2_CHOOSE_ANOTHER_SPORT_EVENT')), 3)
            ], 2));
    }
});
