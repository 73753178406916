import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useBaseSportEventsRegionPageNavigationGuard() {
    const regionPageStore = useRegionPageStore();
    const region = toRef(regionPageStore, 'region');
    function prefetch(router, to, from, next) {
        const navigationParameters = getSportPageNavigationParameters(to);
        if (!isValidSportEventsPageIdentifier(navigationParameters)) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        useRegionPageStore().setPageIdentifier(navigationParameters);
        useSportlineBreadcrumbsStore().setPageIdentifier(navigationParameters);
        next();
    }
    function getRedirectLocation(router, currentLocation) {
        const target = region.value?.navigationParameters;
        if (!target) return null;
        const currentTarget = getSportPageNavigationParameters(currentLocation);
        if (currentTarget.regionId !== target.id) return null;
        if (currentTarget.regionUrlName === target.urlName && currentTarget.sportFamily === target.sportFamily) return null;
        return router.resolve301location(resolveRegionPageLink(target));
    }
    return {
        prefetch,
        getRedirectLocation
    };
}
