import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref, toRef } from 'vue';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import RegionHeadline from 'web/src/modules/sportline/views/headline/RegionHeadline.vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CybersportNoEvents',
    props: {
        regionElement: {},
        isMarketTypesSelectionEnabled: {
            type: Boolean
        },
        customTitle: {}
    },
    setup (__props) {
        const props = __props;
        const basisKey = createSportlineFragmentBasisKey({});
        useProvideMarketColumn({
            basisKey,
            isMarketTypesSelectionEnabled: toRef(props, 'isMarketTypesSelectionEnabled'),
            marketsColumns: ref([]),
            marketsTypes: ref([])
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "title", {}, ()=>[
                        _ctx.regionElement ? (_openBlock(), _createBlock(RegionHeadline, {
                            key: 0,
                            "region-id": _ctx.regionElement.region.id,
                            "custom-title": _ctx.customTitle,
                            "basis-key": _unref(basisKey),
                            "hide-events-counter": ""
                        }, null, 8, [
                            "region-id",
                            "custom-title",
                            "basis-key"
                        ])) : _createCommentVNode("", true)
                    ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['no-events-content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['no-events-content__label'])
                    }, _toDisplayString(_ctx.$t('WEB2_LIVE_NOEVENTS_TITLE')), 3)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'CybersportNoEvents'
                ]
            ]);
        };
    }
});
