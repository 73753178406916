import { toRef } from 'vue';
import { useNavigationBackButtonStore } from 'web/src/modules/core/store/useNavigationBackButtonStore';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { resolveLeaguePageLink, resolveLivePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useSportEventDetailsNavigationBackButton() {
    const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportEventDetails = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    function activateBackButton() {
        setNavigationBackLocationGetter(()=>{
            if (!sportEventDetails.value) return;
            return sportEventDetails.value.type === SportlineType.Live ? resolveLivePageLink(sportEventDetails.value.sport.navigationParameters) : resolveLeaguePageLink(sportEventDetails.value.league.navigationParameters);
        });
    }
    return {
        activateBackButton
    };
}
