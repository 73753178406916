import { computed } from 'vue';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { HeadlineWideLayoutName, HeadlineWideLayoutType } from '../enums';
const HeadlineSportlineType = new Map();
HeadlineSportlineType.set(SportlineType.Live, HeadlineWideLayoutType.Live);
HeadlineSportlineType.set(SportlineType.Prematch, HeadlineWideLayoutType.PreMatch);
// unreactive prop
export function useHeadlineWideLayout(props) {
    const { sportlineEvent, autoLayoutAllowed, hasPrimaryMarket, hasInplayComponent } = props;
    const hasZeroMargin = computed(()=>// LEONWEB-7696 do show ZeroMargin indicator only for PreMatch
        sportlineEvent.value.type === SportlineType.Prematch && !sportlineEvent.value.isOutright && sportlineEvent.value.hasZeroMargin);
    const hasLargeContent = computed(()=>hasPrimaryMarket.value || hasInplayComponent.value);
    const backgroundLineType = computed(()=>{
        if (sportlineEvent.value.isOutright) return HeadlineWideLayoutType.Outright;
        const { type } = sportlineEvent.value;
        return HeadlineSportlineType.get(type) ?? HeadlineWideLayoutType.Default;
    });
    const headlineLayoutName = computed(()=>{
        switch(true){
            case autoLayoutAllowed && hasInplayComponent.value:
                return HeadlineWideLayoutName.Auto;
            case hasLargeContent.value && hasZeroMargin.value:
                return HeadlineWideLayoutName.Large;
            case hasLargeContent.value && !hasZeroMargin.value:
                return HeadlineWideLayoutName.Default;
            case !hasInplayComponent.value && !hasPrimaryMarket.value:
                return HeadlineWideLayoutName.Low;
            default:
                return;
        }
    });
    return {
        backgroundLineType,
        headlineLayoutName
    };
}
