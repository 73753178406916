import { VirtualListElementType } from './enums';
export function isEventElement(element) {
    return element.type === VirtualListElementType.Event;
}
export function isLeagueHeadlineElement(element) {
    return element.type === VirtualListElementType.LeagueHeadline;
}
export function isSportHeadlineElement(element) {
    return element.type === VirtualListElementType.SportHeadline;
}
export function isNoEventsElement(element) {
    return element.type === VirtualListElementType.NoEvents;
}
