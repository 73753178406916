/* eslint-disable class-methods-use-this */ import { toRef } from 'vue';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { useSportlineEventDetailsAnalytics } from 'web/src/modules/sportline/submodules/event-details/composables/analytics';
import { GetEventSubscriptionAction } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';
import { useSportEventDetailsNavigationBackButton } from './useSportEventDetailsNavigationBackButton';
let DefaultSportEventDetailsPageNavigationService = class DefaultSportEventDetailsPageNavigationService extends BaseSportEventDetailsPageNavigationService {
    prefetch(router, to, from, next) {
        super.prefetch(router, to, from, (superResult)=>{
            if (void 0 !== superResult) {
                next(superResult);
                return;
            }
            useSportEventDetailsNavigationBackButton().activateBackButton();
            // do not await handle
            this.handleRedirectsForEmptyPage(router, to);
            useSportlineEventDetailsStore().initialRequests();
            next();
        });
    }
    handleRedirectsForEmptyPage(router, to) {
        const { sportEventId } = getSportlinePageNavigationParameters(to);
        const { showConnectionError } = useSnackbarsStore();
        if (!sportEventId) return;
        const sportlineEventDetailsStore = useSportlineEventDetailsStore();
        const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
        const sportlineEventStatisticStore = useSportlineEventStatisticStore();
        const postMatchStatistics = toRef(sportlineEventStatisticStore, 'postMatchStatistics');
        const analytics = useSportlineEventDetailsAnalytics();
        sportlineEventDetailsStore.createOnInitBehaviour(sportEventId, {
            [GetEventSubscriptionAction.ShowError] () {
                showConnectionError();
            },
            [GetEventSubscriptionAction.Redirect] () {
                useRedirectBySportsTree({
                    router,
                    code: 301,
                    allowedTargets: {
                        sport: true,
                        region: true,
                        league: true
                    },
                    canShowRedirectedInfoTargets: {
                        league: true
                    }
                }).make();
            },
            [GetEventSubscriptionAction.Finish] () {
                if (sportlineEvent.value) {
                    analytics.sendVisitPageAnalytics(sportlineEvent.value);
                    return;
                }
                // eslint-disable-next-line no-useless-return
                if (!postMatchStatistics.value) return;
                analytics.sendVisitStatisticsPageAnalytics(postMatchStatistics.value);
            }
        });
    }
};
/**
 * Handle navigation for user
 * Check missing event and redirect
 */ export { DefaultSportEventDetailsPageNavigationService as default };
