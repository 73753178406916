import { computed, ref, watch, toRef } from 'vue';
import { tryOnMounted, unrefElement } from '../refs';
import { useControllableResizeObserver as useResizeObserver } from '../resize-observer';
function getWindow() {
    const isClient = 'undefined' != typeof window && 'undefined' != typeof document;
    return isClient ? window : void 0;
}
function getBoxSize(entry, box) {
    if ('border-box' === box) return entry.borderBoxSize;
    return 'content-box' === box ? entry.contentBoxSize : entry.devicePixelContentBoxSize;
}
/**
 * Reactive size of an HTML element.
 * @see https://vueuse.org/useElementSize
 * @see https://github.com/vueuse/vueuse/blob/main/packages/core/useElementSize/index.ts
 */ export function useElementSize(target) {
    let initialSize = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        width: 0,
        height: 0
    }, options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const { box = 'content-box' } = options;
    const isSVG = computed(()=>unrefElement(target)?.namespaceURI?.includes('svg'));
    const width = ref(initialSize.width);
    const height = ref(initialSize.height);
    const targetRef = toRef(target);
    const { stop: stopResizeObserver, start: startResizeObserver } = useResizeObserver(targetRef, (param)=>{
        let [entry] = param;
        const boxSize = getBoxSize(entry, box);
        if (getWindow() && isSVG.value) {
            const $elem = unrefElement(target);
            if ($elem) {
                const rect = $elem.getBoundingClientRect();
                width.value = rect.width;
                height.value = rect.height;
            }
        } else if (boxSize) {
            const formatBoxSize = Array.isArray(boxSize) ? boxSize : [
                boxSize
            ];
            width.value = formatBoxSize.reduce((acc, param)=>{
                let { inlineSize } = param;
                return acc + inlineSize;
            }, 0);
            height.value = formatBoxSize.reduce((acc, param)=>{
                let { blockSize } = param;
                return acc + blockSize;
            }, 0);
        } else {
            // fallback
            width.value = entry.contentRect.width;
            height.value = entry.contentRect.height;
        }
    }, options);
    let stopElementChangeWatch;
    function startElementChangeWatch() {
        stopElementChangeWatch = stopElementChangeWatch ?? watch(()=>unrefElement(target), (ele)=>{
            width.value = ele ? initialSize.width : 0;
            height.value = ele ? initialSize.height : 0;
        });
    }
    function stop() {
        stopResizeObserver();
        stopElementChangeWatch?.();
        stopElementChangeWatch = void 0;
    }
    function start() {
        startResizeObserver();
        startElementChangeWatch();
    }
    tryOnMounted(()=>{
        const ele = unrefElement(target);
        if (ele) {
            width.value = 'offsetWidth' in ele ? ele.offsetWidth : initialSize.width;
            height.value = 'offsetHeight' in ele ? ele.offsetHeight : initialSize.height;
        }
        start();
    });
    return {
        width,
        height,
        start,
        stop
    };
}
