import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import SportEventMatchStatistic from 'web/src/modules/sportline/submodules/event-details/components/statistic/SportEventMatchStatistic.vue';
import LastMatchesStatistic from 'web/src/modules/sportline/submodules/event-details/components/statistic/LastMatchesStatistic.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LastGamesResultsMatchStatisticBlock',
    props: {
        teams: {},
        hostMatches: {},
        guestMatches: {},
        sportFamily: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _ctx.teams && (_ctx.hostMatches.length > 0 || _ctx.guestMatches.length > 0) ? (_openBlock(), _createBlock(VListItemAccordion, {
                            key: 0,
                            id: "last-matches",
                            title: _ctx.$t('WEB2_MATCH_STATISTIC_LAST_MATCHES_RESULTS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", null, [
                                        _createVNode(LastMatchesStatistic, {
                                            class: _normalizeClass(_ctx.$style['last-games-results-match-statistic__last-matches']),
                                            teams: _ctx.teams,
                                            "host-matches": _ctx.hostMatches,
                                            "guest-matches": _ctx.guestMatches
                                        }, null, 8, [
                                            "class",
                                            "teams",
                                            "host-matches",
                                            "guest-matches"
                                        ]),
                                        _ctx.hostMatches.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 0
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['sport-event-statistic-subtitle'])
                                            }, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_LAST_GAMES_RESULTS')) + ": " + _toDisplayString(_ctx.teams[0].name), 3),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['sport-event-statistic-last-games'])
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hostMatches, (match)=>(_openBlock(), _createBlock(SportEventMatchStatistic, {
                                                        key: match.id ?? void 0,
                                                        match: match,
                                                        "displayed-for-team": _ctx.teams[0],
                                                        "sport-family": _ctx.sportFamily
                                                    }, null, 8, [
                                                        "match",
                                                        "displayed-for-team",
                                                        "sport-family"
                                                    ]))), 128))
                                            ], 2)
                                        ], 64)) : _createCommentVNode("", true),
                                        _ctx.guestMatches.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 1
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['sport-event-statistic-subtitle'])
                                            }, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_LAST_GAMES_RESULTS')) + ": " + _toDisplayString(_ctx.teams[1].name), 3),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['sport-event-statistic-last-games'])
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guestMatches, (match)=>(_openBlock(), _createBlock(SportEventMatchStatistic, {
                                                        key: match.id ?? void 0,
                                                        match: match,
                                                        "displayed-for-team": _ctx.teams[1],
                                                        "sport-family": _ctx.sportFamily
                                                    }, null, 8, [
                                                        "match",
                                                        "displayed-for-team",
                                                        "sport-family"
                                                    ]))), 128))
                                            ], 2)
                                        ], 64)) : _createCommentVNode("", true)
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LastGamesResultsMatchStatisticBlock'
                ]
            ]);
        };
    }
});
