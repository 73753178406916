export function calculateTeamPlayStatistic(statistics) {
    const totalStatistic = {
        totalGames: statistics?.total || 0,
        draws: statistics?.draws || 0,
        drawsPercentage: 0,
        host: {
            wins: statistics?.wins[0] || 0,
            winsPercentage: 0,
            goals: statistics?.goals[0] || 0
        },
        guest: {
            wins: statistics?.wins[1] || 0,
            winsPercentage: 0,
            goals: statistics?.goals[1] || 0
        }
    };
    if (0 === totalStatistic.totalGames) return null;
    // calculate percentages
    totalStatistic.drawsPercentage = 100 / totalStatistic.totalGames * totalStatistic.draws;
    totalStatistic.host.winsPercentage = 100 / totalStatistic.totalGames * totalStatistic.host.wins;
    totalStatistic.guest.winsPercentage = 100 / totalStatistic.totalGames * totalStatistic.guest.wins;
    // round to 1 digit
    totalStatistic.drawsPercentage = Math.round(10 * totalStatistic.drawsPercentage) / 10;
    totalStatistic.host.winsPercentage = Math.round(10 * totalStatistic.host.winsPercentage) / 10;
    totalStatistic.guest.winsPercentage = Math.round(10 * totalStatistic.guest.winsPercentage) / 10;
    return totalStatistic;
}
