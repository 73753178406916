import { toRef, computed } from 'vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useSportsZeroMarginTab(props) {
    const { isActive } = props;
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isMarketTypesSelectionEnabled = toRef(sportlineSettingsStore, 'isMarketTypesSelectionEnabled');
    const sportlineSportsStore = useSportlineSportsStore();
    const rawZeroMarginEventsList = toRef(sportlineSportsStore, 'rawZeroMarginEventsList');
    const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isZeroMarginListLoaded');
    const zeroMarginEventsList = toRef(sportlineSportsStore, 'zeroMarginEventsList');
    const hasElementsForCurrentFilter = computed(()=>!!zeroMarginEventsList.value?.length);
    const isNeedRedirectFromTab = computed(()=>isActive.value && !rawZeroMarginEventsList.value?.length);
    return {
        isMarketTypesSelectionEnabled,
        isReadyToDisplayContent,
        zeroMarginEventsList,
        hasElementsForCurrentFilter,
        isNeedRedirectFromTab
    };
}
