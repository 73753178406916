import { computed, toRef } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { SportBackgroundColorDark, SportBackgroundColorLight } from 'web/src/modules/sportline/constants';
const defaultStreamAspectRatio = 9 / 16;
export function useHeadlineCybersportLayout(props) {
    const { sportlineEvent } = props;
    const { isLight } = useTheme();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const sportSegmentRepresentationSettings = toRef(sportlineSettingsStore, 'sportSegmentRepresentationSettings');
    const sport = computed(()=>sportlineEvent.value.sport);
    const sportSegmentRepresentationSetting = computed(()=>sportSegmentRepresentationSettings.value[sport.value.segment.id] ?? null);
    const backgroundColor = computed(()=>isLight.value ? SportBackgroundColorLight[SportFamily.ESport] : SportBackgroundColorDark[SportFamily.ESport]);
    const backgroundStyles = computed(()=>{
        const url = sportSegmentRepresentationSetting.value?.backgroundUrl;
        return url ? {
            'background-image': `url('${url}')`
        } : {
            'background-color': backgroundColor.value
        };
    });
    const streamAspectRatio = computed(()=>sportSegmentRepresentationSetting.value?.streamAspectRatio ?? defaultStreamAspectRatio);
    return {
        backgroundStyles,
        streamAspectRatio,
        backgroundColor
    };
}
