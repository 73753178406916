import { ref } from 'vue';
export default function useSwipeByDragAndDropComposable() {
    const container = ref();
    const initClientX = ref(0);
    function swipeByDnDonMouseDown(event) {
        initClientX.value = event.clientX;
        window.addEventListener('mousemove', swipeByDnDonMouseMove);
        window.addEventListener('mouseup', swipeByDnDonMouseUp);
    }
    function swipeByDnDonMouseMove(event) {
        if (!container.value) return;
        container.value.scrollBy({
            left: initClientX.value - event.clientX
        });
        initClientX.value = event.clientX;
    }
    function swipeByDnDonMouseUp() {
        initClientX.value = 0;
        window.removeEventListener('mousemove', swipeByDnDonMouseMove);
        window.removeEventListener('mouseup', swipeByDnDonMouseUp);
    }
    return {
        container,
        swipeByDnDonMouseDown
    };
}
