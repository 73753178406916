import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegionPageEventsList',
    props: {
        sportElementsList: {
            default: null
        },
        outrightEvents: {
            default: null
        },
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _ctx.sportElementsList && _ctx.sportElementsList.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 0
                }, _renderList(_ctx.sportElementsList, (sportElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                        key: sportElement.sport.id,
                        "sport-element": sportElement,
                        "is-market-types-selection-enabled": _ctx.isMarketTypesSelectionEnabled,
                        "can-add-to-favorite": true,
                        "extended-block-type": _unref(ExtendedEventBlock).Region,
                        "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                            sportId: sportElement.sport.id
                        })
                    }, null, 8, [
                        "sport-element",
                        "is-market-types-selection-enabled",
                        "can-add-to-favorite",
                        "extended-block-type",
                        "basis-key"
                    ]))), 128)) : _createCommentVNode("", true),
                _ctx.outrightEvents && _ctx.outrightEvents.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(SportEventsOutrightHeadline),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.outrightEvents, (outrightElement)=>(_openBlock(), _createBlock(SportlineSportBlock, {
                            key: `outright_${outrightElement.sport.id}`,
                            "sport-element": outrightElement,
                            "is-market-types-selection-enabled": false,
                            "is-hide-league-info": false,
                            "is-column-names-display-disabled": "",
                            "without-headline": "",
                            "is-display-main-events-disabled": "",
                            "is-display-outright-headline-disabled": "",
                            "is-display-outright-events-enabled": "",
                            "can-add-to-favorite": true,
                            "can-add-outrights-to-favorite": true,
                            "extended-block-type": _unref(ExtendedEventBlock).Region,
                            "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                                sportId: outrightElement.sport.id,
                                salt: 'outrights'
                            })
                        }, null, 8, [
                            "sport-element",
                            "can-add-to-favorite",
                            "can-add-outrights-to-favorite",
                            "extended-block-type",
                            "basis-key"
                        ]))), 128))
                ], 64)) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'RegionPageEventsList'
                ]
            ]);
        };
    }
});
