import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import StatisticLeagueStandingBlock from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';
import PostMatchStatisticHeadline from './post-match-statistic/PostMatchStatisticHeadline.vue';
import PostMatchStatisticResults from '../components/post-match-statistic/PostMatchStatisticResults.vue';
import TeamsConfrontationStatistic from '../components/statistic/TeamsConfrontationStatistic.vue';
import H2hMatchStatisticBlock from '../components/statistic/H2hMatchStatisticBlock.vue';
import LastGamesResultsMatchStatisticBlock from '../components/statistic/LastGamesResultsMatchStatisticBlock.vue';
import useSportlineEventPostMatchStatistic from './useSportlineEventPostMatchStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventPostMatchStatistic',
    props: {
        isDisplayHeadline: {
            type: Boolean
        }
    },
    setup (__props) {
        const { postMatchStatistic, confrontationBlocks, statisticSportFamily, leagueStandings, teams, h2hMatchStatistic, joinMatches, hostMatches, guestMatches, canDisplayH2hStatistic, canDisplayLastGamesResultsStatistic, canDisplayTournamentTableStatistic, canShowConfrontationStatistic, canShowRightDisclaimer, canShowLeftDisclaimer } = useSportlineEventPostMatchStatistic();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['post-match-statistic'])
            }, [
                _ctx.isDisplayHeadline && _unref(postMatchStatistic) ? (_openBlock(), _createBlock(PostMatchStatisticHeadline, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['post-match-statistic__headline']),
                    "post-match-statistic": _unref(postMatchStatistic)
                }, null, 8, [
                    "class",
                    "post-match-statistic"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['post-match-statistic-heading'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['post-match-statistic-heading__left'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['post-match-statistic-heading__title'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['post-match-statistic-heading__label'])
                            }, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC')), 3)
                        ], 2)
                    ], 2)
                ], 2),
                _unref(postMatchStatistic) ? (_openBlock(), _createBlock(PostMatchStatisticResults, {
                    key: 1,
                    "post-match-statistic": _unref(postMatchStatistic)
                }, null, 8, [
                    "post-match-statistic"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['post-match-statistic-columns'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['post-match-statistic-columns__left'])
                    }, [
                        _unref(canShowConfrontationStatistic) ? (_openBlock(), _createBlock(TeamsConfrontationStatistic, {
                            key: 0,
                            "confrontation-blocks": _unref(confrontationBlocks),
                            teams: _unref(teams),
                            "sport-family": _unref(statisticSportFamily)
                        }, null, 8, [
                            "confrontation-blocks",
                            "teams",
                            "sport-family"
                        ])) : _createCommentVNode("", true),
                        _unref(canShowLeftDisclaimer) ? (_openBlock(), _createBlock(StatisticDisclaimer, {
                            key: 1
                        })) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['post-match-statistic-columns__right'])
                    }, [
                        _unref(canDisplayH2hStatistic) ? (_openBlock(), _createBlock(H2hMatchStatisticBlock, {
                            key: 0,
                            teams: _unref(teams),
                            "join-matches": _unref(joinMatches),
                            "h2h-match-statistic": _unref(h2hMatchStatistic),
                            "sport-family": _unref(statisticSportFamily)
                        }, null, 8, [
                            "teams",
                            "join-matches",
                            "h2h-match-statistic",
                            "sport-family"
                        ])) : _createCommentVNode("", true),
                        _unref(canDisplayLastGamesResultsStatistic) ? (_openBlock(), _createBlock(LastGamesResultsMatchStatisticBlock, {
                            key: 1,
                            teams: _unref(teams),
                            "host-matches": _unref(hostMatches),
                            "guest-matches": _unref(guestMatches),
                            "sport-family": _unref(statisticSportFamily)
                        }, null, 8, [
                            "teams",
                            "host-matches",
                            "guest-matches",
                            "sport-family"
                        ])) : _createCommentVNode("", true),
                        _unref(canDisplayTournamentTableStatistic) ? (_openBlock(), _createBlock(StatisticLeagueStandingBlock, {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['post-match-statistic__league-standings']),
                            teams: _unref(teams),
                            "league-standings": _unref(leagueStandings)
                        }, null, 8, [
                            "class",
                            "teams",
                            "league-standings"
                        ])) : _createCommentVNode("", true),
                        _unref(canShowRightDisclaimer) ? (_openBlock(), _createBlock(StatisticDisclaimer, {
                            key: 3
                        })) : _createCommentVNode("", true)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventPostMatchStatistic'
                ]
            ]);
        };
    }
});
