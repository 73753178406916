import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
import { defineAsyncComponent, onBeforeMount, onUnmounted } from 'vue';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import { useSportlineEventDetailsRoutePage } from './useSportlineEventDetailsRoutePage';
// @TODO extend SportEventDetailsCordovaContent and SportEventDetailsContent from one component
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventDetailsRoutePage',
    setup (__props) {
        const SportEventDetailsContent = // eslint-disable-next-line unicorn/prefer-module
        // eslint-disable-next-line unicorn/prefer-module
        require('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsContent.vue').default;
        const SimilarEventsSection = defineAsyncComponent(()=>import('web/src/modules/sportline/submodules/event-details/views/similar-events/SimilarEventsSection.vue'));
        const { isLoaded, isReadyToDisplayContent, metaParameters, clearDataOnLeavingPage } = useSportlineEventDetailsRoutePage();
        useSeoMetaParameters(metaParameters);
        const { initialRequests: initFavoriteSportEvents } = useFavoritesEventsStore();
        onBeforeMount(()=>{
            initFavoriteSportEvents();
        });
        onUnmounted(()=>{
            clearDataOnLeavingPage();
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "component-wrapper",
                    _ctx.$style['component-wrapper']
                ])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sport-event-details'])
                }, [
                    _unref(isReadyToDisplayContent) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _withDirectives(_createVNode(_unref(SportEventDetailsContent), null, null, 512), [
                            [
                                _directive_data_test,
                                {
                                    el: 'sport-event-details-content'
                                }
                            ]
                        ]),
                        _unref(isLoaded) ? (_openBlock(), _createBlock(_unref(SimilarEventsSection), {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['sport-event-details__related'])
                        }, null, 8, [
                            "class"
                        ])) : _createCommentVNode("", true)
                    ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                        key: "page-loader",
                        delay: 500
                    }))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventDetailsRoutePage'
                ]
            ]);
        };
    }
});
