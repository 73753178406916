import { computed, ref } from 'vue';
export function useLiveSportTab(props) {
    const { group } = props;
    // LEONWEB-2140
    const isExpandAllByDefault = ref(!!process.env.VUE_APP_PRERENDER);
    const liveGroup = computed(()=>group.value && !group.value.isEmpty && group.value.liveElement ? group.value : null);
    const prematchGroup = computed(()=>group.value && !group.value.isEmptyComing && group.value.prematchElement ? group.value : null);
    return {
        isExpandAllByDefault,
        liveGroup,
        prematchGroup
    };
}
