import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "league-matches-results__content"
};
const _hoisted_2 = {
    class: "matches-results-list"
};
const _hoisted_3 = {
    class: "matches-results-list__title"
};
const _hoisted_4 = {
    class: "match-result-item__values"
};
const _hoisted_5 = {
    class: "match-result-item__value"
};
const _hoisted_6 = {
    class: "match-result-label match-result-label--light-background"
};
const _hoisted_7 = {
    class: "match-result-item__value"
};
const _hoisted_8 = {
    class: "match-result-label"
};
const _hoisted_9 = {
    class: "match-result-item__value"
};
const _hoisted_10 = {
    class: "match-result-label"
};
const _hoisted_11 = {
    class: "match-result-item__bar"
};
const _hoisted_12 = {
    class: "matches-results-list"
};
const _hoisted_13 = {
    class: "matches-results-list__title"
};
const _hoisted_14 = {
    class: "match-result-item__values"
};
const _hoisted_15 = {
    class: "match-result-item__value"
};
const _hoisted_16 = {
    class: "match-result-label match-result-label--dark-background"
};
const _hoisted_17 = {
    class: "match-result-item__value"
};
const _hoisted_18 = {
    class: "match-result-label"
};
const _hoisted_19 = {
    class: "match-result-item__value"
};
const _hoisted_20 = {
    class: "match-result-label"
};
const _hoisted_21 = {
    class: "match-result-item__bar"
};
import { toRef } from 'vue';
import { ProgressHeight, ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import { useLeagueMatchesResults } from './useLeagueMatchesResults';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueMatchesResults',
    props: {
        leagueMatchesResults: {}
    },
    setup (__props) {
        const props = __props;
        const { resultsList, goalsList, isExpanded, canExpand } = useLeagueMatchesResults({
            leagueMatchesResults: toRef(props, 'leagueMatchesResults')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-matches-results",
                            title: _ctx.$t('WEB2_STATISTICS_MATCHES_RESULTS'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", _hoisted_1, [
                                        _createElementVNode("div", _hoisted_2, [
                                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('WEB2_STATISTICS_ACCURATE_SCORE')), 1),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultsList), (result)=>(_openBlock(), _createElementBlock("div", {
                                                    key: result.key,
                                                    class: "matches-results-list__item match-result-item"
                                                }, [
                                                    _createElementVNode("div", _hoisted_4, [
                                                        _createElementVNode("div", _hoisted_5, [
                                                            _createElementVNode("div", _hoisted_6, _toDisplayString(result.label), 1)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_7, [
                                                            _createElementVNode("div", _hoisted_8, _toDisplayString(result.valueLabel), 1)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_9, [
                                                            _createElementVNode("div", _hoisted_10, _toDisplayString(result.percentageFixed) + "% ", 1)
                                                        ])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_11, [
                                                        _createVNode(VLinearProgress, {
                                                            value: result.percentage,
                                                            height: _unref(ProgressHeight).SIZE_4,
                                                            kind: _unref(ProgressKind).HIGHLIGHT
                                                        }, null, 8, [
                                                            "value",
                                                            "height",
                                                            "kind"
                                                        ])
                                                    ])
                                                ]))), 128))
                                        ]),
                                        _createElementVNode("div", _hoisted_12, [
                                            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('WEB2_STATISTICS_ACCURATE_GOALS')), 1),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(goalsList), (goals)=>(_openBlock(), _createElementBlock("div", {
                                                    key: goals.key,
                                                    class: "matches-results-list__item match-result-item"
                                                }, [
                                                    _createElementVNode("div", _hoisted_14, [
                                                        _createElementVNode("div", _hoisted_15, [
                                                            _createElementVNode("div", _hoisted_16, _toDisplayString(goals.label), 1)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_17, [
                                                            _createElementVNode("div", _hoisted_18, _toDisplayString(goals.valueLabel), 1)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_19, [
                                                            _createElementVNode("div", _hoisted_20, _toDisplayString(goals.percentageFixed) + "% ", 1)
                                                        ])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_21, [
                                                        _createVNode(VLinearProgress, {
                                                            value: goals.percentage,
                                                            height: _unref(ProgressHeight).SIZE_4,
                                                            kind: _unref(ProgressKind).HIGHLIGHT
                                                        }, null, 8, [
                                                            "value",
                                                            "height",
                                                            "kind"
                                                        ])
                                                    ])
                                                ]))), 128))
                                        ]),
                                        _unref(canExpand) ? (_openBlock(), _createBlock(ExpandButton, {
                                            key: 0,
                                            "is-expanded": _unref(isExpanded),
                                            "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                                            "opened-title": _ctx.$t('WEB2_MATCH_STATISTIC_HIDE'),
                                            "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_SHOW'),
                                            class: "match-result-expand"
                                        }, null, 8, [
                                            "is-expanded",
                                            "opened-title",
                                            "closed-title"
                                        ])) : _createCommentVNode("", true)
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LeagueMatchesResults'
                ]
            ]);
        };
    }
});
