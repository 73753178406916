import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineLiveNoEvents',
    props: {
        sportId: {}
    },
    setup (__props) {
        const props = __props;
        const sportId = toRef(props, 'sportId', null);
        const basisKey = createSportlineFragmentBasisKey({
            sportId
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "title", {}, ()=>[
                        sportId.value ? (_openBlock(), _createBlock(SportHeadline, {
                            key: 0,
                            "sport-id": sportId.value,
                            "hide-events-counter": "",
                            "basis-key": _unref(basisKey)
                        }, null, 8, [
                            "sport-id",
                            "basis-key"
                        ])) : _createCommentVNode("", true)
                    ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['no-events-content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['no-events-content__label'])
                    }, _toDisplayString(_ctx.$t('WEB2_LIVE_NOEVENTS_TITLE')), 3)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'SportlineLiveNoEvents'
                ]
            ]);
        };
    }
});
