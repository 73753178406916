import { ref, watch, toRef, nextTick, onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useSportlineAnalytics } from 'web/src/modules/sportline/composables/analytics';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { sportlinePrematchChildRoutes } from 'web/src/modules/sportline/submodules/sports/constants';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { createSportsListTab } from 'web/src/modules/sportline/submodules/sports/utils';
import { isDifferentSportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/guards';
import { useShownSportFamily } from './useShownSportFamily';
import { useSportsPageCustomTabs } from './useSportsPageCustomTabs';
/**
 * @TODO divide into several parts and use only required parts
 */ export function useSportsRoutePage(props) {
    const router = useRouter();
    const analytics = useSportlineAnalytics();
    const favoritesEventsStore = useFavoritesEventsStore();
    const activeTransitionId = toRef(props?.activeTransitionId ?? null);
    const lastShownSportFamily = toRef(props?.lastShownSportFamily ?? null);
    const listTransitionRef = toRef(props?.listTransitionRef);
    const sportlineSportsStore = useSportlineSportsStore();
    const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
    const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isLoaded');
    const doShowLoadingIndicator = toRef(sportlineSportsStore, 'showLoadingIndicator');
    const fullSportsList = toRef(sportlineSportsStore, 'fullSportsList');
    const isZeroMarginEventsListEmpty = toRef(sportlineSportsStore, 'isZeroMarginEventsListEmpty');
    const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
    const customFilters = toRef(sportlineSportsStore, 'customFilters');
    const { reloadListForFilter, setBackgroundUpdateEnabled, setPageIdentifier, resetFilterOnLeavingPage } = sportlineSportsStore;
    const sportlineNavigationStore = useSportlineNavigationStore();
    // eslint-disable-next-line max-len
    const sportlineNavigationBetweenPagesFilter = toRef(sportlineNavigationStore, 'sportlineNavigationBetweenPagesFilter');
    const sportlineReloadFiltersLocation = toRef(sportlineNavigationStore, 'sportlineReloadFiltersLocation');
    const { setSportlineReloadFiltersLocation, setSportlineNavigationBetweenPagesFilter } = sportlineNavigationStore;
    const { isZeroMarginEventsTabAvailable, hasFavoritesOptimistic, favoritesTab, zeroMarginEventsTab } = useSportsPageCustomTabs({
        lastShownSportFamily,
        activeTransitionId
    });
    const { selectedSportFamily, shownSportFamily } = useShownSportFamily({
        lastShownSportFamily
    });
    const listFilterRef = ref();
    const selectedSportFilterOptions = computed(()=>({
            sports: fullSportsList.value.map((param)=>{
                let { sport } = param;
                return {
                    sport
                };
            })
        }));
    const sportsTabs = computed(()=>fullSportsList.value.map((param)=>{
            let { sport } = param;
            return createSportsListTab({
                sport,
                selectedSportFamily: selectedSportFamily.value,
                shownSportFamily: shownSportFamily.value,
                transitionId: activeTransitionId.value
            });
        }));
    /** The list of all available contents tabs */ const fullTabsList = computed(()=>{
        const customTabs = [];
        if (favoritesTab.value) customTabs.push(favoritesTab.value);
        if (zeroMarginEventsTab.value) customTabs.push(zeroMarginEventsTab.value);
        return [
            ...customTabs,
            ...sportsTabs.value
        ].filter((tab)=>tab.isShown);
    });
    const isPageEmpty = computed(()=>isReadyToDisplayContent.value && 0 === selectedSportFilterOptions.value.sports.length && !hasFavoritesOptimistic.value && isZeroMarginEventsListEmpty.value);
    const isChildRoute = computed(()=>{
        const currentName = router.currentRoute.value.name;
        return !!currentName && sportlinePrematchChildRoutes.has(currentName);
    });
    function isSportsPage() {
        return router.currentRoute.value.name === RouteName.SPORTLINE_PREMATCH_EVENTS;
    }
    function listFilterSlideToActive() {
        if (!isReadyToDisplayContent.value) return;
        listFilterRef.value?.slideToActive();
    }
    function onChangeSelectedSportFilter(filter) {
        const { sportFamily, sportId } = filter;
        let urlName = '';
        const routeSportFamily = router.currentRoute.value.params.family;
        if (sportFamily) urlName = sportFamily;
        else if (customFilters.value.includes(sportId)) urlName = sportId;
        if (sportId === CustomFilter.ZeroMargin) analytics.zeroMarginTabClick();
        if (isSportsPage() && routeSportFamily && routeSportFamily === sportFamily) return;
        if (listTransitionRef.value?.isActive()) // stop navigation for active transition
        return;
        listTransitionRef.value?.enable();
        router.push302(resolveSportsPageLink({
            urlName
        }), {
            saveScrollPosition: true
        });
    }
    async function redirectFromTab() {
        onChangeSelectedSportFilter({});
        await nextTick();
        listFilterSlideToActive();
    }
    let stopWatchSidebarFiltersHandle;
    function startWatchSidebarFilters() {
        stopWatchSidebarFiltersHandle = watch(sportlineReloadFiltersLocation, (to)=>{
            if (to !== RouteName.SPORTLINE_PREMATCH_EVENTS) return;
            const filter = sportlineNavigationBetweenPagesFilter.value;
            const currentFilter = sportsListFilter.value;
            setSportlineReloadFiltersLocation(null);
            setSportlineNavigationBetweenPagesFilter(null);
            if (!isDifferentSportDateTimeFilter(filter, currentFilter)) return;
            reloadListForFilter(filter);
        });
    }
    function stopWatchSidebarFilters() {
        stopWatchSidebarFiltersHandle?.();
        stopWatchSidebarFiltersHandle = void 0;
    }
    function onBeforeMountPage() {
        favoritesEventsStore.initialRequests();
        listFilterSlideToActive();
    }
    function onActivatePage() {
        setBackgroundUpdateEnabled(true);
        startWatchSidebarFilters();
    }
    function onDeactivatePage() {
        stopWatchSidebarFilters();
        setBackgroundUpdateEnabled(false);
        // clear event info after leave page (need for leave page watch in store)
        setPageIdentifier({
            sportFamily: ''
        });
        if (!isChildRoute.value) resetFilterOnLeavingPage();
    }
    onBeforeMount(onBeforeMountPage);
    onComponentActivated(onActivatePage);
    onComponentDeactivated(onDeactivatePage);
    return {
        listFilterRef,
        isZeroMarginEventsTabAvailable,
        isReadyToDisplayContent,
        doShowLoadingIndicator,
        hasFavoritesOptimistic,
        isPageEmpty,
        fullTabsList,
        selectedSportFilter,
        selectedSportFilterOptions,
        isChildRoute,
        reloadListForFilter,
        redirectFromTab,
        onChangeSelectedSportFilter
    };
}
