import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, resolveDirective as _resolveDirective } from "vue";
import { onBeforeUnmount, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VScrollbar } from '@components/v-scrollbar';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useActiveBreadcrumb, useFirstLeagueInSportElement, useSportlineBreadcrumbsSportItem, useSportlineBreadcrumbsRegionItem, useSportlineBreadcrumbsLeagueItem, useSportlineBreadcrumbsMetaParameters } from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { VSidebar } from 'web/src/components/Sidebar';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SportBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/SportBreadcrumbsItem.vue';
import RegionBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RegionBreadcrumbsItem.vue';
import LeagueBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/LeagueBreadcrumbsItem.vue';
import RelatedEventsBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RelatedEventsBreadcrumbsItem.vue';
import SportsSidebarRouteComponent from 'web/src/modules/sportline/submodules/sidebar/views/SportsSidebarRouteComponent.vue';
import { useSportlineEventBreadcrumbs } from './useSportlineEventBreadcrumbs';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventBreadcrumbs',
    setup (__props) {
        const sportlineEventDetailsStore = useSportlineEventDetailsStore();
        const sportElement = toRef(sportlineEventDetailsStore, 'sportElement');
        const { sport, region, league } = useFirstLeagueInSportElement({
            sportElement
        });
        const { activeId, toggleId } = useActiveBreadcrumb();
        const { canNavigateTo, sportTreeElement, title: sportItemTitle, isShown: isSportItemShown, pageLocation: sportPageLocation, sportRepresentationFamily } = useSportlineBreadcrumbsSportItem({
            sport
        });
        const { regionElement, relatedRegions, title: regionItemTitle, isShown: isRegionItemShown, pageLocation: regionPageLocation } = useSportlineBreadcrumbsRegionItem({
            region
        });
        const { leagueElement, relatedLeagues, title: leagueItemTitle, isShown: isLeagueItemShown, pageLocation: leaguePageLocation } = useSportlineBreadcrumbsLeagueItem({
            league
        });
        const { isShown: isEventItemShown, title: eventItemTitle, sportEventDetailsId, leagueEventsList, isSidebarOpen, softCloseSidebar, toggleSidebar } = useSportlineEventBreadcrumbs();
        useSportlineBreadcrumbsMetaParameters({
            sportElement: sportTreeElement,
            sportPageLocation,
            regionElement,
            regionPageLocation,
            leagueElement,
            leaguePageLocation
        });
        onBeforeUnmount(()=>{
            softCloseSidebar();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['sportline-event-breadcrumbs--sidebar-open']]: _unref(isSidebarOpen)
                })
            }, [
                _createVNode(_unref(VSidebar), {
                    class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__sidebar'])
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__sidebar-close'])
                            }, [
                                _createVNode(VButton, {
                                    "icon-name": _unref(IconName).CROSS_PADDED,
                                    "icon-size": _unref(IconSize).SIZE_20,
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    height: _unref(ButtonHeight).MEDIUM,
                                    class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__sidebar-close-button']),
                                    onClick: _unref(toggleSidebar)
                                }, null, 8, [
                                    "icon-name",
                                    "icon-size",
                                    "kind",
                                    "height",
                                    "class",
                                    "onClick"
                                ])
                            ], 2),
                            _withDirectives(_createVNode(_unref(VScrollbar), null, {
                                default: _withCtx(()=>[
                                        _createVNode(SportsSidebarRouteComponent)
                                    ]),
                                _: 1
                            }, 512), [
                                [
                                    _vShow,
                                    _unref(isSidebarOpen) || process.env.VUE_APP_PRERENDER
                                ]
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-breadcrumbs__inner'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__left'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__hamburger'])
                        }, [
                            _createVNode(VButton, {
                                "icon-name": _unref(IconName).HAMBURGER_FULL,
                                "icon-size": _unref(IconSize).SIZE_20,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                height: _unref(ButtonHeight).MEDIUM,
                                class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__hamburger-button']),
                                onClick: _unref(toggleSidebar)
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "height",
                                "class",
                                "onClick"
                            ])
                        ], 2),
                        _createElementVNode("ol", {
                            class: _normalizeClass(_ctx.$style['sportline-breadcrumbs__content'])
                        }, [
                            _unref(isSportItemShown) ? (_openBlock(), _createBlock(SportBreadcrumbsItem, {
                                key: _unref(sportTreeElement) ? _unref(sportTreeElement).sport.id : 'unselectedSport',
                                title: _unref(sportItemTitle),
                                to: _unref(canNavigateTo) ? _unref(sportPageLocation) : void 0,
                                position: 1,
                                "sport-family": _unref(sportRepresentationFamily),
                                "is-menu": ""
                            }, null, 8, [
                                "title",
                                "to",
                                "sport-family"
                            ])) : _createCommentVNode("", true),
                            _unref(isRegionItemShown) ? (_openBlock(), _createBlock(RegionBreadcrumbsItem, {
                                key: _unref(regionElement)?.region.id ?? 'unselectedRegion',
                                "active-region-id": _unref(regionElement)?.region.id,
                                "related-regions": _unref(relatedRegions),
                                title: _unref(regionItemTitle),
                                to: _unref(canNavigateTo) ? _unref(regionPageLocation) : void 0,
                                position: 2,
                                open: _unref(activeId) === _unref(BreadcrumbId).Regions,
                                onToggle: _unref(toggleId)
                            }, null, 8, [
                                "active-region-id",
                                "related-regions",
                                "title",
                                "to",
                                "open",
                                "onToggle"
                            ])) : _createCommentVNode("", true),
                            _unref(isLeagueItemShown) ? (_openBlock(), _createBlock(LeagueBreadcrumbsItem, {
                                key: _unref(leagueElement) ? _unref(leagueElement).league.id : 'unselectedLeague',
                                "league-element": _unref(leagueElement),
                                "related-leagues": _unref(relatedLeagues),
                                title: _unref(leagueItemTitle),
                                to: _unref(canNavigateTo) ? _unref(leaguePageLocation) : void 0,
                                position: 3,
                                open: _unref(activeId) === _unref(BreadcrumbId).Leagues,
                                onToggle: _unref(toggleId)
                            }, null, 8, [
                                "league-element",
                                "related-leagues",
                                "title",
                                "to",
                                "open",
                                "onToggle"
                            ])) : _createCommentVNode("", true),
                            _unref(isEventItemShown) ? (_openBlock(), _createBlock(RelatedEventsBreadcrumbsItem, {
                                key: _unref(sportEventDetailsId) || 'unselectedSportEvent',
                                "sport-element": sportElement.value,
                                "related-events-list": _unref(leagueEventsList),
                                "active-event-id": _unref(sportEventDetailsId),
                                title: _unref(eventItemTitle),
                                position: 4,
                                open: _unref(activeId) === _unref(BreadcrumbId).Events,
                                onToggle: _unref(toggleId)
                            }, null, 8, [
                                "sport-element",
                                "related-events-list",
                                "active-event-id",
                                "title",
                                "open",
                                "onToggle"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2)
                ], 2),
                _createVNode(_Transition, {
                    name: "opacity"
                }, {
                    default: _withCtx(()=>[
                            _unref(isSidebarOpen) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['sportline-event-breadcrumbs__mask']),
                                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(toggleSidebar) && _unref(toggleSidebar)(...args);
                                })
                            }, null, 2)) : _createCommentVNode("", true)
                        ]),
                    _: 1
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventBreadcrumbs'
                ]
            ]);
        };
    }
});
