import { computed } from 'vue';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { useAvailableHeadlineSlides } from 'web/src/modules/sportline/submodules/event-details/composables/headline/useAvailableHeadlineSlides';
import { SportlineType, SportWidgetType } from 'web/src/modules/sportline/enums';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
export function useBaseSportEventDetailsHeadline(props) {
    const { defaultSlideId, sportEventDetails, isStreamAvailable, widgetConfig, liveWidgetType } = props;
    const { locale } = useI18nLocale();
    const widgetProperties = computed(()=>{
        const sportlineEvent = sportEventDetails.value;
        if (!sportlineEvent) return;
        switch(liveWidgetType.value){
            case SportWidgetType.BET_GENIUS:
                {
                    if (sportlineEvent.type !== SportlineType.Live) return;
                    if (!sportlineEvent.liveWidget) return;
                    const widgetProps = {
                        isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
                        liveWidget: sportlineEvent.liveWidget,
                        locale: locale.value
                    };
                    return {
                        type: SportWidgetType.BET_GENIUS,
                        props: widgetProps
                    };
                }
            case SportWidgetType.LSPORTS:
                {
                    if (sportlineEvent.type !== SportlineType.Live) return;
                    const widgetProps = {
                        isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
                        sportEventDetails: sportlineEvent
                    };
                    return {
                        type: SportWidgetType.LSPORTS,
                        props: widgetProps
                    };
                }
            case SportWidgetType.SPORTRADAR:
                {
                    if (sportlineEvent.type !== SportlineType.Live) return;
                    const widgetProps = {
                        isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
                        sportEventDetails: sportlineEvent
                    };
                    return {
                        type: SportWidgetType.SPORTRADAR,
                        props: widgetProps
                    };
                }
            default:
                return;
        }
    });
    const isLiveWidgetAvailable = computed(()=>!!widgetProperties.value);
    const { switcherSlidesIds } = useAvailableHeadlineSlides({
        sportlineEvent: sportEventDetails,
        isLiveWidgetAvailable,
        isStreamAvailable
    });
    const activeSlideId = computed(()=>switcherSlidesIds.value.some((item)=>item.id === defaultSlideId.value) ? defaultSlideId.value : HeadlineSlideName.Info);
    return {
        activeSlideId,
        switcherSlidesIds,
        isLiveWidgetAvailable,
        widgetProperties
    };
}
