import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "post-match-statistic-incident-goal-mark__part"
};
const _hoisted_2 = {
    class: "post-match-statistic-incident-goal-mark__part"
};
const _hoisted_3 = {
    class: "post-match-statistic-incident-goal-label"
};
const _hoisted_4 = {
    key: 1,
    class: "post-match-statistic-incident-card-mark"
};
const _hoisted_5 = {
    class: "post-match-statistic-incident-player"
};
import { IncidentType, TimelineMarkCompetitorType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import StatisticTimelineIncidentIcon from 'web/src/modules/sportline/submodules/event-details/components/statistic/StatisticTimelineIncidentIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticIncidentItem',
    props: {
        incident: {},
        team: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    "post-match-statistic-incident-list-item",
                    {
                        'post-match-statistic-incident-list-item--host': _ctx.incident.competitorType === _unref(TimelineMarkCompetitorType).HOST,
                        'post-match-statistic-incident-list-item--guest': _ctx.incident.competitorType === _unref(TimelineMarkCompetitorType).GUEST
                    }
                ])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([
                        "post-match-statistic-incident-time",
                        {
                            'post-match-statistic-incident-time--guest': _ctx.incident.competitorType === _unref(TimelineMarkCompetitorType).GUEST
                        }
                    ])
                }, _toDisplayString(_ctx.incident.label), 3),
                _createVNode(StatisticTeamLogo, {
                    class: "post-match-statistic-incident-team-logo",
                    team: _ctx.team
                }, null, 8, [
                    "team"
                ]),
                _ctx.incident.type === _unref(IncidentType).GOAL || _ctx.incident.type === _unref(IncidentType).OWN_GOAL || _ctx.incident.type === _unref(IncidentType).DISABLED_GOAL ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
                        "post-match-statistic-incident-goal-mark",
                        {
                            'post-match-statistic-incident-goal-mark--own-goal': _ctx.incident.type === _unref(IncidentType).OWN_GOAL,
                            'post-match-statistic-incident-goal-mark--disabled-goal': _ctx.incident.type === _unref(IncidentType).DISABLED_GOAL
                        }
                    ])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(StatisticTimelineIncidentIcon, {
                            class: "post-match-statistic-incident-goal-icon",
                            incident: _ctx.incident
                        }, null, 8, [
                            "incident"
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _ctx.incident.type === _unref(IncidentType).OWN_GOAL ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_AUTOGOAL')), 1)
                            ], 64)) : _ctx.incident.type === _unref(IncidentType).DISABLED_GOAL ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_DISABLED_GOAL')), 1)
                            ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 2
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_GOAL')), 1)
                            ], 64))
                        ])
                    ])
                ], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(StatisticTimelineIncidentIcon, {
                        class: "post-match-statistic-incident-card-icon",
                        incident: _ctx.incident
                    }, null, 8, [
                        "incident"
                    ])
                ])),
                _createElementVNode("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.incident.incomingPlayer.nameShort || _ctx.incident.incomingPlayer.name) + " ", 1),
                    _ctx.incident.outgoingPlayer ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createTextVNode(" (" + _toDisplayString(_ctx.incident.outgoingPlayer.nameShort || _ctx.incident.outgoingPlayer.name) + ") ", 1)
                    ], 64)) : _createCommentVNode("", true)
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticIncidentItem'
                ]
            ]);
        };
    }
});
