import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef, computed } from 'vue';
import { useInjectIsExpandedSportlineBlock } from 'web/src/modules/sportline/composables/expandable-block';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualListRendererSportHeadline',
    props: {
        element: {},
        isMarketTypesSelectionEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const element = toRef(()=>props.element);
        const isMarketTypesSelectionEnabled = toRef(()=>props.isMarketTypesSelectionEnabled);
        const collapseSportKey = computed(()=>element.value.collapseSportKey ?? null);
        const isMarketTypesSelectionEnabledFinal = computed(()=>element.value?.isMarketTypesSelectionEnabled !== void 0 ? element.value.isMarketTypesSelectionEnabled && isMarketTypesSelectionEnabled.value : isMarketTypesSelectionEnabled.value);
        const { isExpanded, setExpandedState } = useInjectIsExpandedSportlineBlock({
            expandKey: collapseSportKey
        });
        function onToggleExpandState(payload) {
            setExpandedState(payload.value);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportHeadline, {
                "sport-id": element.value.sport.id,
                class: _normalizeClass(_ctx.$style['sport-headline']),
                "is-coming": element.value.blockLayout === _unref(VirtualListBlockLayout).Coming,
                "count-events": element.value.eventsCount,
                "basis-key": element.value.basisKey,
                "is-market-types-selection-enabled": isMarketTypesSelectionEnabledFinal.value,
                "hide-events-counter": element.value.hideEventsCounter,
                "expand-key": element.value.collapseSportKey,
                "is-expanded": _unref(isExpanded),
                onToggleExpandState: onToggleExpandState
            }, null, 8, [
                "sport-id",
                "class",
                "is-coming",
                "count-events",
                "basis-key",
                "is-market-types-selection-enabled",
                "hide-events-counter",
                "expand-key",
                "is-expanded"
            ])), [
                [
                    _directive_auto_id,
                    'VirtualListRendererSportHeadline'
                ]
            ]);
        };
    }
});
