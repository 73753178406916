import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "league-matches-statistic-content"
};
const _hoisted_2 = {
    class: "matches-total-wins"
};
const _hoisted_3 = {
    class: "matches-total-wins__labels statistic-chart-labels"
};
const _hoisted_4 = {
    class: "matches-total-wins__label-holder"
};
const _hoisted_5 = {
    class: "matches-total-wins__label matches-total-wins__label--primary"
};
const _hoisted_6 = {
    class: "matches-total-wins__label"
};
const _hoisted_7 = {
    class: "matches-total-wins__label-holder"
};
const _hoisted_8 = {
    class: "matches-total-wins__label matches-total-wins__label--primary"
};
const _hoisted_9 = {
    class: "matches-total-wins__label"
};
const _hoisted_10 = {
    class: "matches-total-wins__label-holder"
};
const _hoisted_11 = {
    class: "matches-total-wins__label matches-total-wins__label--primary"
};
const _hoisted_12 = {
    class: "matches-total-wins__label"
};
import { toRef } from 'vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticChartBar from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import LeagueMatchesStatisticBlock from './LeagueMatchesStatisticBlock.vue';
import { useLeagueMatchesStatistic } from './useLeagueMatchesStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueMatchesStatistic',
    props: {
        leagueMatchesStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const { leagueWinsStatistic, leagueWinsStatisticLabels, firstGoalTimeAverage, goalsTotal, goalsAverage, timeToGoal } = useLeagueMatchesStatistic({
            leagueMatchesStatistic: toRef(props, 'leagueMatchesStatistic')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VList, {
                "independent-items": ""
            }, {
                default: _withCtx(()=>[
                        _createVNode(VListItemAccordion, {
                            id: "league-matches-statistic",
                            title: _ctx.$t('WEB2_STATISTICS_OVERVIEW'),
                            "is-title-centered": "",
                            open: ""
                        }, {
                            content: _withCtx(()=>[
                                    _createElementVNode("div", _hoisted_1, [
                                        _createElementVNode("div", _hoisted_2, [
                                            _createElementVNode("div", _hoisted_3, [
                                                _createElementVNode("div", _hoisted_4, [
                                                    _cache[0] || (_cache[0] = _createElementVNode("span", {
                                                        class: "statistic-chart-label-indicator statistic-chart-label-indicator--home"
                                                    }, null, -1)),
                                                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('WEB2_STATISTICS_HOME_TEAM')), 1),
                                                    _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(leagueWinsStatisticLabels).home), 1)
                                                ]),
                                                _createElementVNode("div", _hoisted_7, [
                                                    _cache[1] || (_cache[1] = _createElementVNode("span", {
                                                        class: "statistic-chart-label-indicator statistic-chart-label-indicator--draw"
                                                    }, null, -1)),
                                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('WEB2_STATISTICS_DRAWS')), 1),
                                                    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(leagueWinsStatisticLabels).draw), 1)
                                                ]),
                                                _createElementVNode("div", _hoisted_10, [
                                                    _cache[2] || (_cache[2] = _createElementVNode("span", {
                                                        class: "statistic-chart-label-indicator statistic-chart-label-indicator--away"
                                                    }, null, -1)),
                                                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('WEB2_STATISTICS_AWAY_TEAM')), 1),
                                                    _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(leagueWinsStatisticLabels).away), 1)
                                                ])
                                            ]),
                                            _createVNode(StatisticChartBar, {
                                                type: _unref(StatisticChartBarType).LABELED,
                                                "to-fixed": 1,
                                                "home-percentage": _unref(leagueWinsStatistic).homePercentage,
                                                "draw-percentage": _unref(leagueWinsStatistic).drawsPercentage,
                                                "away-percentage": _unref(leagueWinsStatistic).awayPercentage
                                            }, null, 8, [
                                                "type",
                                                "home-percentage",
                                                "draw-percentage",
                                                "away-percentage"
                                            ])
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_TOTAL_GOALS'),
                                            "home-value": _unref(goalsTotal).homeValue,
                                            "overall-value": _unref(goalsTotal).overallValue,
                                            "away-value": _unref(goalsTotal).awayValue,
                                            "home-label": _unref(goalsTotal).homeLabel,
                                            "overall-label": _unref(goalsTotal).overallLabel,
                                            "away-label": _unref(goalsTotal).awayLabel,
                                            "do-use-progress-bar": ""
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_AVERAGE_MATCH_GOALS'),
                                            "home-value": _unref(goalsAverage).homeValue,
                                            "overall-value": _unref(goalsAverage).overallValue,
                                            "away-value": _unref(goalsAverage).awayValue,
                                            "home-label": _unref(goalsAverage).homeLabel,
                                            "overall-label": _unref(goalsAverage).overallLabel,
                                            "away-label": _unref(goalsAverage).awayLabel,
                                            "do-use-progress-bar": ""
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_FIRST_GOAL_AVERAGE_MIN'),
                                            "home-value": _unref(firstGoalTimeAverage).homeValue,
                                            "overall-value": _unref(firstGoalTimeAverage).overallValue,
                                            "away-value": _unref(firstGoalTimeAverage).awayValue,
                                            "home-label": _unref(firstGoalTimeAverage).homeLabel,
                                            "overall-label": _unref(firstGoalTimeAverage).overallLabel,
                                            "away-label": _unref(firstGoalTimeAverage).awayLabel
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ]),
                                        _createVNode(LeagueMatchesStatisticBlock, {
                                            title: _ctx.$t('WEB2_STATISTICS_GOAL_AVERAGE_TIME'),
                                            "home-value": _unref(timeToGoal).homeValue,
                                            "overall-value": _unref(timeToGoal).overallValue,
                                            "away-value": _unref(timeToGoal).awayValue,
                                            "home-label": _unref(timeToGoal).homeLabel,
                                            "overall-label": _unref(timeToGoal).overallLabel,
                                            "away-label": _unref(timeToGoal).awayLabel
                                        }, null, 8, [
                                            "title",
                                            "home-value",
                                            "overall-value",
                                            "away-value",
                                            "home-label",
                                            "overall-label",
                                            "away-label"
                                        ])
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "title"
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LeagueMatchesStatistic'
                ]
            ]);
        };
    }
});
