import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef, computed } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useExtendedEventStore } from 'web/src/modules/sportline/submodules/extended-event/store';
import { createVirtualListGroups } from 'web/src/modules/sportline/submodules/live/utils/createVirtualListGroups';
import { useCanShowSegmentGroup } from 'web/src/modules/sportline/submodules/segment/composables/useCanShowSegmentGroup';
import { useProvideSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useProvideIsExpandedSportlineBlock } from 'web/src/modules/sportline/composables/expandable-block';
import VirtualList from 'web/src/modules/sportline/views/virtual-list-renderer/VirtualList.vue';
import { createVirtualListRef } from '../utils';
import { useVirtualListEventElementResize } from './composables/virtual-list';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualGroupsList',
    props: {
        groups: {},
        extendedBlockType: {},
        canCollapseSport: {
            type: Boolean
        },
        isDefaultCollapsedSport: {
            type: Boolean
        },
        selectedFilter: {},
        activeTransitionId: {
            default: null
        }
    },
    setup (__props) {
        const props = __props;
        const groups = toRef(()=>props.groups);
        const extendedBlockType = toRef(()=>props.extendedBlockType);
        const selectedFilter = toRef(()=>props.selectedFilter);
        const activeTransitionId = toRef(()=>props.activeTransitionId);
        const canCollapseSport = toRef(()=>props.canCollapseSport);
        const isDefaultCollapsedSport = toRef(()=>props.isDefaultCollapsedSport);
        const { createOpenedEventsListRef } = useExtendedEventStore();
        const expandedIds = createOpenedEventsListRef(extendedBlockType);
        const settingsStore = useSportlineSettingsStore();
        const isMarketTypesSelectionEnabled = toRef(()=>settingsStore.isMarketTypesSelectionEnabled);
        const { canShowLiveForGroup, canShowPrematchForGroup } = useCanShowSegmentGroup({
            selectedFilter,
            activeTransitionId
        });
        const { customEventHeightMap, onResizeEventElement } = useVirtualListEventElementResize({
            expandedIds
        });
        const expandedHeights = toRef(()=>customEventHeightMap);
        const displayedGroups = createVirtualListGroups(groups, {
            canShowLiveForGroup,
            canShowPrematchForGroup
        });
        const sportlineColumnInfoElements = computed(()=>displayedGroups.value.map((param)=>{
                let { columnInfo } = param;
                return columnInfo;
            }));
        const { expandedStateMap: expandedSportStateMap } = useProvideIsExpandedSportlineBlock({
            defaultValueRef: computed(()=>!isDefaultCollapsedSport.value)
        });
        useProvideSelectedColumn({
            sportlineElements: sportlineColumnInfoElements
        });
        const elements = createVirtualListRef(displayedGroups, {
            canCollapseSport,
            isDefaultCollapsedSport,
            expandedSportStateMap
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VirtualList, {
                elements: _unref(elements),
                "expanded-ids": _unref(expandedIds),
                "expanded-heights": expandedHeights.value,
                "extended-block-type": extendedBlockType.value,
                "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                onEventResize: _unref(onResizeEventElement)
            }, null, 8, [
                "elements",
                "expanded-ids",
                "expanded-heights",
                "extended-block-type",
                "is-market-types-selection-enabled",
                "onEventResize"
            ])), [
                [
                    _directive_auto_id,
                    'VirtualGroupsList'
                ]
            ]);
        };
    }
});
