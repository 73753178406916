// eslint-disable-next-line max-classes-per-file
import { _ as _define_property } from "@swc/helpers/_/_define_property";
let Pointer = class Pointer {
    get done() {
        return this.currentIndex > this.maxIndex;
    }
    get index() {
        return this.currentIndex;
    }
    get value() {
        return this.list[this.currentIndex];
    }
    get next() {
        return this.list[this.currentIndex + 1];
    }
    move() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1;
        this.currentIndex += value;
    }
    getIdIndex(id) {
        return this.ids.get(id);
    }
    slice(from, to) {
        return this.list.slice(from, to);
    }
    constructor(list, maxIndex){
        _define_property(this, "list", void 0);
        _define_property(this, "currentIndex", void 0);
        _define_property(this, "maxIndex", void 0);
        _define_property(this, "ids", void 0);
        this.list = list;
        this.currentIndex = 0;
        this.maxIndex = -1;
        this.ids = new Map();
        this.maxIndex = maxIndex ?? list.length - 1;
        for (const [index, item] of list.entries()){
            if (index > this.maxIndex) break;
            if (!!item.id) this.ids.set(item.id, index);
        }
    }
};
let Result = class Result {
    add(value) {
        this.value += value;
    }
    constructor(){
        _define_property(this, "value", 0);
    }
};
function calculateTotalSize(list) {
    return list.reduce((sum, item)=>sum + (item?.id ? item?.size ?? 0 : 0), 0);
}
function skipBrokenPointers(pointer1, pointer2) {
    if (pointer1.value?.size && pointer2.value?.size) return false;
    pointer1.move(Number(!pointer1.value?.size));
    pointer2.move(Number(!pointer2.value?.size));
    return true;
}
function comparePointerIds(pointer1, pointer2, result) {
    const id1 = pointer1.value?.id;
    const id2 = pointer2.value?.id;
    if (!id1 || !id2) return false;
    if (id1 === id2) {
        pointer1.move();
        pointer2.move();
        return true;
    }
    const id1in2 = pointer2.getIdIndex(id1);
    if (id1in2 && id1in2 > pointer2.index) {
        result.add(-calculateTotalSize(pointer2.slice(pointer2.index, id1in2)));
        pointer2.move(id1in2 - pointer2.index);
        return true;
    }
    const id2in1 = pointer1.getIdIndex(id2);
    if (id2in1 && id2in1 > pointer1.index) {
        result.add(calculateTotalSize(pointer1.slice(pointer1.index, id2in1)));
        pointer1.move(id2in1 - pointer1.index);
        return true;
    }
    // move them anyway
    pointer1.move();
    pointer2.move();
    return true;
}
export function findListByDimensionDiff(list1, list2, maxIndex1, maxIndex2) {
    if (!list1 || !list2) return 0;
    const result = new Result();
    const pointer1 = new Pointer(list1, maxIndex2 ?? maxIndex1);
    const pointer2 = new Pointer(list2, maxIndex1);
    while(!pointer1.done && !pointer2.done){
        if (skipBrokenPointers(pointer1, pointer2)) continue;
        if (comparePointerIds(pointer1, pointer2, result)) continue;
        if (!pointer1.value?.id) pointer1.move();
        if (!pointer2.value?.id) pointer2.move();
    }
    return result.value;
}
