import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed, toRef } from 'vue';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EmptyLeaguePageContentInfo',
    props: {
        sectionId: {},
        isLeagueStatisticEnabled: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sectionId = toRef(props, 'sectionId');
        const isLeagueStatisticEnabled = toRef(props, 'isLeagueStatisticEnabled');
        const isEmptyEventsList = computed(()=>sectionId.value === LeaguePageSectionId.None && !isLeagueStatisticEnabled.value);
        const isEmptyStatistics = computed(()=>sectionId.value === LeaguePageSectionId.None && isLeagueStatisticEnabled.value);
        return (_ctx, _cache)=>sectionId.value === _unref(LeaguePageSectionId).EventsList || isEmptyEventsList.value ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['empty-content__holder'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['empty-content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['empty-content__title'])
                    }, _toDisplayString(_ctx.$t('JS_EVENTS_NOT_FOUND')), 3)
                ], 2)
            ], 2)) : sectionId.value === _unref(LeaguePageSectionId).Statistic || isEmptyStatistics.value ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['empty-content__holder'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['empty-content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['empty-content__title'])
                    }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_NOT_FOUND_TITLE')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['empty-content__description'])
                    }, _toDisplayString(_ctx.$t('WEB2_STATISTICS_NOT_FOUND_DESCRIPTION')), 3)
                ], 2)
            ], 2)) : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.$style['empty-content__holder'])
            }, null, 2));
    }
});
