import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
export function useRegionRoutePage() {
    const router = useRouter();
    const regionPageStore = useRegionPageStore();
    const { initialRequests: initFavoriteSportEvents } = useFavoritesEventsStore();
    const isReady = toRef(regionPageStore, 'isReady');
    const isLoadingIndicatorShown = toRef(regionPageStore, 'isLoadingIndicatorShown');
    const isMarketTypesSelectionEnabled = toRef(regionPageStore, 'isMarketTypesSelectionEnabled');
    const isContainEvents = toRef(regionPageStore, 'containEvents');
    const sport = toRef(regionPageStore, 'sport');
    const region = toRef(regionPageStore, 'region');
    const sportElementsList = toRef(regionPageStore, 'sportElementsList');
    const outrightEvents = toRef(regionPageStore, 'outrightEvents');
    const metaParameters = computed(()=>{
        if (!isReady.value) return {};
        const sportEvents = sportElementsList.value?.flatMap((sportElement)=>sportElement.regions.flatMap((regionElement)=>regionElement.leagues.flatMap((leagueElement)=>leagueElement.sportEvents.map((sportEventElement)=>getSportEventSeoMetaInfo({
                            sportEvent: sportEventElement.sportEvent,
                            sport: sportElement.sport,
                            region: regionElement.region,
                            league: leagueElement.league,
                            vm: {
                                $router: router
                            }
                        })))));
        return {
            sport: sport.value?.name || '',
            sportFamily: sport.value?.navigationParameters.urlName || '',
            sportEmoji: sport.value?.representation.emoji || '',
            region: region.value?.name || '',
            sportEvents: {
                value: sportEvents,
                merge (oldValue, newValue) {
                    return [
                        ...oldValue,
                        ...newValue
                    ];
                }
            }
        };
    });
    function activatePageHook(isMount) {
        if (isMount) initFavoriteSportEvents();
        regionPageStore.setBackgroundUpdateEnabled(true);
    }
    function deactivatePageHook(isUnmount) {
        if (isUnmount) regionPageStore.clearRegion();
        regionPageStore.setBackgroundUpdateEnabled(false);
        // clear event info after leave page (need for leave page watch in store)
        regionPageStore.setPageIdentifier({
            sportFamily: ''
        });
    }
    return {
        isReady,
        isLoadingIndicatorShown,
        isMarketTypesSelectionEnabled,
        isContainEvents,
        sport,
        region,
        sportElementsList,
        outrightEvents,
        metaParameters,
        activatePageHook,
        deactivatePageHook
    };
}
