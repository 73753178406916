import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, computed } from 'vue';
import { isTeamWinMatch } from 'web/src/modules/sportline/utils/rest/statistic';
import MatchesStatisticCompetitors from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchesStatisticCompetitors.vue';
import MatchStatisticResultIndicator from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchStatisticResultIndicator.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LastMatchesStatistic',
    props: {
        teams: {},
        hostMatches: {},
        guestMatches: {}
    },
    setup (__props) {
        const props = __props;
        const teams = toRef(props, 'teams');
        const hostMatches = toRef(props, 'hostMatches', []);
        const guestMatches = toRef(props, 'guestMatches', []);
        const host = computed(()=>teams.value[0] ?? null);
        const guest = computed(()=>teams.value[1] ?? null);
        const hostWinPercentage = computed(()=>{
            const total = hostMatches.value.length;
            const team = host.value;
            const wins = hostMatches.value.reduce((result, match)=>result + Number(isTeamWinMatch(team, match)), 0);
            const percentage = 100 / total * wins;
            return Math.round(10 * percentage) / 10;
        });
        const guestWinPercentage = computed(()=>{
            const total = guestMatches.value.length;
            const team = guest.value;
            const wins = guestMatches.value.reduce((result, match)=>result + Number(isTeamWinMatch(team, match)), 0);
            const percentage = 100 / total * wins;
            return Math.round(10 * percentage) / 10;
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['last-matches-statistic'])
            }, [
                _createVNode(MatchesStatisticCompetitors, {
                    teams: teams.value
                }, null, 8, [
                    "teams"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['last-matches-statistic-result-indicators__container'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['last-matches-statistic-result-indicators'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hostMatches.value, (match)=>(_openBlock(), _createBlock(MatchStatisticResultIndicator, {
                                key: match.id ?? void 0,
                                match: match,
                                "displayed-for-team": host.value,
                                class: _normalizeClass(_ctx.$style['last-matches-statistic-result-indicator'])
                            }, null, 8, [
                                "match",
                                "displayed-for-team",
                                "class"
                            ]))), 128))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['last-matches-statistic-result-indicators'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(guestMatches.value, (match)=>(_openBlock(), _createBlock(MatchStatisticResultIndicator, {
                                key: match.id ?? void 0,
                                match: match,
                                "displayed-for-team": guest.value,
                                class: _normalizeClass(_ctx.$style['last-matches-statistic-result-indicator'])
                            }, null, 8, [
                                "match",
                                "displayed-for-team",
                                "class"
                            ]))), 128))
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['last-matches-statistic-result-percentages__container'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['last-matches-statistic-result-percentages'])
                    }, _toDisplayString(hostWinPercentage.value) + "%", 3),
                    _createElementVNode("span", {
                        class: _normalizeClass([
                            _ctx.$style['last-matches-statistic-result-percentages'],
                            _ctx.$style['last-matches-statistic-result-percentages--central']
                        ])
                    }, _toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_WINS_PERCENTAGE')), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['last-matches-statistic-result-percentages'])
                    }, _toDisplayString(guestWinPercentage.value) + "%", 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['last-matches-statistic-result-graph__container'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['last-matches-statistic-result-graph'],
                            _ctx.$style['last-matches-statistic-result-graph--left']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['last-matches-statistic-result-graph__filler'],
                                _ctx.$style['last-matches-statistic-result-graph__filler--left']
                            ]),
                            style: _normalizeStyle({
                                width: `${hostWinPercentage.value}%`
                            })
                        }, null, 6)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['last-matches-statistic-result-graph'],
                            _ctx.$style['last-matches-statistic-result-graph--right']
                        ])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                _ctx.$style['last-matches-statistic-result-graph__filler'],
                                _ctx.$style['last-matches-statistic-result-graph__filler--right']
                            ]),
                            style: _normalizeStyle({
                                width: `${guestWinPercentage.value}%`
                            })
                        }, null, 6)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LastMatchesStatistic'
                ]
            ]);
        };
    }
});
