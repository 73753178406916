import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import LeaguesList from 'web/src/modules/sportline/submodules/sports-list/views/leagues-list/LeaguesList.vue';
import RegionSpoiler from 'web/src/modules/sportline/submodules/sports-list/views/regions-list/RegionSpoiler.vue';
import { useCybersportAllTab } from '../composables/useCybersportAllTab';
import { useAllTabDateTimeFilter } from '../composables/useAllTabDateTimeFilter';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CybersportDefaultAllTab',
    setup (__props) {
        const { setOpenRegionState, isRegionOpen, topLeagues, sportsTreeElement: sportElement, hasElementsForCurrentFilter } = useCybersportAllTab();
        const { dateTimeFilterRef, isSportFiltersEnabled, currentDateTimeFilter, datetimeActiveKickoffPeriod, datetimeActiveSportlineType, datetimeCustomFrom, datetimeCustomTo, onDateTimeFilterSelect, onDateTimeFilterSelectCustomRange, resetDateTimeFilter } = useAllTabDateTimeFilter();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isSportFiltersEnabled) ? (_openBlock(), _createBlock(DateTimeFilter, {
                    key: 0,
                    ref_key: "dateTimeFilterRef",
                    ref: dateTimeFilterRef,
                    class: _normalizeClass(_ctx.$style['date-filter']),
                    "active-kickoff-period": _unref(datetimeActiveKickoffPeriod),
                    "active-sportline-type": _unref(datetimeActiveSportlineType),
                    "selected-range-from": _unref(datetimeCustomFrom),
                    "selected-range-to": _unref(datetimeCustomTo),
                    "tabs-type": _unref(TabsType).BORDERED,
                    onSelectFilter: _unref(onDateTimeFilterSelect),
                    onSelectRange: _unref(onDateTimeFilterSelectCustomRange)
                }, null, 8, [
                    "class",
                    "active-kickoff-period",
                    "active-sportline-type",
                    "selected-range-from",
                    "selected-range-to",
                    "tabs-type",
                    "onSelectFilter",
                    "onSelectRange"
                ])) : _createCommentVNode("", true),
                _unref(sportElement) && _unref(topLeagues).length > 0 ? (_openBlock(), _createBlock(LeaguesList, {
                    key: 1,
                    sport: _unref(sportElement).sport,
                    leagues: _unref(topLeagues),
                    "date-time-filter": _unref(currentDateTimeFilter),
                    "is-top": ""
                }, null, 8, [
                    "sport",
                    "leagues",
                    "date-time-filter"
                ])) : _createCommentVNode("", true),
                _unref(sportElement) && _unref(hasElementsForCurrentFilter) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-header'])
                    }, _toDisplayString(_ctx.$t('WEB2_ESPORT_GAMES_ALL_TITLE')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['regions'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sportElement).regions, (regionElement)=>(_openBlock(), _createBlock(RegionSpoiler, {
                                key: `level1-spoiler-${regionElement.region.id}`,
                                "sport-element": _unref(sportElement),
                                "region-element": regionElement,
                                "is-open": !!process.env.VUE_APP_PRERENDER || _unref(isRegionOpen)(regionElement.region.id),
                                "date-time-filter": _unref(currentDateTimeFilter),
                                "is-cyber-sport-page": "",
                                onChangeOpenState: ($event)=>_unref(setOpenRegionState)(regionElement.region.id, $event)
                            }, null, 8, [
                                "sport-element",
                                "region-element",
                                "is-open",
                                "date-time-filter",
                                "onChangeOpenState"
                            ]))), 128))
                    ], 2)
                ], 64)) : (_openBlock(), _createBlock(SportNoResults, {
                    key: 3,
                    "can-reset-filter": "",
                    onReset: _unref(resetDateTimeFilter)
                }, null, 8, [
                    "onReset"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'CybersportDefaultAllTab'
                ]
            ]);
        };
    }
});
