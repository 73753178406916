import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let SportradarStatisticsWidgetPrefetch = class SportradarStatisticsWidgetPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        if (!useSiteConfigStore().isSportradarStatisticsEnabled) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        await useSportlineWidgetsStore().fetchSportradarStatisticsConfig();
        next();
    }
};
export { SportradarStatisticsWidgetPrefetch as default };
