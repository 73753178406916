import { isString } from '@leon-hub/guards';
import { CustomFilter } from 'web/src/modules/sportline/enums';
const customFilterValues = [
    CustomFilter.Favorites,
    CustomFilter.AllEvents,
    CustomFilter.Stream
].map((v)=>v.toLowerCase());
export function isLiveFilterSportFamily(value, allowedSportFamily) {
    return isString(value) && (allowedSportFamily.includes(value) || customFilterValues.includes(value.toLowerCase()));
}
export function isOptionalLiveFilterSportFamily(value, allowedSportFamily) {
    return !value || isLiveFilterSportFamily(value, allowedSportFamily);
}
