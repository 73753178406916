import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import { isOptionalLiveFilterSportFamily } from 'web/src/modules/sportline/submodules/segment/guards';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
let LivePagePrefetch = class LivePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const liveStore = useSportlineLiveStore();
        const sportFamily = to.params?.family?.toLowerCase();
        const isPageEnabled = toRef(liveStore, 'isPageEnabled');
        const allowedSportFamily = toRef(liveStore, 'allowedSportFamily');
        if (!isPageEnabled.value || !isOptionalLiveFilterSportFamily(sportFamily, allowedSportFamily.value)) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return Promise.resolve();
        }
        const navigationStore = useRootNavigationStore();
        const { setPersistLocation } = useSportlinePersistLocation();
        const navigationParameters = getSportlinePageNavigationParameters(to);
        setPersistLocation('live', navigationParameters);
        if (liveStore.isReady) {
            liveStore.setSelectedFilter(sportFamily);
            liveStore.syncBackgroundRequests();
            navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
        } else {
            navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_LIVE_EVENTS);
            liveStore.initialRequests().then(()=>{
                liveStore.setSelectedFilter(sportFamily);
                navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
            });
        }
        next();
        return Promise.resolve();
    }
};
export { LivePagePrefetch as default };
