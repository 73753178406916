import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
import { toRef } from 'vue';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useDoShowMatchProgress } from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/composables';
import SportlineLiveEventProgress from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/SportlineLiveEventProgress.vue';
import { useLiveInfoHeadline } from '../../composables';
import HeadlineLiveTimeInfo from '../HeadlineLiveTimeInfo.vue';
import HeadlineTeam from './components/HeadlineTeam.vue';
import HeadlineTeamScore from '../components/HeadlineTeamScore.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveMatchInfo',
    props: {
        sportlineEvent: {},
        sport: {},
        backgroundColor: {}
    },
    setup (__props) {
        /**
 * @TODO reduce template complexity
 */ const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const sport = toRef(props, 'sport');
        const { matchProgress } = useLiveMatchProgress({
            sportEvent: sportlineEvent
        });
        const { innings, stage, scoreDetailsLine } = useLiveStatus(matchProgress);
        const { hasLogos, date, time } = useLiveInfoHeadline({
            sportlineEvent,
            sport
        });
        const { doShowTimeline } = useDoShowMatchProgress({
            sportlineEvent
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    _ctx.$style['headline-info'],
                    _ctx.$style['headline-info--live']
                ])
            }, [
                _unref(hasLogos) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-info__container'])
                    }, [
                        _createVNode(HeadlineTeam, {
                            position: "center",
                            role: "host",
                            name: sportlineEvent.value.competitors[0]?.name,
                            logo: sportlineEvent.value.competitors[0]?.logo,
                            "logo-meta": sportlineEvent.value.competitors[0]?.logoMeta,
                            "power-play": sportlineEvent.value.incidentStatistics.host.powerPlay
                        }, null, 8, [
                            "name",
                            "logo",
                            "logo-meta",
                            "power-play"
                        ]),
                        _createElementVNode("div", null, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['headline-info__date'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['headline-info__day'])
                                }, _toDisplayString(_unref(date)), 3),
                                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['headline-info__time'])
                                }, _toDisplayString(_unref(time)), 3),
                                _unref(matchProgress) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['headline-info__score-holder'])
                                }, [
                                    _createVNode(HeadlineTeamScore, {
                                        role: "host",
                                        score: _unref(matchProgress).totalScore.host,
                                        innings: _unref(innings)?.host,
                                        size: "32",
                                        gap: "16"
                                    }, null, 8, [
                                        "score",
                                        "innings"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['headline-info__divider'])
                                    }, null, 2),
                                    _createVNode(HeadlineTeamScore, {
                                        role: "guest",
                                        score: _unref(matchProgress).totalScore.guest,
                                        innings: _unref(innings)?.guest,
                                        size: "32",
                                        gap: "16"
                                    }, null, 8, [
                                        "score",
                                        "innings"
                                    ])
                                ], 2)) : _createCommentVNode("", true)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['headline-info__stage']]: true,
                                    [_ctx.$style['headline-info__stage--alone']]: !_unref(doShowTimeline)
                                })
                            }, [
                                _createElementVNode("label", {
                                    class: _normalizeClass(_ctx.$style['headline-info__stage-line'])
                                }, [
                                    _createTextVNode(_toDisplayString(_unref(stage)) + " ", 1),
                                    _createVNode(HeadlineLiveTimeInfo, {
                                        "sportline-event": sportlineEvent.value
                                    }, null, 8, [
                                        "sportline-event"
                                    ])
                                ], 2),
                                _unref(scoreDetailsLine) ? (_openBlock(), _createElementBlock("label", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['headline-info__stage-line'])
                                }, " (" + _toDisplayString(_unref(scoreDetailsLine)) + ") ", 3)) : _createCommentVNode("", true)
                            ], 2),
                            _unref(doShowTimeline) ? (_openBlock(), _createBlock(SportlineLiveEventProgress, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['live-match-info-progress-bar']),
                                "sportline-event": sportlineEvent.value
                            }, null, 8, [
                                "class",
                                "sportline-event"
                            ])) : _createCommentVNode("", true)
                        ]),
                        _createVNode(HeadlineTeam, {
                            position: "center",
                            role: "guest",
                            name: sportlineEvent.value.competitors[1]?.name,
                            logo: sportlineEvent.value.competitors[1]?.logo,
                            "logo-meta": sportlineEvent.value.competitors[1]?.logoMeta,
                            "power-play": sportlineEvent.value.incidentStatistics.guest.powerPlay
                        }, null, 8, [
                            "name",
                            "logo",
                            "logo-meta",
                            "power-play"
                        ])
                    ], 2),
                    _renderSlot(_ctx.$slots, "primary-market", {
                        hasTimeline: _unref(doShowTimeline)
                    })
                ])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-info__date'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['headline-info__day'])
                        }, _toDisplayString(_unref(date)), 3),
                        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['headline-info__time'])
                        }, _toDisplayString(_unref(time)), 3)
                    ], 2),
                    _unref(matchProgress) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['headline-info__score-line'])
                    }, [
                        _createVNode(HeadlineTeam, {
                            position: "left",
                            role: "host",
                            name: sportlineEvent.value.competitors[0]?.name,
                            score: _unref(matchProgress).totalScore.host,
                            innings: _unref(innings)?.host,
                            "power-play": sportlineEvent.value.incidentStatistics.host.powerPlay
                        }, null, 8, [
                            "name",
                            "score",
                            "innings",
                            "power-play"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['headline-info__divider'])
                        }, null, 2),
                        _createVNode(HeadlineTeam, {
                            position: "right",
                            role: "guest",
                            name: sportlineEvent.value.competitors[1]?.name,
                            score: _unref(matchProgress).totalScore.guest,
                            innings: _unref(innings)?.guest,
                            "power-play": sportlineEvent.value.incidentStatistics.guest.powerPlay
                        }, null, 8, [
                            "name",
                            "score",
                            "innings",
                            "power-play"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-info__stage'])
                    }, [
                        _createElementVNode("label", {
                            class: _normalizeClass(_ctx.$style['headline-info__stage-line'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(stage)) + " ", 1),
                            _createVNode(HeadlineLiveTimeInfo, {
                                "sportline-event": sportlineEvent.value
                            }, null, 8, [
                                "sportline-event"
                            ])
                        ], 2),
                        _unref(scoreDetailsLine) ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['headline-info__stage-line'])
                        }, " (" + _toDisplayString(_unref(scoreDetailsLine)) + ") ", 3)) : _createCommentVNode("", true)
                    ], 2),
                    _unref(doShowTimeline) ? (_openBlock(), _createBlock(SportlineLiveEventProgress, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['live-match-info-progress-bar']),
                        "sportline-event": sportlineEvent.value
                    }, null, 8, [
                        "class",
                        "sportline-event"
                    ])) : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "primary-market", {
                        hasTimeline: _unref(doShowTimeline)
                    })
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LiveMatchInfo'
                ]
            ]);
        };
    }
});
