import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { PostMessageEvent } from '@leon-hub/postmessage-bus';
export const PostMessageBusInitiator = 'SportradarLiveMatchTrackerFramedWidget';
let SportradarLiveMatchTrackerPostMessageEvent = class SportradarLiveMatchTrackerPostMessageEvent extends PostMessageEvent {
};
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "mounted", new PostMessageEvent('sir-widget-mounted'));
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "init", new PostMessageEvent('sir-widget-init'));
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "loaded", new PostMessageEvent('sir-widget-loaded'));
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "widgetLoadTimeout", new PostMessageEvent('sir-widget-load-timeout'));
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "widgetLoadError", new PostMessageEvent('sir-widget-load-error'));
_define_property(SportradarLiveMatchTrackerPostMessageEvent, "widgetResized", new PostMessageEvent('sir-widget-resized'));
export { SportradarLiveMatchTrackerPostMessageEvent as default };
