import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePageHeadlineInfoLayout',
    props: {
        title: {},
        subtitle: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['headline-layout-info-layout']]: true,
                    [_ctx.$style['headline-layout-info-layout--with-logo']]: !!_ctx.$slots.logo,
                    [_ctx.$style['headline-layout-info-layout--without-logo']]: !_ctx.$slots.logo
                })
            }, [
                _ctx.$slots.logo ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['headline-layout-info-layout__logo'])
                }, [
                    _renderSlot(_ctx.$slots, "logo")
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['headline-layout-info-layout__info']]: true,
                        [_ctx.$style['headline-layout-info-layout__info--without-logo']]: !_ctx.$slots.logo,
                        [_ctx.$style['headline-layout-info-layout__info--without-progress']]: !_ctx.$slots.progress
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['headline-layout-info-layout__titles'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['headline-layout-info-layout-title'])
                        }, _toDisplayString(_ctx.title), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['headline-layout-info-layout-subtitle'])
                        }, _toDisplayString(_ctx.subtitle), 3)
                    ], 2)
                ], 2),
                _ctx.$slots.progress ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['headline-layout-info-layout__progress'])
                }, [
                    _renderSlot(_ctx.$slots, "progress")
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguePageHeadlineInfoLayout'
                ]
            ]);
        };
    }
});
