import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
import { onActivated, onDeactivated, onBeforeMount } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineRedirectFromEmptyPageInfo from 'web/src/modules/sportline/submodules/redirects/views/SportlineRedirectFromEmptyPageInfo.vue';
import LeagueBreadcrumbs from 'web/src/modules/sportline/submodules/league/views/LeagueBreadcrumbs.vue';
import EmptyLeaguePageContentInfo from 'web/src/modules/sportline/submodules/league/components/EmptyLeaguePageContentInfo.vue';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import useLeagueRoutePage from './useLeagueRoutePage';
import LeaguePageStatistic from './LeaguePageStatistic.vue';
import LeagueDefaultPageHeadline from './default/LeaguePageHeadline.vue';
import LeagueDefaultEventsList from './default/LeagueDefaultEventsList.vue';
import LeagueBetSwipePageHeadline from './bet-swipe/LeaguePageHeadline.vue';
import LeagueBetSwipeEventsList from './bet-swipe/LeagueBetSwipeEventsList.vue';
import LargeLeagueToolbar from './toolbar/LargeLeagueToolbar.vue';
import SmallLeagueToolbar from './toolbar/SmallLeagueToolbar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueRoutePage',
    setup (__props) {
        const { isBetSwipeNavigationLayoutEnabled, isBetSwipeLeagueLayoutEnabled } = useSportlineLayoutSettings();
        const { isReady, canDisplayAnything, hasEvents, sportElement, outrightEvents, isMarketTypesSelectionEnabled, isLeagueStatisticEnabled, isEventsTabAvailable, isStatisticTabAvailable, isPromotionsTabAvailable, regionName, leagueId, leagueName, sportId, isEventsListEmpty, metaParameters, activeSectionId, clickBack, onActivate, onDeactivate, selectSection } = useLeagueRoutePage();
        useSeoMetaParameters(metaParameters);
        const { initialRequests: initFavoriteSportEvents } = useFavoritesEventsStore();
        onBeforeMount(()=>{
            initFavoriteSportEvents();
        });
        onActivated(()=>{
            onActivate();
        });
        onDeactivated(()=>{
            onDeactivate();
        });
        useKeepAlive([
            RouteName.HOME,
            RouteName.SPORTLINE_LIVE_EVENTS,
            RouteName.SPORTLINE_PREMATCH_EVENTS,
            RouteName.SPORT_REGION,
            RouteName.SPORT_LEAGUE,
            RouteName.SPORT_EVENT_DETAILS,
            RouteName.SPORTLINE_CYBERSPORT
        ]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['sport-event-league']]: true,
                        [_ctx.$style['sport-event-league--extra-paddings']]: void 0
                    })
                }, [
                    (_openBlock(), _createBlock(LeagueBreadcrumbs, {
                        key: 1,
                        "default-region-title": _unref(regionName),
                        "default-league-title": _unref(leagueName)
                    }, null, 8, [
                        "default-region-title",
                        "default-league-title"
                    ])),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-league__content'])
                    }, [
                        _unref(isReady) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                            key: 0
                        })),
                        _unref(canDisplayAnything) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                (_openBlock(), _createBlock(LargeLeagueToolbar, {
                                    key: 0,
                                    region: _unref(regionName),
                                    name: _unref(leagueName),
                                    "sport-id": _unref(sportId),
                                    "league-id": _unref(leagueId),
                                    onClickBack: _unref(clickBack)
                                }, null, 8, [
                                    "region",
                                    "name",
                                    "sport-id",
                                    "league-id",
                                    "onClickBack"
                                ])),
                                _createVNode(LeagueDefaultPageHeadline, {
                                    class: _normalizeClass(_ctx.$style['sport-event-league__headline']),
                                    "has-matches": _unref(isEventsTabAvailable),
                                    "has-promotions": _unref(isPromotionsTabAvailable),
                                    "has-statistic": _unref(isStatisticTabAvailable),
                                    "active-section-id": _unref(activeSectionId),
                                    onActiveSectionIdSwitched: _unref(selectSection)
                                }, null, 8, [
                                    "class",
                                    "has-matches",
                                    "has-promotions",
                                    "has-statistic",
                                    "active-section-id",
                                    "onActiveSectionIdSwitched"
                                ])
                            ], 64)),
                            _unref(activeSectionId) === _unref(LeaguePageSectionId).EventsList || process.env.VUE_APP_PRERENDER ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 2
                            }, [
                                _createVNode(SportlineRedirectFromEmptyPageInfo, {
                                    class: _normalizeClass(_ctx.$style['sport-event-league__redirect-info'])
                                }, null, 8, [
                                    "class"
                                ]),
                                _unref(isEventsListEmpty) ? (_openBlock(), _createBlock(EmptyLeaguePageContentInfo, {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['sport-event-league__no-events']),
                                    "section-id": _unref(LeaguePageSectionId).EventsList,
                                    "is-league-statistic-enabled": _unref(isLeagueStatisticEnabled)
                                }, null, 8, [
                                    "class",
                                    "section-id",
                                    "is-league-statistic-enabled"
                                ])) : (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    (_openBlock(), _createBlock(LeagueDefaultEventsList, {
                                        key: 1,
                                        "sport-element": _unref(sportElement),
                                        "outright-events": _unref(outrightEvents),
                                        "has-events": _unref(hasEvents),
                                        "is-market-types-selection-enabled": _unref(isMarketTypesSelectionEnabled)
                                    }, null, 8, [
                                        "sport-element",
                                        "outright-events",
                                        "has-events",
                                        "is-market-types-selection-enabled"
                                    ]))
                                ], 64))
                            ], 64)) : _createCommentVNode("", true),
                            _unref(activeSectionId) === _unref(LeaguePageSectionId).Statistic || process.env.VUE_APP_PRERENDER ? (_openBlock(), _createBlock(LeaguePageStatistic, {
                                key: 3
                            })) : _createCommentVNode("", true),
                            _unref(activeSectionId) === _unref(LeaguePageSectionId).None ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 4
                            }, [
                                _unref(isLeagueStatisticEnabled) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['sport-event-league__content-title'])
                                }, _toDisplayString(_ctx.$t('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC')), 3)) : _createCommentVNode("", true),
                                _createVNode(EmptyLeaguePageContentInfo, {
                                    "section-id": _unref(LeaguePageSectionId).None,
                                    "is-league-statistic-enabled": _unref(isLeagueStatisticEnabled)
                                }, null, 8, [
                                    "section-id",
                                    "is-league-statistic-enabled"
                                ])
                            ], 64)) : _createCommentVNode("", true)
                        ], 64)) : _createCommentVNode("", true)
                    ], 2)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'LeagueRoutePage'
                ]
            ]);
        };
    }
});
