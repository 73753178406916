import { computed, toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
export function useLiveInfoHeadline(props) {
    const { sportlineEvent } = props;
    const settingsStore = useSportlineSettingsStore();
    const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');
    const { date, time } = useSportlineEventDateTime({
        sportlineEvent
    });
    const hasLogos = computed(()=>!!sportlineEvent.value.competitors[0]?.logo && !!sportlineEvent.value.competitors[1]?.logo);
    return {
        isTimeReversedIndicatorAvailable,
        hasLogos,
        date,
        time
    };
}
