export const VirtualListRendererElementSize = {
    EventsGap: 2,
    LeaguesGap: 8,
    RegionsGap: 8,
    SportsGap: 8,
    NoEventsHeight: 88,
    EventHeight: 78,
    LeagueHeadlineHeight: 40,
    LeagueHeadlineGap: 2,
    SportHeadlineHeight: 56,
    SportHeadlineGap: 0
};
