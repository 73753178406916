import { toRef, computed } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { isString } from '@leon-hub/guards';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useBaseLeaguePageNavigationGuard() {
    const { isDevIP } = useIsDevIP();
    const navigationStore = useSportlineNavigationStore();
    const filter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');
    const { setSportlineNavigationBetweenPagesFilter } = navigationStore;
    const leaguePageStore = useLeaguePageStore();
    const leagueNavigationParameters = toRef(leaguePageStore, 'leagueNavigationParameters');
    const { setPageIdentifier } = leaguePageStore;
    const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
    const { setPageIdentifier: setBreadcrumbsPageIdentifier } = sportlineBreadcrumbsStore;
    const loadLeagueConfig = computed(()=>({
            filter: filter.value ?? void 0
        }));
    function clearLoadLeagueConfig() {
        setSportlineNavigationBetweenPagesFilter(null);
    }
    function prefetch(router, to, from, next) {
        const navigationParameters = getSportPageNavigationParameters(to);
        if (!isValidSportEventsPageIdentifier(navigationParameters)) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        const statisticId = isDevIP.value && isString(to.query?.statisticId) ? to.query?.statisticId : void 0;
        setPageIdentifier({
            ...navigationParameters,
            statisticId
        });
        setBreadcrumbsPageIdentifier({
            ...navigationParameters,
            statisticId
        });
        next();
    }
    function getRedirectLocation(router, currentLocation) {
        const target = leagueNavigationParameters.value;
        if (!target) return null;
        const currentTarget = getSportPageNavigationParameters(currentLocation);
        if (currentTarget.leagueId !== target.id) // Is it an error?
        return null;
        if (currentTarget.leagueUrlName === target.urlName && currentTarget.regionUrlName === target.regionUrlName && currentTarget.sportFamily === target.sportFamily) // alright
        return null;
        return router.resolve301location(resolveLeaguePageLink(target));
    }
    return {
        loadLeagueConfig,
        clearLoadLeagueConfig,
        prefetch,
        getRedirectLocation
    };
}
