import { computed } from 'vue';
/**
 * Get capacity – how many items we can display from the start point.
 */ export function createVisibleItemsViewCapacity(scrollOffset, containerSize, visibleItemsStartIndex, sizes) {
    return computed(()=>{
        const start = visibleItemsStartIndex.value;
        let sum = 0;
        let capacity = 0;
        let hiddenSize = 0;
        for (const [index, size] of sizes.value.entries()){
            if (index < start) {
                hiddenSize += size;
                continue;
            }
            const partiallyHidden = Math.max(scrollOffset.value - hiddenSize, 0);
            if (sum + size - partiallyHidden > containerSize.value) break;
            sum += size;
            capacity += 1;
        }
        return capacity;
    });
}
