import { toRef, computed } from 'vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';
import { extractTopLeagues } from 'web/src/modules/sportline/utils/core';
export function useSportsSportTab(props) {
    const { sportId } = props;
    const sportlineSportsStore = useSportlineSportsStore();
    const actualSportsList = toRef(sportlineSportsStore, 'actualSportsList');
    const currentDateTimeFilter = toRef(sportlineSportsStore, 'sportsListFilter');
    const { setOpenRegionState, isRegionOpen } = sportlineSportsStore;
    const sportElement = computed(()=>actualSportsList.value?.find((element)=>element.sport.id === sportId.value) ?? null);
    const topLeagues = computed(()=>extractTopLeagues(sportElement.value).sort(sortTopLeagueElementsASC));
    const isCyberSportPage = computed(()=>sportElement.value?.sport.segment.id === SportSegmentId.CyberSport);
    const hasElementsForCurrentFilter = computed(()=>!!sportElement.value?.regions?.length);
    return {
        sportElement,
        topLeagues,
        isCyberSportPage,
        hasElementsForCurrentFilter,
        currentDateTimeFilter,
        setOpenRegionState,
        isRegionOpen
    };
}
