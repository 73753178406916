import { computed } from 'vue';
export default function useMatchStatisticsTeams(matchStatistics) {
    const teams = computed(()=>{
        const rawTeams = matchStatistics.value?.teams || null;
        return rawTeams && rawTeams.length > 1 ? [
            rawTeams[0],
            rawTeams[1]
        ] : null;
    });
    return {
        teams
    };
}
