import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { toRef, computed } from 'vue';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualListRendererEventElement',
    props: {
        element: {},
        canAddToFavorite: {
            type: Boolean
        },
        extendedBlockType: {}
    },
    emits: [
        "resize"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const element = toRef(props, 'element');
        const isLastEvent = computed(()=>element.value.isLastEventInLeague);
        const isLastLeague = computed(()=>element.value.isLastLeagueInRegion);
        const isLastRegion = computed(()=>element.value.isLastRegionInSport);
        const isLastSport = computed(()=>element.value.isLastSport);
        const upcomingLabelType = computed(()=>element.value.blockLayout === VirtualListBlockLayout.Coming ? UpcomingLabelType.Timer : UpcomingLabelType.StaticLabel);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(SportlineEventBlock, {
                class: _normalizeClass({
                    [_ctx.$style['sportline-event']]: true,
                    [_ctx.$style['sportline-event--before-event-gap']]: !isLastEvent.value,
                    [_ctx.$style['sportline-event--before-league-gap']]: !isLastLeague.value && isLastEvent.value,
                    [_ctx.$style['sportline-event--before-region-gap']]: !isLastRegion.value && isLastLeague.value && isLastEvent.value,
                    [_ctx.$style['sportline-event--before-sport-gap']]: !isLastSport.value && isLastRegion.value && isLastLeague.value && isLastEvent.value
                }),
                "sport-event-element": element.value.sportlineEvent,
                sport: element.value.sport,
                "can-add-to-favorite": _ctx.canAddToFavorite,
                "extended-block-type": _ctx.extendedBlockType,
                "upcoming-label-type": upcomingLabelType.value,
                "basis-key": element.value.basisKey,
                onResize: _cache[0] || (_cache[0] = ($event)=>emit('resize', $event))
            }, null, 8, [
                "class",
                "sport-event-element",
                "sport",
                "can-add-to-favorite",
                "extended-block-type",
                "upcoming-label-type",
                "basis-key"
            ])), [
                [
                    _directive_auto_id,
                    'VirtualListRendererEventElement'
                ]
            ]);
        };
    }
});
