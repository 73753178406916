import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import MainBannerSectionRouteComponent from 'web/src/modules/banners/components/MainBannerSectionRouteComponent/MainBannerSectionRouteComponent.vue';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportlineLiveNoEvents from 'web/src/modules/sportline/submodules/live/components/SportlineLiveNoEvents.vue';
import { useLiveSportTab } from './useLiveSportTab';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LiveDefaultTab',
    props: {
        canExpandElements: {
            type: Boolean
        },
        group: {},
        canShowPrematch: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const group = toRef(props, 'group');
        const canExpandElements = toRef(props, 'canExpandElements', false);
        const canShowPrematch = toRef(props, 'canShowPrematch', false);
        const isOpen = toRef(props, 'isOpen', false);
        const settingsStore = useSportlineSettingsStore();
        const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');
        const { isExpandAllByDefault, liveGroup, prematchGroup } = useLiveSportTab({
            group
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(liveGroup) ? _withDirectives((_openBlock(), _createBlock(SportlineSportBlock, {
                    key: `${_unref(liveGroup).key}-live`,
                    "sport-element": _unref(liveGroup).liveElement,
                    "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                    "expand-key": canExpandElements.value ? _unref(liveGroup).expandKey : null,
                    "is-default-expanded": _unref(isExpandAllByDefault) || group.value.isOpenByDefault,
                    "can-add-to-favorite": true,
                    "upcoming-label-type": _unref(UpcomingLabelType).StaticLabel,
                    "extended-block-type": _unref(ExtendedEventBlock).Live,
                    "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                        sportId: _unref(liveGroup).liveElement.sport.id,
                        salt: 'live'
                    })
                }, null, 8, [
                    "sport-element",
                    "is-market-types-selection-enabled",
                    "expand-key",
                    "is-default-expanded",
                    "can-add-to-favorite",
                    "upcoming-label-type",
                    "extended-block-type",
                    "basis-key"
                ])), [
                    [
                        _vShow,
                        isOpen.value
                    ]
                ]) : _createCommentVNode("", true),
                canShowPrematch.value && group.value.isEmpty ? (_openBlock(), _createBlock(SportlineLiveNoEvents, {
                    key: `${group.value.key}-empty`,
                    "sport-id": group.value.filterElement?.sport.id
                }, null, 8, [
                    "sport-id"
                ])) : _createCommentVNode("", true),
                isOpen.value ? (_openBlock(), _createBlock(MainBannerSectionRouteComponent, {
                    key: `${group.value.key}-banner`,
                    "is-static": "",
                    location: _unref(BannerLocation).CUSTOM,
                    "banner-type": _unref(BannerType).INLINE_BANNER,
                    "custom-location-id": group.value.transitionId,
                    "banner-category": _unref(BannerCategory).SUPER_LIVE
                }, null, 8, [
                    "location",
                    "banner-type",
                    "custom-location-id",
                    "banner-category"
                ])) : _createCommentVNode("", true),
                canShowPrematch.value && _unref(prematchGroup) ? (_openBlock(), _createBlock(VLazyRender, {
                    key: 3
                }, {
                    default: _withCtx(()=>[
                            _unref(prematchGroup).doShowTitleComponent ? (_openBlock(), _createBlock(SportlineComingHeader, {
                                key: `${group.value.key}-before-prematch`
                            })) : _createCommentVNode("", true),
                            _withDirectives((_openBlock(), _createBlock(SportlineSportBlock, {
                                key: `${_unref(prematchGroup).key}-coming`,
                                "sport-element": _unref(prematchGroup).prematchElement,
                                "is-coming-headline": !_unref(prematchGroup).showComingName,
                                "is-market-types-selection-enabled": isMarketTypesSelectionEnabled.value,
                                "can-add-to-favorite": true,
                                "upcoming-label-type": _unref(UpcomingLabelType).Timer,
                                "extended-block-type": _unref(ExtendedEventBlock).Live,
                                "basis-key": _unref(createSportlineFragmentBasisKeyValue)({
                                    sportId: _unref(prematchGroup).prematchElement.sport.id,
                                    salt: 'prematch'
                                })
                            }, null, 8, [
                                "sport-element",
                                "is-coming-headline",
                                "is-market-types-selection-enabled",
                                "can-add-to-favorite",
                                "upcoming-label-type",
                                "extended-block-type",
                                "basis-key"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'prematchGroup-coming'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                })) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'LiveDefaultTab'
                ]
            ]);
        };
    }
});
