import { computed, ref, toRefs } from 'vue';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import { useSegmentPageSeoMeta, useSegmentPageFilters, useSegmentPageResetFiltersHooks } from 'web/src/modules/sportline/submodules/segment/composables';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';
import { SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';
import { resolveLivePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { isLiveCountersEmpty } from 'web/src/modules/sportline/utils';
export default function useLiveSegmentPage() {
    const liveStore = useSportlineLiveStore();
    const { isReady, totalCount, selectedFilter, isFilterTransitionFadeEffectEnabled, sportElementSelectedInFilter, hasFavoritesOptimistic, filterTransitionDuration, liveEventsSportsMap, comingEventsSportsMap, sportElementsForFilter, compiledComingSports, customerOptimisticFavoriteSportEventsIds, customerOptimisticFavoriteLeaguesIds, rawLiveEventsResponse, customerStreamsSportEventsList } = toRefs(liveStore);
    const { setBackgroundUpdateEnabled } = liveStore;
    const mainGroups = computed(()=>{
        const selectedKey = selectedFilter.value;
        const liveElementsMap = liveEventsSportsMap.value;
        const comingElementsMap = comingEventsSportsMap.value;
        const expandFirstCount = 5;
        let isOpenByDefaultCount = 0;
        return (sportElementsForFilter.value || []).map((sportElement)=>{
            const transitionId = sportElement.sport.navigationParameters.urlName;
            const key = `${sportElement.sport.id}_${transitionId}`;
            const liveElement = liveElementsMap.get(transitionId);
            const prematchElement = comingElementsMap.get(transitionId);
            const isEmpty = isLiveCountersEmpty(liveElement);
            const isOpenByDefault = !isEmpty && isOpenByDefaultCount < expandFirstCount;
            if (isOpenByDefault) isOpenByDefaultCount += 1;
            return {
                filterElement: sportElement,
                liveElement,
                prematchElement,
                transitionId,
                allowedForTransitionIds: [
                    CustomFilter.AllEvents,
                    transitionId
                ],
                isActive: selectedKey === transitionId,
                isEmpty,
                isEmptyComing: !prematchElement?.counters.prematch,
                key,
                type: SegmentPageTabType.Default,
                expandKey: `live-${sportElement.sport.representation.family}`,
                isOpenByDefault
            };
        });
    });
    const compiledComingGroups = computed(()=>{
        const sportElements = compiledComingSports.value ?? [];
        if (0 === sportElements.length) return [];
        const selectedKey = selectedFilter.value;
        return sportElements.map((sportElement, index)=>{
            const transitionId = sportElement.sport.navigationParameters.urlName;
            const key = `coming-${sportElement.sport.id}_${transitionId}`;
            return {
                filterElement: sportElement,
                prematchElement: sportElement,
                transitionId: CustomFilter.AllEvents,
                allowedForTransitionIds: [
                    CustomFilter.AllEvents
                ],
                isActive: selectedKey === CustomFilter.AllEvents,
                isEmpty: false,
                // false to hide "No live" block
                isEmptyComing: !sportElement.counters.prematch,
                key,
                type: SegmentPageTabType.Default,
                showComingName: true,
                doShowTitleComponent: 0 === index
            };
        });
    });
    const favoriteGroups = computed(()=>{
        const selectedKey = selectedFilter.value;
        const eventsIds = customerOptimisticFavoriteSportEventsIds.value;
        const leaguesIds = customerOptimisticFavoriteLeaguesIds.value;
        const response = rawLiveEventsResponse.value;
        const hasFavorites = SportEventsResponseChangeUtils.isSomeIdsInList(response, {
            eventsIds,
            leaguesIds
        });
        return hasFavorites ? [
            {
                transitionId: CustomFilter.Favorites,
                allowedForTransitionIds: [
                    CustomFilter.Favorites
                ],
                isActive: selectedKey === CustomFilter.Favorites,
                isEmpty: false,
                isEmptyComing: true,
                key: 'favorite',
                type: SegmentPageTabType.Favorite,
                showComingName: true
            }
        ] : [];
    });
    const streamEventsGroups = computed(()=>{
        const selectedKey = selectedFilter.value;
        return (customerStreamsSportEventsList.value || []).map((sportElement)=>({
                filterElement: sportElement,
                liveElement: sportElement,
                prematchElement: sportElement,
                transitionId: CustomFilter.Stream,
                allowedForTransitionIds: [
                    CustomFilter.Stream
                ],
                isActive: selectedKey === CustomFilter.Stream,
                isEmpty: false,
                // false to hide "No live" block
                isEmptyComing: true,
                key: `stream${sportElement.sport.id}`,
                type: SegmentPageTabType.Default,
                showComingName: true
            }));
    });
    const groupsForDisplay = computed(()=>[
            ...mainGroups.value,
            ...favoriteGroups.value,
            ...streamEventsGroups.value,
            ...compiledComingGroups.value
        ]);
    // @TODO hasStreamEvents from store
    const hasStreamEvents = computed(()=>streamEventsGroups.value.length > 0);
    const { allGroupForFilter, favoriteGroupForFilter, streamEventsGroupForFilter, activeTransitionId, setActiveTransitionId, canShowLiveForGroup, canShowPrematchForGroup } = useSegmentPageFilters({
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        totalCount,
        selectedFilter,
        mainGroups,
        hasStreamEvents,
        hasFavoritesOptimistic
    });
    function getEmptyEventsComponentProperties(group) {
        return group?.filterElement ? {
            sportId: group.filterElement.sport.id
        } : {};
    }
    const { listTransition, onChangeFilter, onActivatedFiltersHook, onDeactivatedFiltersHook } = useSegmentPageResetFiltersHooks({
        isReady,
        selectedFilter,
        groupsForDisplay,
        setBackgroundUpdateEnabled,
        persistentLocationKey: 'live',
        getDefaultPageLocation () {
            return resolveLivePageLink({});
        }
    });
    const { seoMetaInfo } = useSegmentPageSeoMeta({
        isReady,
        activeSportElement: sportElementSelectedInFilter,
        activeRegionElement: ref(null),
        groupsForDisplay,
        canShowLiveForGroup,
        canShowPrematchForGroup
    });
    return {
        isReady,
        totalCount,
        allGroupForFilter,
        mainGroups,
        groupsForDisplay,
        favoriteGroupForFilter,
        streamEventsGroupForFilter,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        seoMetaInfo,
        selectedFilter,
        activeTransitionId,
        setActiveTransitionId,
        canShowLiveForGroup,
        canShowPrematchForGroup,
        listTransition,
        onChangeFilter,
        onActivatedFiltersHook,
        onDeactivatedFiltersHook,
        getEmptyEventsComponentProperties
    };
}
