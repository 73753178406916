import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "league-playoff-round"
};
const _hoisted_2 = [
    "title"
];
const _hoisted_3 = {
    class: "league-playoff-round__block-pair-content"
};
const _hoisted_4 = {
    class: "league-playoff-round__arrow"
};
const _hoisted_5 = {
    key: 0,
    class: "league-playoff-round__arrow-line"
};
const _hoisted_6 = {
    key: 1,
    class: "league-playoff-round__block-pair"
};
import { toRef } from 'vue';
import LeaguePlayoffMatchesBlock from './LeaguePlayoffMatchesBlock.vue';
import { useLeaguePlayoffRound } from './useLeaguePlayoffRound';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePlayoffRound',
    props: {
        round: {},
        isLastRound: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { blockPairs } = useLeaguePlayoffRound({
            round: toRef(props, 'round')
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: "league-playoff-round__title",
                    title: _ctx.round.description ?? void 0
                }, _toDisplayString(_ctx.round.description), 9, _hoisted_2),
                _unref(blockPairs).length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 0
                }, _renderList(_unref(blockPairs), (pair, pairIndex)=>(_openBlock(), _createElementBlock("div", {
                        key: pairIndex,
                        class: "league-playoff-round__block-pair"
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pair, (block, blockIndex)=>(_openBlock(), _createElementBlock("div", {
                                    key: blockIndex,
                                    class: "league-playoff-round__block-holder"
                                }, [
                                    block ? (_openBlock(), _createBlock(LeaguePlayoffMatchesBlock, {
                                        key: 0,
                                        block: block
                                    }, null, 8, [
                                        "block"
                                    ])) : _createCommentVNode("", true)
                                ]))), 128))
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _ctx.isLastRound ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_5))
                        ])
                    ]))), 128)) : (_openBlock(), _createElementBlock("div", _hoisted_6))
            ])), [
                [
                    _directive_auto_id,
                    'LeaguePlayoffRound'
                ]
            ]);
        };
    }
});
