import { shallowRef, ref } from 'vue';
import { useHorizontalWrapperProps, useRecycleScrollerResources, useVerticalWrapperProps, useWatchForSizes, useWatchForFixStartPosition, useVisibleItemsState } from './composables';
import { RecycleScrollerDirection } from './enums';
import { createGetDistance, createCalculateRange, createHiddenSize, createScrollTo, createScrollBy, createVisibleList, createListItemsByDirection, createDimensionSizesRefForDirection } from './utils';
/** the item types configuration */ /** the extra buffer items outside of the view area */ function useRecycleScroller(props, direction) {
    const options = shallowRef(props.options);
    const overscan = ref(props.overscan);
    const { scrollOffset, containerSize, isPageMode, sourceLength, dimensions, containerRef, listenerTargetRef, setScrollableElementDimension } = useRecycleScrollerResources({
        options
    });
    const itemsByDirection = createListItemsByDirection(dimensions, direction);
    const dimensionSizes = createDimensionSizesRefForDirection(dimensions, direction);
    const { visibleItemsState } = useVisibleItemsState(scrollOffset, dimensionSizes, overscan, containerSize, sourceLength);
    const visibleList = createVisibleList(visibleItemsState, dimensions);
    const hiddenSize = createHiddenSize(visibleItemsState, dimensionSizes);
    const getDistance = createGetDistance(options, direction);
    const calculateRange = createCalculateRange({
        isPageMode,
        direction,
        hiddenSize,
        containerRef,
        listenerTargetRef,
        setScrollableElementDimension
    });
    const scrollTo = createScrollTo(direction, calculateRange, getDistance, listenerTargetRef);
    const scrollBy = createScrollBy(direction, calculateRange, listenerTargetRef);
    const { wrapperProps } = direction === RecycleScrollerDirection.VERTICAL ? useVerticalWrapperProps({
        options,
        visibleItemsState
    }, getDistance) : useHorizontalWrapperProps({
        options,
        visibleItemsState
    }, getDistance);
    useWatchForFixStartPosition(itemsByDirection, visibleItemsState, scrollBy);
    useWatchForSizes(listenerTargetRef, listenerTargetRef, calculateRange);
    return {
        list: visibleList,
        scrollTo,
        scrollBy,
        containerProps: {
            containerRef,
            listenerTargetRef,
            onScroll: ()=>{
                calculateRange();
            }
        },
        wrapperProps
    };
}
export function useVerticalRecycleScroller(props) {
    return useRecycleScroller(props, RecycleScrollerDirection.VERTICAL);
}
export function useHorizontalRecycleScroller(props) {
    return useRecycleScroller(props, RecycleScrollerDirection.HORIZONTAL);
}
