import { toRef, computed } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineRedirectInfoStore } from 'web/src/modules/sportline/submodules/redirects/store';
// could be passed through props
const allowedRedirectedRoutesList = [
    RouteName.SPORT_EVENT_DETAILS
];
export function useSportlineRedirectFromEmptyPageInfo() {
    const { $translate } = useI18n();
    const sportlineRedirectInfoStore = useSportlineRedirectInfoStore();
    const redirectedFromRoute = toRef(sportlineRedirectInfoStore, 'redirectedFromRoute');
    const isShown = computed(()=>!!redirectedFromRoute.value && allowedRedirectedRoutesList.includes(redirectedFromRoute.value));
    const title = computed(()=>{
        if (redirectedFromRoute.value === RouteName.SPORT_EVENT_DETAILS) return $translate('WEB2_REDIRECT_TO_LEAGUE_PAGE_INFO_EVENT_NOT_FOUND_TITLE').value;
        return '';
    });
    const description = computed(()=>{
        if (redirectedFromRoute.value === RouteName.SPORT_EVENT_DETAILS) // here could be different texts for different pages
        return $translate('WEB2_REDIRECT_TO_LEAGUE_PAGE_INFO_EVENT_NOT_FOUND').value;
        return '';
    });
    function clearInfo() {
        sportlineRedirectInfoStore.clearRedirectWasHandledInfo();
    }
    return {
        title,
        description,
        isShown,
        clearInfo
    };
}
