import { offsetKeys, viewCapacityKeys, elementPositionKeys } from '../constants';
import { getElementPosition } from './getElementPosition';
export function createCalculateRange(props) {
    const { isPageMode, direction, hiddenSize, containerRef, listenerTargetRef, setScrollableElementDimension } = props;
    const offsetKey = offsetKeys[direction];
    const viewCapacityKey = viewCapacityKeys[direction];
    const elementPositionKey = elementPositionKeys[direction];
    return ()=>{
        const scrollableElement = listenerTargetRef.value;
        const listElement = containerRef.value;
        if (!scrollableElement || !listElement) return;
        const elementPosition = getElementPosition(listElement, scrollableElement)[elementPositionKey];
        const gapBefore = isPageMode.value ? elementPosition - hiddenSize.value.start : 0;
        setScrollableElementDimension({
            scrollOffset: scrollableElement[offsetKey] - gapBefore,
            containerSize: scrollableElement[viewCapacityKey]
        });
    };
}
