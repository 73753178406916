import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportlineSportEventDetailsNavigationService } from 'web/src/modules/sportline/submodules/event-details/services/navigation';
let SportEventDetailsPagePrefetch = class SportEventDetailsPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        useSportlineSportEventDetailsNavigationService().prefetch(router, to, from, next);
        return Promise.resolve();
    }
};
export { SportEventDetailsPagePrefetch as default };
