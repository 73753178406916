import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import StatisticLeagueStandingBlock from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import LeaguePlayoff from 'web/src/modules/sportline/submodules/league-statistic/components/playoff/LeaguePlayoff.vue';
import LeagueMatchesStatistic from 'web/src/modules/sportline/submodules/league-statistic/components/LeagueMatchesStatistic.vue';
import LeagueTopPlayers from 'web/src/modules/sportline/submodules/league-statistic/components/topPlayers/LeagueTopPlayers.vue';
import LeagueMatchesResults from 'web/src/modules/sportline/submodules/league-statistic/components/LeagueMatchesResults.vue';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';
import EmptyLeaguePageContentInfo from 'web/src/modules/sportline/submodules/league/components/EmptyLeaguePageContentInfo.vue';
import { useLeaguePageStatistic } from './useLeaguePageStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePageStatistic',
    setup (__props) {
        const { leagueStandings, leagueMatchesStatistic, leagueMatchesResults, leaguePlayoff, leagueTopPlayers, isReady, isLoading, isEmpty } = useLeaguePageStatistic();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-league-statistic'])
            }, [
                !_unref(isReady) && _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                    key: 0
                })) : _createCommentVNode("", true),
                _unref(isReady) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-league-statistic__content-title'])
                    }, _toDisplayString(_ctx.$t('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-league-statistic__blocks'])
                    }, [
                        _unref(leagueMatchesStatistic) ? (_openBlock(), _createBlock(LeagueMatchesStatistic, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['sport-event-league-statistic__block']),
                            "league-matches-statistic": _unref(leagueMatchesStatistic)
                        }, null, 8, [
                            "class",
                            "league-matches-statistic"
                        ])) : _createCommentVNode("", true),
                        _unref(leagueMatchesResults) ? (_openBlock(), _createBlock(LeagueMatchesResults, {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['sport-event-league-statistic__block']),
                            "league-matches-results": _unref(leagueMatchesResults)
                        }, null, 8, [
                            "class",
                            "league-matches-results"
                        ])) : _createCommentVNode("", true),
                        _unref(leagueTopPlayers) ? (_openBlock(), _createBlock(LeagueTopPlayers, {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['sport-event-league-statistic__block']),
                            "league-top-players": _unref(leagueTopPlayers)
                        }, null, 8, [
                            "class",
                            "league-top-players"
                        ])) : _createCommentVNode("", true),
                        _unref(leagueStandings) && _unref(leagueStandings).length > 0 ? (_openBlock(), _createBlock(StatisticLeagueStandingBlock, {
                            key: 3,
                            class: _normalizeClass(_ctx.$style['sport-event-league-statistic__block']),
                            "league-standings": _unref(leagueStandings)
                        }, null, 8, [
                            "class",
                            "league-standings"
                        ])) : _createCommentVNode("", true),
                        _unref(leaguePlayoff) ? (_openBlock(), _createBlock(LeaguePlayoff, {
                            key: 4,
                            class: _normalizeClass(_ctx.$style['sport-event-league-statistic__block']),
                            "league-playoff": _unref(leaguePlayoff)
                        }, null, 8, [
                            "class",
                            "league-playoff"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _unref(isEmpty) && !_unref(isLoading) ? (_openBlock(), _createBlock(EmptyLeaguePageContentInfo, {
                        key: 0,
                        "section-id": _unref(LeaguePageSectionId).Statistic
                    }, null, 8, [
                        "section-id"
                    ])) : (_openBlock(), _createBlock(StatisticDisclaimer, {
                        key: 1
                    }))
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LeaguePageStatistic'
                ]
            ]);
        };
    }
});
