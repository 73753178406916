import { computed } from 'vue';
import { getItemSizeStyle } from 'web/src/components/RecycleScroller/utils/getItemSizeStyle';
export function createVisibleList(visibleItemsState, dimensions) {
    return computed(()=>{
        const visibleList = [];
        let itemOffsetX = 0;
        let itemOffsetY = 0;
        for (const [index, itemSize] of dimensions.value.entries()){
            const itemOffset = {
                width: itemOffsetX,
                height: itemOffsetY
            };
            itemOffsetX += itemSize.width ?? 0;
            itemOffsetY += itemSize.height ?? 0;
            const isOverScreen = index < visibleItemsState.value.start;
            if (isOverScreen && !itemSize.keepInDOM) continue;
            if (index >= visibleItemsState.value.end) break;
            const item = {
                size: itemSize,
                offset: itemOffset,
                style: getItemSizeStyle(itemSize, isOverScreen),
                index
            };
            visibleList.push(item);
        }
        return visibleList;
    });
}
