import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportlineRegionPageNavigationService } from 'web/src/modules/sportline/submodules/region/services/navigation/useSportlineRegionPageNavigationService';
let RegionPagePrefetch = class RegionPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        useSportlineRegionPageNavigationService().prefetch(router, to, from, next);
        return Promise.resolve();
    }
};
export { RegionPagePrefetch as default };
