import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSportlineLeaguePageNavigationService } from 'web/src/modules/sportline/submodules/league/services/navigation/useSportlineLeaguePageNavigationService';
let LeaguePagePrefetch = class LeaguePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        useSportlineLeaguePageNavigationService().prefetch(router, to, from, next);
        return Promise.resolve();
    }
};
export { LeaguePagePrefetch as default };
