import { computed, toRef } from 'vue';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { extractTopLeagues, sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';
export function useCybersportAllTab() {
    const sportlineCybersportStore = useSportlineCybersportStore();
    const sportsTreeElement = toRef(sportlineCybersportStore, 'sportsTreeElement');
    const { setOpenRegionState, isRegionOpen } = sportlineCybersportStore;
    const topLeagues = computed(()=>extractTopLeagues(sportsTreeElement.value).sort(sortTopLeagueElementsASC));
    const hasElementsForCurrentFilter = computed(()=>!!sportsTreeElement.value?.regions?.length);
    return {
        setOpenRegionState,
        isRegionOpen,
        topLeagues,
        sportsTreeElement,
        hasElementsForCurrentFilter
    };
}
