import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventDetailsHeadlineHolder',
    emits: [
        "visibility-changed"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const element = ref();
        let interactionEnabled = false;
        function checkVisibility(isIntersecting) {
            if (!interactionEnabled) return;
            emit('visibility-changed', isIntersecting);
        }
        useIntersectionObserver(element, checkVisibility, {
            rootMargin: '0px',
            threshold: 0.3
        });
        // start to emit events only after first full render
        useIntersectionObserver(element, (isIntersecting)=>{
            if (!isIntersecting) return;
            interactionEnabled = true;
        }, {
            rootMargin: '0px',
            threshold: 0.9
        }, true);
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "element",
                ref: element,
                class: _normalizeClass({
                    [_ctx.$style['sport-event-details-headline-holder']]: true,
                    [_ctx.$style['sport-event-details-headline-holder--large-gaps']]: void 0
                })
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'SportlineEventDetailsHeadlineHolder'
                ]
            ]);
        };
    }
});
