import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
export function useRegionHeadline(props) {
    const { regionId, customTitle, isComing } = props;
    const { $translate } = useI18n();
    const cacheStore = useSportlineCacheStorage();
    const sportlineCatalogClick = useSportlineCatalogClick({
        regionId
    });
    const regionCache = computed(()=>cacheStore.getRegionCache(regionId.value).value);
    const region = computed(()=>regionCache.value?.region ?? null);
    const title = computed(()=>{
        if (customTitle.value) return customTitle.value;
        if (isComing.value) return $translate('WEB2_COMING_EVENTS').value;
        if (region.value) return region.value.name;
        return null;
    });
    return {
        ...sportlineCatalogClick,
        title
    };
}
