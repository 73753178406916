import { computed, ref, toRef } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { createSportsListTab } from 'web/src/modules/sportline/submodules/sports/utils';
import { useShownSportFamily } from './useShownSportFamily';
export function useSportsPageCustomTabs(props) {
    const lastShownSportFamily = props?.lastShownSportFamily ? props.lastShownSportFamily : ref(null);
    const activeTransitionId = props?.activeTransitionId ? props.activeTransitionId : ref(null);
    const { isLoggedIn } = useIsLoggedIn();
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isZeroMarginEnabled = toRef(sportlineSettingsStore, 'isZeroMarginEnabled');
    const sportlineSportsStore = useSportlineSportsStore();
    const isZeroMarginEventsTabAvailable = toRef(sportlineSportsStore, 'isZeroMarginEventsTabAvailable');
    const customerFavoritesService = useCustomerFavoritesService();
    const { hasFavoritesOptimistic } = customerFavoritesService;
    const { selectedSportFamily, shownSportFamily } = useShownSportFamily({
        lastShownSportFamily
    });
    const favoritesTab = computed(()=>isLoggedIn.value ? createSportsListTab({
            sport: CustomFilter.Favorites,
            selectedSportFamily: selectedSportFamily.value,
            shownSportFamily: shownSportFamily.value,
            transitionId: activeTransitionId.value
        }) : null);
    const zeroMarginEventsTab = computed(()=>isZeroMarginEnabled.value ? createSportsListTab({
            sport: CustomFilter.ZeroMargin,
            selectedSportFamily: selectedSportFamily.value,
            shownSportFamily: shownSportFamily.value,
            transitionId: activeTransitionId.value
        }) : null);
    return {
        isZeroMarginEventsTabAvailable,
        hasFavoritesOptimistic,
        favoritesTab,
        zeroMarginEventsTab
    };
}
