import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "post-match-statistic-timeline"
};
const _hoisted_2 = {
    class: "post-match-statistic-timeline__team"
};
const _hoisted_3 = {
    class: "post-match-statistic-timeline__team-name"
};
const _hoisted_4 = {
    class: "post-match-statistic-timeline__timeline-container"
};
const _hoisted_5 = {
    class: "post-match-statistic-timeline__team"
};
const _hoisted_6 = {
    class: "post-match-statistic-timeline__team-name"
};
import { toRef } from 'vue';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import popperHint from 'web/src/components/Popper/directives/popperHint';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import StatisticTimelineIncidentIcon from 'web/src/modules/sportline/submodules/event-details/components/statistic/StatisticTimelineIncidentIcon.vue';
import usePostMatchStatisticTimeline from './usePostMatchStatisticTimeline';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticTimeline',
    props: {
        postMatchStatistic: {}
    },
    setup (__props) {
        const props = __props;
        const vPopperHint = popperHint;
        const { teams, times, onMouseDown } = usePostMatchStatisticTimeline({
            postMatchStatistic: toRef(props, 'postMatchStatistic')
        });
        // @hint break marks don't have translations (HT, FT, ET...)
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(teams) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(StatisticTeamLogo, {
                        class: "post-match-statistic-timeline__team-logo",
                        team: _unref(teams)[0]
                    }, null, 8, [
                        "team"
                    ]),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(teams)[0].name), 1)
                ]),
                _createElementVNode("div", {
                    ref: "container",
                    class: "post-match-statistic-timeline__scroll-wrap",
                    onMousedown: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onMouseDown) && _unref(onMouseDown)(...args);
                    })
                }, [
                    _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(times), (time, timeNumber)=>(_openBlock(), _createElementBlock("div", {
                                key: timeNumber,
                                class: _normalizeClass([
                                    "post-match-statistic-timeline__time",
                                    {
                                        'post-match-statistic-timeline__time--extra-time': time.type === _unref(TimelineMarkType).FULL_EXTRA_TIME
                                    }
                                ])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(time.marks, (mark, index)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                        mark.type === _unref(TimelineMarkType).INCIDENTS ? (_openBlock(), _createElementBlock("div", {
                                            key: `${mark.type}_${index}`,
                                            class: "post-match-statistic-timeline__timeline-mark post-match-statistic-timeline__timeline-mark--incident",
                                            style: _normalizeStyle({
                                                left: `${mark.position}%`
                                            })
                                        }, [
                                            _createTextVNode(_toDisplayString(Math.round(mark.time)) + "’ ", 1),
                                            mark.hostIncidents.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 0
                                            }, [
                                                (_openBlock(), _createElementBlock("div", {
                                                    key: `${mark.type}_${index}_arrow_host`,
                                                    class: "post-match-statistic-timeline__timeline-mark-arrow post-match-statistic-timeline__timeline-mark-arrow--host"
                                                })),
                                                _createElementVNode("div", {
                                                    class: "post-match-statistic-timeline__timeline-mark-icons post-match-statistic-timeline__timeline-mark-icons--host",
                                                    style: _normalizeStyle({
                                                        'margin-left': `-${8 + (mark.hostIncidents.length - 1) * 6}px`
                                                    })
                                                }, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mark.hostIncidents, (incident, incidentIndex)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                                                            key: `${incident.competitorType}_${incident.type}_${incidentIndex}_icon`,
                                                            class: "post-match-statistic-timeline__timeline-mark-icon",
                                                            style: _normalizeStyle({
                                                                'margin-left': incidentIndex > 0 ? '-4px' : '0'
                                                            })
                                                        }, [
                                                            _createVNode(StatisticTimelineIncidentIcon, {
                                                                incident: incident
                                                            }, null, 8, [
                                                                "incident"
                                                            ])
                                                        ], 4)), [
                                                            [
                                                                _unref(vPopperHint),
                                                                `${incident.label} ${incident.incomingPlayer.name || ''}`
                                                            ]
                                                        ])), 128))
                                                ], 4)
                                            ], 64)) : _createCommentVNode("", true),
                                            mark.guestIncidents.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 1
                                            }, [
                                                (_openBlock(), _createElementBlock("div", {
                                                    key: `${mark.type}_${index}_arrow_guest`,
                                                    class: "post-match-statistic-timeline__timeline-mark-arrow post-match-statistic-timeline__timeline-mark-arrow--guest"
                                                })),
                                                _createElementVNode("div", {
                                                    class: "post-match-statistic-timeline__timeline-mark-icons post-match-statistic-timeline__timeline-mark-icons--guest",
                                                    style: _normalizeStyle({
                                                        'margin-left': `-${8 + (mark.guestIncidents.length - 1) * 6}px`
                                                    })
                                                }, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mark.guestIncidents, (incident, incidentIndex)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                                                            key: `${incident.competitorType}_${incident.type}_${incidentIndex}_icon`,
                                                            class: "post-match-statistic-timeline__timeline-mark-icon",
                                                            style: _normalizeStyle({
                                                                'margin-left': incidentIndex > 0 ? '-4px' : '0'
                                                            })
                                                        }, [
                                                            _createVNode(StatisticTimelineIncidentIcon, {
                                                                incident: incident
                                                            }, null, 8, [
                                                                "incident"
                                                            ])
                                                        ], 4)), [
                                                            [
                                                                _unref(vPopperHint),
                                                                `${incident.label} ${incident.incomingPlayer.name || ''}`
                                                            ]
                                                        ])), 128))
                                                ], 4)
                                            ], 64)) : _createCommentVNode("", true)
                                        ], 4)) : _createCommentVNode("", true)
                                    ], 64))), 256)),
                                time.type === _unref(TimelineMarkType).HALF_TIME && timeNumber + 1 < _unref(times).length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: `${time.type}_${timeNumber}`,
                                    class: "post-match-statistic-timeline__timeline-mark post-match-statistic-timeline__timeline-mark--break",
                                    style: {
                                        left: '100%'
                                    }
                                }, _cache[1] || (_cache[1] = [
                                    _createTextVNode(" HT ")
                                ]))), [
                                    [
                                        _unref(vPopperHint),
                                        _ctx.$t('WEB2_MATCH_STATISTIC_HALF_TIME')
                                    ]
                                ]) : time.type === _unref(TimelineMarkType).FULL_TIME && timeNumber + 1 < _unref(times).length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: `${time.type}_${timeNumber}`,
                                    class: "post-match-statistic-timeline__timeline-mark post-match-statistic-timeline__timeline-mark--break",
                                    style: {
                                        left: '100%'
                                    }
                                }, _cache[2] || (_cache[2] = [
                                    _createTextVNode(" FT ")
                                ]))), [
                                    [
                                        _unref(vPopperHint),
                                        _ctx.$t('WEB2_MATCH_STATISTIC_FULL_TIME')
                                    ]
                                ]) : time.type === _unref(TimelineMarkType).FULL_EXTRA_TIME ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: `${time.type}_${timeNumber}`,
                                    class: "post-match-statistic-timeline__timeline-mark post-match-statistic-timeline__timeline-mark--break",
                                    style: {
                                        left: '50%'
                                    }
                                }, _cache[3] || (_cache[3] = [
                                    _createTextVNode(" ET ")
                                ]))), [
                                    [
                                        _unref(vPopperHint),
                                        _ctx.$t('WEB2_MATCH_STATISTIC_FULL_EXTRA_TIME')
                                    ]
                                ]) : _createCommentVNode("", true)
                            ], 2))), 128))
                    ])
                ], 544),
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(StatisticTeamLogo, {
                        class: "post-match-statistic-timeline__team-logo",
                        team: _unref(teams)[1]
                    }, null, 8, [
                        "team"
                    ]),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(teams)[1].name), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'PostMatchStatisticTimeline'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
