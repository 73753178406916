import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent } from 'vue';
import { logger } from '@leon-hub/logging';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { VEmpty } from 'web/src/components/Empty';
import MarketsList from 'web/src/modules/sportline/views/markets-list/MarketsList.vue';
import SportlineEventSuspendedInfo from 'web/src/modules/sportline/submodules/event-details/components/finished-info/SportlineEventSuspendedInfo.vue';
import SportEventStatistic from 'web/src/modules/sportline/submodules/event-details/views/SportEventStatistic.vue';
import SportlineEventPostMatchStatistic from 'web/src/modules/sportline/submodules/event-details/views/SportlineEventPostMatchStatistic.vue';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import { useSportlineEventDetailsContent } from './useSportlineEventDetailsContent';
import SportEventDetailsHeadlineHolder from './headlline/SportlineEventDetailsHeadlineHolder.vue';
import WideSportlineEventDetailsHeadline from './headlline/WideSportlineEventDetailsHeadline.vue';
import SwiperSportlineEventDetailsHeadline from './headlline/SwiperSportlineEventDetailsHeadline.vue';
import ClosedSportlineEventDetailsHeadline from './headlline/ClosedSportlineEventDetailsHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportlineEventDetailsContent',
    setup (__props) {
        const SportEventRelatedList = // eslint-disable-next-line unicorn/prefer-module,max-len
        defineAsyncComponent(()=>import('web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsTable.vue'));
        // eslint-disable-next-line unicorn/prefer-module,max-len
        const SportEventDetailsToolbar = // eslint-disable-next-line unicorn/prefer-module,max-len
        require('web/src/modules/sportline/submodules/event-details/views/toolbar/SportEventDetailsToolbarDesktop.vue').default;
        // eslint-disable-next-line unicorn/prefer-module,max-len
        const SportlineEventBreadcrumbs = // eslint-disable-next-line unicorn/prefer-module
        require('web/src/modules/sportline/submodules/event-details/views/SportlineEventBreadcrumbs.vue').default;
        const { isMarketGroupsTabsEnabled, isLoaded, isShowingLoadingIndicator, sportlineEvent, relatedSportListElement, leagueEventsList, defaultLayoutType, hasStatistic, hasPostMatchStatistic, sport, activeEventId, isSportlineEventExist, isSportlineEventClosed, isSportlineEventSuspended, isRelatedEventsAvailable, suspendedLabel, onHeadlineVisibilityChanged, onLayoutTypeChanged } = useSportlineEventDetailsContent();
        const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['sportline-event-content__breadcrumbs-holder'])
                    }, [
                        _createVNode(_unref(SportlineEventBreadcrumbs))
                    ], 2)),
                    _createVNode(_unref(SportEventDetailsToolbar), {
                        "has-related-events": _unref(isSportlineEventExist) && _unref(isRelatedEventsAvailable)
                    }, {
                        relatedList: _withCtx((param)=>{
                            let { hide } = param;
                            return [
                                _unref(isLoaded) ? (_openBlock(), _createBlock(_unref(SportEventRelatedList), {
                                    key: 0,
                                    "sport-element": _unref(relatedSportListElement),
                                    "active-id": _unref(activeEventId),
                                    onClickEvent: hide
                                }, null, 8, [
                                    "sport-element",
                                    "active-id",
                                    "onClickEvent"
                                ])) : _createCommentVNode("", true)
                            ];
                        }),
                        _: 1
                    }, 8, [
                        "has-related-events"
                    ])
                ], 64)),
                _unref(isSportlineEventExist) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _unref(isSportlineEventClosed) ? (_openBlock(), _createBlock(ClosedSportlineEventDetailsHeadline, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['sportline-event-content__post-match-statistic-headline'])
                    }, null, 8, [
                        "class"
                    ])) : (_openBlock(), _createBlock(SportEventDetailsHeadlineHolder, {
                        key: 1,
                        onVisibilityChanged: _unref(onHeadlineVisibilityChanged)
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(), _createBlock(WideSportlineEventDetailsHeadline, {
                                    key: 0
                                }))
                            ]),
                        _: 1
                    }, 8, [
                        "onVisibilityChanged"
                    ])),
                    _unref(isSportlineEventSuspended) ? (_openBlock(), _createBlock(SportlineEventSuspendedInfo, {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['sportline-event-content__suspended-info']),
                        label: _unref(suspendedLabel),
                        "is-paused": !_unref(isSportlineEventClosed),
                        "is-short": ""
                    }, null, 8, [
                        "class",
                        "label",
                        "is-paused"
                    ])) : _createCommentVNode("", true),
                    _createVNode(MarketsList, {
                        "sport-event": _unref(sportlineEvent),
                        "representation-sport-family": _unref(sport)?.representation.family,
                        "default-layout-type": _unref(defaultLayoutType),
                        "is-loaded": _unref(isLoaded),
                        "is-showing-loading-indicator": _unref(isShowingLoadingIndicator),
                        "is-market-groups-tabs-enabled": _unref(isMarketGroupsTabsEnabled),
                        "is-layout-type-change-enabled": "",
                        "has-statistic": _unref(hasStatistic),
                        onLayoutTypeChanged: _unref(onLayoutTypeChanged)
                    }, _createSlots({
                        _: 2
                    }, [
                        _unref(hasStatistic) ? {
                            name: "statistic",
                            fn: _withCtx(()=>[
                                    _createVNode(SportEventStatistic, {
                                        class: _normalizeClass(_ctx.$style['sportline-event-content__statistic'])
                                    }, null, 8, [
                                        "class"
                                    ])
                                ]),
                            key: "0"
                        } : void 0,
                        _unref(isSportlineEventSuspended) && _unref(hasPostMatchStatistic) ? {
                            name: "post-statistic",
                            fn: _withCtx(()=>[
                                    _createVNode(SportlineEventPostMatchStatistic)
                                ]),
                            key: "1"
                        } : void 0
                    ]), 1032, [
                        "sport-event",
                        "representation-sport-family",
                        "default-layout-type",
                        "is-loaded",
                        "is-showing-loading-indicator",
                        "is-market-groups-tabs-enabled",
                        "has-statistic",
                        "onLayoutTypeChanged"
                    ])
                ], 64)) : _unref(hasPostMatchStatistic) ? (_openBlock(), _createBlock(SportlineEventPostMatchStatistic, {
                    key: 3,
                    "is-display-headline": ""
                })) : (_openBlock(), _createBlock(SportlineEventSuspendedInfo, {
                    key: 4,
                    class: _normalizeClass(_ctx.$style['sportline-event-content__suspended-info'])
                }, null, 8, [
                    "class"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'SportlineEventDetailsContent'
                ]
            ]);
        };
    }
});
