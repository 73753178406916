import { computed } from 'vue';
import { useHeadlineInplayComponentShowState } from './composables';
export function useWideSportlineEventDetailsHeadline(props) {
    const { doForceShowInplayComponent, activeSlideId, isLiveWidgetAvailable, isStreamAvailable, widgetConfig } = props;
    const { isLiveWidgetShown, isStreamShown } = useHeadlineInplayComponentShowState({
        doForceShowInplayComponent,
        activeSlideId,
        isLiveWidgetAvailable,
        isStreamAvailable
    });
    const isFloatingModeEnabled = computed(()=>isStreamShown.value && !!widgetConfig.value?.liveStreamWidgetFloatingEnabled || isLiveWidgetShown.value && !!widgetConfig.value?.sportRadarWidgetFloatingEnabled);
    return {
        isFloatingModeEnabled,
        isLiveWidgetShown,
        isStreamShown
    };
}
