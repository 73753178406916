import { computed } from 'vue';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
export function useLoadRegionConfig(props) {
    const { to, sportlineNavigationBetweenPagesFilter } = props;
    const navigationParameters = computed(()=>getSportPageNavigationParameters(to.value));
    const filter = computed(()=>sportlineNavigationBetweenPagesFilter.value ?? void 0);
    const regionConfig = computed(()=>({
            id: navigationParameters.value.regionId,
            filter: filter.value
        }));
    return {
        regionConfig
    };
}
