import { ref, watch, computed } from 'vue';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
export function useFloatingWidgetBox(props) {
    const { isFloatingModeEnabled } = props;
    const bus = useEventsBus();
    let hasScrollListener = false;
    const wrapper = ref();
    const doFixPosition = ref(false);
    const isSmallFrameClosed = ref(false);
    const isPositionFixed = computed(()=>doFixPosition.value && !isSmallFrameClosed.value);
    function onScroll() {
        const el = wrapper.value?.parentElement;
        if (!el) return;
        // @TODO: Remove expensive (relayout) getBoundingClientRect and watch parent element content and attrs change with
        // @TODO: observer, and recalculate only on change?
        const { top, height } = el.getBoundingClientRect();
        if (top > 0) isSmallFrameClosed.value = false;
        const spaceTop = 56;
        doFixPosition.value = height + top < spaceTop;
    }
    function closeFrame() {
        isSmallFrameClosed.value = true;
    }
    function addScrollListener() {
        if (!isFloatingModeEnabled.value) return;
        if (hasScrollListener) return;
        bus.on(BusEvent.LAYOUT_CONTENT_SCROLL, onScroll);
        hasScrollListener = true;
    }
    function removeScrollListener() {
        if (!hasScrollListener) return;
        bus.off(BusEvent.LAYOUT_CONTENT_SCROLL, onScroll);
        hasScrollListener = false;
    }
    watch(isFloatingModeEnabled, (value)=>{
        if (value) addScrollListener();
        else removeScrollListener();
    });
    return {
        wrapper,
        isPositionFixed,
        closeFrame,
        addScrollListener,
        removeScrollListener
    };
}
