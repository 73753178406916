import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
import { useWidgetSwitcher } from './useWidgetSwitcher';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinWidgetSwitcher',
    props: {
        activeSlideId: {},
        slideIds: {}
    },
    emits: [
        "update:activeSlideId"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slideIds = toRef(props, 'slideIds');
        const activeSlideId = toRef(props, 'activeSlideId');
        function emitSetActiveSlide(value) {
            emit('update:activeSlideId', value);
        }
        const { isStreamAvailable, isLiveWidgetAvailable, setActiveSlide } = useWidgetSwitcher({
            slideIds,
            activeSlideId,
            emitSetActiveSlide
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isStreamAvailable) && _unref(isLiveWidgetAvailable) ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: _normalizeClass(_ctx.$style['pin-widget-switcher']),
                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(setActiveSlide)(activeSlideId.value === _unref(HeadlineSlideName).LiveStream ? _unref(HeadlineSlideName).LiveWidget : _unref(HeadlineSlideName).LiveStream))
            }, [
                activeSlideId.value === _unref(HeadlineSlideName).LiveStream ? (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 0,
                    name: _unref(IconName).FIELD,
                    size: _unref(IconSize).SIZE_16
                }, null, 8, [
                    "name",
                    "size"
                ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 1,
                    name: _unref(IconName).STREAM,
                    size: _unref(IconSize).SIZE_16
                }, null, 8, [
                    "name",
                    "size"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinWidgetSwitcher'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
