import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import SportlineFavoritesTabList from 'web/src/modules/sportline/submodules/favorites/views/default/SportlineFavoritesTabList.vue';
import { isSportEventsSportsDateTimeFilterChanged } from 'web/src/modules/sportline/submodules/sports/utils';
import SportsFavoriteRegionsFilter from './SportsFavoriteRegionsFilter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsFavoritesTab',
    props: {
        isActive: {
            type: Boolean
        }
    },
    emits: [
        "redirect-from-tab",
        "reset-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const sportlineSportsStore = useSportlineSportsStore();
        const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
        const selectedRegionFamily = toRef(sportlineSportsStore, 'favoriteSelectedRegionFamily');
        const favoriteEventsList = toRef(sportlineSportsStore, 'favoriteEventsListForDisplay');
        const { setFavoriteSelectedRegionFamily } = sportlineSportsStore;
        const isSomeFilterSelected = computed(()=>isSportEventsSportsDateTimeFilterChanged(sportsListFilter.value, defaultListFilter));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(SportsFavoriteRegionsFilter, {
                    class: _normalizeClass(_ctx.$style['sports-favorites-region-filter']),
                    "selected-region-family": selectedRegionFamily.value,
                    onSelectRegionFamily: _unref(setFavoriteSelectedRegionFamily)
                }, null, 8, [
                    "class",
                    "selected-region-family",
                    "onSelectRegionFamily"
                ]),
                _createVNode(SportlineFavoritesTabList, {
                    "is-active": _ctx.isActive,
                    "favorite-events-list": favoriteEventsList.value,
                    "selected-region-family": selectedRegionFamily.value,
                    "is-some-filter-selected": isSomeFilterSelected.value,
                    "default-expand-league-key": "favorite-all-league",
                    "default-expand-sport-key": "favorite-all-sport",
                    onRedirectFromTab: _cache[0] || (_cache[0] = ($event)=>emit('redirect-from-tab')),
                    onResetFilter: _cache[1] || (_cache[1] = ($event)=>emit('reset-filter'))
                }, null, 8, [
                    "is-active",
                    "favorite-events-list",
                    "selected-region-family",
                    "is-some-filter-selected"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportsFavoritesTab'
                ]
            ]);
        };
    }
});
