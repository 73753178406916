import { computed } from 'vue';
function calculateInternalOffset(scrollOffset, sizes) {
    let sum = 0;
    let offset = 0;
    for (const [index, size] of sizes.entries()){
        if (sum <= scrollOffset) offset = index;
        if (sum + size > scrollOffset) break;
        sum += size;
    }
    return {
        index: offset,
        value: sum
    };
}
/**
 * basically, it returns start index for render list
 */ export function createVisibleItemsOffset(scrollOffset, sizes) {
    const internalOffset = computed(()=>calculateInternalOffset(scrollOffset.value, sizes.value));
    return {
        index: computed(()=>internalOffset.value.index),
        value: computed(()=>internalOffset.value.value)
    };
}
