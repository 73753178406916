import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Teleport as _Teleport, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef, onMounted, onUnmounted } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useFloatingWidgetBox } from './useFloatingWidgetBox';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FloatingWidgetBox',
    props: {
        isFloatingModeEnabled: {
            type: Boolean
        },
        isStream: {
            type: Boolean
        },
        isFullSize: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const isStream = toRef(props, 'isStream', false);
        const isFloatingModeEnabled = toRef(props, 'isFloatingModeEnabled', false);
        const isFullSize = toRef(props, 'isFullSize', false);
        const { wrapper, isPositionFixed, closeFrame, addScrollListener, removeScrollListener } = useFloatingWidgetBox({
            isFloatingModeEnabled
        });
        onMounted(addScrollListener);
        onUnmounted(removeScrollListener);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass({
                    [_ctx.$style['floating-widget-box__wrapper']]: true,
                    [_ctx.$style['floating-widget-box__wrapper--full-size']]: isFullSize.value
                })
            }, [
                _unref(isPositionFixed) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 1,
                    to: "body"
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['floating-widget-box']]: true,
                            [_ctx.$style['floating-widget-box--fixed']]: true,
                            [_ctx.$style['floating-widget-box--stream']]: isStream.value
                        })
                    }, [
                        _renderSlot(_ctx.$slots, "default"),
                        _createElementVNode("div", {
                            onClick: _cache[0] || (_cache[0] = //@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(closeFrame) && _unref(closeFrame)(...args);
                            })
                        }, [
                            _unref(isPositionFixed) ? (_openBlock(), _createBlock(_unref(VIcon), {
                                key: 0,
                                name: _unref(IconName).CROSS,
                                size: _unref(IconSize).SIZE_12,
                                class: _normalizeClass(_ctx.$style['floating-widget-box__icon-close'])
                            }, null, 8, [
                                "name",
                                "size",
                                "class"
                            ])) : _createCommentVNode("", true)
                        ])
                    ], 2)
                ])) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['floating-widget-box']]: true,
                        [_ctx.$style['floating-widget-box--stream']]: isStream.value,
                        [_ctx.$style['floating-widget-box--full-size']]: isFullSize.value
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'FloatingWidgetBox'
                ]
            ]);
        };
    }
});
