import { provide } from 'vue';
import { useExpandedSportsLocalStorageRef } from './useExpandSportBlockLocalStorage';
import { ProvideExpandedBlockMapKey, ProvideSetExpandedBlockStateKey } from '../constants';
import { applyExpandedStatePayload } from '../utils';
export function useProvideIsExpandedSportlineBlock(props) {
    const { defaultValueRef } = props;
    const expandedStateMap = useExpandedSportsLocalStorageRef();
    function setExpandedState(payload) {
        expandedStateMap.value = applyExpandedStatePayload(payload, expandedStateMap.value, defaultValueRef);
    }
    provide(ProvideExpandedBlockMapKey, expandedStateMap);
    provide(ProvideSetExpandedBlockStateKey, setExpandedState);
    return {
        expandedStateMap,
        setExpandedState
    };
}
