import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import LeagueHeadline from 'web/src/modules/sportline/views/headline/LeagueHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualListRendererLeagueHeadline',
    props: {
        element: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(LeagueHeadline, {
                class: _normalizeClass(_ctx.$style['league-headline']),
                sport: _ctx.element.sport,
                region: _ctx.element.region,
                "league-element": _ctx.element.leagueElement,
                "basis-key": _ctx.element.basisKey
            }, null, 8, [
                "class",
                "sport",
                "region",
                "league-element",
                "basis-key"
            ])), [
                [
                    _directive_auto_id,
                    'VirtualListRendererLeagueHeadline'
                ]
            ]);
        };
    }
});
