import { computed } from 'vue';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
export function useHeadlineLayoutExtendedHeight(props) {
    const { sport } = props;
    const isExtendedHeight = computed(()=>isSportFamilyEquals(sport.value?.representation.family, SportFamily.VirtualCricket));
    return {
        isExtendedHeight
    };
}
