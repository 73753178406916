import { computed } from 'vue';
import { calculateTeamPlayStatistic } from 'web/src/modules/sportline/utils/rest/statistic';
export function useH2hMatchStatistic(props) {
    const h2hMatchStatistic = computed(()=>calculateTeamPlayStatistic(props.betlineMatchStatistics.value?.scores24Head2Head));
    const joinMatches = computed(()=>[
            ...props.betlineMatchStatistics.value?.joinMatches ?? []
        ]);
    return {
        h2hMatchStatistic,
        joinMatches
    };
}
