import { reactive, watch, toRef } from 'vue';
export function useVirtualListEventElementResize(props) {
    const expandedIds = toRef(props.expandedIds);
    const customEventHeightMap = reactive({});
    function onResizeEventElement(payload) {
        customEventHeightMap[payload.sportlineEventId] = payload.height;
    }
    function clearCollapsedEventsHeights() {
        const idMap = new Set(expandedIds.value);
        for (const key of Object.keys(customEventHeightMap)){
            if (!idMap.has(key)) delete customEventHeightMap[key];
        }
    }
    watch(expandedIds, clearCollapsedEventsHeights);
    return {
        customEventHeightMap,
        onResizeEventElement
    };
}
