import { computed, toRef } from 'vue';
import { replace } from '@leon-hub/utils';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { LSportsFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
export function useLSportsWidget(props) {
    const { sportlineEvent } = props;
    const lang = toRef(useI18nStore(), 'lang');
    const lSportsWidgetConfig = toRef(useSportlineWidgetsStore(), 'lsportsConfigFinal');
    const matchId = computed(()=>sportlineEvent.value.liveWidget?.id ?? '');
    const isVirtual = computed(()=>!!sportlineEvent.value.liveWidget?.isVirtual);
    const productionUrl = computed(()=>{
        const baseUrl = replace(`${lSportsWidgetConfig.value?.productionUrl}`, {
            eventId: matchId.value
        });
        const parameters = [
            `language=${lSportsWidgetConfig.value?.customLang || lang.value}`
        ];
        if (isVirtual.value) parameters.push('virtual=true');
        return `${baseUrl}&${parameters.join('&')}`;
    });
    const iframeWidgetSrc = productionUrl;
    const additionalQueryStringParameters = computed(()=>[
            {
                [LSportsFramedWidgetProperties.EncodedUrl]: encodeURIComponent(productionUrl.value)
            }
        ]);
    return {
        iframeWidgetSrc,
        additionalQueryStringParameters
    };
}
