import { RecycleScrollerDirection } from '../enums';
/**
 * Item size normalization
 * This could be a function before,
 * but it is a good idea to keep a method to convert options into an internal value
 */ export function convertEdgeToItemSize(itemSize) {
    return itemSize;
}
export function getItemOptionsSizeValue(param) {
    let { width, height } = param, direction = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : RecycleScrollerDirection.VERTICAL;
    return direction === RecycleScrollerDirection.VERTICAL ? convertEdgeToItemSize(height ?? 0) : convertEdgeToItemSize(width ?? 0);
}
export function getTotalItemOptionsSizeValue(source) {
    let direction = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : RecycleScrollerDirection.VERTICAL;
    return source.reduce((sum, item)=>sum + getItemOptionsSizeValue(item, direction), 0);
}
