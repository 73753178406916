import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef, useSlots } from 'vue';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { SportlineType } from 'web/src/modules/sportline/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
import { useOutrightInfoHeadline } from '../../composables';
import HeadlineLiveTimeInfo from '../HeadlineLiveTimeInfo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OutrightInfo',
    props: {
        backgroundColor: {},
        sport: {},
        leagueRepresentationData: {},
        sportlineEvent: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const backgroundColor = toRef(props, 'backgroundColor');
        const sport = toRef(props, 'sport', null);
        const leagueRepresentationData = toRef(props, 'leagueRepresentationData', null);
        const slots = useSlots();
        const hasPrimaryMarket = computed(()=>hasSlot(slots, 'primary-market'));
        const { sportlineType } = useSportlineEventType({
            sportEvent: sportlineEvent
        });
        const { championshipName, outrightName, date, time } = useOutrightInfoHeadline({
            sport,
            sportlineEvent,
            leagueRepresentationData
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['outright-headline-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['outright-headline-info__main-content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['outright-headline-info__championship'])
                    }, _toDisplayString(_unref(championshipName)), 3),
                    _createElementVNode("h2", {
                        class: _normalizeClass({
                            [_ctx.$style['outright-headline-info__primary-market-name']]: true,
                            [_ctx.$style['outright-headline-info__primary-market-name--colored']]: !!backgroundColor.value
                        })
                    }, _toDisplayString(_unref(outrightName)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['outright-headline-date'])
                    }, [
                        _unref(sportlineType) === _unref(SportlineType).Live ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['outright-headline-date__live-indicator'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['sport-event-badge'])
                                }, "Live", 2)
                            ], 2),
                            _createVNode(HeadlineLiveTimeInfo, {
                                class: _normalizeClass(_ctx.$style['outright-headline-date__live-time']),
                                "sportline-event": sportlineEvent.value
                            }, null, 8, [
                                "class",
                                "sportline-event"
                            ])
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['outright-headline-date__kickoff-date'])
                            }, _toDisplayString(_unref(date)), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['outright-headline-date__kickoff-time'])
                            }, _toDisplayString(_unref(time)), 3)
                        ], 64))
                    ], 2),
                    hasPrimaryMarket.value ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['outright-headline-info__primary-market'])
                    }, [
                        _renderSlot(_ctx.$slots, "primary-market")
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'OutrightInfo'
                ]
            ]);
        };
    }
});
