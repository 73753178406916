import { VirtualListRendererElementSize } from 'web/src/modules/sportline/views/virtual-list-renderer/constants';
import { getEventVirtualElementId } from './getElementId';
export function createGetEventElementSize(param) {
    let { expandedIds, expandedHeights } = param;
    return (element)=>{
        const { sportlineEvent, isLastEventInLeague, isLastLeagueInRegion, isLastRegionInSport, isLastSport } = element;
        const { id } = sportlineEvent.sportEvent;
        const customHeight = expandedHeights.value[id];
        const isExtended = expandedIds.value.includes(id);
        const sportsGap = isLastSport ? void 0 : VirtualListRendererElementSize.SportsGap;
        const regionsGap = isLastRegionInSport ? void 0 : VirtualListRendererElementSize.RegionsGap;
        const leaguesGap = isLastLeagueInRegion ? void 0 : VirtualListRendererElementSize.LeaguesGap;
        const eventsGap = isLastEventInLeague ? void 0 : VirtualListRendererElementSize.EventsGap;
        const gap = eventsGap ?? leaguesGap ?? regionsGap ?? sportsGap ?? 0;
        return {
            id: getEventVirtualElementId(sportlineEvent),
            height: customHeight ?? VirtualListRendererElementSize.EventHeight + gap,
            keepInDOM: isExtended
        };
    };
}
