import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';
export function useCybersportNavigationSectionActivation() {
    const { setSportlineNavigationSection } = useSportlineNavigationStore();
    onComponentActivated(()=>{
        setSportlineNavigationSection(SportlineNavigationSection.CyberSport);
    });
    onComponentDeactivated(()=>{
        setSportlineNavigationSection(null);
    });
}
