import minBy from 'lodash/minBy';
import { ref, toRef, computed, watch } from 'vue';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useSportlineWidgetsStore, useSportlineWidgetsDebugStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { IframeWidgetPageName } from 'web/src/modules/framed-app/utils';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { SportradarFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import SportradarLiveMatchTrackerPostMessageEvent, { PostMessageBusInitiator } from 'web/src/modules/framed-app/components/SportradarLiveMatchTrackerFramedWidget/utils/SportradarLiveMatchTrackerPostMessageEvent';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import { concatUrlPathNames } from 'web/src/utils/url';
export default function useSportradarLiveMatchTrackerWidget(props, emits) {
    const { sportEventDetails, isActive } = props;
    const { theme } = useTheme();
    const lang = toRef(useI18nStore(), 'lang');
    const sportradarLiveMatchTrackerConfig = toRef(useSportlineWidgetsStore(), 'sportradarLiveMatchTrackerConfigFinal');
    const sportlineWidgetsDebug = useSportlineWidgetsDebugStore();
    const debugIframeUrl = toRef(sportlineWidgetsDebug, 'iframeUrl');
    let postMessageBus = null;
    const iframeName = IframeWidget.SportradarLiveMatchTracker;
    /** Used for get next iframe window on rerender component, actually change name for current iframe after init */ const widgetLoaded = ref(false);
    const readyToLoading = ref(false);
    const showFailureMessage = ref(false);
    const matchId = computed(()=>sportEventDetails.value.liveWidget?.id || '');
    const widgetProperties = computed(()=>{
        const { liveWidget } = sportEventDetails.value;
        const sportWidgets = (sportradarLiveMatchTrackerConfig.value?.widgets ?? []).filter((param)=>{
            let { active, sport } = param;
            return active && sport === liveWidget?.sportFamily;
        });
        return sportWidgets.length > 0 ? minBy(sportWidgets, 'order') : sportWidgets[0];
    });
    const widgetSportFamily = computed(()=>sportEventDetails.value.liveWidget?.sportFamily ?? sportEventDetails.value.sport.representation.family);
    const iframeWidgetSrc = computed(()=>{
        const baseUrl = debugIframeUrl.value ?? sportradarLiveMatchTrackerConfig.value?.iframeUrl ?? IframeWidgetPageName;
        return concatUrlPathNames(baseUrl, theme.value.toLowerCase(), IframeWidget.SportradarLiveMatchTracker);
    });
    const additionalQueryStringParameters = computed(()=>[
            {
                [SportradarFramedWidgetProperties.MATCH_ID]: matchId.value
            },
            {
                [SportradarFramedWidgetProperties.TYPE]: widgetProperties.value?.name || ''
            },
            {
                [SportradarFramedWidgetProperties.NAME]: widgetProperties.value?.name || ''
            },
            {
                [SportradarFramedWidgetProperties.LANGUAGE]: sportradarLiveMatchTrackerConfig.value?.customLang || lang.value
            },
            {
                [SportradarFramedWidgetProperties.THEME]: sportradarLiveMatchTrackerConfig.value?.theme || ''
            },
            {
                [SportradarFramedWidgetProperties.ENCODED_URL]: encodeURIComponent(sportradarLiveMatchTrackerConfig.value?.productionUrl || '')
            },
            {
                [SportradarFramedWidgetProperties.SPORT_FAMILY]: widgetSportFamily.value
            }
        ]);
    function onIframeMounted() {
        widgetLoaded.value = false;
        if (postMessageBus) {
            postMessageBus?.dispose();
            postMessageBus = null;
        }
        const iframeContentWindow = requireContentWindowByIFrameName(iframeName);
        postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: PostMessageBusInitiator
        });
        postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.mounted, ()=>{
            const rawConfig = widgetProperties.value?.config || '{}';
            const config = JSON.parse(rawConfig);
            const initOptions = {
                id: matchId.value,
                config
            };
            postMessageBus?.emit(SportradarLiveMatchTrackerPostMessageEvent.init, initOptions);
        });
        postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.loaded, ()=>{
            widgetLoaded.value = true;
        });
        postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetLoadError, ()=>{
            // eslint-disable-next-line no-console
            console.error('Live match tracker widget load error');
            widgetLoaded.value = false;
            showFailureMessage.value = true;
        });
        postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetLoadTimeout, ()=>{
            // eslint-disable-next-line no-console
            console.error('Live match tracker widget load timeout');
            widgetLoaded.value = false;
            showFailureMessage.value = true;
        });
        postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetResized, (param)=>{
            let { height } = param;
            emits.onSizeChanged?.({
                height
            });
        });
    }
    watch(isActive, (to)=>{
        if (to) readyToLoading.value = true;
    }, {
        immediate: true
    });
    watch(widgetLoaded, (to)=>{
        if (to) showFailureMessage.value = false;
    });
    function releaseMessageBus() {
        postMessageBus?.dispose();
        postMessageBus = null;
    }
    return {
        widgetLoaded,
        readyToLoading,
        showFailureMessage,
        iframeWidgetSrc,
        iframeName,
        additionalQueryStringParameters,
        onIframeMounted,
        releaseMessageBus
    };
}
