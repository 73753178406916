import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
let BaseSportEventDetailsPageNavigationService = class BaseSportEventDetailsPageNavigationService {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const navigationParameters = getSportPageNavigationParameters(to);
        if (!isValidSportEventsPageIdentifier(navigationParameters)) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        const { isDevIP } = useIsDevIP();
        const statisticId = isDevIP.value && isString(to.query?.statisticId) ? to.query?.statisticId : void 0;
        useSportlineEventDetailsStore().setPageIdentifier({
            ...navigationParameters,
            statisticId
        });
        useSportlineEventStatisticStore().setPageIdentifier({
            ...navigationParameters,
            statisticId
        });
        useSportlineBreadcrumbsStore().setPageIdentifier({
            ...navigationParameters,
            statisticId
        });
        useSportlineWidgetsStore().fetchSportlineConfigs();
        next();
    }
};
/**
 * Default details page navigation logic
 * Load data and handle navigation parameters
 */ export { BaseSportEventDetailsPageNavigationService as default };
