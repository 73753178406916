import { ref, shallowRef, computed } from 'vue';
import { getItemOptionDimensions } from '../utils';
export function useRecycleScrollerResources(props) {
    const { options } = props;
    const containerRef = ref(null);
    const listenerTargetRef = ref(null);
    const source = shallowRef(options);
    const dimensions = computed(()=>getItemOptionDimensions(source.value));
    const isPageMode = computed(()=>containerRef.value !== listenerTargetRef.value);
    const sourceLength = computed(()=>source.value.length);
    /** Refs for element dimensions calculated on scroll */ const scrollOffset = ref(0);
    const containerSize = ref(0);
    return {
        scrollOffset,
        containerSize,
        source,
        dimensions,
        containerRef,
        listenerTargetRef,
        isPageMode,
        sourceLength,
        setScrollableElementDimension (value) {
            scrollOffset.value = value.scrollOffset;
            containerSize.value = value.containerSize;
        }
    };
}
