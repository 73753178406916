import { computed, toRef } from 'vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
export function useShownSportFamily(props) {
    const { lastShownSportFamily } = props;
    const sportlineSportsStore = useSportlineSportsStore();
    const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
    const selectedSportFamily = computed(()=>selectedSportFilter.value.sportFamily);
    // show last tab for case when active tab changed but transition is not start yet
    // for avoid extra mount for tab component
    const shownSportFamily = computed(()=>lastShownSportFamily.value ?? selectedSportFamily.value);
    return {
        selectedSportFamily,
        shownSportFamily
    };
}
