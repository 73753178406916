import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 6
};
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { IncidentType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticTimelineIncidentIcon',
    props: {
        incident: {}
    },
    setup (__props) {
        const cardIconProperties = {
            name: IconName.SOCCER_CARD,
            size: IconSize.SIZE_16
        };
        const goalIconProperties = {
            name: IconName.SPORT_SOCCER,
            size: IconSize.SIZE_16
        };
        const substitutionIconProperties = {
            name: IconName.GENERATE_CODE,
            size: IconSize.SIZE_16
        };
        return (_ctx, _cache)=>_ctx.incident.type === _unref(IncidentType).CARD_RED ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 0,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--card-red"
            }, cardIconProperties), null, 16)) : _ctx.incident.type === _unref(IncidentType).CARD_YELLOW ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 1,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--card-yellow"
            }, cardIconProperties), null, 16)) : _ctx.incident.type === _unref(IncidentType).GOAL ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 2,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--goal"
            }, goalIconProperties), null, 16)) : _ctx.incident.type === _unref(IncidentType).OWN_GOAL ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 3,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--own-goal"
            }, goalIconProperties), null, 16)) : _ctx.incident.type === _unref(IncidentType).DISABLED_GOAL ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 4,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--disabled-goal"
            }, goalIconProperties), null, 16)) : _ctx.incident.type === _unref(IncidentType).SUBSTITUTION ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                key: 5,
                class: "statistic-timeline-incident-icon statistic-timeline-incident-icon--substitution"
            }, substitutionIconProperties), null, 16)) : (_openBlock(), _createElementBlock("div", _hoisted_1));
    }
});
