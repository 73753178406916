import { computed } from 'vue';
import { RecycleScrollerDirection } from '../enums';
import { convertEdgeToItemSize } from './getItemSizeValue';
export function getItemOptionDimensions(options) {
    return options.map((param)=>{
        let { id, width, height, keepInDOM } = param;
        return {
            id,
            width: width ? convertEdgeToItemSize(width) : void 0,
            height: height ? convertEdgeToItemSize(height) : void 0,
            keepInDOM
        };
    });
}
export function getDimensionSizeForDirection(dimension) {
    let direction = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : RecycleScrollerDirection.VERTICAL;
    return direction === RecycleScrollerDirection.VERTICAL ? dimension.height ?? 0 : dimension.width ?? 0;
}
export function createListItemsByDirection(dimensions) {
    let direction = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : RecycleScrollerDirection.VERTICAL;
    return computed(()=>dimensions.value.map((dimension)=>({
                id: dimension.id,
                size: getDimensionSizeForDirection(dimension, direction),
                keepInDOM: dimension.keepInDOM
            })));
}
export function createDimensionSizesRefForDirection(dimensions) {
    let direction = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : RecycleScrollerDirection.VERTICAL;
    return computed(()=>dimensions.value.map((dimension)=>getDimensionSizeForDirection(dimension, direction)));
}
