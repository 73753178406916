import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
import InfoCountdown from 'web/src/modules/sportline/submodules/event-details/components/info-countdown/InfoCountdown.vue';
import HeadlineTeam from './components/HeadlineTeam.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PreMatchInfo',
    props: {
        sportlineEvent: {},
        backgroundColor: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const competitors = computed(()=>sportlineEvent.value?.competitors ?? []);
        const hasLogos = computed(()=>!!competitors.value[0]?.logo && !!competitors.value[1]?.logo);
        const { date, time, kickoff } = useSportlineEventDateTime({
            sportlineEvent
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    _ctx.$style['headline-info'],
                    _ctx.$style['headline-info--prematch']
                ])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['headline-info__container'])
                }, [
                    _createVNode(HeadlineTeam, {
                        position: "center",
                        role: "host",
                        name: competitors.value[0]?.name,
                        logo: hasLogos.value ? competitors.value[0]?.logo : void 0,
                        "logo-meta": hasLogos.value ? competitors.value[0]?.logoMeta : void 0
                    }, null, 8, [
                        "name",
                        "logo",
                        "logo-meta"
                    ]),
                    _createElementVNode("div", null, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['headline-info__date'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['headline-info__day'])
                            }, _toDisplayString(_unref(date)), 3),
                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['headline-info__time'])
                            }, _toDisplayString(_unref(time)), 3)
                        ], 2),
                        _createVNode(InfoCountdown, {
                            class: _normalizeClass(_ctx.$style['headline-info__countdown']),
                            kickoff: _unref(kickoff)
                        }, null, 8, [
                            "class",
                            "kickoff"
                        ]),
                        _renderSlot(_ctx.$slots, "primary-market")
                    ]),
                    _createVNode(HeadlineTeam, {
                        position: "center",
                        role: "guest",
                        name: competitors.value[1]?.name,
                        logo: hasLogos.value ? competitors.value[1]?.logo : void 0,
                        "logo-meta": hasLogos.value ? competitors.value[1]?.logoMeta : void 0
                    }, null, 8, [
                        "name",
                        "logo",
                        "logo-meta"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PreMatchInfo'
                ]
            ]);
        };
    }
});
