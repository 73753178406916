import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { toRef, computed } from 'vue';
import { useInfoSlideType } from 'web/src/modules/sportline/submodules/event-details/composables/headline';
import { HeadlineSlideType } from 'web/src/modules/sportline/submodules/event-details/enums';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';
import OutrightInfo from './default/wide/OutrightInfo.vue';
import LiveMatchInfo from './default/wide/LiveMatchInfo.vue';
import PreMatchInfo from './default/wide/PreMatchInfo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WideInfo',
    props: {
        sportlineEvent: {},
        backgroundColor: {}
    },
    setup (__props) {
        const props = __props;
        const sportlineEvent = toRef(props, 'sportlineEvent');
        const backgroundColor = toRef(props, 'backgroundColor', null);
        const sport = computed(()=>sportlineEvent.value.sport);
        const { infoSlideType } = useInfoSlideType({
            sportlineEvent
        });
        return (_ctx, _cache)=>_unref(infoSlideType) === _unref(HeadlineSlideType).OutrightInfo ? (_openBlock(), _createBlock(OutrightInfo, {
                key: 0,
                "sportline-event": sportlineEvent.value,
                "background-color": backgroundColor.value
            }, _createSlots({
                _: 2
            }, [
                _ctx.$slots['primary-market'] ? {
                    name: "primary-market",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "primary-market")
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "sportline-event",
                "background-color"
            ])) : _unref(infoSlideType) === _unref(HeadlineSlideType).LiveInfo && _unref(isLiveSportlineEvent)(sportlineEvent.value) ? (_openBlock(), _createBlock(LiveMatchInfo, {
                key: 1,
                "sportline-event": sportlineEvent.value,
                sport: sport.value,
                "background-color": backgroundColor.value
            }, _createSlots({
                _: 2
            }, [
                _ctx.$slots['primary-market'] ? {
                    name: "primary-market",
                    fn: _withCtx((param)=>{
                        let { hasTimeline } = param;
                        return [
                            _renderSlot(_ctx.$slots, "primary-market", {
                                hasTimeline: hasTimeline
                            })
                        ];
                    }),
                    key: "0"
                } : void 0
            ]), 1032, [
                "sportline-event",
                "sport",
                "background-color"
            ])) : _unref(infoSlideType) === _unref(HeadlineSlideType).PreMatchInfo ? (_openBlock(), _createBlock(PreMatchInfo, {
                key: 2,
                "sportline-event": sportlineEvent.value,
                "background-color": backgroundColor.value
            }, _createSlots({
                _: 2
            }, [
                _ctx.$slots['primary-market'] ? {
                    name: "primary-market",
                    fn: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "primary-market", {
                                hasCountdown: true
                            })
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "sportline-event",
                "background-color"
            ])) : _createCommentVNode("", true);
    }
});
