import { ref, computed } from 'vue';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { splitScoreValue } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export function useSportEventMatchStatistic(props) {
    const { match, sportFamily } = props;
    // @TODO compare with current open details
    const isLive = ref(false);
    const host = computed(()=>match.value.teams[0] || null);
    const guest = computed(()=>match.value.teams[1] || null);
    const date = computed(()=>{
        const timestamp = match.value.matchDate;
        return timestamp ? DateTime.formatTimeStamp(timestamp, TimeFormats.dayMonthShortYear) : '';
    });
    const isHostWinner = computed(()=>!!host.value && host.value.type === match.value.winner);
    const isGuestWinner = computed(()=>!!guest.value && guest.value.type === match.value.winner);
    const resultScore = computed(()=>{
        if (sportFamily.value === SportFamily.Tennis) {
            const totalSetsScoreValue = (match.value.resultScores || []).find((scores)=>scores.type === BetlineStatisticScoresType.Sets);
            if (totalSetsScoreValue) return splitScoreValue(totalSetsScoreValue.value, '0:0');
        }
        // fallback to default result score value
        return splitScoreValue(match.value.resultScore, '0:0');
    });
    const setsScore = computed(()=>{
        if (sportFamily.value === SportFamily.Tennis) {
            const resultScores = (match.value.resultScores || []).filter((scores)=>scores.type !== BetlineStatisticScoresType.Sets && scores.type !== BetlineStatisticScoresType.Winner);
            return Array.from({
                length: 5
            }, (_, index)=>splitScoreValue(resultScores[index]?.value, ':'));
        }
        return [];
    });
    return {
        isLive,
        host,
        guest,
        isHostWinner,
        isGuestWinner,
        date,
        resultScore,
        setsScore
    };
}
