import { toRef } from 'vue';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import useMatchStatisticsTeams from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import { useLeagueStandings } from './composables/useLeagueStandings';
import { useH2hMatchStatistic } from './composables/useH2hMatchStatistic';
import { useLastGamesMatchStatistic } from './composables/useLastGamesMatchStatistic';
export default function useSportEventStatistic() {
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const betlineMatchStatistics = toRef(sportlineEventStatisticStore, 'betlineMatchStatistics');
    const betlineLeagueStandings = toRef(sportlineEventStatisticStore, 'betlineLeagueStandings');
    const statisticSportFamily = toRef(sportlineEventStatisticStore, 'statisticSportFamily');
    const { leagueStandings } = useLeagueStandings({
        betlineLeagueStandings
    });
    const { h2hMatchStatistic, joinMatches } = useH2hMatchStatistic({
        betlineMatchStatistics
    });
    const { hostMatches, guestMatches } = useLastGamesMatchStatistic({
        betlineMatchStatistics
    });
    const { teams } = useMatchStatisticsTeams(betlineMatchStatistics);
    return {
        leagueStandings,
        teams,
        h2hMatchStatistic,
        joinMatches,
        hostMatches,
        guestMatches,
        statisticSportFamily
    };
}
