import { computed } from 'vue';
import useMatchStatisticsTeams from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
const specialScoresTypes = [
    BetlineStatisticScoresType.HalfTime,
    BetlineStatisticScoresType.FullTime,
    BetlineStatisticScoresType.OverTime,
    BetlineStatisticScoresType.StopTime,
    BetlineStatisticScoresType.Sets,
    BetlineStatisticScoresType.AfterPenalty
];
export default function usePostMatchStatisticSimpleResultScore(props) {
    const { postMatchStatistic } = props;
    const { teams } = useMatchStatisticsTeams(postMatchStatistic);
    const resultScore = computed(()=>postMatchStatistic.value.resultScore ? postMatchStatistic.value.resultScore.split(':') : null);
    const scoresDetails = computed(()=>postMatchStatistic.value.scores.filter((score)=>!!score.type && !!score.value && !specialScoresTypes.includes(score.type)).map((score)=>(score.value || '0:0').split(':')));
    const overtimeScores = computed(()=>{
        const scoresOT = postMatchStatistic.value.scores.find((scores)=>scores.type === BetlineStatisticScoresType.OverTime);
        const scoresFT = postMatchStatistic.value.scores.find((scores)=>scores.type === BetlineStatisticScoresType.FullTime);
        if (!scoresOT || !scoresFT) return null;
        try {
            const scoresOTValue = (scoresOT.value || '0:0').split(':');
            const scoresFTValue = (scoresFT.value || '0:0').split(':');
            return [
                String(parseInt(scoresOTValue[0], 10) - parseInt(scoresFTValue[0], 10)),
                String(parseInt(scoresOTValue[1], 10) - parseInt(scoresFTValue[1], 10))
            ];
        } catch  {
            return null;
        }
    });
    return {
        teams,
        resultScore,
        scoresDetails,
        overtimeScores
    };
}
