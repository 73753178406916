import { computed, toRef } from 'vue';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import useMatchStatisticsTeams from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import { useLeagueStandings } from './composables/useLeagueStandings';
import { useH2hMatchStatistic } from './composables/useH2hMatchStatistic';
import { useLastGamesMatchStatistic } from './composables/useLastGamesMatchStatistic';
export default function useSportlineEventPostMatchStatistic() {
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const betlineMatchStatistics = toRef(sportlineEventStatisticStore, 'betlineMatchStatistics');
    const betlineLeagueStandings = toRef(sportlineEventStatisticStore, 'betlineLeagueStandings');
    const betlinePostMatchStatistics = toRef(sportlineEventStatisticStore, 'betlinePostMatchStatistics');
    const confrontationBlocks = toRef(sportlineEventStatisticStore, 'confrontationBlocks');
    const statisticSportFamily = toRef(sportlineEventStatisticStore, 'statisticSportFamily');
    const postMatchStatistic = computed(// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    ()=>betlinePostMatchStatistics.value || null);
    const { leagueStandings } = useLeagueStandings({
        betlineLeagueStandings
    });
    const { h2hMatchStatistic, joinMatches } = useH2hMatchStatistic({
        betlineMatchStatistics
    });
    const { hostMatches, guestMatches } = useLastGamesMatchStatistic({
        betlineMatchStatistics
    });
    const { teams } = useMatchStatisticsTeams(betlineMatchStatistics);
    const canDisplayH2hStatistic = computed(()=>!!teams.value && (joinMatches.value.length > 0 || !!h2hMatchStatistic.value));
    const canDisplayLastGamesResultsStatistic = computed(()=>!!teams.value && (hostMatches.value.length > 0 || guestMatches.value.length > 0));
    const canDisplayTournamentTableStatistic = computed(()=>!!teams.value && leagueStandings.value.length > 0);
    const canShowConfrontationStatistic = computed(()=>confrontationBlocks.value.length > 0);
    const canShowRightDisclaimer = computed(()=>canDisplayH2hStatistic.value || canDisplayLastGamesResultsStatistic.value || canDisplayTournamentTableStatistic.value);
    const canShowLeftDisclaimer = computed(()=>!canDisplayH2hStatistic.value && !canDisplayLastGamesResultsStatistic.value && !canDisplayTournamentTableStatistic.value && canShowConfrontationStatistic.value);
    return {
        postMatchStatistic,
        confrontationBlocks,
        statisticSportFamily,
        leagueStandings,
        teams,
        h2hMatchStatistic,
        joinMatches,
        hostMatches,
        guestMatches,
        canDisplayH2hStatistic,
        canDisplayLastGamesResultsStatistic,
        canDisplayTournamentTableStatistic,
        canShowConfrontationStatistic,
        canShowRightDisclaimer,
        canShowLeftDisclaimer
    };
}
