import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
import { activeFavoriteIcon, inactiveFavoriteIcon } from 'web/src/modules/sportline/components/SportlineToolbar/constants';
import { useLeagueFavoriteIndicator } from 'web/src/modules/sportline/submodules/favorites/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LargeLeagueToolbar',
    props: {
        region: {
            default: ''
        },
        name: {
            default: ''
        },
        leagueId: {},
        sportId: {}
    },
    emits: [
        "click-back"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isFavorite, isOnlyPending, hasFavoriteButton, toggleLeagueFavoriteState } = useLeagueFavoriteIndicator({
            sportId: toRef(props, 'sportId'),
            leagueId: toRef(props, 'leagueId')
        });
        const leagueTitle = computed(()=>[
                props.region,
                props.name
            ].filter((part)=>!!part).join(' | '));
        function onClickBack() {
            emit('click-back');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sportline-toolbar'])
            }, [
                _createVNode(VButton, {
                    class: _normalizeClass(_ctx.$style['sport-event-league-toolbar__back']),
                    "icon-name": _unref(IconName).ARROW_LEFT,
                    "icon-size": _unref(IconSize).SIZE_24,
                    kind: _unref(ButtonKind).TRANSPARENT,
                    onClick: onClickBack
                }, null, 8, [
                    "class",
                    "icon-name",
                    "icon-size",
                    "kind"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-toolbar__title-block'])
                }, [
                    leagueTitle.value ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['sportline-toolbar__title'])
                    }, _toDisplayString(leagueTitle.value), 3)) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-toolbar__right-block'])
                }, [
                    _unref(hasFavoriteButton) ? (_openBlock(), _createBlock(VFavoriteToggle, {
                        key: 0,
                        "active-icon": _unref(activeFavoriteIcon),
                        "inactive-icon": _unref(inactiveFavoriteIcon),
                        "is-favorite": _unref(isFavorite),
                        "is-button": "",
                        "is-only-pending": _unref(isOnlyPending),
                        onClick: _unref(toggleLeagueFavoriteState)
                    }, null, 8, [
                        "active-icon",
                        "inactive-icon",
                        "is-favorite",
                        "is-only-pending",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LargeLeagueToolbar'
                ]
            ]);
        };
    }
});
