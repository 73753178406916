export default function getImageSourceFromRawImageData(imageData) {
    if (!imageData || !imageData.src) return null;
    return {
        src: imageData.src,
        x1: imageData.x1 || void 0,
        x2: imageData.x2 || void 0,
        x3: imageData.x3 || void 0,
        x1webp: imageData.x1webp || void 0,
        x2webp: imageData.x2webp || void 0,
        x3webp: imageData.x3webp || void 0
    };
}
