/**
 * @see https://github.com/olahol/scrollparent.js
 * @see https://github.com/Akryum/vue-virtual-scroller/blob/next/packages/vue-virtual-scroller/src/scrollparent.js
 * @see node_modules/vue-datepicker-next/index.es.js
 */ function getStyle(value, prop) {
    return getComputedStyle(value, null).getPropertyValue(prop);
}
function isScrollable(node) {
    const regex = /(auto|scroll)/;
    return regex.test(getStyle(node, 'overflow') + getStyle(node, 'overflow-y') + getStyle(node, 'overflow-x'));
}
export function getScrollParent(node) {
    let until = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : document.body;
    if (!node || node === until) return null;
    return isScrollable(node) ? node : getScrollParent(node.parentElement, until);
}
export function getScrollTarget(node) {
    return getScrollParent(node, document.body) ?? document.scrollingElement ?? document.documentElement;
}
export function getListenerTarget(node) {
    let target = getScrollTarget(node);
    // Fix global scroll target for Chrome and Safari
    if (document && (target === document.documentElement || target === document.body)) target = window;
    return target;
}
