import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import DefaultPostMatchStatisticHeadline from './default/DefaultPostMatchStatisticHeadline.vue';
import BetSwipePostMatchStatisticHeadline from './bet-swipe/BetSwipePostMatchStatisticHeadline.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchStatisticHeadline',
    props: {
        postMatchStatistic: {}
    },
    setup (__props) {
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        return (_ctx, _cache)=>(_openBlock(), _createBlock(DefaultPostMatchStatisticHeadline, {
                key: 1,
                "post-match-statistic": _ctx.postMatchStatistic
            }, null, 8, [
                "post-match-statistic"
            ]));
    }
});
