import { toRef } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { SportEventMatchPhase } from 'web/src/modules/sportline/enums';
import { MetrikaEvents, MetrikaCategory } from 'web/src/modules/sportline/submodules/event-details/enums';
export function getVisitPagePayload(param) {
    let { sportlineEvent, language } = param;
    // @TODO check analytics for live outright
    if (sportlineEvent.isOutright) return null;
    const { sport } = sportlineEvent;
    return {
        eventId: sportlineEvent.id,
        home: sportlineEvent.competitors[0]?.name || '',
        homeLogo: sportlineEvent.competitors[0]?.logo,
        away: sportlineEvent.competitors[1]?.name || '',
        awayLogo: sportlineEvent.competitors[1]?.logo,
        scheduled: new Date(sportlineEvent.kickoff).toISOString(),
        status: sportlineEvent.matchPhase,
        tournamentName: sportlineEvent.league.name,
        sport: sport.family,
        markets: sportlineEvent.markets,
        homeShort: void 0,
        awayShort: void 0,
        oddsHome: void 0,
        oddsDraw: void 0,
        oddsAway: void 0,
        language
    };
}
export function getVisitStatisticsPagePayload(param) {
    let { statistics, language } = param;
    if (!statistics || !language) return null;
    const { id: eventId, teams, matchDate, family: sport } = statistics;
    const home = teams[0]?.name;
    const homeLogo = teams[0]?.logo ?? void 0;
    const away = teams[1]?.name;
    const awayLogo = teams[1]?.logo ?? void 0;
    if (!eventId || !home || !away || !matchDate || !sport) return null;
    return {
        eventId,
        home,
        homeLogo,
        away,
        awayLogo,
        scheduled: new Date(matchDate).toISOString(),
        status: SportEventMatchPhase.PostGame,
        tournamentName: '',
        sport,
        markets: [],
        homeShort: void 0,
        awayShort: void 0,
        oddsHome: void 0,
        oddsDraw: void 0,
        oddsAway: void 0,
        language
    };
}
export function useSportlineEventDetailsAnalytics() {
    if (process.env.VUE_APP_PRERENDER) return {
        sendVisitPageAnalytics () {},
        sendVisitStatisticsPageAnalytics () {}
    };
    const analytics = useAnalytics();
    const lang = toRef(useI18nStore(), 'lang');
    function sendVisitPageAnalytics(sportlineEvent) {
        if (!sportlineEvent) return;
        const payload = getVisitPagePayload({
            sportlineEvent,
            language: lang.value
        });
        if (payload) analytics.visitSportEvent(payload);
    }
    function sendVisitStatisticsPageAnalytics(statistics) {
        const payload = getVisitStatisticsPagePayload({
            statistics,
            language: lang.value
        });
        if (payload) analytics.visitSportEvent(payload);
    }
    return {
        sendVisitPageAnalytics,
        sendVisitStatisticsPageAnalytics
    };
}
export function useSportlineEventDetailsHeadlineAnalytics() {
    const analytics = useAnalytics();
    function sendSwiperNavigationAction(payload) {
        analytics.push(AnalyticsEvent.USER_ACTIONS, {
            [MetrikaCategory.SWIPER_NAVIGATION]: payload
        });
    }
    function sendButtonsNavigationAction(payload) {
        analytics.push(AnalyticsEvent.USER_ACTIONS, {
            [MetrikaCategory.BUTTONS_NAVIGATION]: payload
        });
    }
    function sendCarouselEvent() {
        let event = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : MetrikaEvents.EVENT_KNOBS;
        sendSwiperNavigationAction({
            [MetrikaEvents.EVENTS_CAROUSEL]: event,
            [MetrikaEvents.EVENTS_CHANGE_SLIDE]: event
        });
    }
    function sendChangeSlideEvent() {
        let event = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : MetrikaEvents.EVENT_KNOBS;
        sendSwiperNavigationAction({
            [MetrikaEvents.EVENTS_CHANGE_SLIDE]: event
        });
    }
    function sendAvailableButtonsNavigation(type) {
        sendButtonsNavigationAction({
            [MetrikaEvents.EVENTS_IS_AVAILABLE]: type
        });
    }
    function sendSelectButtonsNavigation(type) {
        sendButtonsNavigationAction({
            [MetrikaEvents.EVENTS_CLICKS]: type
        });
    }
    return {
        sendCarouselEvent,
        sendChangeSlideEvent,
        sendAvailableButtonsNavigation,
        sendSelectButtonsNavigation
    };
}
