import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import FilterTransition from 'web/src/modules/sportline/components/FilterTransition/FilterTransition.vue';
import SportsListFilter from 'web/src/modules/sportline/submodules/sports/components/Filter/SportlineSportsFilter.vue';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useSportsRoutePage } from './composables/useSportsRoutePage';
import { useSportsListFilterTransition } from './composables/useSportsListFilterTransition';
import { useSportsDateTimeFilter } from './composables/useSportsDateTimeFilter';
import { useSportsRoutePageMetaInfo } from './composables/useSportsRoutePageMetaInfo';
import SportsFavoritesTab from './default/SportsFavoritesTab.vue';
import SportsSportTab from './default/SportsSportTab.vue';
import SportsZeroMarginTab from './default/SportsZeroMarginTab.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SportsRoutePage',
    setup (__props) {
        useSportsRoutePageMetaInfo();
        const { listTransitionRef, activeTransitionId, lastShownSportFamily, isFilterTransitionFadeEffectEnabled, filterTransitionDuration, onChangeTransitionId } = useSportsListFilterTransition();
        const { dateTimeFilterRef, isSportFiltersEnabled, datetimeActiveKickoffPeriod, datetimeActiveSportlineType, datetimeCustomFrom, datetimeCustomTo, onDateTimeFilterSelect, onDateTimeFilterSelectCustomRange } = useSportsDateTimeFilter();
        const { listFilterRef, isZeroMarginEventsTabAvailable, isReadyToDisplayContent, doShowLoadingIndicator, hasFavoritesOptimistic, isPageEmpty, fullTabsList, selectedSportFilter, selectedSportFilterOptions, isChildRoute, reloadListForFilter, redirectFromTab, onChangeSelectedSportFilter } = useSportsRoutePage({
            activeTransitionId,
            lastShownSportFamily,
            listTransitionRef
        });
        useKeepAlive();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(FilterTransition, {
                    ref_key: "listTransitionRef",
                    ref: listTransitionRef,
                    key: "sports-list-page-content",
                    class: _normalizeClass(_ctx.$style['prematch']),
                    disabled: !_unref(isFilterTransitionFadeEffectEnabled),
                    "fade-in-duration": _unref(filterTransitionDuration).fadeIn,
                    "fade-out-duration": _unref(filterTransitionDuration).fadeOut,
                    onChangeId: _unref(onChangeTransitionId)
                }, {
                    filter: _withCtx((param)=>{
                        let { duration, afterEnter, afterLeave, beforeEnter, beforeLeave } = param;
                        return [
                            _unref(isPageEmpty) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(SportsListFilter, {
                                key: 0,
                                ref_key: "listFilterRef",
                                ref: listFilterRef,
                                filter: _unref(selectedSportFilter),
                                options: _unref(selectedSportFilterOptions),
                                "is-favorites-tab-enabled": _unref(hasFavoritesOptimistic),
                                "is-zero-margin-tab-enabled": _unref(isZeroMarginEventsTabAvailable),
                                onChangeFilter: _unref(onChangeSelectedSportFilter)
                            }, {
                                afterItem: _withCtx((param)=>{
                                    let { item } = param;
                                    return [
                                        _createVNode(_Transition, {
                                            name: "fade",
                                            duration: duration,
                                            onBeforeLeave: ($event)=>beforeLeave(item.key),
                                            onAfterLeave: afterLeave,
                                            onBeforeEnter: ($event)=>beforeEnter(item.key),
                                            onAfterEnter: afterEnter
                                        }, {
                                            default: _withCtx(()=>[
                                                    item.isActive ? (_openBlock(), _createElementBlock("div", {
                                                        key: item.key
                                                    })) : _createCommentVNode("", true)
                                                ]),
                                            _: 2
                                        }, 1032, [
                                            "duration",
                                            "onBeforeLeave",
                                            "onAfterLeave",
                                            "onBeforeEnter",
                                            "onAfterEnter"
                                        ])
                                    ];
                                }),
                                _: 2
                            }, 1032, [
                                "filter",
                                "options",
                                "is-favorites-tab-enabled",
                                "is-zero-margin-tab-enabled",
                                "onChangeFilter"
                            ])),
                            !_unref(isPageEmpty) && _unref(isSportFiltersEnabled) ? (_openBlock(), _createBlock(DateTimeFilter, {
                                key: 1,
                                ref_key: "dateTimeFilterRef",
                                ref: dateTimeFilterRef,
                                class: _normalizeClass(_ctx.$style['prematch__date-filter']),
                                "active-kickoff-period": _unref(datetimeActiveKickoffPeriod),
                                "active-sportline-type": _unref(datetimeActiveSportlineType),
                                "selected-range-from": _unref(datetimeCustomFrom),
                                "selected-range-to": _unref(datetimeCustomTo),
                                "tabs-type": _unref(TabsType).BORDERED,
                                onSelectFilter: _unref(onDateTimeFilterSelect),
                                onSelectRange: _unref(onDateTimeFilterSelectCustomRange)
                            }, null, 8, [
                                "class",
                                "active-kickoff-period",
                                "active-sportline-type",
                                "selected-range-from",
                                "selected-range-to",
                                "tabs-type",
                                "onSelectFilter",
                                "onSelectRange"
                            ])) : _createCommentVNode("", true)
                        ];
                    }),
                    content: _withCtx((param)=>{
                        let { isFadeOut, extraStyle } = param;
                        return [
                            _withDirectives(_createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['prematch__list']),
                                style: _normalizeStyle(extraStyle)
                            }, [
                                !_unref(isReadyToDisplayContent) || _unref(doShowLoadingIndicator) ? (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                                    key: 0
                                })) : _createCommentVNode("", true),
                                _unref(isReadyToDisplayContent) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fullTabsList), (tab)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                                            key: tab.key,
                                            class: _normalizeClass({
                                                'group--shown': true
                                            })
                                        }, [
                                            tab.key === _unref(CustomFilter).Favorites ? (_openBlock(), _createBlock(SportsFavoritesTab, {
                                                key: 0,
                                                "is-active": tab.isActive,
                                                onResetFilter: _cache[0] || (_cache[0] = ($event)=>_unref(reloadListForFilter)(null)),
                                                onRedirectFromTab: _unref(redirectFromTab)
                                            }, null, 8, [
                                                "is-active",
                                                "onRedirectFromTab"
                                            ])) : tab.key === _unref(CustomFilter).ZeroMargin ? (_openBlock(), _createBlock(SportsZeroMarginTab, {
                                                key: 1,
                                                "is-active": tab.isActive,
                                                onResetFilter: _cache[1] || (_cache[1] = ($event)=>_unref(reloadListForFilter)(null)),
                                                onRedirectFromTab: _unref(redirectFromTab)
                                            }, null, 8, [
                                                "is-active",
                                                "onRedirectFromTab"
                                            ])) : (_openBlock(), _createBlock(SportsSportTab, {
                                                key: 2,
                                                "is-active": tab.isActive,
                                                "sport-id": tab.key,
                                                "is-child-route": _unref(isChildRoute),
                                                onResetFilter: _cache[2] || (_cache[2] = ($event)=>_unref(reloadListForFilter)(null)),
                                                onRedirectFromTab: _unref(redirectFromTab)
                                            }, null, 8, [
                                                "is-active",
                                                "sport-id",
                                                "is-child-route",
                                                "onRedirectFromTab"
                                            ]))
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'sports-tab',
                                                    shown: true
                                                }
                                            ]
                                        ])), 128)),
                                    _unref(isPageEmpty) ? (_openBlock(), _createBlock(SportNoResults, {
                                        key: 0,
                                        "sportline-is-empty": ""
                                    })) : _createCommentVNode("", true)
                                ], 64)) : _createCommentVNode("", true)
                            ], 6), [
                                [
                                    _vShow,
                                    !isFadeOut
                                ]
                            ])
                        ];
                    }),
                    _: 1
                }, 8, [
                    "class",
                    "disabled",
                    "fade-in-duration",
                    "fade-out-duration",
                    "onChangeId"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SportsRoutePage'
                ]
            ]);
        };
    }
});
