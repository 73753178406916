import { computed } from 'vue';
import { splitScoreValue } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export function useLeaguePlayoffMatchesBlock(props) {
    const { block } = props;
    const matchScores = computed(()=>block.value.matches.map((match)=>{
            const score = splitScoreValue(match.resultScore);
            return match.teams.reduce((result, team, index)=>{
                if (!team.id) return result;
                return {
                    ...result,
                    [team.id]: score[index] || ''
                };
            }, {});
        }).filter((scores)=>Object.values(scores).some((score)=>!!score)));
    const participants = computed(()=>(block.value.participants || []).map((participant)=>({
                ...participant
            })));
    const matchesHistory = computed(()=>(block.value.matches || []).map((match)=>({
                date: match.matchDate ? DateTime.formatTimeStamp(match.matchDate, TimeFormats.dayMonthShortYear) : '',
                names: match.teams.map((team)=>(team.name || '').slice(0, 3)),
                scores: splitScoreValue(match.resultScore)
            })));
    return {
        matchScores,
        matchesHistory,
        participants
    };
}
