import { toRef } from 'vue';
import { useSportlineEventId } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
export function useSportlineEventStream() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isFloatingModeEnabled = toRef(()=>sportlineSettingsStore.isLiveStreamWidgetFloatingEnabled);
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportlineEvent = toRef(()=>sportlineEventDetailsStore.sportEventDetails);
    const isStreamAvailable = toRef(()=>sportlineEventDetailsStore.isStreamAvailable);
    const isStreamPreviewOnly = toRef(()=>sportlineEventDetailsStore.isStreamPreviewOnly);
    const doShowStreamPreviewInfo = toRef(()=>sportlineEventDetailsStore.doShowStreamPreviewInfo);
    const streamUrl = toRef(()=>sportlineEventDetailsStore.streamUrl);
    const { reloadStream, onStreamStarted, releaseStream } = sportlineEventDetailsStore;
    const sportlineEventId = useSportlineEventId(sportlineEvent);
    return {
        isFloatingModeEnabled,
        isStreamAvailable,
        isStreamPreviewOnly,
        doShowStreamPreviewInfo,
        streamUrl,
        sportlineEventId,
        reloadStream,
        onStreamStarted,
        releaseStream
    };
}
