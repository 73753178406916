import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = [
    "alt",
    "src"
];
import { computed, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { SportIcon } from '@components/sport-icon';
import VCountryFlag from 'web/src/components/CountryFlag/VCountryFlag/VCountryFlag.vue';
import { mapIconToFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/utils';
import { getCyberSportIconName } from 'web/src/modules/sportline/utils/theme';
import { useRegionLogoProps } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FilledSportlineLogo',
    props: {
        sportIconName: {},
        fillColor: {},
        countryCode: {},
        sportSegmentId: {},
        size: {},
        flagSize: {},
        src: {},
        alt: {},
        borderlessFlag: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const size = toRef(props, 'size');
        const flagSize = toRef(props, 'flagSize');
        const finalFlagSize = computed(()=>flagSize.value ?? mapIconToFlagSize(size.value));
        const { countryCode, isCyberSport } = useRegionLogoProps(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.src ? _withDirectives((_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: _ctx.alt,
                src: _ctx.src,
                class: _normalizeClass(_ctx.$style['filled-sportline-logo-image'])
            }, null, 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'FilledSportlineLogo'
                ]
            ]) : _unref(isCyberSport) && _unref(countryCode) ? (_openBlock(), _createBlock(_unref(VIcon), {
                key: 1,
                name: _unref(getCyberSportIconName)({
                    icon: _unref(countryCode)
                }),
                class: _normalizeClass(_ctx.$style['filled-sportline-logo-icon'])
            }, null, 8, [
                "name",
                "class"
            ])) : _unref(countryCode) ? (_openBlock(), _createBlock(VCountryFlag, {
                key: 2,
                class: _normalizeClass({
                    [_ctx.$style['filled-sportline-logo-icon']]: true,
                    [_ctx.$style['filled-sportline-logo-flag']]: true,
                    [_ctx.$style['filled-sportline-logo-flag--with-border']]: !_ctx.borderlessFlag
                }),
                size: finalFlagSize.value,
                code: _unref(countryCode),
                style: _normalizeStyle({
                    fill: _ctx.fillColor
                })
            }, null, 8, [
                "class",
                "size",
                "code",
                "style"
            ])) : _ctx.sportIconName ? (_openBlock(), _createBlock(_unref(SportIcon), {
                key: 3,
                class: _normalizeClass(_ctx.$style['filled-sportline-logo-icon']),
                size: size.value,
                "sport-name": _ctx.sportIconName,
                style: _normalizeStyle({
                    fill: _ctx.fillColor
                })
            }, null, 8, [
                "class",
                "size",
                "sport-name",
                "style"
            ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                key: 4,
                class: _normalizeClass(_ctx.$style['filled-sportline-logo-icon']),
                name: _unref(IconName).BRAND_LOGO,
                size: size.value,
                style: _normalizeStyle({
                    fill: _ctx.fillColor
                })
            }, null, 8, [
                "class",
                "name",
                "size",
                "style"
            ]));
        };
    }
});
