import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "component-wrapper"
};
const _hoisted_2 = {
    key: 3
};
import { onMounted, onUnmounted, onActivated, onDeactivated } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import RegionBreadcrumbs from 'web/src/modules/sportline/submodules/region/views/RegionBreadcrumbs.vue';
import RegionTitleBar from 'web/src/modules/sportline/submodules/region/components/RegionTitleBar.vue';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useRegionRoutePage } from './useRegionRoutePage';
import DefaultEventsList from './default/RegionPageEventsList.vue';
import BetSwipeEventsList from './bet-swipe/RegionPageEventsList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegionRoutePage',
    setup (__props) {
        const { isBetSwipeNavigationLayoutEnabled, isBetSwipeRegionLayoutEnabled } = useSportlineLayoutSettings();
        const { isReady, isLoadingIndicatorShown, isMarketTypesSelectionEnabled, isContainEvents, sportElementsList, sport, region, outrightEvents, metaParameters, activatePageHook, deactivatePageHook } = useRegionRoutePage();
        useSeoMetaParameters(metaParameters);
        onMounted(()=>activatePageHook(true));
        onActivated(()=>activatePageHook(false));
        onUnmounted(()=>deactivatePageHook(true));
        onDeactivated(()=>deactivatePageHook(false));
        useKeepAlive([
            RouteName.HOME,
            RouteName.SPORTLINE_LIVE_EVENTS,
            RouteName.SPORTLINE_PREMATCH_EVENTS,
            RouteName.SPORT_REGION,
            RouteName.SPORT_LEAGUE,
            RouteName.SPORT_EVENT_DETAILS,
            RouteName.SPORTLINE_CYBERSPORT
        ]);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['sport-event-region']]: true,
                        [_ctx.$style['sport-event-region--extra-paddings']]: void 0
                    })
                }, [
                    !_unref(isReady) || _unref(isLoadingIndicatorShown) ? (_openBlock(), _createBlock(_unref(VLoaderMaskedDelayed), {
                        key: 0
                    })) : _createCommentVNode("", true),
                    _unref(isReady) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            (_openBlock(), _createBlock(RegionBreadcrumbs, {
                                key: 0,
                                "default-region-title": _unref(region)?.name
                            }, null, 8, [
                                "default-region-title"
                            ]))
                        ], 64)),
                        _unref(isContainEvents) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            (_openBlock(), _createBlock(DefaultEventsList, {
                                key: 1,
                                "sport-elements-list": _unref(sportElementsList),
                                "outright-events": _unref(outrightEvents),
                                "is-market-types-selection-enabled": _unref(isMarketTypesSelectionEnabled)
                            }, null, 8, [
                                "sport-elements-list",
                                "outright-events",
                                "is-market-types-selection-enabled"
                            ]))
                        ], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['no-events-content'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['no-events-content__label'])
                                }, _toDisplayString(_ctx.$t('JS_EVENTS_NOT_FOUND')), 3)
                            ], 2)
                        ]))
                    ], 64)) : _createCommentVNode("", true)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'RegionRoutePage'
                ]
            ]);
        };
    }
});
