import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { useBaseSportEventDetailsHeadline } from 'web/src/modules/sportline/submodules/event-details/composables/headline';
import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import TrackerWidget from 'web/src/modules/sportline/submodules/widgets/views/TrackerWidget.vue';
import SportlineEventStream from 'web/src/modules/sportline/submodules/event-details/views/widgets/SportlineEventStream.vue';
import WideHeadlineLayout from 'web/src/modules/sportline/submodules/event-details/views/headline-layout/WideHeadlineLayout.vue';
import WideInfo from 'web/src/modules/sportline/submodules/event-details/views/headline-info/WideInfo.vue';
import FloatingWidgetBox from 'web/src/modules/sportline/submodules/widgets/components/FloatingWidgetBox.vue';
import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import PrimaryMarketName from 'web/src/modules/sportline/views/primary-market/PrimaryMarketName.vue';
import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';
import { useHeadlineDimensionExpose, useSportlineEventDetailsHeadlineData } from './composables';
import { useWideSportlineEventDetailsHeadline } from './useWideSportlineEventDetailsHeadline';
import PinWidgetSwitcher from '../../components/widget-switcher/PinWidgetSwitcher.vue';
import HorizontalBetSwipeInfo from './bet-swipe/HorizontalBetSwipeInfo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WideSportlineEventDetailsHeadline',
    setup (__props, param) {
        let { expose: __expose } = param;
        const { isLight } = useTheme();
        const { sportlineEvent, sportElement, sport, region, league, isStreamAvailable, widgetConfig, liveWidgetType, defaultSwiperSlideId, onHeadlineSlideChanged } = useSportlineEventDetailsHeadlineData();
        const { activeSlideId, isLiveWidgetAvailable, widgetProperties, switcherSlidesIds } = useBaseSportEventDetailsHeadline({
            defaultSlideId: defaultSwiperSlideId,
            sportEventDetails: sportlineEvent,
            isStreamAvailable,
            widgetConfig,
            liveWidgetType
        });
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        const { primaryMarket } = useSportlineEventMarketsPriority({
            sportEvent: sportlineEvent,
            isPrimaryMarketFiltrationDisabled: computed(()=>false)
        });
        const { isFloatingModeEnabled, isLiveWidgetShown, isStreamShown } = useWideSportlineEventDetailsHeadline({
            activeSlideId,
            isStreamAvailable,
            isLiveWidgetAvailable,
            widgetConfig,
            doForceShowInplayComponent: computed(()=>true)
        });
        const { headlineElement, getPosition, getHeight } = useHeadlineDimensionExpose();
        __expose({
            getPosition,
            getHeight
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(sportlineEvent) && _unref(sport) && _unref(region) && _unref(league) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "headlineElement",
                ref: headlineElement,
                class: _normalizeClass({
                    [_ctx.$style['sport-event-details-headline']]: true,
                    [_ctx.$style['sport-event-details-headline--light']]: _unref(isLight)
                })
            }, [
                (_openBlock(), _createBlock(WideHeadlineLayout, {
                    key: 1,
                    "segment-id": _unref(sport).segment.id,
                    "sport-event-details": _unref(sportlineEvent),
                    "has-inplay-component": _unref(isStreamAvailable) || _unref(isLiveWidgetAvailable),
                    "has-primary-market": !!_unref(primaryMarket)
                }, {
                    info: _withCtx((param)=>{
                        let { backgroundColor } = param;
                        return [
                            _createVNode(WideInfo, {
                                "sportline-event": _unref(sportlineEvent),
                                "background-color": backgroundColor
                            }, _createSlots({
                                _: 2
                            }, [
                                _unref(primaryMarket) ? {
                                    name: "primary-market",
                                    fn: _withCtx((param)=>{
                                        let { hasTimeline, hasCountdown } = param;
                                        return [
                                            _createVNode(PrimaryMarket, {
                                                market: _unref(primaryMarket),
                                                "sport-element": _unref(sportElement),
                                                "sport-event-details": _unref(sportlineEvent)
                                            }, _createSlots({
                                                top: _withCtx((topScope)=>[
                                                        _createVNode(PrimaryMarketName, {
                                                            class: _normalizeClass({
                                                                [_ctx.$style['primary-market__name--after-timeline']]: hasTimeline,
                                                                [_ctx.$style['primary-market__name--after-countdown']]: hasCountdown
                                                            }),
                                                            label: topScope.marketName
                                                        }, null, 8, [
                                                            "class",
                                                            "label"
                                                        ])
                                                    ]),
                                                _: 2
                                            }, [
                                                _unref(sportlineEvent).hasZeroMargin ? {
                                                    name: "bottom",
                                                    fn: _withCtx(()=>[
                                                            _createVNode(HighestOddsBadge, {
                                                                "highest-odds-icon-color": "highlight",
                                                                "badge-background": backgroundColor ? 'dark' : 'default',
                                                                "badge-height": "16"
                                                            }, null, 8, [
                                                                "badge-background"
                                                            ])
                                                        ]),
                                                    key: "0"
                                                } : void 0
                                            ]), 1032, [
                                                "market",
                                                "sport-element",
                                                "sport-event-details"
                                            ])
                                        ];
                                    }),
                                    key: "0"
                                } : void 0
                            ]), 1032, [
                                "sportline-event",
                                "background-color"
                            ])
                        ];
                    }),
                    "inplay-component": _withCtx((param)=>{
                        let { setHeight, backgroundColor } = param;
                        return [
                            _createVNode(FloatingWidgetBox, {
                                "is-floating-mode-enabled": _unref(isFloatingModeEnabled),
                                "is-full-size": ""
                            }, {
                                default: _withCtx(()=>[
                                        _unref(isStreamAvailable) && _unref(isLiveWidgetAvailable) ? (_openBlock(), _createBlock(PinWidgetSwitcher, {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['sport-event-details-headline__widget-button']),
                                            "active-slide-id": _unref(activeSlideId),
                                            "slide-ids": _unref(switcherSlidesIds),
                                            "onUpdate:activeSlideId": _unref(onHeadlineSlideChanged)
                                        }, null, 8, [
                                            "class",
                                            "active-slide-id",
                                            "slide-ids",
                                            "onUpdate:activeSlideId"
                                        ])) : _createCommentVNode("", true),
                                        _unref(isStreamAvailable) ? _withDirectives((_openBlock(), _createBlock(SportlineEventStream, {
                                            key: 1,
                                            "is-active": _unref(isStreamShown),
                                            "is-floating-mode-allowed": false,
                                            onSizeChanged: ($event)=>setHeight?.($event.height)
                                        }, null, 8, [
                                            "is-active",
                                            "onSizeChanged"
                                        ])), [
                                            [
                                                _vShow,
                                                _unref(isStreamShown)
                                            ]
                                        ]) : _createCommentVNode("", true),
                                        _unref(widgetProperties) ? (_openBlock(), _createBlock(TrackerWidget, {
                                            key: 2,
                                            widget: _unref(widgetProperties),
                                            "is-active": _unref(isLiveWidgetShown),
                                            "is-floating-mode-allowed": false,
                                            "background-color": backgroundColor,
                                            onSizeChanged: ($event)=>setHeight?.($event.height)
                                        }, null, 8, [
                                            "widget",
                                            "is-active",
                                            "background-color",
                                            "onSizeChanged"
                                        ])) : _createCommentVNode("", true)
                                    ]),
                                _: 2
                            }, 1032, [
                                "is-floating-mode-enabled"
                            ])
                        ];
                    }),
                    _: 1
                }, 8, [
                    "segment-id",
                    "sport-event-details",
                    "has-inplay-component",
                    "has-primary-market"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'WideSportlineEventDetailsHeadline'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
