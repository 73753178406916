import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0
};
import { computed, ref, toRef } from 'vue';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RelatedEventsDropdown',
    props: {
        offsetTop: {}
    },
    emits: [
        "opened",
        "closed"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        toRef(props, 'offsetTop', 0);
        const popper = ref();
        const wrapper = ref();
        const isOpened = ref(false);
        const offset = ref(-8);
        const popperModifiers = computed(()=>[
                {
                    name: 'offset',
                    options: {
                        offset: [
                            0,
                            offset.value
                        ]
                    }
                }
            ]);
        function hide() {
            popper.value?.hide();
        }
        function onShow() {
            isOpened.value = true;
            emit('opened');
        }
        function onHide() {
            isOpened.value = false;
            emit('closed');
        }
        __expose({
            hide
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass(_ctx.$style['sport-event-details-dropdown'])
            }, [
                _createVNode(VPopper, {
                    ref_key: "popper",
                    ref: popper,
                    trigger: "clickToToggle",
                    position: _unref(PopperPosition).BOTTOM,
                    modifiers: popperModifiers.value,
                    "is-full-width": "",
                    "is-full-height": "",
                    "has-offset": false,
                    onShow: onShow,
                    onHide: onHide
                }, {
                    reference: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "reference", {
                                opened: isOpened.value
                            }, ()=>[
                                    _cache[0] || (_cache[0] = _createElementVNode("span", null, null, -1))
                                ])
                        ]),
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['sport-event-details-dropdown__content'])
                            }, [
                                _createVNode(_Transition, {
                                    name: "fade"
                                }, {
                                    default: _withCtx(()=>[
                                            _withDirectives(_createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['sport-event-details-dropdown__background']),
                                                onClick: hide
                                            }, null, 2), [
                                                [
                                                    _vShow,
                                                    isOpened.value
                                                ]
                                            ])
                                        ]),
                                    _: 1
                                }),
                                _createVNode(_Transition, {
                                    name: "slide"
                                }, {
                                    default: _withCtx(()=>[
                                            isOpened.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                                _renderSlot(_ctx.$slots, "default")
                                            ])) : _createCommentVNode("", true)
                                        ]),
                                    _: 3
                                })
                            ], 2)
                        ]),
                    _: 3
                }, 8, [
                    "position",
                    "modifiers"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'RelatedEventsDropdown'
                ]
            ]);
        };
    }
});
