import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { isString } from '@leon-hub/guards';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SportlineNavigationItemType, SportlineNavigationItemCustomStyle } from 'web/src/modules/sportline/enums/navigation';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { isSportFamilyEquals, isSportIdEquals } from 'web/src/modules/sportline/utils';
import { resolveOptionLocation } from './utils';
/**
 * @TODO review input options
 */ export function useSportlineSportsFilter(props, emit) {
    const { options, isFavoritesTabEnabled, isZeroMarginTabEnabled, filter } = props;
    const { $translate } = useI18n();
    function isSportIdSelected(sport) {
        return isSportIdEquals(sport, filter.value?.sportId) || isSportFamilyEquals(sport, filter.value?.sportFamily);
    }
    function emitChangeFilter(sport) {
        const currentFilter = filter.value || {};
        const newFilter = !sport || isString(sport) ? {
            ...currentFilter,
            sportId: sport || void 0,
            sportFamily: void 0
        } : {
            ...currentFilter,
            sportId: sport.id,
            sportFamily: sport.navigationParameters.urlName
        };
        emit.changeFilter(newFilter);
    }
    const favoriteFilterItem = computed(()=>{
        if (!isFavoritesTabEnabled.value) return null;
        const isActive = isSportIdSelected(CustomFilter.Favorites);
        const to = resolveOptionLocation(CustomFilter.Favorites);
        const icon = {
            type: SportlineNavigationItemType.Icon,
            props: {
                name: IconName.STAR_ALT
            }
        };
        return {
            key: CustomFilter.Favorites,
            icon,
            title: $translate('WEB2_SPORT_EVENTS_FILTER_FAVORITE').value,
            isActive,
            customFilter: CustomFilter.Favorites,
            to,
            onClick: ()=>emitChangeFilter(CustomFilter.Favorites),
            dataTest: CustomFilter.Favorites,
            transitionId: CustomFilter.Favorites
        };
    });
    const zeroMarginFilterItem = computed(()=>{
        if (!isZeroMarginTabEnabled.value) return null;
        const isActive = isSportIdSelected(CustomFilter.ZeroMargin);
        const to = resolveOptionLocation(CustomFilter.ZeroMargin);
        const icon = {
            type: SportlineNavigationItemType.Icon,
            props: {
                name: IconName.SPORTLINE_HIGHEST_ODDS_TABS
            }
        };
        return {
            key: CustomFilter.ZeroMargin,
            icon,
            title: $translate('WEB2_SPORT_EVENTS_FILTER_HIGHEST_ODDS').value,
            isActive,
            customFilter: CustomFilter.ZeroMargin,
            customStyle: SportlineNavigationItemCustomStyle.HighestOdds,
            to,
            onClick: ()=>emitChangeFilter(CustomFilter.ZeroMargin),
            dataTest: CustomFilter.ZeroMargin,
            transitionId: CustomFilter.ZeroMargin
        };
    });
    const items = computed(()=>{
        const list = [];
        if (favoriteFilterItem.value) list.push(favoriteFilterItem.value);
        if (zeroMarginFilterItem.value) list.push(zeroMarginFilterItem.value);
        for (const item of options.value.sports){
            const isActive = isSportIdSelected(item.sport);
            const to = resolveOptionLocation(item);
            const icon = {
                type: SportlineNavigationItemType.SportIcon,
                props: {
                    sportName: item.sport.representation.family,
                    size: IconSize.SIZE_16
                }
            };
            const filterItem = {
                key: item.sport.id,
                icon,
                title: item.sport.name,
                isActive,
                to,
                onClick: ()=>emitChangeFilter(item.sport),
                dataTest: item.sport.name,
                transitionId: item.sport.navigationParameters.urlName
            };
            list.push(filterItem);
        }
        return list;
    });
    return {
        items
    };
}
