import { computed } from 'vue';
import useMatchStatisticsTeams from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import useSwipeByDragAndDropComposable from 'web/src/components/SwipeByDragAndDrop/mixins/useSwipeByDragAndDropComposable';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { filterEmptyMark, marksApproximation, parseCardToMark, parseGoalToMark, sortMarksByAsc, splitMarksByTimes } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import { getWholeMatchTimeForSportFamily } from 'web/src/modules/sportline/utils/timeline';
export default function usePostMatchStatisticTimeline(props) {
    const { swipeByDnDonMouseDown } = useSwipeByDragAndDropComposable();
    const { teams } = useMatchStatisticsTeams(props.postMatchStatistic);
    const times = computed(()=>{
        const sportFamily = SportFamily.Soccer;
        const wholeMatchTime = getWholeMatchTimeForSportFamily(sportFamily);
        if (!wholeMatchTime) return [];
        const cardMarks = props.postMatchStatistic.value.cards.map((card)=>parseCardToMark(card, {
                sportFamily
            })).filter(filterEmptyMark);
        const goalsMarks = props.postMatchStatistic.value.goals.map((goal)=>parseGoalToMark(goal, {
                sportFamily
            })).filter(filterEmptyMark);
        return splitMarksByTimes(sortMarksByAsc([
            ...cardMarks,
            ...goalsMarks
        ]), props.postMatchStatistic.value.scores).map((time)=>({
                ...time,
                marks: marksApproximation(time.marks, {
                    minDistance: 5,
                    minPosition: 2,
                    maxPosition: 98
                })
            }));
    });
    function onMouseDown(event) {
        swipeByDnDonMouseDown(event);
    }
    return {
        teams,
        times,
        onMouseDown
    };
}
