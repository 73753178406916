import { ref, toRef, computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { framedVirtualSportIdBySport } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/constants';
import { isVirtualSportTournament } from 'web/src/modules/sportline/submodules/virtual-sport/guards';
export function useVirtualSportRoutePage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const virtualSportStore = useVirtualSportStore();
    const widgetConfig = toRef(virtualSportStore, 'widgetConfig');
    const selectedFilter = toRef(virtualSportStore, 'selectedFilter');
    const filterOptions = toRef(virtualSportStore, 'filterOptions');
    const selectedOption = toRef(virtualSportStore, 'selectedOption');
    const selectedBetsMappings = toRef(virtualSportStore, 'selectedBetsMappings');
    const internalWidgetLoaded = ref(false);
    const genericTitle = $translate('JSP_VIRTUAL_SPORTS_TTL');
    const title = computed(()=>{
        const selectedSport = selectedOption.value?.sport;
        const framedVirtualSportId = selectedSport && framedVirtualSportIdBySport[selectedSport];
        const displayConfig = isVirtualSportTournament(framedVirtualSportId) ? selectedOption.value?.displayConfig : void 0;
        return displayConfig?.label ? `${genericTitle.value} - ${displayConfig.label}` : genericTitle.value;
    });
    function onChangeSelectedSportFilter(filter) {
        const sport = filter.urlName?.toLowerCase();
        if (sport !== selectedFilter.value.urlName?.toLowerCase()) internalWidgetLoaded.value = false;
        router.push302({
            name: RouteName.SPORTLINE_VIRTUAL_SPORT,
            params: {
                sport
            }
        });
    }
    function onWidgetLoaded(value) {
        internalWidgetLoaded.value = value;
    }
    return {
        title,
        internalWidgetLoaded,
        widgetConfig,
        selectedFilter,
        filterOptions,
        selectedOption,
        selectedBetsMappings,
        onChangeSelectedSportFilter,
        onWidgetLoaded
    };
}
