import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useActiveBreadcrumb, useSportlineBreadcrumbsSportItem, useSportlineBreadcrumbsRegionItem, useSportlineBreadcrumbsLeagueItem, useSportlineBreadcrumbsMetaParameters } from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import SportBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/SportBreadcrumbsItem.vue';
import RegionBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RegionBreadcrumbsItem.vue';
import LeagueBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/LeagueBreadcrumbsItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeagueBreadcrumbs',
    props: {
        defaultRegionTitle: {},
        defaultLeagueTitle: {}
    },
    setup (__props) {
        const { activeId, toggleId } = useActiveBreadcrumb();
        const { sportTreeElement, title: sportItemTitle, isShown: isSportItemShown, pageLocation: sportPageLocation, sportRepresentationFamily } = useSportlineBreadcrumbsSportItem({});
        const { regionElement, relatedRegions, title: regionItemTitle, isShown: isRegionItemShown, pageLocation: regionPageLocation } = useSportlineBreadcrumbsRegionItem({});
        const { leagueElement, relatedLeagues, title: leagueItemTitle, isShown: isLeagueItemShown, pageLocation: leaguePageLocation } = useSportlineBreadcrumbsLeagueItem({});
        useSportlineBreadcrumbsMetaParameters({
            sportElement: sportTreeElement,
            sportPageLocation,
            regionElement,
            regionPageLocation,
            leagueElement,
            leaguePageLocation
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sportline-breadcrumbs__inner'])
                }, [
                    _createElementVNode("ol", {
                        class: _normalizeClass(_ctx.$style['sportline-breadcrumbs__content'])
                    }, [
                        _unref(isSportItemShown) ? (_openBlock(), _createBlock(SportBreadcrumbsItem, {
                            key: _unref(sportTreeElement) ? _unref(sportTreeElement).sport.id : 'unselectedSport',
                            title: _unref(sportItemTitle),
                            to: _unref(sportPageLocation),
                            position: 1,
                            "sport-family": _unref(sportRepresentationFamily),
                            "is-menu": ""
                        }, null, 8, [
                            "title",
                            "to",
                            "sport-family"
                        ])) : _createCommentVNode("", true),
                        _unref(isRegionItemShown) ? (_openBlock(), _createBlock(RegionBreadcrumbsItem, {
                            key: _unref(regionElement)?.region.id ?? 'unselectedRegion',
                            "active-region-id": _unref(regionElement)?.region.id,
                            "related-regions": _unref(relatedRegions),
                            title: _ctx.defaultRegionTitle || _unref(regionItemTitle),
                            to: _unref(regionPageLocation),
                            position: 2,
                            open: _unref(activeId) === _unref(BreadcrumbId).Regions,
                            onToggle: _unref(toggleId)
                        }, null, 8, [
                            "active-region-id",
                            "related-regions",
                            "title",
                            "to",
                            "open",
                            "onToggle"
                        ])) : _createCommentVNode("", true),
                        _unref(isLeagueItemShown) ? (_openBlock(), _createBlock(LeagueBreadcrumbsItem, {
                            key: _unref(leagueElement) ? _unref(leagueElement).league.id : 'unselectedLeague',
                            "league-element": _unref(leagueElement),
                            "related-leagues": _unref(relatedLeagues),
                            title: _ctx.defaultLeagueTitle || _unref(leagueItemTitle),
                            to: _unref(leaguePageLocation),
                            position: 3,
                            open: _unref(activeId) === _unref(BreadcrumbId).Leagues,
                            onToggle: _unref(toggleId)
                        }, null, 8, [
                            "league-element",
                            "related-leagues",
                            "title",
                            "to",
                            "open",
                            "onToggle"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'LeagueBreadcrumbs'
                ]
            ]);
        };
    }
});
