import { watch } from 'vue';
import { findListByDimensionDiff } from '../utils/position-correction';
export function useWatchForFixStartPosition(itemsByDirection, visibleItemsState, scrollBy) {
    let last;
    watch(itemsByDirection, (value, oldValue)=>{
        const diff = findListByDimensionDiff(value, oldValue, visibleItemsState.value.end, last);
        last = visibleItemsState.value.end;
        if (!diff) return;
        scrollBy(diff);
    }, {
        immediate: true
    });
}
