import { createVisibleItemsOffset, createVisibleItemsViewCapacity, createVisibleItemsStartIndex, createVisibleItemsEndIndex, createVisibleItemsState } from '../utils/visible-items';
/**
 * Visible list start and end calculation
 */ export function useVisibleItemsState(scrollOffset, dimensionSizes, overscan, containerSize, sourceLength) {
    const visibleItemsOffset = createVisibleItemsOffset(scrollOffset, dimensionSizes);
    const visibleItemsStartIndex = createVisibleItemsStartIndex(visibleItemsOffset.index, overscan);
    const visibleItemsViewCapacity = createVisibleItemsViewCapacity(scrollOffset, containerSize, visibleItemsStartIndex, dimensionSizes);
    const visibleItemsEndIndex = createVisibleItemsEndIndex(visibleItemsOffset.index, visibleItemsViewCapacity, overscan, sourceLength);
    return {
        visibleItemsOffset,
        visibleItemsState: createVisibleItemsState(visibleItemsStartIndex, visibleItemsEndIndex)
    };
}
