import { toRef, computed, ref } from 'vue';
import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useWindowResize } from '@leon-hub/browser-composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { LayoutSizes } from 'web/src/modules/core/enums';
/**
 * Specific breadcrumbs data for details page
 */ export function useSportlineEventBreadcrumbs() {
    const eventsBus = useEventsBus();
    const { $translate } = useI18n();
    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportEventDetails = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const rawLeagueEventsList = toRef(sportlineEventDetailsStore, 'leagueEventsList');
    const isSidebarOpen = ref(false);
    const sportEventDetailsId = computed(()=>sportEventDetails.value?.id);
    const leagueEventsList = computed(()=>rawLeagueEventsList.value.filter((param)=>{
            let { sportEvent } = param;
            return sportEvent.id !== sportEventDetailsId.value;
        }));
    const isShown = computed(()=>!!sportEventDetails.value || leagueEventsList.value.length > 0);
    const title = computed(()=>sportEventDetails.value ? sportEventDetails.value.name : $translate('JSP_EVENTS_SIMILAR').value);
    useWindowResize(()=>{
        if (document.documentElement.clientWidth > +LayoutSizes.ShowLeftSidebarBreakpoint) isSidebarOpen.value = false;
    }, {
        debounce: 100
    });
    useBusSafeSubscribe(BusEvent.SIDE_MENU_TOGGLE, ()=>{
        isSidebarOpen.value = !isSidebarOpen.value;
    });
    return {
        isShown,
        title,
        sportEventDetails,
        sportEventDetailsId,
        leagueEventsList,
        isSidebarOpen,
        softCloseSidebar () {
            if (!isSidebarOpen.value) return;
            eventsBus.emit(BusEvent.SIDE_MENU_SOFT_CLOSE, {});
        },
        toggleSidebar () {
            eventsBus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
        }
    };
}
