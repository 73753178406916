import { useNavigationBackButtonStore } from 'web/src/modules/core/store/useNavigationBackButtonStore';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
export function useRegionNavigationBackButton() {
    const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();
    function activateBackButton(to) {
        setNavigationBackLocationGetter(()=>resolveSportsPageLink({
                urlName: to.sportFamily
            }));
    }
    return {
        activateBackButton
    };
}
