import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createCommentVNode as _createCommentVNode } from "vue";
import { toRef } from 'vue';
import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticChartBar from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import MatchesStatisticCompetitors from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchesStatisticCompetitors.vue';
import { useJoinMatchesStatistic } from './useJoinMatchesStatistic';
export default /*@__PURE__*/ _defineComponent({
    __name: 'JoinMatchesStatistic',
    props: {
        playStatistic: {},
        teams: {}
    },
    setup (__props) {
        const props = __props;
        const playStatistic = toRef(props, 'playStatistic', null);
        const { hostGoalsLabel, hostWinsLabel, guestGoalsLabel, guestWinsLabel, totalMatchesLabel, totalDrawsLabel } = useJoinMatchesStatistic({
            playStatistic
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['join-matches-statistic'])
            }, [
                _createVNode(MatchesStatisticCompetitors, {
                    teams: _ctx.teams
                }, null, 8, [
                    "teams"
                ]),
                playStatistic.value ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['join-matches-statistic-goals__container'],
                            _ctx.$style['statistic-chart-labels']
                        ])
                    }, [
                        _withDirectives((_openBlock(), _createElementBlock("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-goals__label'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(hostGoalsLabel)), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'join-matches-statistic__total__goals-home'
                                }
                            ]
                        ]),
                        _withDirectives((_openBlock(), _createElementBlock("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-goals__label'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(totalMatchesLabel)), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'join-matches-statistic__total__matches'
                                }
                            ]
                        ]),
                        _withDirectives((_openBlock(), _createElementBlock("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-goals__label'])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(guestGoalsLabel)), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'join-matches-statistic__total__goals-away'
                                }
                            ]
                        ])
                    ], 2),
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.$style['join-matches-statistic-graph__container'])
                    }, [
                        _createVNode(StatisticChartBar, {
                            type: _unref(StatisticChartBarType).LABELED,
                            "to-fixed": 1,
                            "home-percentage": playStatistic.value.host.winsPercentage,
                            "draw-percentage": playStatistic.value.drawsPercentage,
                            "away-percentage": playStatistic.value.guest.winsPercentage
                        }, null, 8, [
                            "type",
                            "home-percentage",
                            "draw-percentage",
                            "away-percentage"
                        ])
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'join-matches-statistic__graph'
                            }
                        ]
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['join-matches-statistic-wins__container'],
                            _ctx.$style['statistic-chart-labels']
                        ])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-wins__label'])
                        }, [
                            _withDirectives(_createElementVNode("span", {
                                class: _normalizeClass([
                                    _ctx.$style['statistic-chart-label-indicator'],
                                    _ctx.$style['statistic-chart-label-indicator--home']
                                ])
                            }, null, 2), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'join-matches-statistic__total__wins-home'
                                    }
                                ]
                            ]),
                            _createTextVNode(" " + _toDisplayString(_unref(hostWinsLabel)), 1)
                        ], 2),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-wins__label'])
                        }, [
                            _withDirectives(_createElementVNode("span", {
                                class: _normalizeClass([
                                    _ctx.$style['statistic-chart-label-indicator'],
                                    _ctx.$style['statistic-chart-label-indicator--draw']
                                ])
                            }, null, 2), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'join-matches-statistic__total__draws'
                                    }
                                ]
                            ]),
                            _createTextVNode(" " + _toDisplayString(_unref(totalDrawsLabel)), 1)
                        ], 2),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['join-matches-statistic-wins__label'])
                        }, [
                            _withDirectives(_createElementVNode("span", {
                                class: _normalizeClass([
                                    _ctx.$style['statistic-chart-label-indicator'],
                                    _ctx.$style['statistic-chart-label-indicator--away']
                                ])
                            }, null, 2), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'join-matches-statistic__total__wins-away'
                                    }
                                ]
                            ]),
                            _createTextVNode(" " + _toDisplayString(_unref(guestWinsLabel)), 1)
                        ], 2)
                    ], 2)
                ], 64)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'JoinMatchesStatistic'
                ]
            ]);
        };
    }
});
