import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeadlineTeamScore',
    props: {
        score: {},
        role: {},
        innings: {
            type: Boolean
        },
        size: {
            default: '24'
        },
        gap: {
            default: '0'
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", {
                class: _normalizeClass({
                    [_ctx.$style['headline-team-score']]: true,
                    [_ctx.$style[`headline-team-score--size-${_ctx.size}`]]: true,
                    [_ctx.$style[`headline-team-score--${_ctx.role}`]]: true,
                    [_ctx.$style[`headline-team-score--gap-16-${_ctx.role}`]]: '16' === _ctx.gap,
                    [_ctx.$style['active']]: !!_ctx.innings
                })
            }, [
                _createTextVNode(_toDisplayString(_ctx.score), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'HeadlineTeamScore'
                ]
            ]);
        };
    }
});
