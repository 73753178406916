import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
import { toRef } from 'vue';
import { FilledLogoBackgroundSize } from 'web/src/modules/sportline/components/SportlineLogo/enums';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import PageContentHeadlineLayout from 'web/src/modules/sportline/components/PageContentHeadlineLayout/PageContentHeadlineLayout.vue';
import FilledSportlineLogoWithBackground from 'web/src/modules/sportline/components/SportlineLogo/FilledSportlineLogoWithBackground.vue';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import LeagueProgressBar from 'web/src/modules/sportline/submodules/league/components/LeagueProgressBar/LeagueProgressBar.vue';
import LeaguePageHeadlineInfoLayout from 'web/src/modules/sportline/submodules/league/components/Headline/default/LeaguePageHeadlineInfoLayout.vue';
import { useLeaguePageHeadline } from '../composables/useLeaguePageHeadline';
import { useLeaguePageHeadlineData } from '../composables/useLeaguePageHeadlineData';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LeaguePageHeadline',
    props: {
        hasMatches: {
            type: Boolean
        },
        hasPromotions: {
            type: Boolean
        },
        hasStatistic: {
            type: Boolean
        },
        activeSectionId: {}
    },
    emits: [
        "active-section-id-switched"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const hasMatches = toRef(props, 'hasMatches', false);
        const hasPromotions = toRef(props, 'hasPromotions', false);
        const hasStatistic = toRef(props, 'hasStatistic', false);
        const activeSectionId = toRef(props, 'activeSectionId', LeaguePageSectionId.EventsList);
        const { sport, region, league, leagueData, leagueRepresentationData } = useLeaguePageHeadlineData();
        const { backgroundColor, backgroundImage, switcherOptions, switcherProperties, title, subtitle, logoUrl, sportIconName, hasTimeline } = useLeaguePageHeadline({
            sport,
            league,
            leagueData,
            leagueRepresentationData,
            hasMatches,
            hasPromotions,
            hasStatistic,
            activeSectionId
        });
        function onTabSwitched(id) {
            emit('active-section-id-switched', {
                id
            });
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(PageContentHeadlineLayout, {
                class: _normalizeClass(_ctx.$style['headline-layout']),
                "background-color": _unref(backgroundColor),
                "background-image": _unref(backgroundImage)
            }, {
                content: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['headline-layout__content'])
                        }, [
                            _unref(switcherOptions).length > 1 ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['headline-layout-tabs'])
                            }, [
                                _withDirectives(_createVNode(VSwitcher, _mergeProps(_unref(switcherProperties), {
                                    "active-id": activeSectionId.value,
                                    options: _unref(switcherOptions),
                                    onChange: onTabSwitched
                                }), null, 16, [
                                    "active-id",
                                    "options"
                                ]), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'league-page-headline-slide-switcher'
                                        }
                                    ]
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _createVNode(LeaguePageHeadlineInfoLayout, {
                                title: _unref(title),
                                subtitle: _unref(subtitle)
                            }, _createSlots({
                                _: 2
                            }, [
                                _unref(logoUrl) || _unref(sportIconName) ? {
                                    name: "logo",
                                    fn: _withCtx(()=>[
                                            _createVNode(FilledSportlineLogoWithBackground, {
                                                class: _normalizeClass(_ctx.$style['headline-layout__logo']),
                                                src: _unref(logoUrl),
                                                alt: _unref(title),
                                                "country-code": _unref(region)?.representation.icon,
                                                "sport-segment-id": _unref(sport)?.segment.id,
                                                "sport-icon-name": _unref(sportIconName),
                                                "fill-color": _unref(backgroundColor),
                                                height: _unref(FilledLogoBackgroundSize).FULL_SIZE,
                                                "borderless-flag": ""
                                            }, null, 8, [
                                                "class",
                                                "src",
                                                "alt",
                                                "country-code",
                                                "sport-segment-id",
                                                "sport-icon-name",
                                                "fill-color",
                                                "height"
                                            ])
                                        ]),
                                    key: "0"
                                } : void 0,
                                _unref(hasTimeline) ? {
                                    name: "progress",
                                    fn: _withCtx(()=>[
                                            _createVNode(LeagueProgressBar, {
                                                "current-season-start": _unref(leagueData) ? _unref(leagueData).currentSeasonStart : null,
                                                "current-season-end": _unref(leagueData) ? _unref(leagueData).currentSeasonEnd : null,
                                                "background-color": _unref(backgroundColor)
                                            }, null, 8, [
                                                "current-season-start",
                                                "current-season-end",
                                                "background-color"
                                            ])
                                        ]),
                                    key: "1"
                                } : void 0
                            ]), 1032, [
                                "title",
                                "subtitle"
                            ])
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class",
                "background-color",
                "background-image"
            ])), [
                [
                    _directive_auto_id,
                    'LeaguePageHeadline'
                ]
            ]);
        };
    }
});
