import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
export function usePrerenderSportsPageNavigation(props) {
    const { router, to } = props;
    const sportlineSportsStore = useSportlineSportsStore();
    const isZeroMarginEventsTabSelected = toRef(sportlineSportsStore, 'isZeroMarginEventsTabSelected');
    const isZeroMarginEventsTabAvailable = toRef(sportlineSportsStore, 'isZeroMarginEventsTabAvailable');
    const actualSportElement = toRef(sportlineSportsStore, 'actualSportElement');
    async function proceedRedirects(next) {
        await Promise.resolve();
        // redirects from zero margin events empty page
        if (isZeroMarginEventsTabSelected.value) {
            if (!isZeroMarginEventsTabAvailable.value) {
                next(router.resolve302location({
                    name: RouteName.HOME
                }));
                return;
            }
        } else // redirects if sport family is correct but have not got sport after load state
        if (!actualSportElement.value) {
            const navigationParameters = getSportPageNavigationParameters(to);
            // do redirect to bets only if we have a selected sportFamily
            if (navigationParameters.sportFamily) {
                next(router.resolve302location({
                    name: RouteName.SPORTLINE_PREMATCH_EVENTS
                }));
                return;
            }
        }
        next();
    }
    return {
        proceedRedirects
    };
}
